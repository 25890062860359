import React from "react";
import classNames from "classnames";
import { Translate, withLocalize } from "react-localize-redux";
import { formatMoney, getASArray, getBonus, getPlural } from "../../../../helpers/Utils";
import { Fragment } from "react";
import useLocalize from "../../../../helpers/use-localize";
import { isLotteryMultiPickX } from "../../../../helpers/GameUtils";


const LotteryPayout = ({ currentGame }) => {
  const { translate } = useLocalize();

  const getGamePlaySizeBonus = () => {
    if (currentGame.gameRule == 'oneDrawGameBonusV2') {
      return 1;
    } else {
      return currentGame.gamePlaySize2nd;
    }
  };

  const getGameTypeString = (rankNumber) => {
    switch (rankNumber) {
      case 1: return translate("game.payout.straight")
      case 2: return translate("game.payout.rumble")
      case 3: return translate("game.payout.lucky")
      default: return "none"
    }
  };


  const sortMultiPayout = (payoutValues) => {

    const sortedPayouts = payoutValues
      .filter(p => p.rankPrize != 0)
      .sort((a, b) => {
        if (a.rankNumber !== b.rankNumber) {
          return a.rankNumber - b.rankNumber;
        }
        return b.prizePercentage - a.prizePercentage;
      })
      .reduce((acc, payout) => {
        const rankKey = payout.rankNumber === 1 ? 'straight' : payout.rankNumber === 2 ? 'rumble' : 'lucky';
        acc[rankKey].push(payout);
        return acc;
      }, { lucky: [], straight: [], rumble: [] });


    const finalSortedPayouts = [
      ...sortedPayouts.lucky.sort((a, b) => b.prizePercentage - a.prizePercentage),
      ...sortedPayouts.straight.sort((a, b) => b.prizePercentage - a.prizePercentage),
      ...sortedPayouts.rumble.sort((a, b) => b.prizePercentage - a.prizePercentage)
    ];

    return finalSortedPayouts;
  };

  const getSortedPayoutValues = (payoutValues) => {
    if (!payoutValues.length) {
      return [];
    }

    let filteredPayoutValues = payoutValues.filter(p => p.rankPrize != 0);

    if (isLotteryMultiPickX(currentGame)) {
      return sortMultiPayout(filteredPayoutValues);

    } else {
      return filteredPayoutValues
        .sort((a, b) =>
          ((currentGame.gamePlaySize - a.rankNumber + 1) > (currentGame.gamePlaySize - b.rankNumber + 1)) ?
            -1 :
            ((currentGame.gamePlaySize - b.rankNumber + 1) > (currentGame.gamePlaySize - a.rankNumber + 1) ? 1 : 0))
        .sort((a, b) => (a.rankPrize > b.rankPrize) ? -1 : ((b.rankPrize > a.rankPrize) ? 1 : 0))

    }
  };

  const getBallLabel = (currentGame, p) => {
    const ballCount = currentGame.gamePlaySize - p.rankNumber2nd + 1;
    const firstRank = ballCount + "/" + currentGame.gamePlaySize;
    return firstRank
  };

  const getMultiPickLabel = (currentGame, p) => {
    return `${getGameTypeString(p.rankNumber)} | ${translate("game.crossword.cardpick.match")} ${getBallLabel(currentGame, p)}`
  };

  return (
    <div className="row py-3 px-2">
      <div className={classNames("col-md-8")}>
        <div className="text-center">
          <div className="text-bigger-50 text-light font-weight-bold pb-2">
            <Translate id="payout.estimated_payout" defaultMessage="Estimated Payout" />&nbsp;
            <br className="payout-ranks-break" />
            {currentGame.gameLobby && currentGame.gameLobby.gamePayoutInfo &&
              (<Translate id="payout.ranks_paid" data={{ count: getASArray(currentGame.gameLobby.gamePayoutInfo).filter(p => p.rankPrize != 0).length }} defaultMessage="{count} Ranks Paid" />)}
          </div>

          {currentGame.gameLobby && getASArray(currentGame.gameLobby.gamePayoutInfo) &&
            <div className="table-responsive payout-list-container">
              <table className="w-100 bg-white text-dark">
                <thead className="bg-dark text-light text-bigger-20">
                  <tr>
                    <th className="px-2"><Translate id="payout.balls_matched" defaultMessage="Balls Matched" /></th>
                    <th className="px-2"><Translate id="payout.payout" defaultMessage="Payout" /></th>
                  </tr>
                </thead>


                <tbody>
                  {currentGame?.gameLobby?.gamePayoutInfo &&
                    getSortedPayoutValues(getASArray(currentGame.gameLobby.gamePayoutInfo))
                      .map(function (p, i) {
                        return (
                          <tr key={i} className={classNames({ "bg-white": i % 2 == 0, "bg-light": i % 2 != 0 }, "text-bigger-20")}>
                            <td className="px-2 text-left">
                              {/* Multi Pick Label */}
                              {isLotteryMultiPickX(currentGame) && getMultiPickLabel(currentGame, p)}

                              {/* Other lottery types Label */}
                              {!isLotteryMultiPickX(currentGame) && p.rankNumber != 0 &&
                                <span>
                                  {currentGame.gamePlaySize - p.rankNumber + 1} {currentGame.gamePlaySize - p.rankNumber + 1 > 1 ? translate("general.balls") : translate("general.ball")}
                                  {p.rankNumber2nd != 0 &&
                                    <Fragment> {(getGamePlaySizeBonus() - p.rankNumber2nd + 1) == 0 ?

                                      `${translate("general.no_bonus")}` :

                                      (getGamePlaySizeBonus() - p.rankNumber2nd + 1) > 1 ?

                                        `+ ${getGamePlaySizeBonus() - p.rankNumber2nd + 1} ${translate("general.bonus")}` :

                                        `+ 1 ${translate("general.bonus")}`}</Fragment>}
                                </span>}

                              {p.rankNumber == 0 && p.rankNumber2nd != 0 &&
                                <span>{(getGamePlaySizeBonus() - p.rankNumber2nd + 1) == 0 ? translate("general.no_bonus") : (getGamePlaySizeBonus() - p.rankNumber2nd + 1) > 1 ? `${getGamePlaySizeBonus() - p.rankNumber2nd + 1} + ${translate("general.bonus")}` : translate("general.bonus")}</span>}
                            </td>
                            <td className="px-2 text-left">{formatMoney(p.rankPrize, currentGame.payoutCurrencyId)}</td>
                          </tr>
                        )
                      })}
                </tbody>
              </table>
            </div>}
        </div>
        <div className="w-100 text-smaller-75 text-light">

        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex justify-content-center">
          <img src="./static/img/smiley_face.png" className="img-fluid" />
        </div>
      </div>
    </div>
  )
}

export default LotteryPayout;
