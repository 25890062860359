import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function mapStateToProps(state, ownProps) {
  return {
    loggedIn: state.lottorace.loggedIn && state.lottorace.init,
    connected: state.lottorace.connected,
    connectRetries: state.lottorace.connectRetries,
    configuration: state.lottorace.session.configuration
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    navigate: (route) => ownProps.history.push(route),
  };
}

class MaintenancePage extends Component {
  static propTypes /* remove-proptypes */ = {
    navigate: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool,
    connected: PropTypes.bool,
    connectRetries: PropTypes.number,
    configuration: PropTypes.object
  };

  componentDidMount() {
    if (this.props.configuration && this.props.configuration.MAINTENANCE_ENABLED == 'false' && this.props.connected) {
      console.log('connected, redirecting to main page');
      //this.props.navigate('/');
      window.location.href = window.location.href.split('#')[0];
    } else {
      trackPageview("maintenance");
      trackEvent({
        eventName: "maintenance_init", eventDescription: {
          configuration: this.props.configuration != null,
          connected: this.props.connected,
          maintenance: this.props.configuration?.MAINTENANCE_ENABLED
        }
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.connected && !this.props.connected) {
      trackEvent({ eventName: "maintenance_reconnect", eventDescription: {} });
      window.location.href = window.location.href.split('#')[0];
    } else
      if (nextProps.configuration && nextProps.configuration.MAINTENANCE_ENABLED == 'false' && !this.props.configuration) {
        trackEvent({ eventName: "maintenance_reconnect", eventDescription: "configuration loaded, redirecting to main page" });
        //this.props.navigate('/');
        window.location.href = window.location.href.split('#')[0];
      }
    if (this.props.configuration && this.props.configuration.MAINTENANCE_ENABLED == 'false' && this.props.connected) {
      trackEvent({ eventName: "maintenance_reconnect", eventDescription: "configuration present, redirecting to main page" });
      //this.props.navigate('/');
      window.location.href = window.location.href.split('#')[0];
    }
  }

  render() {
    return (
      <section className="jumbotron text-light">
        <div className="container">
          <div id="mainContent" className="rounded">
            <div className="row">
              {this.props.configuration && this.props.configuration.MAINTENANCE_ENABLED == 'true' && !this.props.connected &&
                <div className="col-12 p-4">
                  <h3 className="">
                    <FontAwesomeIcon icon={"wifi-slash"} className="mr-2" /><Translate id="maintenance1.title" data={{ site: siteName }} />
                  </h3>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <p><Translate id="maintenance1.body1" /></p>
                      <p><Translate id="maintenance1.body2" /></p>
                    </div>
                    <div className="col-12 col-sm-6 text-center">
                      <img className="img-fluid" src="/static/img/main/lottery_online_platform.png" />
                    </div>
                  </div>
                </div>}
              {this.props.configuration && this.props.configuration.MAINTENANCE_ENABLED == 'true' && this.props.connected &&
                <div className="col-12 p-4">
                  <h3 className="">
                    <FontAwesomeIcon icon={"wifi-slash"} className="mr-2" /><Translate id="maintenance1.title" data={{ site: this.props.configuration.siteName }} />
                  </h3>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <p><Translate id="maintenance1.body1" /></p>
                      <p><Translate id="maintenance1.body2" /></p>
                    </div>
                    <div className="col-12 col-sm-6 text-center">
                      <img className="img-fluid" src="/static/img/main/lottery_online_platform.png" />
                    </div>
                  </div>
                </div>}
              {this.props.configuration && this.props.configuration.MAINTENANCE_ENABLED == 'false' && !this.props.connected &&
                <div className="col-12 p-4">
                  <h3>
                    <FontAwesomeIcon icon={"wifi-slash"} className="mr-2" /><Translate id="maintenance2.title" />
                  </h3>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <p><Translate id="maintenance2.body1" /></p>
                      <div className="text-center">
                        <div className="font-weight-bold pb-2">Retrying every 30 seconds</div>
                        <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Retrying...</span>
                        </div>
                        <div className="font-weight-bold pt-2">Attempt #{this.props.connectRetries}</div>
                        <button className={"btn btn-primary btn-sm mt-2"} onClick={() => document.location.reload()}>Reload Page</button>
                      </div>
                      <p className="pt-3"><Translate id="maintenance2.body2" /></p>
                    </div>
                    <div className="col-12 col-sm-6 text-center">
                      <img className="img-fluid" src="/static/img/main/lottery_online_platform.png" />
                    </div>
                  </div>
                </div>}
              {!this.props.configuration && !this.props.connected &&
                <div className="col-12 p-4">
                  <h3>
                    <FontAwesomeIcon icon={"wifi-slash"} className="mr-2" /><Translate id="maintenance3.title" data={{ site: siteName }} />
                  </h3>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <p><Translate id="maintenance2.body1" /></p>
                      <div className="text-center">
                        <div className="font-weight-bold pb-2">Retrying every 30 seconds</div>
                        <div className="spinner-border text-light" role="status">
                          <span className="sr-only">Retrying...</span>
                        </div>
                        <div className="font-weight-bold pt-2">Attempt #{this.props.connectRetries}</div>
                        <button className={"btn btn-primary btn-sm mt-2"} onClick={() => document.location.reload()}>Reload Page</button>
                      </div>
                      <p className="pt-3"><Translate id="maintenance2.body2" /></p>
                    </div>
                    <div className="col-12 col-sm-6 text-center">
                      <img className="img-fluid" src="/static/img/main/lottery_online_platform.png" />
                    </div>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenancePage);
