import {useContext} from "react";
import { LocalizeContext } from "react-localize-redux/es/LocalizeContext";

/*
	The following properties are exposed:
	translate, languages, defaultLanguage, activeLanguage, initialize, addTranslation, addTranslationForLanguage, setActiveLanguage, renderToStaticMarkup, ignoreTranslateChildren
 */
export default function useLocalize() {
	return useContext(LocalizeContext);
}
