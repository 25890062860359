import React, {Component, Fragment } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createSelector} from "reselect";
import {getASArray} from "../helpers/Utils";
import {showOneClickBuy, showOneClickBuyInstant} from "../reducers/lottorace";
import {withRouter} from "react-router";
import {Translate} from "react-localize-redux";

const getPageById = createSelector(
  (state, ownProps) => {
      return getASArray(state.lottorace.session.configuration.customPages).find(g => g.id == ownProps.pageId)
  },
  (g) => {
    return g;
  }
);

const getPageByLink = createSelector(
  (state, ownProps) => {
    return getASArray(state.lottorace.session.configuration.customPages).find(g => g.pageLinkName == ownProps.pageLink)
  },
  (g) => {
    return g;
  }
);

function mapStateToProps(state, ownProps) {
  return {
    language: 'en_US',
    homePageOverridePageId: state.lottorace.init ? state.lottorace.session.configuration.homeOverridePageId : null,
    pageObject: state.lottorace.init ? (ownProps.pageId ? getPageById(state, ownProps) : getPageByLink(state, ownProps)) : {},
    pageId: state.lottorace.init ? (ownProps.pageId ? getPageById(state, ownProps).id : getPageByLink(state, ownProps)).id : {},
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    navigate: (route) => ownProps.history.push(route),
    showOneClickBuy: (gameId, gameInstanceId) => dispatch(showOneClickBuy(gameId, gameInstanceId)),
    showOneClickBuyInstant: (gameId, gameInstanceId) => dispatch(showOneClickBuyInstant(gameId, gameInstanceId))
  };
}

class CustomPage extends Component {
  static propTypes /* remove-proptypes */ = {
    language: PropTypes.string.isRequired,
    pageId: PropTypes.number,
    pageLink: PropTypes.string,
    homePageOverridePageId: PropTypes.number,
    navigate: PropTypes.func.isRequired,
    showOneClickBuy: PropTypes.func.isRequired,
    showOneClickBuyInstant: PropTypes.func.isRequired,
    pageObject: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      htmlContent: null
    }
  }

  componentDidMount()
  {
    this.loadContent();
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    if (this.props.pageLink != prevProps.pageLink) {
      this.loadContent();
    } else
    if (this.state.htmlContent != prevState.htmlContent) {
      this.postProcessLinks();
    }
  }

  componentWillUnmount() {

  }

  loadContent = () => {
    const fetchUrl = this.props.pageId ?
      siteUrlOverride + '/client/i18n/customPageById/' + window.mid + "/" + this.props.pageId + "/" + localStorage.getItem('language') :
      (this.props.homePageOverridePageId ?
        siteUrlOverride + '/client/i18n/customPageById/' + window.mid + "/" + this.props.homePageOverridePageId + "/" + localStorage.getItem('language') :
        siteUrlOverride + '/client/i18n/customPageByLink/' + window.mid + "/" + this.props.pageLink + "/" + localStorage.getItem('language'));

    fetch(fetchUrl)
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.text();
      }).then( (data) => {
        if (data && data.startsWith('http')) {
          window.open(data, '_blank');
          this.props.navigate('/');
        } else {
          console.log(this.props.pageObject);
          this.setState({
            htmlContent: data ? data : '<center><i>No content</i></center>',
            fullWidth: this.props.pageObject && this.props.pageObject.hasOwnProperty('pageFullWidth') && this.props.pageObject.pageFullWidth == true
          });
        }
      });
  }

  postProcessLinks = () =>
  {
    const customPageLinkOneClickBuy = document.getElementById('customPageLinkOneClickBuy');

    if (customPageLinkOneClickBuy) {
      $(".customPageLinkOneClickBuy").on('click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        const gameId = customPageLinkOneClickBuy.getAttribute('data-gameid') || null;
        const gameInstance = getASArray(window.store.getState().lottorace.session?.filteredGames).find(g => g.gameId == gameId);
        const gameInstanceId = gameInstance ? gameInstance.gameInstanceId : null;
        this.props.showOneClickBuy(gameId ? Number(gameId) : null, gameInstanceId);
      });

      const autoLaunch = customPageLinkOneClickBuy.getAttribute('data-autolaunch') || 'false';
      if (autoLaunch == 'true') {
          const gameId = customPageLinkOneClickBuy.getAttribute('data-gameid') || null;
          const gameInstance = getASArray(window.store.getState().lottorace.session?.filteredGames).find(g => g.gameId == gameId);
          const gameInstanceId = gameInstance ? gameInstance.gameInstanceId : null;
          this.props.showOneClickBuy(gameId ? Number(gameId) : null, gameInstanceId);
      }
    }

    const customPageLinkOneClickBuyInstant = document.getElementById('customPageLinkOneClickBuyInstant');

    if (customPageLinkOneClickBuyInstant) {
      $(".customPageLinkOneClickBuyInstant").on('click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        const gameId = customPageLinkOneClickBuyInstant.getAttribute('data-gameid') || null;
        const gameInstance = getASArray(window.store.getState().lottorace.session?.filteredGames).find(g => g.gameId == gameId);
        const gameInstanceId = gameInstance ? gameInstance.gameInstanceId : null;
        this.props.showOneClickBuyInstant(gameId ? Number(gameId) : null, gameInstanceId);
      });

      const autoLaunch = customPageLinkOneClickBuyInstant.getAttribute('data-autolaunch') || 'false';
      if (autoLaunch == 'true') {
        const gameId = customPageLinkOneClickBuyInstant.getAttribute('data-gameid') || null;
        const gameInstance = getASArray(window.store.getState().lottorace.session?.filteredGames).find(g => g.gameId == gameId);
        const gameInstanceId = gameInstance ? gameInstance.gameInstanceId : null;
        this.props.showOneClickBuyInstant(gameId ? Number(gameId) : null, gameInstanceId);
      }
    }
  }

  render()
  {
    const isFullWidth = this.state.fullWidth;
    const isHomePage = (this.props.pageId && this.props.homePageOverridePageId && (this.props.pageId == this.props.homePageOverridePageId)) || false;

    console.log(isFullWidth, isHomePage);

    return (
      <section className="jumbotron">

        {/* Home Page */}
        {isHomePage &&
          <Fragment>
            {!this.state.htmlContent &&
            <div className="container">
              <div className="row px-5">
                <div className="col-sm-12 text-center text-light">
                  <div className="spinner-border text-dark mb-4" role="status">
                    <span className="sr-only"><Translate id={"generic.loading"} /></span>
                  </div>
                </div>
              </div>
            </div>}
            {this.state.htmlContent &&
            <div dangerouslySetInnerHTML={{__html: this.state.htmlContent}}></div>}
          </Fragment>}

        {/* Custom Full Width Page */}
        {isFullWidth == true && isHomePage == false &&
        <div>
          {!this.state.htmlContent &&
            <div className="container">
              <div className="row">
                <div className="col-sm-12 text-center text-light">
                  <div className="spinner-border text-dark mb-4" role="status">
                    <span className="sr-only"><Translate id={"generic.loading"} /></span>
                  </div>
                </div>
              </div>
            </div>}
          {this.state.htmlContent &&
            <div dangerouslySetInnerHTML={{__html: this.state.htmlContent}}></div>}
        </div>}

        {/* Custom Page */}
        {isFullWidth == false && isHomePage == false &&
        <div className="container">
          <div id="whiteContent" className="rounded bg-white text-dark">
            <div id="close-container" className="d-flex justify-content-end">
              <button id="close-button" className="btn btn-link p-0" onClick={this.props.navigate.bind(this, '/')}>
                <FontAwesomeIcon icon={"times-circle"} size={"2x"} className={"bg-light border rounded-circle pull-right"} />
              </button>
            </div>
            <div className="row">
              <div className="col-sm-12 px-4">
                {!this.state.htmlContent &&
                <div className="row px-5">
                  <div className="col-sm-12 text-center text-light">
                    <div className="spinner-border text-dark mb-4" role="status">
                      <span className="sr-only"><Translate id={"generic.loading"} /></span>
                    </div>
                  </div>
                </div>}
                {this.state.htmlContent &&
                <div dangerouslySetInnerHTML={{__html: this.state.htmlContent}}></div>}
                <br/>
              </div>
            </div>
          </div>
        </div>}
      </section>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomPage));
