import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import { withRouter } from "react-router";
import ifvisible from 'ifvisible.js';
import Helmet from "react-helmet";
import { withLocalize, Translate } from "react-localize-redux";

import LoaderStatus from "../components/LoaderStatus";

import Backdrop from '../components/SideDrawer/Backdrop';
import ModalContainer from "../components/ModalContainer";
import { setActiveLanguage } from "react-localize-redux";
import { getASArray, isMobile, loadLocale, loadLocalLanguage, loadRemoteLanguage } from '../helpers/Utils';

import {
  pingme,
  login,
  loginWhitelabel,
  logout,
  load as loadSession,
  trackReferralCode,
  showLogin,
  showRegister,
  showPlayGame,
  updateLanguage,
  showRules,
  showPayout,
  showProcessing,
  showCancelSubscription,
  requestEmailConfirmation,
  showFullRegister as show,
  hideDepositModal,
  showLiveGames,
  showRealityCheck,
  getMainAds,
  showOneClickBuy,
  toggleAllSounds, showOneClickBuyInstant,
} from "../reducers/lottorace";

import '../styles/new/index.scss';

import routes from '../store/routerSplit';
import RulesModal from "../components/RulesModal";
import PayoutModal from "../components/PayoutModal";
import AdContainer from "../components/AdContainer";

import { decryptString } from "../helpers/Utils";
import MaintenanceMessage from "../components/MaintenanceMessage";

const queryString = require('query-string');

const LoginModal = Loadable({ loader: () => import("../components/LoginModal"), loading: () => null });
const MbobModal = Loadable({ loader: () => import("../components/MbobModal"), loading: () => null });
const LoginPhoneModal = Loadable({ loader: () => import("../components/LoginPhoneModal"), loading: () => null });
const RegisterModal = Loadable({ loader: () => import("../components/RegisterModal"), loading: () => null });
const RegisterFullModal = Loadable({ loader: () => import("../components/RegisterFullModal"), loading: () => null });
const PrizeDetailsModal = Loadable({ loader: () => import("../components/PrizeDetailsModal"), loading: () => null });
const CancelSubscriptionModal = Loadable({ loader: () => import("../components/CancelSubscriptionModal"), loading: () => null });
const DepositModal = Loadable({ loader: () => import("../components/DepositModal"), loading: () => null });
const ProcessingModal = Loadable({ loader: () => import('../components/ProcessingModal'), loading: () => null });
const PlayGameModal = Loadable({ loader: () => import('../components/PlayGameModal'), loading: () => null });
const LiveSelectGamesModal = Loadable({ loader: () => import('../components/LiveSelectGamesModal'), loading: () => null });
const ForgotPasswordModal = Loadable({ loader: () => import('../components/ForgotPasswordModal'), loading: () => null });
const ForgotPasswordConfirmationModal = Loadable({ loader: () => import('../components/ForgotPasswordConfirmationModal'), loading: () => null });
const OneClickBuyModal = Loadable({ loader: () => import('./OneClickBuyV2/OneClickBuyV2Modal'), loading: () => null });
const OneClickBuyModalInstant = Loadable({ loader: () => import('./OneClickBuy/OneClickBuyModal'), loading: () => null });
const RealityCheckModal = Loadable({ loader: () => import('../components/RealityCheckModal'), loading: () => null });
const AdModal = Loadable({ loader: () => import('../components/AdModal'), loading: () => null });
const ForcedPasswordModal = Loadable({ loader: () => import('../components/ForcedPasswordModal'), loading: () => null });
const ReconnectingModal = Loadable({ loader: () => import('../components/ReconnectingModal'), loading: () => null });

const Drawer = Loadable({
  loader: () => {
    if (window.templateName == 'bhutan')
      return import('../components/SideDrawer/SideDrawerBhutan');
    else if (window.templateName == 'hfc')
      return import('../components/SideDrawer/SideDrawerHfc');
    else
      return import('../components/SideDrawer/SideDrawer');
  },
  loading: () => null
}
);

const Footer = Loadable({
  loader: () => {
    if (window.templateName == 'scl')
      return import('../components/FooterSCL');
    else
      if (window.templateName == 'bhutan')
        return import('../components/FooterBhutan');
      else
        if (window.templateName == 'lcbo')
          return import('../components/FooterLCBO');
        else
          return import('../components/Footer');
  },
  loading: () => null
}
);

const Navigation = Loadable({
  loader: () => {
    if (window.templateName == 'scl')
      return import('../components/NavigationSCL');
    else
      if (window.templateName == 'bhutan')
        return import('../components/NavigationBhutan');
      else
        if (window.templateName == 'lcbo')
          return import('../components/NavigationLCBO');
        else
          // if (window.templateName == 'alaska')
          //   return import('../components/NavigationAlaska');
          // else
          return import('../components/Navigation');
  },
  loading: () => null
}
);

Navigation.preload();
Drawer.preload();
Footer.preload();

function mapStateToProps(state, ownProps) {
  return {
    location: ownProps.location,
    pathname: ownProps.location.pathname,
    init: state.lottorace.init,
    loggedIn: state.lottorace.loggedIn && state.lottorace.init,
    loggingIn: state.lottorace.loggingIn,
    loginBackground: state.lottorace.loginBackground,
    forceLogout: state.lottorace.forceLogout,
    loggingOut: state.lottorace.loggingOut,
    connected: state.lottorace.connected,
    session: state.lottorace.session,
    gameInstanceId: queryString.parse(ownProps.location.search).id,
    gameId: queryString.parse(ownProps.location.search).gid,
    globalFilter: queryString.parse(ownProps.location.search).f,
    continueSessionId: queryString.parse(ownProps.location.search).continue_session_id,
    continuePaymentId: queryString.parse(ownProps.location.search).pid,
    screenOrientation: state.browser.orientation,
    screenSize: state.browser.mediaType,
    notifications: state.lottorace.notifications,
    currentLanguage: ownProps.activeLanguage,
    regSuccess: state.lottorace.regSuccess,
    fullRegSuccess: state.lottorace.fullRegSuccess,
    regUsername: state.lottorace.regUsername,
    regPass: state.lottorace.regPass,
    gameLastWin: state.lottorace.gameLastWin,
    translate: ownProps.translate,
    refStr: queryString.parse(ownProps.location.search).refStr,
    adminStr: queryString.parse(ownProps.location.search).adminStr,
    lang: queryString.parse(ownProps.location.search).lang,
    showLogin: queryString.parse(ownProps.location.search).login,
    showRegister: queryString.parse(ownProps.location.search).signup,
    modal: state.lottorace.modal,
    configuration: state.lottorace.session.configuration,
    maintenance: state.lottorace.session.configuration ? state.lottorace.session.configuration.MAINTENANCE_ENABLED : "false",
    switchMain: state.lottorace.switchMain,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    navigate: (route) => ownProps.history.push(route),//dispatch(push(route)),
    navigateBack: () => ownProps.history.goBack(),
    pingme: () => dispatch(pingme()),
    login: (username, password) => dispatch(pingme()).then(
      (action) => {
        if (action?.type == 'PING_SUCCESS') {
          dispatch(login(username, password)).then(
            (action) => {
              if (action?.type == 'LOGIN_SUCCESS') {
                dispatch(loadSession()).then(
                  (action) => {
                    if (action?.type == 'LOAD_SUCCESS') {
                      //dispatch(getMainAds('WEB', 'MEDIUM'));
                    } else {
                      console.error("loadSession fail App.js");
                    };
                  });
              } else {
                console.error("login fail App.js");
              };
            })
        } else {
          console.error("ping fail App.js");
        };
      }),
    loginNoPing: (username, password) => dispatch(login(username, password)).then(
      (action) => {
        if (action?.type == 'LOGIN_SUCCESS') {
          dispatch(loadSession());
        }
      }),
    loginWhitelabel: (sessionId) => dispatch(loginWhitelabel(sessionId)).then(
      (action) => {
        if (action?.type == 'LOGIN_WHITELABEL_SUCCESS') {
          dispatch(login(action.result.username, action.result.credentials)).then(
            (action) => {
              if (action?.type == 'LOGIN_SUCCESS') {
                dispatch(loadSession());
              }
            })
        }
      }),
    logout: () => dispatch(logout()),
    loadSession: () => dispatch(pingme()).then(
      (action) => {
        if (action?.type == 'PING_SUCCESS') {
          dispatch(loadSession()).then(
            (action) => {
              if (action?.type == 'LOAD_SUCCESS') {
                //dispatch(getMainAds('WEB', 'MEDIUM'));
              }
            });
        }
      }),
    updateLanguage: (language) => dispatch(updateLanguage(language)).then(
      (action) => {
        if (action?.type == 'UPDATE_LANGUAGE_SUCCESS') {
          window.location.reload();
        }
      }),

    // showProcessingScreen: () => dispatch({ type: 'SHOW_PROCESSING' }),
    // hideProcessingScreen: () => dispatch({ type: 'HIDE_PROCESSING' }),
    updateStoreSoundEnabled: () => dispatch(toggleAllSounds(window.soundEnabled)),
    trackReferralCode: (refStr) => dispatch(trackReferralCode(refStr)),
    getMainAds: (clientType, screenSize) => dispatch(getMainAds(clientType, screenSize)),
    showLoginModal: () => dispatch(showLogin()),
    showRegisterModal: () => dispatch(showRegister()),
    showRulesModal: () => dispatch(showRules()),
    showPayoutModal: () => dispatch(showPayout()),
    showPlayGameModal: (gameInstanceId) => dispatch(showPlayGame(gameInstanceId)),
    showLiveGamesModal: (gameInstanceId) => dispatch(showLiveGames()),
    showOneClickBuyModal: (gameId, gameInstanceId, continueSessionId, continuePaymentId) => dispatch(showOneClickBuy(gameId, gameInstanceId, continueSessionId, continuePaymentId)),
    showOneClickBuyInstantModal: (gameId, gameInstantId, continuePaymentId) => dispatch(showOneClickBuyInstant(gameId, gameInstantId, continuePaymentId)),
    showProcessingModal: () => dispatch(showProcessing()),
    showFullRegModal: () => dispatch(show()),
    showCancelSubscription: () => dispatch(showCancelSubscription()),
    changeLanguage: (locale) => dispatch(setActiveLanguage(locale)),
    requestEmailConfirmation: () => dispatch(requestEmailConfirmation()),
    hideDepositModal: () => dispatch(hideDepositModal()),
    showProcessing: () => dispatch(showProcessing()),
    showRealityCheck: () => dispatch(showRealityCheck())
  };
}

let primary;
let that;

class App extends Component {
  static propTypes /* remove-proptypes */ = {
    location: PropTypes.object,
    pathname: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    navigateBack: PropTypes.func.isRequired,
    trackReferralCode: PropTypes.func.isRequired,
    showPlayGameModal: PropTypes.func.isRequired,
    showLoginModal: PropTypes.func.isRequired,
    showRegisterModal: PropTypes.func.isRequired,
    showProcessingModal: PropTypes.func.isRequired,
    showRulesModal: PropTypes.func.isRequired,
    showCancelSubscription: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool,
    loggingIn: PropTypes.bool,
    loginBackground: PropTypes.bool,
    forceLogout: PropTypes.bool,
    loggingOut: PropTypes.bool,
    session: PropTypes.object,
    connected: PropTypes.bool,
    gameInstanceId: PropTypes.string,
    gameId: PropTypes.string,
    globalFilter: PropTypes.string,
    screenOrientation: PropTypes.string,
    changeLanguage: PropTypes.func.isRequired,
    currentLanguage: PropTypes.object,
    regSuccess: PropTypes.bool,
    login: PropTypes.func.isRequired,
    loginNoPing: PropTypes.func.isRequired,
    fullRegSuccess: PropTypes.bool,
    requestEmailConfirmation: PropTypes.func.isRequired,
    showFullRegModal: PropTypes.func.isRequired,
    showLiveGamesModal: PropTypes.func.isRequired,
    refStr: PropTypes.string,
    adminStr: PropTypes.string,
    lang: PropTypes.string,
    showLogin: PropTypes.string,
    showRegister: PropTypes.string,
    showRules: PropTypes.string,
    modal: PropTypes.object,
    hideDepositModal: PropTypes.func.isRequired,
    gameLastWin: PropTypes.object,
    maintenance: PropTypes.string,
    configuration: PropTypes.object,
    showProcessing: PropTypes.func.isRequired,
    switchMain: PropTypes.bool
  };

  // static contextTypes = {
  //   router: PropTypes.object.isRequired,
  //   store: PropTypes.object.isRequired
  // };

  constructor(props) {
    super(props);

    this.state = { sideDrawerOpen: false, languageReady: false };

    let languageToUse = this.props.lang || window.localStorage.getItem("language") || (typeof defaultLanguage === 'undefined' ? "en_US" : defaultLanguage);
    languageToUse = languageToUse.replace(/-/g, '_');

    window.localStorage.setItem("language", languageToUse.replace(/-/g, '_'));

    loadLocalLanguage(this.props.initialize)
      .then(() =>
        loadRemoteLanguage(languageToUse)
          .then((fullfill) => {
            this.props.initialize(fullfill);
            console.log("Initializing language...")
            this.setState((prevState) => {
              return { ...prevState, languageReady: true };
            });
          })
      );
  }

  changeCurrentLanguage = (lang) => {
    window.localStorage.setItem("language", lang);
    loadLocale(lang)
      .then((payload) => {
        this.props.addTranslationForLanguage(payload.messages, lang);
        this.props.changeLanguage(lang);
        this.props.setActiveLanguage(lang);
      }
      )
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { ...prevState, sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  setupVisibleJs = (i) => {
    ifvisible.setIdleDuration(3600);

    ifvisible.on("wakeup", () => {
      const timeDiffMinutes = Math.round((new Date().getTime() - i.props.session.lastLoadedOn) / 60000);
      console.log("Slept for " + timeDiffMinutes + " minutes");
      if (timeDiffMinutes >= 5 && switchMain == false) {
        if (this.props.pathname.indexOf('scratchcard') == -1 &&
          this.props.pathname.indexOf('crossword') == -1 &&
          this.props.pathname.indexOf('bingo') == -1 &&
          this.props.pathname.indexOf('permpick') == -1) {
          console.log('reload app after wakeup');
          window.location.reload();
        }
        //} else {
        //  i.props.loadSession();
        //}
      } else {
        i.props.pingme();
      }

      // if (this.props.pathname.indexOf('playout') > -1) {
      //   this.props.navigate('/playout?id=' + this.props.gameInstanceId + "&gid=" + this.props.gameId);
      // }
    });
  }

  getOpenGames = (gameslist) => {
    return gameslist.filter(game => game.playerInGame || (game.playerInGame && (game.gameStatus === 'OPEN' || game.gameStatus === 'STARTED')))
  };

  startErrorLog = () => {
    window.onerror = (message, file, line, column, errorObject) => {
      if (window.logAccount != null) {
        window.LogRocket.captureException(errorObject)
      }
      console.log("Error caught in onerror", message);

      trackError({
        'exPlayer': (this.props.loggedIn && this.props.session.player) ? this.props.session.player.id + "" : -1,
        'exUsername': (this.props.loggedIn && this.props.session.player) ? this.props.session.player.username : '',
        'exUrl': document.location.href,
        'exMessage': 'HTML5 JS Error',
        'exDescription': message,
        'exErrorObject': JSON.stringify(errorObject),
        'exFatal': false
      });

      if (message && message.indexOf('cross-origin frame') > -1) {
        this.props.hideDepositModal();
      } else
        if (message && message.indexOf('cross-origin frame') == -1) {
          showMetroDialog('Refresh Time', "Its time to get squeaky clean! Click OK to clean away!", '[Reload#btn-info]',
            (reload) => {
              window.location.href = siteRoot;
            });
        }

      return !__DEV__;
    }
  }

  autoSaveRef = () => {
    if (this.props.refStr) {
      localStorage.setItem("refStr", this.props.refStr);
      sessionStorage.setItem("refStr", this.props.refStr);
      this.props.trackReferralCode(this.props.refStr);
    }
  }

  autoLogin = () => {
    if (sessionId != '') {
      this.props.loginWhitelabel(sessionId);
    } else
      if (sessionStorage.getItem('auth_username')) {
        let password = decryptString(sessionStorage.getItem('auth_password'));
        this.props.login(sessionStorage.getItem('auth_username'), password);
      } else {
        this.props.loadSession();
      }
  }

  showReloadLottoRace = () => {
    setTimeout(function () {
      window.location.href = siteRoot;
    }, Math.floor(Math.random() * 10000) + 3000);
  }

  showMaintenanceOver = () => {
    window.location.href = siteRoot;
  }

  componentWillMount()
  {
    window.signalReactLoaded();

    if (window.enforceLocation !== undefined && window.enforceLocation == true) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            window.latitude = position.coords.latitude;
            window.longitude = position.coords.longitude;

            window.apiClient.isEligibleLocation(window.latitude, window.longitude)
              .then((allowed) => {
                if (!allowed)
                  alert("You cannot access this site from your location!");

                return;
              });
          },
          (error) => {

            switch(error.code) {
              case error.PERMISSION_DENIED:
                alert("User denied the request for Geolocation.");
                break;
              case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
              case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
              case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
            }

            $.ajax({
              type: 'GET',
              url: window.siteUrlOverride + "/client/web/unsecured/getIP",
              success: function (data) {
                window.clientIP = data;
                window.apiClient.isEligibleLocation(null, null)
                  .then((allowed) => {
                    if (!allowed)
                      alert("You cannot access this site from your location!");
                    return;
                  });
              }
            });
          }
        );
      }
    }

    //this.startErrorLog();
    this.autoSaveRef();

    that = this;

    if (localStorage.getItem("soundEnabled")) {
      soundEnabled = localStorage.getItem("soundEnabled");
      that.props.updateStoreSoundEnabled();

    } else {
      localStorage.setItem("soundEnabled", soundEnabled);
      that.props.updateStoreSoundEnabled();

    }

    if (this.props.globalFilter != null) {
      localStorage.setItem('globalFilter', this.props.globalFilter);
    }

    if (this.props.loggedIn == false && (apiType == 1 || apiType == 3 || apiType == 2)) {
      this.autoLogin();
    }

    // initialize richSoundSettings
    if (
      // if no richSoundSettings
      !localStorage.getItem('richSoundSettings') ||
      // if richSoundSettings object is empty
      Object.keys(JSON.parse(localStorage.getItem('richSoundSettings'))).length == 0 ||
      // if old .dynamicScratchcard appears
      (localStorage.getItem('richSoundSettings') &&
        (JSON.parse(localStorage.getItem('richSoundSettings')).dynamicScratchcard))
    ) {

      let richSoundSettings = {
        crossword: {
          richSoundEnabled: false,
          set: false
        },
        bingo: {
          richSoundEnabled: false,
          set: false
        },
        lottery: {
          richSoundEnabled: false,
          set: false
        },
        lottorace: {
          richSoundEnabled: false,
          set: false
        },
        scratchcard: {
          richSoundEnabled: false,
          richAnimationsEnabled: false,
          set: false
        },

      };

      localStorage.setItem('richSoundSettings', JSON.stringify(richSoundSettings));

    }

  }

  componentDidMount() {
    this.setupVisibleJs(this);

    // this.props.showProcessingScreen();
    window.initSounds();
    window.initBingoSoundEffects();
    window.initPlayoutSounds();

    try {
      let richSoundSettings = JSON.parse(localStorage.getItem('richSoundSettings'));

      if (!richSoundSettings || (richSoundSettings && richSoundSettings.bingo.richSoundEnabled == true)) {
        window.initBingoCalloutSounds();
      }

    } catch (e) {
      console.log("failed in richSoundSettings initBIngoCalloutSounds", { e });
    }

    // this.props.hideProcessingScreen();

    if (this.props.showLogin) {
      this.props.showLoginModal();
    } else
      if (this.props.showRegister) {
        this.props.showRegisterModal();
      }

    if (this.props.continueSessionId) {
      this.props.showOneClickBuyModal(this.props.gameId, this.props.gameInstanceId, this.props.continueSessionId, this.props.continuePaymentId);
    } else if (this.props.gameId && this.props.continuePaymentId) {
      this.props.showOneClickBuyInstantModal(this.props.gameId, this.props.gameInstanceId, this.props.continuePaymentId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.init == false && this.props.init == true && this.props.loggedIn == false) {
      this.props.getMainAds(isMobile() ? 'MOBILE' : 'WEB', this.props.screenSize == 'infinity' ? 'ALL' : this.props.screenSize);
    }
    if (prevProps.loggedIn == false && this.props.loggedIn == true) {
      this.props.getMainAds(isMobile() ? 'MOBILE' : 'WEB', this.props.screenSize == 'infinity' ? 'ALL' : this.props.screenSize);
    }
  }
  componentWillReceiveProps(nextProps) {
    // Logout
    if (this.props.loggedIn && !nextProps.loggedIn && !nextProps.loggingIn) {
      console.log('reload app after logout');
      window.location.href = '/';
      window.location.reload();
      /*showMetroDialog('You have been logged out', "Your session has been invalidated.", '[OK#btn-info]',
        function () {
          if (mid == 28) {
            window.location.href = 'https://www.westernlotto.com/logout';
          } else {
            window.location.reload();
          }
        });*/
    }

    if (!this.props.loginBackground && nextProps.loginBackground) {
      if (sessionStorage.getItem('auth_username')) {
        const password = decryptString(sessionStorage.getItem('auth_password'));
        this.props.loginNoPing(sessionStorage.getItem('auth_username'), password);
      }
    }

    // Login
    if (!this.props.loggedIn && nextProps.loggedIn) {

      try {
        ga('set', 'userId', nextProps.session.player.id + "");
      } catch (e) {
        console.log('Could not set LogRocket user info');
      }
      try {
        if (typeof logAccount !== 'undefined' && logAccount !== null) {
          window.LogRocket.identify(nextProps.session.player.id, {
            name: nextProps.session.player?.details.firstName + " " + nextProps.session.player?.details.lastName,
            email: nextProps.session.player?.details.primaryEmail,
            username: nextProps.session.player.username
          });
        }
      } catch (e) {
        console.log('Could not set LogRocket user info');
      }

      if (typeof msClarity !== 'undefined' && msClarity == true) {
        try {
          window.clarity("identify", nextProps.session.player.id);
          window.clarity("set", "username", nextProps.session.player.username);
          window.clarity("set", "email", nextProps.session.player.details.primaryEmail);
        } catch (e) {
          console.log('Could not set Clarity user info', e);
        }
      }

      // delay the popup to make sure the languages get loaded
      setTimeout(() => {
        try {
          if (nextProps.session.player.verification.playerSegment == 'B') {
            showQuickRegSuccess(
              this.props.translate('drawer.welcome') + " " + nextProps.session.player.screenName,
              this.props.translate('registration.popup.please_complete'),
              this.props.translate('registration.popup.later'),
              this.props.translate('registration.popup.complete'));

            $('#qrLater').click(function () {
              $(this).trigger('notify-hide');
            });

            $('#qrComplete').click(function () {
              that.props.showFullRegModal();
              $(this).trigger('notify-hide');
            });

          }
        } catch (e) {
          console.log("Something wrong in showQuickRegSuccess " + e.toString());
        }
      }, 3000);

      // Redirect to main
      if (this.props.pathname != '/lobby' && this.props.pathname != '/raffle' && this.props.pathname != '/slim'
        && this.props.pathname != '/terms'
        && this.props.pathname != '/draws' && this.props.pathname != '/privacy' && this.props.pathname != '/howtoplay'
        && this.props.pathname != '/replay' && this.props.pathname != '/scan' && this.props.pathname != '/contact'
        && this.props.pathname != '/' && this.props.pathname != '/emailconfirmation'
        && this.props.pathname.indexOf('/p/') == -1
        && this.props.pathname != '/scratchcard' && this.props.pathname != '/crossword' && this.props.pathname != '/bingo/lobby') {
        this.props.navigate('/');
      }
    }

    if (!this.props.regSuccess && nextProps.regSuccess) {
      //console.log("loggging in....");
      this.props.login(this.props.regUsername, this.props.regPass)
    }

    if (!this.props.fullRegSuccess && nextProps.fullRegSuccess) {

      if (this.props.session.player.verification.emailVerified == true) {
        return;
      }

      if (this.props.session.configuration['EMAIL_ON_FULL_REGISTRATION'] == "true") {
        showRegistrationConfirmation(this.props.translate('registration.popup.reg_complete'),
          this.props.translate('registration.popup.thank_you1'), this.props.translate('registration.popup.resend'), this.props.translate('generic.ok'));
      } else {
        showRegistrationConfirmationNoEmail(this.props.translate('registration.popup.reg_complete'),
          this.props.translate('registration.popup.thank_you2'), this.props.translate('generic.ok'), this.props.translate('registration.popup.deposit'));
      }

      primary = this.props.session.player?.details.primaryEmail;

      $('#regDeposit').click(function () {
        $(this).trigger('notify-hide');
        that.props.navigate('/cashier');
      });

      $('#regResend').click(function () {
        that.props.requestEmailConfirmation();
        //console.log("showing just ok2");
        window.showRegistrationJustOk("Email Sent", "We sent you an email " + primary);

        $('#regOkJustOk').click(function () {
          $(this).trigger('notify-hide');
        });

        $(this).trigger('notify-hide');
      });

      $('#regOk').click(function () {
        $(this).trigger('notify-hide');
      });
    }

    if (this.props.connected && !nextProps.connected) {
      this.props.navigate('/idle');
    }

    if (!this.props.connected && nextProps.connected) {
      if (!this.props.configuration) {
        if (apiType != 1) {
          this.autoLogin();
        }
      } else
        if (this.props.session.configuration && this.props.session.configuration.MAINTENANCE_ENABLED == "false") {
          this.showReloadLottoRace();
          //this.props.navigate('/');
          //this.autoLogin();
        }
    }

    if (this.props.locale != nextProps.locale) {
      //if (this.props.loggedIn)
      //this.props.loadSession();
    }

    if (nextProps.forceLogout == true) {
      showMetroDialog('You have been logged out', "Your account has been locked! Please contact support for details.", '[OK#btn-danger]',
        function () {
          that.props.logout();
        });
    }

    if (nextProps.loggingOut == true) {
      showMetroDialog('You have been logged out', "You have been logged out as you have logged in from another device. If this was not you, please contact support.", '[OK#btn-warning]',
        function () {
          if (mid == 28) {
            window.location.href = 'https://www.westernlotto.com/logout';
          } else {
            that.props.logout();
          }
        });
    }

    if (this.props.showLogin != nextProps.showLogin && this.props.loggedIn == false) {
      this.props.showLoginModal();
    } else
      if (this.props.showRegister != nextProps.showRegister && this.props.loggedIn == false) {
        this.props.showRegisterModal();
      }

    if (!this.props.adminStr) {
      if (!this.props.session.configuration) {
        if (nextProps.session.configuration && nextProps.session.configuration.MAINTENANCE_ENABLED == 'true') {
          this.props.navigate('/maintenance');
        }
      } else if (this.props.session.configuration && nextProps.session.configuration) {
        if (this.props.session.configuration.MAINTENANCE_ENABLED == "false" && nextProps.session.configuration.MAINTENANCE_ENABLED == 'true') {
          this.props.navigate('/maintenance');
        } else if (this.props.session.configuration.MAINTENANCE_ENABLED == "true" && nextProps.session.configuration.MAINTENANCE_ENABLED == 'false') {
          this.showMaintenanceOver();
        }
      }
    } else {
      if (this.props.session.configuration) {

        store.getState().lottorace.session.configuration.MAINTENANCE_ENABLED = "false";
      }
    }

    if (this.props.switchMain == false && nextProps.switchMain == true &&
      this.props.pathname != '/replay' && this.props.pathname != '/lobby' && this.props.pathname != '/raffle'
      && this.props.pathname != '/playout' && this.props.pathname != '/password'
      && this.props.pathname != '/terms' && this.props.pathname != '/privacy'
      && this.props.pathname != '/howtoplay' && this.props.pathname != '/scan'
      && this.props.pathname != '/emailconfirmation' && this.props.pathname != '/contact'
      && this.props.pathname != '/draws' && this.props.pathname.indexOf('/p/') == -1) {
      switchMain = true;
      this.props.navigate('/slim');
    } else
      if (this.props.switchMain == true && sessionStorage.getItem('slimView') != null) {
        switchMain = true;
      }
  }

  render() {
    const { props } = this;

    if (process.env.NODE_ENV == 'production' && (props.languageReady == false || props.currentLanguage == null)) {
      return (
        <LoaderStatus>
          Initializing App...
        </LoaderStatus>
      )
    }

    // const handleBackClick = () => {
    //   if (props.location.indexOf('playout') > -1 || props.location.indexOf('gameOver') > -1 || props.location.indexOf('gameOverLive') > -1 || props.location.indexOf('lobby') > -1) {
    //     this.props.navigate(switchMain == false ? '/' : '/?id=' + mainGameInstanceId + '&gid=' + mainGameId);
    //   } else {
    //     this.props.navigateBack();
    //   }
    // };

    const isLiveMode = sessionStorage.getItem('live') == null ? false : true;

    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }

    return (
      <Fragment>
        {props.session.configuration &&
          <Helmet
            htmlAttributes={{ "lang": props.translate('locale'), "amp": undefined }}
            title={props.session.configuration.siteMetaTitle}
            titleTemplate={props.session.configuration.siteName + " - %s"}
            defaultTitle="LottoRace - Guaranteed Winners In Every Game"
            meta={[
              { "name": "description", "content": props.session.configuration.siteMetaDescription },
              { "name": "keywords", "content": props.session.configuration.siteMetaKeywords },
              { "property": "og:url", "content": props.session.configuration.siteUrl },
              { "property": "og:locale", "content": props.currentLanguage ? props.currentLanguage.code : 'en_US' },
              { "property": "og:title", "content": props.session.configuration.siteMetaTitle },
              { "property": "og:description", "content": props.session.configuration.siteMetaDescription },
              { "property": "og:site_name", "content": props.session.configuration.siteMetaTitle },
              { "property": "og:type", "content": "website" },
              { "property": "og:image", "content": props.session.configuration.siteUrl + (isMobile() ? "/static/img/main/logo/logo_small.png" : "/static/img/main/logo/logo.png") }
            ]}
          >
            <link href={window.siteUrlOverride + "/client/css?m=" + window.mid} rel="stylesheet" type="text/css" />

            {isLiveMode == true ? (
              <link rel="stylesheet" href={"/static/css/live_" + (props.currentLanguage ? props.currentLanguage.code : 'en_US') + ".css"} />
            ) : (
              <link rel="manifest" href={"/static/icon/site.webmanifest"} />
            )}
          </Helmet>}

        <MaintenanceMessage />

        {<AdContainer screenLocation={'FULL'} screenType={'ALL'} />}

        <Navigation drawerClickHandler={this.drawerToggleClickHandler} pathname={props.pathname}
          navigate={props.navigate}
          loggedIn={props.loggedIn}
          gameInstanceId={props.gameInstanceId}
          gameslist={props.session.filteredGames ? this.getOpenGames(getASArray(props.session.filteredGames)) : []}
          notifications={props.notifications ? props.notifications : []}
          logout={props.logout}
          login={props.showLoginModal}
          register={props.showRegisterModal}
          screenOrientation={props.screenOrientation}
          connected={props.connected}
          maintenance={props.maintenance}
          showFullRegModal={props.showFullRegModal}
          showLiveGamesModal={props.showLiveGamesModal}
          player={props.loggedIn ? props.session.player : null}
          configuration={props.configuration} />

        <Drawer show={this.state.sideDrawerOpen} drawerClickHandler={this.drawerToggleClickHandler} changeLanguage={this.changeCurrentLanguage}
          loggedIn={props.loggedIn} navigate={props.navigate} configuration={props.configuration}
          login={props.showLoginModal} logout={props.logout}
          register={props.showRegisterModal} />
        {backdrop}

        {props.loggingIn ? (
          <LoaderStatus>
            <Translate id="generic.messages.wait_logging" defaultMessage="Please wait, we are logging you in!" />
          </LoaderStatus>
        ) : (
          /*<TransitionGroup component="main">
            <CSSTransition key={this.props.location.key} classNames="fade" timeout={300}>
              {routes(this.props)}
            </CSSTransition>
          </TransitionGroup>*/
          <Fragment>{routes(props)}</Fragment>
        )}

        <Footer configuration={props.configuration} showRealityCheck={props.showRealityCheck} loggedIn={props.loggedIn} />

        {props.modal.showProcessing && <ProcessingModal />}

        {props.modal.showLogin && <LoginModal />}
        {props.modal.showPhoneLogin && <LoginPhoneModal />}
        {props.modal.showMbob && <MbobModal />}
        {props.modal.showRules && <RulesModal />}
        {props.modal.showPayout && <PayoutModal />}
        {props.modal.showPrizeDetails && <PrizeDetailsModal />}
        {props.modal.showCancelSubscription && <CancelSubscriptionModal />}
        {props.modal.showDeposit && <DepositModal />}
        {props.modal.showRegister && <RegisterModal />}
        {props.modal.showFullReg && <RegisterFullModal />}
        {props.modal.showPlayGame && <PlayGameModal />}
        {props.modal.showLiveGames && <LiveSelectGamesModal />}
        {props.modal.showForgotPass && <ForgotPasswordModal />}
        {props.modal.showForgotConfirm && <ForgotPasswordConfirmationModal />}
        {props.modal.showOneClickBuy && <OneClickBuyModal />}
        {props.modal.showOneClickBuyInstant && <OneClickBuyModalInstant />}
        {props.modal.showRealityCheck && <RealityCheckModal navigate={props.navigate} />}
        {props.modal.showAdPopup && <AdModal />}
        {props.modal.showResetPassword && <ForcedPasswordModal />}
        {props.modal.showReconnecting && <ReconnectingModal />}
        <div style={{ WebkitTransform: 'translateZ(0)' }}></div>

        <ModalContainer />
      </Fragment>
    );
  }
}

export default withRouter(withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(App)));
