import { decodeBase64, formatMoney, getASArray } from "../helpers/Utils";
import update from 'immutability-helper';

const PING = 'PING';
const PING_SUCCESS = 'PING_SUCCESS';
const PING_FAIL = 'PING_FAIL';

const LOGIN_BACKGROUND = 'LOGIN_BACKGROUND';

const SERVER_CONNECTING = 'SERVER_CONNECTING';
const SERVER_CONNECTED = 'SERVER_CONNECTED';
const SERVER_DISCONNECTED = 'SERVER_DISCONNECTED';
const SERVER_DISCONNECTED_NOW = 'SERVER_DISCONNECTED_NOW';

const GLOBAL_MESSAGE = 'GLOBAL_MESSAGE';
const PRIVATE_MESSAGE = 'PRIVATE_MESSAGE';

const INIT_LOAD = 'INIT_LOAD';
const LOAD = 'LOAD';
const LOAD_SUCCESS = 'LOAD_SUCCESS';
const LOAD_FAIL = 'LOAD_FAIL';
const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
const LOGIN_WHITELABEL = 'LOGIN_WHITELABEL';
const LOGIN_WHITELABEL_SUCCESS = 'LOGIN_WHITELABEL_SUCCESS';
const LOGIN_WHITELABEL_FAIL = 'LOGIN_WHITELABEL_FAIL';
const LOGOUT = 'LOGOUT';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'LOGOUT_FAIL';
const LOGOUT_INTERNAL = 'LOGOUT_INTERNAL';

const UPDATE_PLAYER_DETAILS = 'UPDATE_PLAYER_DETAILS';

const REMOVE_FAV_FROM_LIST = "REMOVE_FAV_FROM_LIST";

const PLAYER_SEGMENT = 'PLAYER_SEGMENT';

const GET_DEPOSIT_REQUESTS = 'GET_DEPOSIT_REQUESTS';
const GET_DEPOSIT_REQUESTS_SUCCESS = 'GET_DEPOSIT_REQUESTS_SUCCESS';
const GET_DEPOSIT_REQUESTS_FAIL = 'GET_DEPOSIT_REQUESTS_FAIL';

const CANCEL_DEPOSIT_REQUEST = 'CANCEL_DEPOSIT_REQUEST';
const CANCEL_DEPOSIT_REQUEST_SUCCESS = 'CANCEL_DEPOSIT_REQUEST_SUCCESS';
const CANCEL_DEPOSIT_REQUEST_FAIL = 'CANCEL_DEPOSIT_REQUEST_FAIL';


const GET_ACCOUNTS = 'GET_ACCOUNTS';
const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL';

const LOAD_GAME_TYPES = 'LOAD_GAME_TYPES';
const LOAD_GAME_TYPES_SUCCESS = 'LOAD_GAME_TYPES_SUCCESS';
const LOAD_GAME_TYPES_FAIL = 'LOAD_GAME_TYPES_FAIL';

const LOAD_GAME_TYPE_INSTANCES = 'LOAD_GAME_TYPE_INSTANCES';
const LOAD_GAME_TYPE_INSTANCES_SUCCESS = 'LOAD_GAME_TYPE_INSTANCES_SUCCESS';
const LOAD_GAME_TYPE_INSTANCES_FAIL = 'LOAD_GAME_TYPE_INSTANCES_FAIL';

const LOAD_ALL_GAME_TYPE_INSTANCES = 'LOAD_ALL_GAME_TYPE_INSTANCES';
const LOAD_ALL_GAME_TYPE_INSTANCES_SUCCESS = 'LOAD_ALL_GAME_TYPE_INSTANCES_SUCCESS';
const LOAD_ALL_GAME_TYPE_INSTANCES_FAIL = 'LOAD_ALL_GAME_TYPE_INSTANCES_FAIL';

const GET_PENDING_WITHDRAWALS = 'GET_PENDING_WITHDRAWALS';
const GET_PENDING_WITHDRAWALS_SUCCESS = 'GET_PENDING_WITHDRAWALS_SUCCESS';
const GET_PENDING_WITHDRAWALS_FAIL = 'GET_PENDING_WITHDRAWALS_FAIL';

const SEARCH_TRANSACTIONS = 'SEARCH_TRANSACTIONS';
const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS';
const SEARCH_TRANSACTIONS_FAIL = 'SEARCH_TRANSACTIONS_FAIL';

const LOAD_GAME_LOBBY = 'LOAD_GAME_LOBBY';
const LOAD_GAME_LOBBY_SUCCESS = 'LOAD_GAME_LOBBY_SUCCESS';
const LOAD_GAME_LOBBY_FAIL = 'LOAD_GAME_LOBBY_FAIL';

const LOAD_GAMES = 'LOAD_GAMES';
const LOAD_GAMES_SUCCESS = 'LOAD_GAMES_SUCCESS';
const LOAD_GAMES_FAIL = 'LOAD_GAMES_FAIL';

const GET_BONOBO_PAYOUT = 'GET_BONOBO_PAYOUT';
const GET_BONOBO_PAYOUT_SUCCESS = 'GET_BONOBO_PAYOUT_SUCCESS';
const GET_BONOBO_PAYOUT_FAIL = 'GET_BONOBO_PAYOUT_FAIL';

const GET_ACCOUNT_BALANCE = 'GET_ACCOUNT_BALANCE';
const GET_ACCOUNT_BALANCE_SUCCESS = 'GET_ACCOUNT_BALANCE_SUCCESS';
const GET_ACCOUNT_BALANCE_FAIL = 'GET_ACCOUNT_BALANCE_FAIL';

const PLAYS_AVAILABLE = 'PLAYS_AVAILABLE';
const PLAYS_AVAILABLE_SUCCESS = 'PLAYS_AVAILABLE_SUCCESS';
const PLAYS_AVAILABLE_FAIL = 'PLAYS_AVAILABLE_FAIL';

const BALANCE_UPDATE = 'BALANCE_UPDATE';

const JOIN_GAME = 'JOIN_GAME';

const JOIN_GAME_SUCCESS = 'JOIN_GAME_SUCCESS';
const JOIN_GAME_FAIL = 'JOIN_GAME_FAIL';

const JOIN_GAME_PERM_SUCCESS = 'JOIN_GAME_PERM_SUCCESS';
const JOIN_GAME_PERM_FAIL = 'JOIN_GAME_PERM_FAIL';

const SAVE_FAV_GAME = 'SAVE_FAV_GAME';
const SAVE_FAV_GAME_SUCCESS = 'SAVE_FAV_GAME_SUCCESS';
const SAVE_FAV_GAME_FAIL = 'SAVE_FAV_GAME_FAIL';

const DELETE_FAV_GAME = 'DELETE_FAV_GAME';
const DELETE_FAV_GAME_SUCCESS = 'DELETE_FAV_GAME_SUCCESS';
const DELETE_FAV_GAME_FAIL = 'DELETE_FAV_GAME_FAIL';

const PLAYER_JOIN = 'PLAYER_JOIN';

const GAME_UPDATE = 'GAME_UPDATE';
const GAME_UPDATE_PLAYS_PLAYERS = 'GAME_UPDATE_PLAYS_PLAYERS';
const GAME_UPDATE_GLOBAL = 'GAME_UPDATE_GLOBAL';
const GAME_CREATE = 'GAME_CREATE';
const GAME_DELETE = 'GAME_DELETE';

const DO_GET_COUNTRY_BY_IP = 'DO_GET_COUNTRY_BY_IP';
const DO_GET_COUNTRY_BY_IP_SUCCESS = 'DO_GET_COUNTRY_BY_IP_SUCCESS';
const DO_GET_COUNTRY_BY_IP_FAIL = 'DO_GET_COUNTRY_BY_IP_FAIL';

const DO_CHECK_POSTAL_CODE = 'DO_CHECK_POSTAL_CODE';
const DO_CHECK_POSTAL_CODE_SUCCESS = 'DO_CHECK_POSTAL_CODE_SUCCESS';
const DO_CHECK_POSTAL_CODE_FAIL = 'DO_CHECK_POSTAL_CODE_FAIL';

const GAME_END = 'GAME_END';
const GAME_FULL = 'GAME_FULL';
const GAME_READY = 'GAME_READY';
const GAME_START = 'GAME_START';
const GAME_REGISTRATION_START = 'GAME_REGISTRATION_START';
const GAME_REGISTRATION_END = 'GAME_REGISTRATION_END';
const GAME_PROGRESSIVE = 'GAME_PROGRESSIVE';
const GAME_REFUNDED = 'GAME_REFUNDED';
const GAME_RESCHEDULED = 'GAME_RESCHEDULED';
const GAME_FAIL_RETRY = 'GAME_FAIL_RETRY';
const GAME_MAINTENANCE = 'GAME_MAINTENANCE';

const GAME_RESULTS = 'GAME_RESULTS';
const GAME_RESULTS_SUCCESS = 'GAME_RESULTS_SUCCESS';
const GAME_RESULTS_FAIL = 'GAME_RESULTS_FAIL';

const GAME_PLAYERS = 'GAME_PLAYERS';
const GAME_PLAYERS_SUCCESS = 'GAME_PLAYERS_SUCCESS';
const GAME_PLAYERS_FAIL = 'GAME_PLAYERS_FAIL';

const GAME_PAYOUT = 'GAME_PAYOUT';
const GAME_PAYOUT_SUCCESS = 'GAME_PAYOUT_SUCCESS';
const GAME_PAYOUT_FAIL = 'GAME_PAYOUT_FAIL';

const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
const LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS';
const LOAD_COUNTRIES_FAIL = 'LOAD_COUNTRIES_FAIL';

const LOAD_PROVINCES = 'LOAD_PROVINCES';
const LOAD_PROVINCES_SUCCESS = 'LOAD_PROVINCES_SUCCESS';
const LOAD_PROVINCES_FAIL = 'LOAD_PROVINCES_FAIL';

const DO_REQUEST_RESET = 'DO_REQUEST_RESET';
const DO_REQUEST_RESET_SUCCESS = 'DO_REQUEST_RESET_SUCCESS';
const DO_REQUEST_RESET_FAIL = 'DO_REQUEST_RESET_FAIL';

const DO_CHECK_USERNAME = 'DO_CHECK_USERNAME';
const DO_CHECK_USERNAME_SUCCESS = 'DO_CHECK_USERNAME_SUCCESS';
const DO_CHECK_USERNAME_FAIL = 'DO_CHECK_USERNAME_FAIL';

const DO_CHECK_EMAIL = 'DO_CHECK_EMAIL';
const DO_CHECK_EMAIL_SUCCESS = 'DO_CHECK_EMAIL_SUCCESS';
const DO_CHECK_EMAIL_FAIL = 'DO_CHECK_EMAIL_FAIL';

const DO_CHECK_GOVERNMENT_ID = 'DO_CHECK_GOVERNMENT_ID';
const DO_CHECK_GOVERNMENT_ID_SUCCESS = 'DO_CHECK_GOVERNMENT_ID_SUCCESS';
const DO_CHECK_GOVERNMENT_ID_FAIL = 'DO_CHECK_GOVERNMENT_ID_FAIL';

const DO_CHECK_PASSWORD = 'DO_CHECK_PASSWORD';
const DO_CHECK_PASSWORD_SUCCESS = 'DO_CHECK_PASSWORD_SUCCESS';
const DO_CHECK_PASSWORD_FAIL = 'DO_CHECK_PASSWORD_FAIL';

const DO_CHECK_COUPON = 'DO_CHECK_COUPON';
const DO_CHECK_COUPON_SUCCESS = 'DO_CHECK_COUPON_SUCCESS';
const DO_CHECK_COUPON_FAIL = 'DO_CHECK_COUPON_FAIL';

const GET_DROPDOWN_ITEMS = 'GET_DROPDOWN_ITEMS';
const GET_DROPDOWN_ITEMS_SUCCESS = 'GET_DROPDOWN_ITEMS_SUCCESS';
const GET_DROPDOWN_ITEMS_FAIL = 'GET_DROPDOWN_ITEMS_FAIL';

const SEARCH_GAME_HISTORY = 'SEARCH_GAME_HISTORY';
const SEARCH_GAME_HISTORY_SUCCESS = 'SEARCH_GAME_HISTORY_SUCCESS';
const SEARCH_GAME_HISTORY_FAIL = 'SEARCH_GAME_HISTORY_FAIL';

const GET_GAME_HISTORY = 'GET_GAME_HISTORY';
const GET_GAME_HISTORY_SUCCESS = 'GET_GAME_HISTORY_SUCCESS';
const GET_GAME_HISTORY_FAIL = 'GET_GAME_HISTORY_FAIL';

const HAS_SUBSCRIPTIONS = 'HAS_SUBSCRIPTIONS';
const HAS_SUBSCRIPTIONS_SUCCESS = 'HAS_SUBSCRIPTIONS_SUCCESS';
const HAS_SUBSCRIPTIONS_FAIL = 'HAS_SUBSCRIPTIONS_FAIL';

const SUBSCRIPTION_ACTIVE = 'SUBSCRIPTION_ACTIVE';
const SUBSCRIPTION_ACTIVE_SUCCESS = 'SUBSCRIPTION_ACTIVE_SUCCESS';
const SUBSCRIPTION_ACTIVE_FAIL = 'SUBSCRIPTION_ACTIVE_FAIL';

const PLAYER_GAME_SUBSCRIPTION_PLAYS = 'PLAYER_GAME_SUBSCRIPTION_PLAYS';
const PLAYER_GAME_SUBSCRIPTION_PLAYS_SUCCESS = 'PLAYER_GAME_SUBSCRIPTION_PLAYS_SUCCESS';
const PLAYER_GAME_SUBSCRIPTION_PLAYS_FAIL = 'PLAYER_GAME_SUBSCRIPTION_PLAYS_FAIL';

const LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS';
const LOAD_SUBSCRIPTIONS_SUCCESS = 'LOAD_SUBSCRIPTIONS_SUCCESS';
const LOAD_SUBSCRIPTIONS_FAIL = 'LOAD_SUBSCRIPTIONS_FAIL';

const LOAD_SUBSCRIPTION_DETAILS = 'LOAD_SUBSCRIPTION_DETAILS';
const LOAD_SUBSCRIPTION_DETAILS_SUCCESS = 'LOAD_SUBSCRIPTION_DETAILS_SUCCESS';
const LOAD_SUBSCRIPTION_DETAILS_FAIL = 'LOAD_SUBSCRIPTION_DETAILS_FAIL';

const LOAD_SUBSCRIPTION_TRANSACTIONS = 'LOAD_SUBSCRIPTION_TRANSACTIONS';
const LOAD_SUBSCRIPTION_TRANSACTIONS_SUCCESS = 'LOAD_SUBSCRIPTION_TRANSACTIONS_SUCCESS';
const LOAD_SUBSCRIPTION_TRANSACTIONS_FAIL = 'LOAD_SUBSCRIPTION_TRANSACTIONS_FAIL';

const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';

const DO_PLAYER_REGISTRATION = 'DO_PLAYER_REGISTRATION';
const DO_PLAYER_REGISTRATION_SUCCESS = 'DO_PLAYER_REGISTRATION_SUCCESS';
const DO_PLAYER_REGISTRATION_FAIL = 'DO_PLAYER_REGISTRATION_FAIL';

const DO_PLAYER_REGISTRATION_FULL = 'DO_PLAYER_REGISTRATION_FULL';
const DO_PLAYER_REGISTRATION_FULL_SUCCESS = 'DO_PLAYER_REGISTRATION_FULL_SUCCESS';
const DO_PLAYER_REGISTRATION_FULL_FAIL = 'DO_PLAYER_REGISTRATION_FULL_FAIL';

const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
const CONFIRM_EMAIL_FAIL = 'CONFIRM_EMAIL_FAIL';

const REQUEST_EMAIL_CONF = 'REQUEST_EMAIL_CONF';
const REQUEST_EMAIL_CONF_SUCCESS = 'REQUEST_EMAIL_CONF_SUCCESS';
const REQUEST_EMAIL_CONF_FAIL = 'REQUEST_EMAIL_CONF_FAIL';

const REQUEST_ID_VERIFICATION = 'REQUEST_ID_VERIFICATION';
const REQUEST_ID_VERIFICATION_SUCCESS = 'REQUEST_ID_VERIFICATION_SUCCESS';
const REQUEST_ID_VERIFICATION_FAIL = 'REQUEST_ID_VERIFICATION_FAIL';

const RESET_PASSWORD = 'RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

const UPDATE_EMAIL = 'UPDATE_EMAIL';
const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
const UPDATE_EMAIL_FAIL = 'UPDATE_EMAIL_FAIL';

const UPDATE_PHONES = 'UPDATE_PHONES';
const UPDATE_PHONES_SUCCESS = 'UPDATE_PHONES_SUCCESS';
const UPDATE_PHONES_FAIL = 'UPDATE_PHONES_FAIL';

const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL';

const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
const UPDATE_LANGUAGE_FAIL = 'UPDATE_LANGUAGE_FAIL';

const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
const UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS';
const UPDATE_NOTIFICATIONS_FAIL = 'UPDATE_NOTIFICATIONS_FAIL';

const UPDATE_SELF_LIMITS = 'UPDATE_SELF_LIMITS';
const UPDATE_SELF_LIMITS_SUCCESS = 'UPDATE_SELF_LIMITS_SUCCESS';
const UPDATE_SELF_LIMITS_FAIL = 'UPDATE_SELF_LIMITS_FAIL';

const UPDATE_SELF_EXCLUSION = 'UPDATE_SELF_EXCLUSION';
const UPDATE_SELF_EXCLUSION_SUCCESS = 'UPDATE_SELF_EXCLUSION_SUCCESS';
const UPDATE_SELF_EXCLUSION_FAIL = 'UPDATE_SELF_EXCLUSION_FAIL';

const UPDATE_TIMEOUT = 'UPDATE_TIMEOUT';
const UPDATE_TIMEOUT_SUCCESS = 'UPDATE_TIMEOUT_SUCCESS';
const UPDATE_TIMEOUT_FAIL = 'UPDATE_TIMEOUT_FAIL';

const GET_REFERRAL_REPORT = 'GET_REFERRAL_REPORT';
const GET_REFERRAL_REPORT_SUCCESS = 'GET_REFERRAL_REPORT_SUCCESS';
const GET_REFERRAL_REPORT_FAIL = 'GET_REFERRAL_REPORT_FAIL';

const SEND_PLAYER_INVITE = 'SEND_PLAYER_INVITE';
const SEND_PLAYER_INVITE_SUCCESS = 'SEND_PLAYER_INVITE_SUCCESS';
const SEND_PLAYER_INVITE_FAIL = 'SEND_PLAYER_INVITE_FAIL';

const CHECK_TOKEN = 'CHECK_TOKEN';
const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
const CHECK_TOKEN_FAIL = 'CHECK_TOKEN_FAIL';

const GET_DEPOSIT_POPUP_STEP = 'GET_DEPOSIT_POPUP_STEP';
const GET_DEPOSIT_POPUP_STEP_SUCCESS = 'GET_DEPOSIT_POPUP_STEP_SUCCESS';
const GET_DEPOSIT_POPUP_STEP_FAIL = 'GET_DEPOSIT_POPUP_STEP_FAIL';

const GET_DEPOSIT_STEP = 'GET_DEPOSIT_STEP';
const GET_DEPOSIT_STEP_SUCCESS = 'GET_DEPOSIT_STEP_SUCCESS';
const GET_DEPOSIT_STEP_FAIL = 'GET_DEPOSIT_STEP_FAIL';

const GET_WITHDRAWAL_STEP = 'GET_WITHDRAWAL_STEP';
const GET_WITHDRAWAL_STEP_SUCCESS = 'GET_WITHDRAWAL_STEP_SUCCESS';
const GET_WITHDRAWAL_STEP_FAIL = 'GET_WITHDRAWAL_STEP_FAIL';

const GET_WITHDRAWAL_STEP2 = 'GET_WITHDRAWAL_STEP2';
const GET_WITHDRAWAL_STEP2_SUCCESS = 'GET_WITHDRAWAL_STEP2_SUCCESS';
const GET_WITHDRAWAL_STEP2_FAIL = 'GET_WITHDRAWAL_STEP2_FAIL';

const WITHDRAWAL_SUBMIT = 'WITHDRAWAL_SUBMIT';
const WITHDRAWAL_SUBMIT_SUCCESS = 'WITHDRAWAL_SUBMIT_SUCCESS';
const WITHDRAWAL_SUBMIT_FAIL = 'WITHDRAWAL_SUBMIT_FAIL';

const WITHDRAWAL_CANCEL = 'WITHDRAWAL_CANCEL';
const WITHDRAWAL_CANCEL_SUCCESS = 'WITHDRAWAL_CANCEL_SUCCESS';
const WITHDRAWAL_CANCEL_FAIL = 'WITHDRAWAL_CANCEL_FAIL';

const WITHDRAWAL_CONFIRM = "WITHDRAWAL_CONFIRM";

const SAVED_PAYMENT_OPTIONS = 'SAVED_PAYMENT_OPTIONS';
const SAVED_PAYMENT_OPTIONS_SUCCESS = 'SAVED_PAYMENT_OPTIONS_SUCCESS';
const SAVED_PAYMENT_OPTIONS_FAIL = 'SAVED_PAYMENT_OPTIONS_FAIL';

const REQUEST_PHONE_VALIDATION = 'REQUEST_PHONE_VALIDATION';
const REQUEST_PHONE_VALIDATION_SUCCESS = 'REQUEST_PHONE_VALIDATION_SUCCESS';
const REQUEST_PHONE_VALIDATION_FAIL = 'REQUEST_PHONE_VALIDATION_FAIL';

const SUBMIT_PHONE_VALIDATION = 'SUBMIT_PHONE_VALIDATION';
const SUBMIT_PHONE_VALIDATION_SUCCESS = 'SUBMIT_PHONE_VALIDATION_SUCCESS';
const SUBMIT_PHONE_VALIDATION_FAIL = 'SUBMIT_PHONE_VALIDATION_FAIL';

const CREATE_SUPPORT_CASE = 'CREATE_SUPPORT_CASE';
const CREATE_SUPPORT_CASE_SUCCESS = 'CREATE_SUPPORT_CASE_SUCCESS';
const CREATE_SUPPORT_CASE_FAIL = 'CREATE_SUPPORT_CASE_FAIL';

const GET_GAME_LAST_WINNING_DRAWS = 'GET_GAME_LAST_WINNING_DRAWS';
const GET_GAME_LAST_WINNING_DRAWS_SUCCESS = 'GET_GAME_LAST_WINNING_DRAWS_SUCCESS';
const GET_GAME_LAST_WINNING_DRAWS_FAIL = 'GET_GAME_LAST_WINNING_DRAWS_FAIL';

const TRACK_REFERRAL_CODE = 'TRACK_REFERRAL_CODE';
const TRACK_REFERRAL_CODE_SUCCESS = 'TRACK_REFERRAL_CODE_SUCCESS';
const TRACK_REFERRAL_CODE_FAIL = 'TRACK_REFERRAL_CODE_FAIL';

const CLAIM_IN_GAME_BONUS = 'CLAIM_IN_GAME_BONUS';
const CLAIM_IN_GAME_BONUS_SUCCESS = 'CLAIM_IN_GAME_BONUS_SUCCESS';
const CLAIM_IN_GAME_BONUS_FAIL = 'CLAIM_IN_GAME_BONUS_FAIL';

const GET_MAIN_ADS = 'GET_MAIN_ADS';
const GET_MAIN_ADS_SUCCESS = 'GET_MAIN_ADS_SUCCESS';
const GET_MAIN_ADS_FAIL = 'GET_MAIN_ADS_FAIL';

const CONFIRM_CROSSWORD_END = 'CONFIRM_CROSSWORD_END';
const CONFIRM_CROSSWORD_END_SUCCESS = 'CONFIRM_CROSSWORD_END_SUCCESS';
const CONFIRM_CROSSWORD_END_FAIL = 'CONFIRM_CROSSWORD_END_FAIL';

const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';
const SAVE_LOBBY_GAMES = 'SAVE_LOBBY_GAMES';

const SAVE_USER_PASS = 'SAVE_USER_PASS';

const SHOW_LOGIN = 'SHOW_LOGIN';
const HIDE_LOGIN = 'HIDE_LOGIN';
const SHOW_REGISTER = 'SHOW_REGISTER';
const HIDE_REGISTER = 'HIDE_REGISTER';
const SHOW_PLAY_GAME = 'SHOW_PLAY_GAME';
const HIDE_PLAY_GAME = 'HIDE_PLAY_GAME';
const SHOW_CANCEL_SUBSCRIPTION = 'SHOW_CANCEL_SUBSCRIPTION';
const HIDE_CANCEL_SUBSCRIPTION = 'HIDE_CANCEL_SUBSCRIPTION';
const SHOW_ERROR = 'SHOW_ERROR';
const SHOW_RECONNECTING = 'SHOW_RECONNECTING';
const SHOW_PROCESSING = 'SHOW_PROCESSING';
const HIDE_PROCESSING = 'HIDE_PROCESSING';
const UPDATE_CURRENT_REG_STEP = "UPDATE_CURRENT_REG_STEP";
const SHOW_FULL_REG = "SHOW_FULL_REG";
const HIDE_FULL_REG = "HIDE_FULL_REG";
const SHOW_FORGOT_PASS = "SHOW_FORGOT_PASS";
const HIDE_FORGOT_PASS = "HIDE_FORGOT_PASS";
const SHOW_RULES = "SHOW_RULES";
const HIDE_RULES = "HIDE_RULES";
const SHOW_PAYOUT = "SHOW_PAYOUT";
const HIDE_PAYOUT = "HIDE_PAYOUT";

const SHOW_PHONE_VALIDATION = "SHOW_PHONE_VALIDATION";
const HIDE_PHONE_VALIDATION = "HIDE_PHONE_VALIDATION";
const SHOW_FORGOT_CONFIRM = "SHOW_FORGOT_CONFIRM";
const HIDE_FORGOT_CONFIRM = "HIDE_FORGOT_CONFIRM";
const SHOW_DEPOSIT = "SHOW_DEPOSIT";
const HIDE_DEPOSIT = "HIDE_DEPOSIT";
const SHOW_DEPOSIT_CC = "SHOW_DEPOSIT_CC";
const HIDE_DEPOSIT_CC = "HIDE_DEPOSIT_CC";

const SHOW_PRIZE_DETAILS = 'SHOW_PRIZE_DETAILS';
const HIDE_PRIZE_DETAILS = 'HIDE_PRIZE_DETAILS';

const SHOW_LIVE_GAMES = 'SHOW_LIVE_GAMES';
const HIDE_LIVE_GAMES = 'HIDE_LIVE_GAMES';

const PLAYER_EMAIL_VERIFICATION = "PLAYER_EMAIL_VERIFICATION";
const PLAYER_ID_VERIFICATION = "PLAYER_ID_VERIFICATION";
const PLAYER_PHONE_VERIFICATION = "PLAYER_PHONE_VERIFICATION";
const PLAYER_ADDRESS_VERFICATION = "PLAYER_ADDRESS_VERFICATION";
const PLAYER_BANNED = 'PLAYER_BANNED';
const PLAYER_ORDER = 'PLAYER_ORDER';
const PLAYER_COUPON = 'PLAYER_COUPON';
const PLAYER_RELOAD_VERIFICATION_REQUEST = 'PLAYER_RELOAD_VERIFICATION_REQUEST';

const BONOBO_TICKER = 'BONOBO_TICKER';

const SHOW_ONE_CLICK_BUY = 'SHOW_ONE_CLICK_BUY';
const HIDE_ONE_CLICK_BUY = 'HIDE_ONE_CLICK_BUY';
const SHOW_ONE_CLICK_BUY_INSTANT = 'SHOW_ONE_CLICK_BUY_INSTANT';
const HIDE_ONE_CLICK_BUY_INSTANT = 'HIDE_ONE_CLICK_BUY_INSTANT';

const SHOW_REALITY_CHECK = 'SHOW_REALITY_CHECK';
const HIDE_REALITY_CHECK = 'HIDE_REALITY_CHECK';

const SHOW_ONECLICK_NUMBER_PICK = 'SHOW_ONECLICK_NUMBER_PICK';
const HIDE_ONECLICK_NUMBER_PICK = 'HIDE_ONECLICK_NUMBER_PICK';

const SHOW_AD_POPUP = 'SHOW_AD_POPUP';
const HIDE_AD_POPUP = 'HIDE_AD_POPUP';

const TOGGLE_ALL_SOUNDS = 'TOGGLE_ALL_SOUNDS';

const initialState = {
  loading: false,
  error: false,
  loaded: false,
  init: false,
  loggedIn: false,
  loginBackground: false,
  joiningGame: false,
  connected: false,
  connectRetries: 0,
  forceLogout: false,
  hasPlayerGameSubscriptions: null,
  modal: {
    showLogin: false,
    showPhoneLogin: false,
    showRegister: false,
    showPlayGame: false,
    showError: false,
    showFullReg: false,
    showForgotPass: false,
    showRules: false,
    showPayout: false,
    showForgotConfirm: false,
    showProcessing: false,
    showCancelSubscription: false,
    showPhoneValidation: false,
    showDeposit: false,
    showDepositCC: false,
    showPrizeDetails: false,
    showLiveGames: false,
    showOneClickBuy: false,
    showOneClickBuyInstant: false,
    showRealityCheck: false,
    showOneClickNumberPick: false,
    showAdPopup: false
  },
  backgroundUrl: null,
  backgroundId: 0,
  notifications: [],
  session: {},
  soundEnabled: 1,
  cachedPayouts: {},
  lobbyGamesList: {},
  progressiveWon: false,
  progressiveMatchedNeeded: 0,
  countries: [],
  provinces: [],
  myCurrentRegStep: 1,
  regUsername: '',
  regPass: '',
  regSuccess: false,
  fullRegSuccess: false,
  regUsernameExists: "-2",
  regEmailExists: "-2",
  regCountryByIp: '',
  regPostalCodeValidation: "-1",
  favouriteGameList: [],
  changePassword: false,
  changedPassword: 'false',
  changedEmail: 'false',
  changedPhones: 'false',
  changedAddress: 'false',
  changedNotifications: 'false',
  passwordChecked: 'false',
  tokenChecked: 'false',
  referralEmailSent: 'false',
  changedSelfLimits: 'false',
  changedSelfExclude: 'false',
  changedTimeout: 'false',
  createdTicket: 0,
  rulesDesc: "",
  switchMain: switchMain,
  phoneLoginEnabled: typeof window.phoneLoginEnabled != 'undefined' ? window.phoneLoginEnabled : false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case PING:
      return update(state, {
        pinging: { $set: true }
      });

    case UPDATE_CURRENT_REG_STEP:
      return updateCurrentRegStep(state, action);

    case PING_SUCCESS:
      return update(state, {
        pinging: { $set: false }
      });

    case LOGIN_BACKGROUND:
      return {
        ...state,
        loginBackground: true
      }

    case SERVER_CONNECTED:
      return update(state, {
        connectRetries: { $set: 0 },
        connected: { $set: true },
        modal: {
          showReconnecting: { $set: false }
        }
      });

    case SERVER_DISCONNECTED:
      return update(state, {
        connected: { $set: state.connectRetries >= 2 ? false : true },
        connectRetries: { $set: state.connectRetries + 1 },
        modal: {
          showLogin: { $set: false },
          showPhoneLogin: { $set: false },
          showRegister: { $set: false },
          showPlayGame: { $set: false },
          showError: { $set: false },
          showFullReg: { $set: false },
          showForgotPass: { $set: false },
          showRules: { $set: false },
          showPayout: { $set: false },
          showForgotConfirm: { $set: false },
          showProcessing: { $set: false },
          showCancelSubscription: { $set: false },
          showPhoneValidation: { $set: false },
          showDeposit: { $set: false },
          showDepositCC: { $set: false },
          showPrizeDetails: { $set: false },
          showLiveGames: { $set: false },
          showOneClickBuy: { $set: false },
          showOneClickBuyInstant: { $set: false },
          showRealityCheck: { $set: false },
          showOneClickNumberPick: { $set: false },
          showAdPopup: { $set: false }
        }
      });

    case SERVER_DISCONNECTED_NOW:
      return update(state, {
        connected: { $set: false },
        modal: {
          showLogin: { $set: false },
          showPhoneLogin: { $set: false },
          showRegister: { $set: false },
          showPlayGame: { $set: false },
          showError: { $set: false },
          showFullReg: { $set: false },
          showForgotPass: { $set: false },
          showRules: { $set: false },
          showPayout: { $set: false },
          showForgotConfirm: { $set: false },
          showProcessing: { $set: false },
          showCancelSubscription: { $set: false },
          showPhoneValidation: { $set: false },
          showDeposit: { $set: false },
          showDepositCC: { $set: false },
          showPrizeDetails: { $set: false },
          showLiveGames: { $set: false },
          showOneClickBuy: { $set: false },
          showOneClickBuyInstant: { $set: false },
          showRealityCheck: { $set: false },
          showOneClickNumberPick: { $set: false },
          showAdPopup: { $set: false }
        }
      });


    case SHOW_PROCESSING:
      return update(state, {
        modal: {
          showProcessing: { $set: true }
        }
      }
      );

    case SHOW_PRIZE_DETAILS:
      return update(state, {
        modal: {
          showPrizeDetails: { $set: true },
          prizeDetailsModalPrizeItem: { $set: action.data.prizeItem }
        }
      }
      );

    case SHOW_LIVE_GAMES:
      return update(state, {
        modal: {
          showLiveGames: { $set: true },
        }
      }
      );

    case HIDE_LIVE_GAMES:
      return update(state, {
        modal: {
          showLiveGames: { $set: false },
        }
      }
      );

    case SHOW_ONE_CLICK_BUY:
      return update(state, {
        modal: {
          showOneClickBuy: { $set: true },
          oneClickBuyGameId: { $set: action.data.gameId },
          oneClickBuyGameInstanceId: { $set: action.data.gameInstanceId },
          oneClickBuySessionId: { $set: action.data.continueSessionId },
          oneClickBuyPaymentOptionId: { $set: action.data.paymentOptionId },
        }
      }
      );

    case HIDE_ONE_CLICK_BUY:
      return update(state, {
        modal: {
          showOneClickBuy: { $set: false },
        }
      }
      );

    case SHOW_ONE_CLICK_BUY_INSTANT:
      return update(state, {
        modal: {
          showOneClickBuyInstant: { $set: true },
          oneClickBuyGameId: { $set: action.data.gameId },
          oneClickBuyGameInstanceId: { $set: action.data.gameInstanceId }
        }
      }
      );

    case HIDE_ONE_CLICK_BUY_INSTANT:
      return update(state, {
        modal: {
          showOneClickBuyInstant: { $set: false },
        }
      }
      );

    case SHOW_REALITY_CHECK:
      return update(state, {
        modal: {
          showRealityCheck: { $set: true }
        }
      }
      );

    case HIDE_REALITY_CHECK:
      return update(state, {
        modal: {
          showRealityCheck: { $set: false },
        }
      }
      );

    case SHOW_ONECLICK_NUMBER_PICK:
      return update(state, {
        modal: {
          showOneClickNumberPick: { $set: true },
          oneClickNumberPickValue: { $set: action.data.pick },
          oneClickNumberPickIndex: { $set: action.data.pickIndex },
          oneClickNumberPickCallback: { $set: action.data.pickChangeCallback },
          oneClickNumberPickDrawRangeMax: { $set: action.data.drawRangeMax }
        }
      }
      );

    case HIDE_ONECLICK_NUMBER_PICK:
      return update(state, {
        modal: {
          showOneClickNumberPick: { $set: false },
          oneClickNumberPickValue: { $set: null },
          oneClickNumberPickIndex: { $set: null },
          oneClickNumberPickCallback: { $set: null },
          oneClickNumberPickDrawRangeMax: { $set: null }
        }
      }
      );

    case SHOW_AD_POPUP:
      return update(state, {
        modal: {
          showAdPopup: { $set: true },
          ad: { $set: action.data.ad },
          adInfo: { $set: action.data.adInfo }
        }
      });

    case HIDE_AD_POPUP:
      return update(state, {
        modal: {
          showAdPopup: { $set: false },
          ad: { $set: null },
          adInfo: { $set: null }
        }
      });

    case SHOW_RECONNECTING:
      return update(state, {
        modal: {
          showReconnecting: { $set: true }
        }
      }
      );

    case HIDE_PROCESSING:
      return update(state, {
        modal: {
          showProcessing: { $set: false }
        }
      }
      );

    case HIDE_PRIZE_DETAILS:
      return update(state, {
        modal: {
          showPrizeDetails: { $set: false },
          prizeDetailsModalPrizeItem: { $set: null }
        }
      }
      );

    case SHOW_FULL_REG:
      return update(state, {
        modal: {
          showFullReg: { $set: true }
        }
      }
      );

    case HIDE_FULL_REG:
      return update(state, {
        myCurrentRegStep: { $set: 1 },
        modal: {
          showFullReg: { $set: false }
        }
      }
      );

    case SHOW_FORGOT_PASS:
      return update(state, {
        modal: {
          showForgotPass: { $set: true }
        }
      }
      );

    case HIDE_FORGOT_PASS:
      return update(state, {
        modal: {
          showForgotPass: { $set: false }
        }
      }
      );

    case SHOW_RULES:
      return update(state, {
        modal: {
          showRules: { $set: true }
        },
        rulesDesc: { $set: action.data.gameDesc }
      }
      );

    case HIDE_RULES:
      return update(state, {
        modal: {
          showRules: { $set: false }
        },
        rulesDesc: { $set: null }
      }
      );

    case SHOW_PAYOUT:
      return update(state, {
        modal: {
          showPayout: { $set: true }
        },
        // rulesDesc: { $set: action.data.gameDesc }
      }
      );

    case HIDE_PAYOUT:
      return update(state, {
        modal: {
          showPayout: { $set: false }
        },
        // rulesDesc: { $set: null }
      }
      );


    case SHOW_PHONE_VALIDATION:
      return update(state, {
        session: {
          phoneValidationStatus: { $set: null }
        },
        modal: {
          showPhoneValidation: { $set: true }
        }
      }
      );

    case HIDE_PHONE_VALIDATION:
      return update(state, {
        modal: {
          showPhoneValidation: { $set: false }
        }
      }
      );

    case SHOW_FORGOT_CONFIRM:
      return update(state, {
        modal: {
          showForgotConfirm: { $set: true }
        }
      }
      );

    case HIDE_FORGOT_CONFIRM:
      return update(state, {
        modal: {
          showForgotConfirm: { $set: false }
        }
      }
      );

    case SHOW_DEPOSIT:
      return update(state, {
        modal: {
          showDeposit: { $set: true }
        }
      }
      );

    case HIDE_DEPOSIT:
      return update(state, {
        modal: {
          showDeposit: { $set: false }
        }
      }
      );

    case SHOW_DEPOSIT_CC:
      return update(state, {
        modal: {
          showDepositCC: { $set: true }
        }
      }
      );

    case HIDE_DEPOSIT_CC:
      return update(state, {
        modal: {
          showDepositCC: { $set: false }
        }
      }
      );

    case SHOW_ERROR:
      return update(state, {
        modal: {
          showError: { $set: true }
        }
      }
      );

    case SHOW_LOGIN:
      /*if (window.phoneLoginEnabled == true) {
        return update(state, {
          loggedIn: {$set: false },
          modal: {
            showPhoneLogin: { $set: true }
          },
          loginPhoneError: { $set: null }
        }
        );
      } else {*/
      return update(state, {
        loggedIn: { $set: false },
        modal: {
          showLogin: { $set: true }
        },
        loginError: { $set: null },
        loginErrorMessage: { $set: null }
      }
      );
    //}

    case HIDE_LOGIN:
      /*if (window.phoneLoginEnabled == true) {
        return update(state, {
          modal: {
            showPhoneLogin: { $set: false }
          },
          loginPhoneError: { $set: null }
        }
        );
      } else {*/
      return update(state, {
        modal: {
          showLogin: { $set: false }
        },
        loginError: { $set: null },
        loginErrorMessage: { $set: null }
      }
      );
    //}

    case SHOW_REGISTER:
      return update(state, {
        modal: {
          showRegister: { $set: true }
        }
      }
      );

    case HIDE_REGISTER:
      return update(state, {
        modal: {
          showRegister: { $set: false }
        }
      }
      );

    case SHOW_PLAY_GAME:
      return update(state, {
        modal: {
          showPlayGame: { $set: true }
        },
        modalGameInstanceId: { $set: action.modalGameInstanceId }
      }
      );

    case HIDE_PLAY_GAME:
      return update(state, {
        modal: {
          showPlayGame: { $set: false }
        },
        modalGameInstanceId: { $set: null }
      }
      );

    case SHOW_CANCEL_SUBSCRIPTION:
      return update(state, {
        modal: {
          showCancelSubscription: { $set: true }
        },
        modalSubscription: { $set: action.data.subscription }
      }
      );

    case HIDE_CANCEL_SUBSCRIPTION:
      return update(state, {
        modal: {
          showCancelSubscription: { $set: false }
        },
        modalSubscription: { $set: null }
      }
      );

    case GLOBAL_MESSAGE:
      return handleGlobalMessage(state, action);

    case PRIVATE_MESSAGE:
      return handlePrivateMessage(state, action);

    case INIT_LOAD:
      return {
        ...state
      };

    case LOAD:
      return update(state, {
        loading: { $set: true }
      });

    case LOAD_SUCCESS:
      try {
        return processLoad(state, action);
      } catch (e) {
        return state;
      }

    case LOAD_FAIL:
      return update(state, {
        loading: { $set: false },
        loaded: { $set: false },
        error: { $set: action.error }
      });

    case LOAD_GAMES_SUCCESS:
      try {
        return processLoadGames(state, action);
      } catch (e) {
        return state;
      }

    case LOGIN:
      return update(state, {
        loggingIn: { $set: true },
        loggedIn: { $set: false },
        init: { $set: false }
      });

    case LOGIN_SUCCESS:
      return update(state, {
        loggingIn: { $set: false },
        loggedIn: { $set: true },
        loginBackground: { $set: false },
        modal: {
          showLogin: { $set: false }
        }
      });

    case LOGIN_FAIL:
      if (action.error.message) {
        sessionStorage.removeItem('auth_username');
        sessionStorage.removeItem('auth_password');
      }
      return update(state, {
        init: { $set: true },
        loggingIn: { $set: false },
        loggedIn: { $set: false },
        loginError: { $set: null },
        loginErrorMessage: { $set: action.error.message }
      });

    case LOGIN_WHITELABEL:
      return {
        ...state,
      };

    case LOGIN_WHITELABEL_SUCCESS:
      sessionStorage.setItem("sessionId", sessionId);
      return {
        ...state
      };

    case LOGIN_WHITELABEL_FAIL:
      showMetroDialog("<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i> Login Error", "Sorry, we could not log you in at this time.", '', function () { });
      return {
        ...state
      };

    case LOGOUT:
      return {
        ...state,
        //loggingOut: true
      };

    case LOGOUT_SUCCESS:
    case LOGOUT_INTERNAL:
      return {
        ...state,
        loggingOut: false,
        loggedIn: false,
        loaded: false,
        init: false,
        myCurrentRegStep: 1,
        regUsername: '',
        regPass: '',
        regSuccess: false,
        fullRegSuccess: false,
        regUsernameExists: "-2",
        regEmailExists: "-2",
        regCountryByIp: '',
        regPostalCodeValidation: "-1",
        session: {}
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };

    case BALANCE_UPDATE:
      return balanceUpdate(state, action);

    case LOAD_GAME_LOBBY:
      return {
        ...state,
        loading: true,
        error: false
      };

    case LOAD_GAME_LOBBY_SUCCESS:
      return processGameLobby(state, action);

    case LOAD_GAME_LOBBY_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case GET_BONOBO_PAYOUT_SUCCESS:
      return processBonoboPayout(state, action);

    case GET_ACCOUNT_BALANCE_SUCCESS:
      return accountUpdate(state, action);

    case SEARCH_GAME_HISTORY:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SEARCH_GAME_HISTORY_SUCCESS:
      return handleSearchGameHistorySuccess(state, action);

    case SEARCH_GAME_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case GET_GAME_HISTORY:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_GAME_HISTORY_SUCCESS:
      let history = getASArray(state.session.gameHistory.result);
      let historyIndex = history.findIndex(function (s) {
        return s.gameInstanceId == action.result.gameInstanceId;
      });

      return update(state,
        {
          loading: { $set: false },
          session: {
            gameHistory: {
              result: {
                [historyIndex]: {
                  detail: { $set: action.result }
                }
              }
            }
          }
        });

    case GET_GAME_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case GET_ACCOUNTS_SUCCESS:
      return update(state,
        {
          session: {
            playerAccounts: {
              $set: action.result
            }
          }
        });

    case GET_DEPOSIT_REQUESTS_SUCCESS:
      return update(state,
        {
          session: {
            depositRequests: {
              $set: action.result
            }
          }
        });

    case GET_PENDING_WITHDRAWALS_SUCCESS:
      return update(state,
        {
          session: {
            pendingWithdrawals: {
              $set: action.result
            },
            paymentOptions: { $set: null }
          }
        });

    case GET_DEPOSIT_POPUP_STEP_SUCCESS:
      return update(state,
        {
          session: {
            depositPopup: {
              $set: action.result
            }
          }
        });

    case GET_DEPOSIT_STEP_SUCCESS:
      return update(state,
        {
          session: {
            depositStatus: {
              $set: action.result
            }
          }
        });

    case GET_WITHDRAWAL_STEP:
    case GET_WITHDRAWAL_STEP2:
    case WITHDRAWAL_SUBMIT:
      return update(state, { loading: { $set: true } });

    case GET_WITHDRAWAL_STEP_SUCCESS:
      return update(state,
        {
          loading: { $set: false },
          session: {
            withdrawalStatus: {
              $set: action.result
            }
          }
        });

    case GET_WITHDRAWAL_STEP2_SUCCESS:
      return update(state,
        {
          loading: { $set: false },
          session: {
            withdrawalStatus: {
              $set: action.result
            }
          }
        });

    case WITHDRAWAL_SUBMIT_SUCCESS:
      return update(state,
        {
          loading: { $set: false },
          session: {
            withdrawalStatus: {
              next: { $set: true },
              status: { $set: action.result }
            }
          }
        });

    case WITHDRAWAL_SUBMIT_FAIL:
      return update(state,
        {
          loading: { $set: false },
          session: {
            withdrawalStatus: {
              next: { $set: 'error' },
              error: { $set: action.error.$c.message }
            }
          }
        });

    case WITHDRAWAL_CANCEL_SUCCESS:
      return update(state,
        {
          session: {
            pendingWithdrawals:
              { $set: [] }
          }
        });

    case SAVED_PAYMENT_OPTIONS:
      return update(state,
        {
          loading: { $set: true },
          session: {
            depositStatus: {
              amount: { $set: action.data.amount },
              currencyId: { $set: action.data.currencyId }
            }
          }
        });

    case SAVED_PAYMENT_OPTIONS_SUCCESS:
      return update(state,
        {
          loading: { $set: false },
          session: {
            paymentOptions: {
              $set: action.result
            }
          }
        });

    case WITHDRAWAL_CONFIRM:
      return update(state,
        {
          session: {
            withdrawalStatus: {
              $set: action.data
            }
          }
        });

    case REQUEST_PHONE_VALIDATION_SUCCESS:
      return update(state,
        {
          session: {
            phoneValidationStatus: {
              $set: action.result
            }
          }
        });

    case REQUEST_PHONE_VALIDATION_FAIL:
      return update(state,
        {
          session: {
            phoneValidationStatus: {
              $set: action.error
            }
          }
        });

    case SUBMIT_PHONE_VALIDATION_SUCCESS:
      return update(state,
        {
          session: {
            phoneValidationStatus: {
              $set: action.result
            },
            player: {
              verification: {
                phoneVerifiedDate: { $set: new Date() }
              }
            }
          }
        });

    case SUBMIT_PHONE_VALIDATION_FAIL:
      return update(state,
        {
          session: {
            phoneValidationStatus: {
              $set: action.error
            }
          }
        });

    case CREATE_SUPPORT_CASE:
      return {
        ...state,
        createdTicket: 0
      };

    case CREATE_SUPPORT_CASE_SUCCESS:
      return {
        ...state,
        createdTicket: action.result
      };

    case CREATE_SUPPORT_CASE_FAIL:
      return {
        ...state,
        createdTicket: -1
      };

    case GET_GAME_LAST_WINNING_DRAWS_SUCCESS:
      action.result.gameTitle = action.result.gameTitle ? decodeBase64(action.result.gameTitle) : null;
      return {
        ...state,
        gameLastWin: action.result
      };

    case SEARCH_TRANSACTIONS:
      return {
        ...state,
        loading: true
      };

    case LOAD_PROVINCES:
      return {
        ...state,
        loading: true
      };

    case LOAD_COUNTRIES:
      return {
        ...state,
        loading: true
      };

    case SEARCH_TRANSACTIONS_SUCCESS:
      return update(state,
        {
          loading: { $set: false },
          session: {
            cashierHistory: {
              $set: action.result
            }
          }
        });

    case SUBSCRIPTION_ACTIVE:
      return {
        ...state
      };

    case SUBSCRIPTION_ACTIVE_SUCCESS:
      return {
        ...state,
        subscriptionActive: action.result
      };

    case PLAYER_GAME_SUBSCRIPTION_PLAYS:
      return {
        ...state
      };

    case PLAYER_GAME_SUBSCRIPTION_PLAYS_SUCCESS:
      let subs = getASArray(state.subscriptions.result);
      let subIndex = subs.findIndex(function (s) {
        return s.id == action.result.id;
      });

      if (subIndex > -1) {
        return update(state,
          {
            loading: { $set: false },
            subscriptions: {
              result: {
                [subIndex]: {
                  playerPlays: { $set: action.result.playerPlays }
                }
              }
            }
          });
      }

    case HAS_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        hasPlayerGameSubscriptions: action.result
      };

    case LOAD_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true
      };

    case LOAD_SUBSCRIPTIONS_SUCCESS:
      return handleSearchSubscriptionsSuccess(state, action);

    case LOAD_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        subscriptions: null
      };

    case LOAD_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        loading: true,
        subscriptionDetails: null
      };

    case LOAD_SUBSCRIPTION_DETAILS_SUCCESS:
      return handleSubscriptionDetailsSuccess(state, action);

    case LOAD_SUBSCRIPTION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionDetails: null
      };

    case LOAD_SUBSCRIPTION_TRANSACTIONS:
      return {
        ...state,
        loading: true
      };

    case LOAD_SUBSCRIPTION_TRANSACTIONS_SUCCESS:
      return handleSearchSubscriptionTransactionsSuccess(state, action);

    case LOAD_SUBSCRIPTION_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionTransactions: null
      };


    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loading: true
      };

    case CANCEL_SUBSCRIPTION_SUCCESS:

      let subscriptions = getASArray(state.subscriptions.result);
      let subscriptionIndex = subscriptions.findIndex(function (s) {
        return s.id == state.modalSubscription.id;
      });

      return update(state,
        {
          loading: { $set: false },
          modal: {
            showCancelSubscription: { $set: false }
          },
          subscriptionCanceled: { $set: true },
          subscriptions: {
            result: {
              [subscriptionIndex]: {
                active: { $set: false },
                remainingBalance: { $set: 0 }
              }
            }
          }
        });

    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false
      };

    case PLAYER_JOIN:
      return handlePlayerJoin(state, action);

    case JOIN_GAME:
      return {
        ...state,
        joiningGame: true
      };

    case DO_CHECK_USERNAME_SUCCESS:
      return handleDoCheckUsernameSuccess(state, action);

    case DO_CHECK_EMAIL_SUCCESS:
      return handleDoCheckEmailSuccess(state, action);

    case DO_CHECK_GOVERNMENT_ID_SUCCESS:
      return handleDoCheckGovernmentIdSuccess(state, action);

    case PLAYER_SEGMENT:
      return handlePlayerSegment(state, action);

    case JOIN_GAME_SUCCESS:
      return handleJoinSuccess(state, action);

    case JOIN_GAME_FAIL:
      return handleJoinFail(state, action);

    case JOIN_GAME_PERM_SUCCESS:
      return handleJoinSuccessPerm(state, action);

    case JOIN_GAME_PERM_FAIL:
      return handleJoinFail(state, action);

    case GAME_UPDATE:
      return updateGame(state, action);

    case GAME_UPDATE_PLAYS_PLAYERS:
      return updateGamePlaysPlayers(state, action);

    case GAME_UPDATE_GLOBAL:
      return updateGameConflated(state, action);

    case GAME_CREATE:
      return createGame(state, action);

    case SAVE_FAV_GAME_SUCCESS:
      return saveFavGameSuccess(state, action);

    case SAVE_USER_PASS:
      return updateUsrPass(state, action);

    case GAME_DELETE:
      return deleteGame(state, action);

    case GAME_END:
      return gameEnd(state, action);

    case GAME_START:
      return gameStart(state, action);

    case GAME_RESCHEDULED:
      return gameRescheduled(state, action);

    case GAME_FAIL_RETRY:
      return gameFailRetry(state, action);

    case GAME_MAINTENANCE:
      return gameMaintenance(state, action);

    case DO_PLAYER_REGISTRATION_SUCCESS:
      return doPlayerRegistrationSuccess(state, action);

    case DO_PLAYER_REGISTRATION_FAIL:
      return doPlayerRegistrationFail(state, action);

    case DO_PLAYER_REGISTRATION_FULL_SUCCESS:
      return doPlayerRegistrationFullSuccess(state, action);

    case GAME_REFUNDED:
      return gameRefunded(state, action);

    case REMOVE_FAV_FROM_LIST:
      return removeGameFromFav(state, action);

    case DO_GET_COUNTRY_BY_IP_SUCCESS:
      return getCountryByIpSuccess(state, action);

    case DO_CHECK_POSTAL_CODE:
      return update(state, {
        regPostalCodeValidation: { $set: "0" }
      });

    case DO_CHECK_POSTAL_CODE_SUCCESS:
      return checkPostalCodesuccess(state, action);

    case PLAYER_EMAIL_VERIFICATION:
      return playerEmailVerification(state, action);

    case PLAYER_ADDRESS_VERFICATION:
      return playerAddressVerification(state, action);

    case PLAYER_PHONE_VERIFICATION:
      return playerPhoneVerification(state, action);

    case PLAYER_ID_VERIFICATION:
      return playerIdVerification(state, action);

    case PLAYER_ORDER:
      return playerOrder(state, action);

    case PLAYER_COUPON:
      return playerCoupon(state, action);

    case PLAYER_RELOAD_VERIFICATION_REQUEST:
      return playerReloadVerificationRequest(state, action);

    case UPDATE_PLAYER_DETAILS:
      return updatePlayerDetails(state, action);

    case PLAYER_BANNED:
      return {
        ...state,
        forceLogout: true
      };

    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state
      };

    case DO_CHECK_PASSWORD:
      return {
        ...state,
        loading: true,
        passwordChecked: 'false'
      };

    case DO_CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordChecked: 'true'
      };

    case DO_CHECK_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        passwordChecked: 'error'
      };

    case DO_CHECK_COUPON:
      return update(
        state, {
        couponStatus: { $set: null }
      });

    case DO_CHECK_COUPON_SUCCESS:
      return update(
        state, {
        couponStatus: { $set: action.result }
      });

    case RESET_PASSWORD:
      return {
        ...state,
        changedPassword: 'false'
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        changedPassword: 'true'
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        changedPassword: 'error'
      };

    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
        changedPassword: 'false'
      };

    case UPDATE_PASSWORD_SUCCESS:
      return update(
        state,
        {
          loading: { $set: false },
          changedPassword: { $set: 'true' },
          session: {
            player: {
              statistics: {
                lastPasswordChangeDate: { $set: Date.now() }
              }
            }
          }
        }
      );

    case UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        changedPassword: 'error'
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        changedPassword: 'false'
      };

    case CHANGE_PASSWORD_SUCCESS:
      return update(
        state,
        {
          changedPassword: { $set: 'true' },
          session: {
            player: {
              changePassword: { $set: false },
              statistics: {
                lastPasswordChangeDate: { $set: Date.now() }
              }
            }
          }
        }
      );

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changedPassword: 'error'
      };

    case UPDATE_EMAIL:
      return {
        ...state,
        loading: true,
        changedEmail: 'false'
      };

    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        changedEmail: 'true'
      };

    case UPDATE_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        changedEmail: 'error'
      };

    case UPDATE_PHONES:
      return {
        ...state,
        loading: true,
        changedPhones: 'false'
      };

    case UPDATE_PHONES_SUCCESS:
      return {
        ...state,
        loading: false,
        changedPhones: 'true'
      };

    case UPDATE_PHONES_FAIL:
      return {
        ...state,
        loading: false,
        changedPhones: 'error'
      };

    case UPDATE_ADDRESS:
      return {
        ...state,
        loading: true,
        changedAddress: 'false'
      };

    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        changedAddress: 'true'
      };

    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        changedAddress: 'error'
      };

    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        changedNotifications: 'false'
      };

    case UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        changedNotifications: 'true'
      };

    case UPDATE_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        changedNotifications: 'error'
      };

    case UPDATE_SELF_LIMITS:
      return {
        ...state,
        changedSelfLimits: 'false'
      };

    case UPDATE_SELF_LIMITS_SUCCESS:
      return {
        ...state,
        changedSelfLimits: 'true'
      };

    case UPDATE_SELF_LIMITS_FAIL:
      return {
        ...state,
        changedSelfLimits: action.error
      };

    case UPDATE_SELF_EXCLUSION:
      return {
        ...state,
        changedSelfExclude: 'false'
      };

    case UPDATE_SELF_EXCLUSION_SUCCESS:
      return {
        ...state,
        changedSelfExclude: 'true'
      };

    case UPDATE_SELF_EXCLUSION_FAIL:
      return {
        ...state,
        changedSelfExclude: action.error
      };

    case UPDATE_TIMEOUT:
      return {
        ...state,
        changedTimeout: 'false'
      };

    case UPDATE_TIMEOUT_SUCCESS:
      return {
        ...state,
        changedTimeout: 'true'
      };

    case UPDATE_TIMEOUT_FAIL:
      return {
        ...state,
        changedTimeout: action.error
      };

    case GET_REFERRAL_REPORT_SUCCESS:
      return {
        ...state,
        referrals: action.result
      }

    case SEND_PLAYER_INVITE:
      return {
        ...state,
        referralEmailSent: 'false'
      }

    case SEND_PLAYER_INVITE_SUCCESS:
      return {
        ...state,
        referralEmailSent: 'true'
      }

    case SEND_PLAYER_INVITE_FAIL:
      return {
        ...state,
        referralEmailSent: action.error ? action.error : 'error'
      }

    case CHECK_TOKEN:
      return {
        ...state,
        tokenChecked: 'false'
      };

    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        tokenChecked: 'true'
      };

    case CHECK_TOKEN_FAIL:
      return {
        ...state,
        tokenChecked: 'error'
      };

    case GAME_REGISTRATION_START:
      return registrationStart(state, action);

    case GAME_REGISTRATION_END:
      return registrationEnd(state, action);

    case GAME_RESULTS_SUCCESS:
      return gameResultsSuccess(state, action);

    case GAME_PLAYERS_SUCCESS:
      return getPlayersSuccess(state, action);

    case LOAD_COUNTRIES_SUCCESS:
      return getCountriesSuccess(state, action);

    case LOAD_PROVINCES_SUCCESS:
      return getProvincesSuccess(state, action);

    case GAME_PAYOUT_SUCCESS:
      return getPayoutSuccess(state, action);

    case CLAIM_IN_GAME_BONUS_SUCCESS:
      return claimInGameBonusSuccess(state, action);

    case GET_MAIN_ADS_SUCCESS:
      return getMainAdsSuccess(state, action);

    case GAME_READY:
      return gameReady(state, action);

    case GAME_PROGRESSIVE:
      return gameProgressive(state, action);

    case CHANGE_BACKGROUND:
      if (action.data.url) {
        $("#contentSection")
          .css("background", "url('" + action.data.url + "') no-repeat center center fixed")
          .css("-webkit-background-size", "cover")
          .css("-moz-background-size", "cover")
          .css("-o-background-size", "cover")
          .css("background-size", "cover");
      }
      return {
        ...state,
        backgroundUrl: action.data.url,
        backgroundId: action.data.id
      };

    case SAVE_LOBBY_GAMES:
      return update(state, {
        lobbyGamesList: { $set: action.data }
      });

    case TOGGLE_ALL_SOUNDS:
      return update(state, {
        soundEnabled: { $set: action.data }
      })

    case BONOBO_TICKER:
      return update(state, {
        bonoboTicker: { $set: action.data }
      });

    case LOAD_GAME_TYPES:
      return {
        ...state,
        loading: true
      };

    case LOAD_GAME_TYPES_SUCCESS:
      try {
        return processLoadGameTypes(state, action);
      } catch (e) {
        return state;
      }

    case LOAD_GAME_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    case LOAD_GAME_TYPE_INSTANCES:
      return {
        ...state,
        loading: true
      };

    case LOAD_GAME_TYPE_INSTANCES_SUCCESS:
      try {
        return processLoadGameTypeInstances(state, action);
      } catch (e) {
        return state;
      }

    case LOAD_ALL_GAME_TYPE_INSTANCES:
      return {
        ...state,
        loading: true
      };

    case LOAD_ALL_GAME_TYPE_INSTANCES_SUCCESS:
      try {
        return processLoadAllGameTypeInstances(state, action);
      } catch (e) {
        return state;
      }

    case LOAD_GAME_TYPE_INSTANCES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };

    default:
      return state;
  }
}

function handleGlobalMessage(state, action) {
  const message = {
    type: 'global',
    gameId: action.data.gameId,
    title: action.data.messageName,
    body: action.data.messageBody
  };

  return update(state,
    {
      notifications: { $push: [message] }
    });
}

function handlePrivateMessage(state, action) {
  return update(state,
    {
      notifications: { $push: [action.data] }
    });
}

function processLoadGameTypes(state, action) {
  return update(state, {
    loading: { $set: false },
    gameTypes: { $set: action.result }
  });
}

function processLoadGameTypeInstances(state, action) {
  return update(state, {
    loading: { $set: false },
    gameTypeInstances: { $set: action.result }
  });
}

function processLoadAllGameTypeInstances(state, action) {
  return update(state, {
    loading: { $set: false },
    gameTypeAllInstances: { $set: action.result }
  });
}

function balanceUpdate(state, action) {
  const updated = getASArray(action.data.accounts)[0];

  if (!updated) return state;

  const accounts = getASArray(state.session.playerAccounts);
  const accountIndex = accounts.findIndex(function (game) {
    return (game.accountId == updated.accountId) && (game.accountCurrency == updated.accountCurrency);
  });

  if (accountIndex == -1) return state;

  // Game Win
  if (action.data.triggerEvent == 3) {
    // dont update balance while game is playing
    // if (window?.playingGameInstanceId == action?.data?.gameInstanceId) {
    //   return state;
    // }
  }

  if (state.session.playerAccounts[accountIndex].timeStamp &&
    state.session.playerAccounts[accountIndex].timeStamp > action.data.timeStamp) {
    console.log("TIMESTAMP OLDER, SKIPPED BALANCE UPDATE",
      { action, timeStamp: action.data.timeStamp });
    return state;
  }
  console.log({ TIMESTAMP: action.data.timeStamp })
  return update(state,
    {
      session: {
        playerAccounts: {
          [accountIndex]: {
            accountBalance: { $set: parseFloat(updated.accountBalance) },
            timeStamp: { $set: action.data.timeStamp }
          }
        }
      }
    });
}

function accountUpdate(state, action) {
  const updated = action.result;

  if (!updated) return state;

  const accounts = getASArray(state.session.playerAccounts);
  const accountIndex = accounts.findIndex(function (game) {
    return (game.accountId == updated.accountId) && (game.accountCurrency == updated.accountCurrency);
  });

  if (accountIndex == -1) return state;

  return update(state,
    {
      session: {
        playerAccounts: {
          [accountIndex]: {
            accountBalance: { $set: parseFloat(updated.accountBalance) }
          }
        }
      }
    });
}

function handlePlayerJoin(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  if (gameInstanceIndex < 0) {
    return state;
  }

  return update(state,
    {
      session: {
        filteredGames: {
          [gameInstanceIndex]: {
            playerInGame: { $set: true },
            playerPlaysInGame: { $set: getASArray(action.data.plays).length },
          }
        }
      }
    });
}

function handleDoCheckUsernameSuccess(state, action) {
  //default is -2
  //-1 for validation error
  // 0 if it exists
  // 1 if it doesn't exist (OK to use)
  console.log("Username: ");
  console.log(action);
  return update(state, {
    regUsernameExists: { $set: action.result }
  });
}

function handlePlayerSegment(state, action) {
  console.log("My plaer segment: ");
  console.log(action.data.segment);
  return update(state, {
    session: {
      player: {
        verification: {
          playerSegment: { $set: action.data.segment }
        }
      }
    }
  });
}

function handleDoCheckEmailSuccess(state, action) {
  //default is -2
  //-1 for validation error
  // 0 if it exists
  // 1 if it doesn't exist (OK to use)
  return update(state, {
    regEmailExists: { $set: action.result }
  })
}

function handleDoCheckGovernmentIdSuccess(state, action) {
  //default is -2
  //-1 for validation error
  // 0 if it exists
  // 1 if it doesn't exist (OK to use)
  return update(state, {
    regGovIdExists: { $set: action.result }
  })
}

function handleJoinSuccess(state, action) {
  console.log("Join Success");

  const gamesInstances = getASArray(state.session.filteredGames);

  let createGame = false;
  let gameInstanceIndex = -1;
  let gameInstance;

  gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.result.gameInstanceId;
  });
  gameInstance = Object.assign({}, gamesInstances[gameInstanceIndex]);

  if (action.result.gameInstanceIdJoined && action.result.gameInstanceId != action.result.gameInstanceIdJoined) {
    gameInstanceIndex = gamesInstances.findIndex(function (game) {
      return game.gameInstanceId == action.result.gameInstanceIdJoined;
    });

    if (gameInstanceIndex != -1) {
      gameInstance = Object.assign({}, gamesInstances[gameInstanceIndex]);
    } else {
      createGame = true;
      gameInstance.gameInstanceId = action.result.gameInstanceIdJoined;
      gameInstance.gamePlayCurrent = action.result.playsInGame;
      gameInstance.gamePlayerCurrent = action.result.playersInGame;
      gameInstance.playerInGame = true;
      gameInstance.playerPlaysInGame = getASArray(action.result.joinEvent.plays).length;
      gameInstance.lastJoinTimeMs = new Date().getTime();
      gameInstance.joinCode = action.result.exitCode;
      gameInstance.subscriptionCode = action.result.subscriptionCode;
      gameInstance.gameStatus = 'FULL';
    }
  }

  const accounts = getASArray(state.session.playerAccounts);
  const updatedAccount = getASArray(action.result.joinEvent.balanceUpdateEvent.accounts)[0];

  const accountIndex = accounts.findIndex(function (account) {
    return (account.accountId == updatedAccount.accountId && account.accountCurrency == updatedAccount.accountCurrency);
  });

  const playsInGame = action.result.playsInGame ? action.result.playsInGame : gameInstance.gamePlayCurrent;
  const playersInGame = action.result.playersInGame ? action.result.playersInGame : gameInstance.gamePlayerCurrent;

  if (createGame == false) {
    return update(state,
      {
        joiningGame: { $set: false },
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gamePlayCurrent: { $set: playsInGame },
              gamePlayerCurrent: { $set: playersInGame },
              playerInGame: { $set: true },
              playerPlaysInGame: { $set: getASArray(action.result.joinEvent.plays).length },
              lastJoinTimeMs: { $set: new Date().getTime() },
              joinCode: { $set: action.result.exitCode },
              subscriptionCode: { $set: action.result.subscriptionCode }
            }
          },
          playerAccounts: {
            [accountIndex]: {
              accountBalance: { $set: parseFloat(updatedAccount.accountBalance) }
            }
          }
        }
      });
  } else {
    return update(state,
      {
        joiningGame: { $set: false },
        session: {
          filteredGames: {
            $push: [gameInstance]
          },
          playerAccounts: {
            [accountIndex]: {
              accountBalance: { $set: parseFloat(updatedAccount.accountBalance) }
            }
          }

        },
      });
  }
}

function handleJoinSuccessPerm(state, action) {

  const gamesInstances = getASArray(state.session.filteredGames);

  let responses = getASArray(action.result.response);
  let updatedFilteredGames = [];
  let responseGames = [];

  const accounts = getASArray(state.session.playerAccounts);
  const updatedAccount = getASArray(
    action.result.joinEvent.balanceUpdateEvent.accounts
  )[0];

  const accountIndex = accounts.findIndex(function (account) {
    return (account.accountId == updatedAccount.accountId &&
      account.accountCurrency == updatedAccount.accountCurrency);
  });

  console.log({ updatedAccount })


  responses.map(response => {
    let gameInstance;
    let createGame = false;
    let gameInstanceIndex = -1;

    gameInstanceIndex = gamesInstances.findIndex(function (game) {
      return game.gameInstanceId == response.gameInstanceId;
    });
    gameInstance = Object.assign({}, gamesInstances[gameInstanceIndex]);

    // if joined id is different from attempted join id
    if (response.gameInstanceIdJoined && response.gameInstanceId != response.gameInstanceIdJoined) {
      gameInstanceIndex = gamesInstances.findIndex(function (game) {
        return game.gameInstanceId == response.gameInstanceIdJoined;
      });

      if (gameInstanceIndex != -1) {
        gameInstance = Object.assign({}, gamesInstances[gameInstanceIndex]);
      } else {
        createGame = true;
        gameInstance.gameInstanceId = response.gameInstanceIdJoined;
        gameInstance.gamePlayCurrent = response.playsInGame;
        gameInstance.gamePlayerCurrent = response.playersInGame;
        gameInstance.playerInGame = true;
        gameInstance.playerPlaysInGame = getASArray(response.joinEvent.plays).length;
        gameInstance.lastJoinTimeMs = new Date().getTime();
        gameInstance.joinCode = response.exitCode;
        gameInstance.subscriptionCode = response.subscriptionCode;
      }
    }


    const playsInGame = response.playsInGame ? response.playsInGame : gameInstance.gamePlayCurrent;
    const playersInGame = response.playersInGame ? response.playersInGame : gameInstance.gamePlayerCurrent;

    if (createGame == false) {
      responseGames.push({
        ...gameInstance,
        gamePlayCurrent: playsInGame,
        gamePlayerCurrent: playersInGame,
        playerInGame: true,
        playerPlaysInGame: getASArray(response.joinEvent.plays).length,
        lastJoinTimeMs: new Date().getTime(),
        joinCode: response.exitCode,
        subscriptionCode: response.subscriptionCode
      })
    } else {
      responseGames.push(gameInstance);
    };

  });

  responseGames.map((responseGame) => {
    let filteredGameResponseGame = gamesInstances.find(filteredGame => {
      return filteredGame.gameInstanceId == responseGame.gameInstanceId;
    });

    if (filteredGameResponseGame) {
      Object.assign(filteredGameResponseGame, responseGame);
    } else {
      gamesInstances.push(responseGame);
    }
  });

  return update(state,
    {
      joiningGame: { $set: false },
      session: {
        filteredGames: { $set: gamesInstances }
        ,
        playerAccounts: {
          [accountIndex]: {
            accountBalance: { $set: parseFloat(updatedAccount.accountBalance) }
          }
        }
      }
    });

}

function handleJoinFail(state, action) {
  console.log("Join Fail", action.error);
  let errorCode = action.error.exitCode ? action.error.exitCode : (action.error.Vc ? Number(action.error.Vc) : -1);

  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == (action.error.gameInstanceId ? action.error.gameInstanceId : Number(action.error.Wc));
  });

  if (gameInstanceIndex == -1) return state;

  if (action.error.playersInGame && action.error.playersInGame > 0) {
    return update(state,
      {
        joiningGame: { $set: false },
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              lastJoinTimeMs: { $set: new Date().getTime() },
              gamePlayerCurrent: { $set: action.error.playersInGame },
              gamePlayCurrent: { $set: action.error.playsInGame },
              joinCode: { $set: errorCode }
            }
          }
        }
      });
  } else
    return update(state,
      {
        joiningGame: { $set: false },
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              lastJoinTimeMs: { $set: new Date().getTime() },
              joinCode: { $set: errorCode }
            }
          }
        }
      });
}

function processGameLobby(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.result.gameDetails.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  const gameInstance = gamesInstances[gameInstanceIndex];
  const gameLobbyBackground = getASArray(action.result.gameBrandingInfo).find(b => b.brandingType == "GAME_LOBBY_BACKGROUND");

  if (gameLobbyBackground) {
    $("#app")
      .css("background", "url('" + gameLobbyBackground.brandingUrl + "')")
      .css("background-size", "cover");
  }

  action.result.gameDetails.gameTitleShort = action.result.gameDetails.gameTitleShort ? decodeBase64(action.result.gameDetails.gameTitleShort) : null;
  action.result.gameDetails.gameDescription = action.result.gameDetails.gameDescription ? decodeBase64(action.result.gameDetails.gameDescription) : null;
  action.result.gameDetails.gameDescriptionShort = action.result.gameDetails.gameDescriptionShort ? decodeBase64(action.result.gameDetails.gameDescriptionShort) : null;
  action.result.gameDetails.gameInfo = action.result.gameDetails.gameInfo ? decodeBase64(action.result.gameDetails.gameInfo) : null;
  action.result.gameDetails.gameInfoShort = action.result.gameDetails.gameInfoShort ? decodeBase64(action.result.gameDetails.gameInfoShort) : null;

  action.result.gameDetails.oneClickStartInfo = action.result.gameDetails.oneClickStartInfo ? decodeBase64(action.result.gameDetails.oneClickStartInfo) : null;
  action.result.gameDetails.oneClickGameLobbyInfo = action.result.gameDetails.oneClickGameLobbyInfo ? decodeBase64(action.result.gameDetails.oneClickGameLobbyInfo) : null;
  action.result.gameDetails.oneClickSuccessInfo = action.result.gameDetails.oneClickSuccessInfo ? decodeBase64(action.result.gameDetails.oneClickSuccessInfo) : null;

  return update(state,
    {
      loading: { $set: false },
      showSection: { $set: "numberPicking" },
      session: {
        filteredGames: {
          [gameInstanceIndex]: {
            gameLobby: { $set: action.result },
            //bonoboPayout: {$set: action.result.gameDetails.payoutTopPrize}
          }
        }
      }
    });
}

function processBonoboPayout(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.result.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  return update(state,
    {
      session: {
        filteredGames: {
          [gameInstanceIndex]: {
            prizesAvailable: { $set: action.result.prizesAvailable },
            bonoboPayout: { $set: getASArray(action.result.payoutInfo)[0].rankPrize },
            bonoboItem: { $set: getASArray(action.result.payoutInfo)[0] }
          }
        }
      }
    });
}

function handleSearchGameHistorySuccess(state, action) {

  return update(state,
    {
      loading: { $set: false },
      session: {
        gameHistory: { $set: action.result },
        gameHistoryMore: { $set: getASArray(action.result.result).length != 5 }
      }
    });

}

function handleSearchSubscriptionsSuccess(state, action) {

  if (!state.subscriptions)
    return update(state,
      {
        loading: { $set: false },
        subscriptionCanceled: { $set: false },
        subscriptions: { $set: action.result }
      });
  else
    return update(state,
      {
        loading: { $set: false },
        subscriptions: {
          result: {
            $push: getASArray(action.result.result)
          }
        }
      });
}

function handleSubscriptionDetailsSuccess(state, action) {
  return update(state,
    {
      loading: { $set: false },
      subscriptionDetails: { $set: action.result }
    });
}

function handleSearchSubscriptionTransactionsSuccess(state, action) {
  return update(state,
    {
      loading: { $set: false },
      subscriptionTransactions: { $set: action.result }
    });
}

function gameReady(state, action) {

  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  const isGameReadyToPlay = (idx) => {
    if (idx == -1) return false;

    const game = getASArray(state.session.filteredGames)[idx];

    if (game.gameStatus == "STARTED")
      return true;

    if (game.tournament == false) {
      return true;
    } else {
      const countdown = game.tournamentStartTime - serverTimeDelta - new Date().getTime();

      if (countdown > 30000) {
        return false;
      } else {
        if (game.gameId == window.mainGameId) {
          mainGameInstanceId = -1;
        }
        return true;
      }
    }
  }

  if (gameInstanceIndex != -1) {
    let newState = update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: "READY" },
              timeStamp: { $set: new Date().getTime() }
            }
          }
        }
      });

    if (gamesInstances[gameInstanceIndex].playerInGame) {
      newState = update(newState, {
        modal: {
          showPlayGame: { $set: isGameReadyToPlay(gameInstanceIndex) }
        },
        modalGameInstanceId: { $set: gamesInstances[gameInstanceIndex].gameInstanceId }
      });
    }
    return newState;
  } else {
    return state;
  }
}

function addGameToFav(myList, fGame) {
  for (let i = 0; i < myList.length; i++) {
    const game = myList[i];

    if (fGame.gameId == game.gameId) {
      fGame.favGame = true;
      break;
    } else {
      fGame.favGame = false;
    }
  }
}

function setBestGameId(games) {
  const closestStartTime = Math.min.apply(Math, games.filter(g => g.tournament == true).map(function (o) { return o.tournamentStartTime; }));
  if (isFinite(closestStartTime)) {
    console.log('finding tournament game with start time ' + closestStartTime);
    var tournament = games.filter(o => o.tournamentStartTime === closestStartTime)[0];
    console.log(tournament);
    // don't replace game if we have a mainGameId
    if (!mainGameId) {
      mainGameId = tournament.gameId;
    }
  } else {
    const mostFilledInsta = Math.min.apply(Math, games.filter(g => g.tournament == false).map(function (o) { return o.gamePlayMax - o.gamePlayCurrent; }));
    console.log('finding instaplay game with plays left ' + mostFilledInsta);
    var instaplay = games.filter(o => (o.gamePlayMax - o.gamePlayCurrent) === mostFilledInsta)[0];
    console.log(instaplay);
    // don't replace game if we have a mainGameId
    if (!mainGameId) {
      mainGameId = instaplay.gameId;
    }
  }
  const slimViewMinGames = (typeof slimGames !== "undefined") ? slimGames : 4;

  if (switchMain == false && games.length < slimViewMinGames) {
    sessionStorage.setItem('slimView', true);
    sessionStorage.setItem('slimViewGameId', mainGameId);
  }
}

function processLoad(state, action) {
  let favGames = [];
  const gamesCount = getASArray(action.result.filteredGames).filter(g => g.gameStatus == 'OPEN').length;
  let switchMainFlag = switchMain;

  const slimViewMinGames = (typeof slimGames !== "undefined") ? slimGames : 4;

  if ((gamesCount > 0 && (switchMain == false && gamesCount < slimViewMinGames)) || sessionStorage.getItem('live')) {
    setBestGameId(getASArray(action.result.filteredGames));
    switchMainFlag = true;
  } else {
    sessionStorage.removeItem('slimView');
  }

  let instancesOfMainGameId = [];

  for (let j = 0; j < getASArray(action.result.filteredGames).length; j++) {
    let fGame = getASArray(action.result.filteredGames)[j];

    // if gameId == mainGameId push to instances array if not cw, sc, bingo
    if (fGame.gameId == window.mainGameId) {
      if (!(fGame.gameRule.contains('scratchcardGame') ||
        fGame.gameRule.contains('crosswordGame') ||
        fGame.gameRule.contains('bingoGame'))) {
        instancesOfMainGameId.push(fGame);
      };
    };

    fGame.gameTitle = decodeBase64(fGame.gameTitle);
    fGame.gameTitleShort = decodeBase64(fGame.gameTitleShort);

    fGame.oneClickStartInfo = fGame.oneClickStartInfo != null ? decodeBase64(fGame.oneClickStartInfo) : null;
    fGame.oneClickGameLobbyInfo = fGame.oneClickGameLobbyInfo != null ? decodeBase64(fGame.oneClickGameLobbyInfo) : null;
    fGame.oneClickSuccessInfo = fGame.oneClickSuccessInfo != null ? decodeBase64(fGame.oneClickSuccessInfo) : null;

    if (state.loggedIn == true) {
      addGameToFav(getASArray(action.result.favoriteGames), fGame);
    }
    if (fGame.tournament == true) {
      //getASArray(action.result.filteredGames)[j].bonoboPayout = 0;
    }
    if (fGame.gameId == mainGameId && (fGame.gameStatus == 'OPEN' || fGame.gameStatus == 'READY')) {
      action.result.mainGameInstanceId = fGame.gameInstanceId;
      mainGameInstanceId = fGame.gameInstanceId;
    }

    if (fGame.oneClickBundleName != null) {
      window.oneClickGameIds.push(fGame.gameId);
    }
    fGame.previousPlays = fGame.gamePlayCurrent;

    if (fGame.gameLobby == null) {
      fGame.gameLobby = new GameLobbyDto();
    }
  }

  if (instancesOfMainGameId.length > 0) {
    // if only one game set that as main
    if (instancesOfMainGameId.length == 1) {
      action.result.mainGameInstanceId = instancesOfMainGameId[0].gameInstanceId;
      mainGameInstanceId = instancesOfMainGameId[0].gameInstanceId;
    };

    if (instancesOfMainGameId.length > 1) {
      let openGameFound = instancesOfMainGameId.find(game => game.gameStatus == 'OPEN' || game.gameStatus == 'READY');
      action.result.mainGameInstanceId = openGameFound.gameInstanceId;
      mainGameInstanceId = openGameFound.gameInstanceId;

    };
  };

  if (action.result.configuration.currenciesServed) {
    for (var x = 0; x < getASArray(action.result.configuration.currenciesServed).length; x++) {
      let c = getASArray(action.result.configuration.currenciesServed)[x];
      c.name = decodeBase64(c.name);
      c.formatPattern = decodeBase64(c.formatPattern);
      c.symbolLeft = decodeBase64(c.symbolLeft);
      c.symbolRight = c.symbolRight != "" ? decodeBase64(c.symbolRight) : "";
    }
  }

  const currentLanguage = localStorage.getItem("language");

  if (action.result.configuration.languagesServed) {
    for (var y = 0; y < getASArray(action.result.configuration.languagesServed).length; y++) {
      let l = getASArray(action.result.configuration.languagesServed)[y];
      l.name = decodeBase64(l.name);
      if (l.languageId == currentLanguage) {
        action.result.configuration.siteMetaTitle = decodeBase64(l.siteMetaTitle);
        action.result.configuration.siteMetaDescription = decodeBase64(l.siteMetaDescription);
        action.result.configuration.siteMetaKeywords = decodeBase64(l.siteMetaKeywords);
        if (l.siteAboutUs)
          action.result.configuration.siteAboutUs = decodeBase64(l.siteAboutUs);
        action.result.configuration.defaultCustomerFooter = l.defaultCustomerFooter ? decodeBase64(l.defaultCustomerFooter) : null;
        action.result.configuration.defaultPermaFooter = l.defaultPermaFooter ? decodeBase64(l.defaultPermaFooter) : null;
        action.result.configuration.defaultCustomerHeader = l.defaultCustomerHeader ? decodeBase64(l.defaultCustomerHeader) : null;
      }
    }
  }

  action.result.lastLoadedOn = new Date().getTime();

  if (action.result.configuration.pinRequired == true) {
    showEnterPIN();
  }

  return update(state, {
    loading: { $set: false },
    loaded: { $set: true },
    init: { $set: true },
    loggedIn: { $set: action.result.player != null },
    MAINTENANCE_ENABLED: { $set: action.result.configuration.MAINTENANCE_ENABLED },
    session: { $set: action.result },
    notifications: { $set: action.result.afterLoginEvents },
    favouriteGameList: { $set: favGames },
    switchMain: { $set: switchMainFlag },
    changePassword: { $set: action.result.player ? action.result.player.changePassword : false },
  });
}

function processLoadGames(state, action) {

  for (let j = 0; j < getASArray(action.result).length; j++) {
    let fGame = getASArray(action.result)[j];
    fGame.gameTitle = decodeBase64(fGame.gameTitle);
    fGame.gameTitleShort = decodeBase64(fGame.gameTitleShort);
    fGame.oneClickStartInfo = fGame.oneClickStartInfo != null ? decodeBase64(fGame.oneClickStartInfo) : null;
    fGame.oneClickGameLobbyInfo = fGame.oneClickGameLobbyInfo != null ? decodeBase64(fGame.oneClickGameLobbyInfo) : null;
    fGame.oneClickSuccessInfo = fGame.oneClickSuccessInfo != null ? decodeBase64(fGame.oneClickSuccessInfo) : null;
  }

  return update(state,
    {
      session: {
        filteredGames: { $set: action.result }
      }
    });
}

function gameEnd(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });
  if (gameInstanceIndex == -1) return state;

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: "ENDED" }
            }
          }
        }
      });
  } catch (error) {
    return state;
  }
}

function removeGameFromFav(state, action) {
  const favGames = getASArray(state.session.filteredGames);
  let favIndex = -1;
  for (let i = 0; i < favGames.length; i++) {
    if (favGames[i].gameId == action.data) {
      favIndex = i;
      console.log("Deleting fav game " + favGames[i].gameId);
      console.log("Fav Index: " + favIndex);
      console.log(getASArray(state.session.filteredGames)[favIndex]);
    }
  }
  if (favIndex == -1) return state;

  const favGames1 = getASArray(state.session.favoriteGames);
  let favIndex1 = -1;
  for (let i = 0; i < favGames1.length; i++) {
    if (favGames1[i].gameId == action.data) {
      favIndex1 = i;
      console.log("Deleting fav game " + favGames1[i].gameId);
    }
  }
  if (favIndex1 == -1) return state;

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [favIndex]: {
              favGame: { $set: false }
            }
          },
          favoriteGames: {
            $splice: [[favIndex1, 1]]
          }
        }
      });
  } catch (error) {
    return state;
  }
}

function deleteGame(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  let gameInstanceIndex = -1;
  for (let i = 0; i < gamesInstances.length; i++) {
    if (gamesInstances[i].gameInstanceId == action.data.gameInstanceId) {
      gameInstanceIndex = i;
      break;
    }
  }
  if (gameInstanceIndex == -1) return state;
  try {
    return update(state,
      {
        session: {
          filteredGames: {
            $splice: [[gameInstanceIndex, 1]]
          }
        }
      }
    );
  } catch (error) {
    return state;
  }
}

function updateCurrentRegStep(state, action) {
  console.log("changing to: " + action.data);
  return update(state,
    {
      myCurrentRegStep: { $set: action.data }
    })
}

function updateUsrPass(state, action) {
  return update(state,
    {
      regUsername: { $set: action.data.usr },
      regPass: { $set: action.data.pass }
    })
}

function doPlayerRegistrationFail(state, action) {
  console.log(action);
  return update(state, {
    regSuccess: { $set: false },
    registerError: { $set: action.error.Vc ? action.error.Vc : (action.error.k.faultCode ? action.error.k.faultCode : action.error.args) }
  })
}

function doPlayerRegistrationSuccess(state, action) {
  const track = state.session.configuration.tracking;
  if (track) {
    if (track.trackOnShortRegistration) {
      $.ajax({
        url: track.trackingURL,
        type: 'GET',
        crossDomain: true,
        success: function () { console.log("Tracking Success"); },
        error: function () { console.log('Tracking Failed!'); },
      });
    }
  }
  return update(state, {
    regSuccess: { $set: true }
  })
}

function doPlayerRegistrationFullSuccess(state, action) {
  const track = state.session.configuration.tracking;
  if (track) {
    if (track.trackOnFullRegistration) {
      $.ajax({
        url: track.trackingURL,
        type: 'GET',
        crossDomain: true,
        success: function () { console.log("Tracking Success"); },
        error: function () { console.log('Tracking Failed!'); },
      });
    }
  }
  return update(state, {
    fullRegSuccess: { $set: true },
    session: {
      player: {
        verification: {
          playerSegment: { $set: 'C' }
        }
      }
    }
  })
}

function getCountryByIpSuccess(state, action) {
  if (action.result == "") {
    action.result = window.defaultCountry || '';
  }
  return update(state, {
    regCountryByIp: { $set: action.result }
  });
}

function checkPostalCodesuccess(state, action) {
  return update(state, {
    regPostalCodeValidation: { $set: action.result }
  });
}

function playerEmailVerification(state, action) {
  return update(state, {
    session: {
      player: {
        verification: {
          emailVerified: { $set: action.data.verified },
          emailVerifiedDate: { $set: Date.now() }
        }
      }
    }
  })
}

function playerAddressVerification(state, action) {
  return update(state, {
    session: {
      player: {
        verification: {
          addressVerified: { $set: action.data.verified },
          addressVerifiedDate: { $set: Date.now() }
        }
      }
    }
  })
}

function playerPhoneVerification(state, action) {
  return update(state, {
    session: {
      player: {
        details: {
          homePhoneVerified: { $set: action.data.homePhoneVerified },
          mobilePhoneVerified: { $set: action.data.mobilePhoneVerified },
          phoneVerifiedDate: { $set: Date.now() }
        }
      }
    }
  })
}

function playerIdVerification(state, action) {
  return update(state, {
    session: {
      player: {
        verification: {
          verificationStatus: { $set: action.data.verified ? "VERIFIED" : "REJECTED" },
          idVerified: { $set: action.data.verified },
          idVerifiedDate: { $set: Date.now() }
        }
      }
    }
  })
}

function playerReloadVerificationRequest(state, action) {
  return update(state, {
    session: {
      lastVerificationRequestReload: { $set: new Date().getTime() }
    }
  });
}

function playerCoupon(state, action) {
  const accounts = getASArray(state.session.playerAccounts);

  if (action.data.instantCoupon == true) {
    window.showRegistrationJustOk("Promotion Awarded!",
      "You were awarded " + formatMoney(action.data.amount, action.data.currencyId) + ".");
  } else {
    window.showRegistrationJustOk("Promotion Awarded!",
      "You were awarded " + formatMoney(action.data.amount, action.data.currencyId) + " in your bonus bucket account. This amount will be released to you as you play.");
  }

  $('#regOkJustOk').click(function () {
    $(this).trigger('notify-hide');
  });

  if (action.data.balanceUpdateEvent) {
    const updatedAccount = getASArray(action.data.balanceUpdateEvent.accounts)[0];

    const accountIndex = accounts.findIndex(function (account) {
      return (account.accountId == updatedAccount.accountId && account.accountCurrency == updatedAccount.accountCurrency);
    });

    return update(state,
      {
        session: {
          playerAccounts: {
            [accountIndex]: {
              accountBalance: { $set: parseFloat(updatedAccount.accountBalance) }
            }
          }
        }
      });
  } else {
    return state;
  }
}

function playerOrder(state, action) {
  if (action.data.orderType == 1 && action.data.orderStatus == 2 && state.session.player.statistics.lastDepositDate == null) {
    const track = state.session.configuration.tracking;
    if (track) {
      if (track.trackOnDeposit) {
        $.ajax({
          url: track.trackingURL,
          type: 'GET',
          crossDomain: true,
          success: function () { console.log("Tracking Success"); },
          error: function () { console.log('Tracking Failed!'); },
        });
      }
    }
  }
  const accounts = getASArray(state.session.playerAccounts);

  if (action.data.balanceUpdateEvent) {
    const updatedAccount = getASArray(action.data.balanceUpdateEvent.accounts)[0];

    const accountIndex = accounts.findIndex(function (account) {
      return (account.accountId == updatedAccount.accountId && account.accountCurrency == updatedAccount.accountCurrency);
    });

    return update(state,
      {
        session: {
          playerAccounts: {
            [accountIndex]: {
              accountBalance: { $set: parseFloat(updatedAccount.accountBalance) }
            }
          },
          player: {
            statistics: {
              lastDepositDate: { $set: new Date() }
            }
          }
        }
      });
  } else {
    return state;
  }
}


function updatePlayerDetails(state, action) {
  return update(state, {
    session: {
      player: {
        details: {
          firstName: { $set: action.data.firstName },
          lastName: { $set: action.data.lastName },
          primaryAddressStreet: { $set: action.data.address },
          primaryAddressCity: { $set: action.data.city },
          primaryAddressState: { $set: action.data.province },
          primaryAddressCountry: { $set: action.data.country },
          phoneMobile: { $set: action.data.mobilePhone },
          primaryAddressPostalcode: { $set: action.data.postCode },
          birthdate: { $set: action.data.birthday },
          country: { $set: action.data.selCountry }
        },
        selfLimits: {
          dailyEnabled: { $set: action.data.dailyDepositLimit ? true : false },
          dailyDepositLimit: { $set: action.data.dailyDepositLimit ? action.data.dailyDepositLimit + '' : '0.0' },
          weeklyEnabled: { $set: action.data.weeklyDepositLimit ? true : false },
          weeklyDepositLimit: { $set: action.data.weeklyDepositLimit ? action.data.weeklyDepositLimit + '' : '0.0' },
          monthlyEnabled: { $set: action.data.monthlyDepositLimit ? true : false },
          monthlyDepositLimit: { $set: action.data.monthlyDepositLimit ? action.data.monthlyDepositLimit + '' : '0.0' },
          weeklyBetEnabled: { $set: action.data.weeklyWageringLimit ? true : false },
          weeklyBetLimit: { $set: action.data.weeklyWageringLimit ? action.data.weeklyWageringLimit + '' : '0.0' },
        }
      }
    }
  })
}

function gameMaintenance(state, action) {
  return update(state, {
    MAINTENANCE_ENABLED: { $set: action.data.enabled + "" },
    session: {
      configuration: {
        MAINTENANCE_ENABLED: { $set: action.data.enabled + "" },
        MAINTENANCE_MESSAGE: { $set: action.data.message }
      }
    }
  })
}

function gameFailRetry(state, action) {
  const gameInstanceIndex = getASArray(state.session.filteredGames)
    .findIndex(
      g => g.gameInstanceId == action.data.gameInstanceId
    );

  if (gameInstanceIndex != -1) {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: 'RETRY' }
            }
          }
        }
      });
  } else {
    return state;
  }
}

function gameRescheduled(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  let gameInstanceIndex = -1;
  for (let i = 0; i < gamesInstances.length; i++) {
    if (gamesInstances[i].gameInstanceId == action.data.gameInstanceId) {
      gameInstanceIndex = i;
      break;
    }
  }

  if (gameInstanceIndex == -1) return state;

  //TODO move out of here
  const gi = gamesInstances[gameInstanceIndex];

  if (gi.playerInGame) {
    console.log(action.data);
    showGameRescheduled(
      "Game Rescheduled",
      action.data.gameTitle ? decodeBase64(action.data.gameTitle) : "Game has been rescheduled to:",
      action.data.gameInstanceId,
      action.data.gameLogo,
      null,
      action.data.tournamentStartTimeMs);
  }

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: "OPEN" },
              tournamentStartTime: { $set: action.data.tournamentStartTimeMs },
              registrationEndTime: { $set: action.data.tournamentStartTimeMs - action.data.registrationEndTimeMs }
            }
          }
        }
      });
  } catch (error) {
    return state;
  }
}

function gameRefunded(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  let gameInstanceIndex = -1;
  for (let i = 0; i < gamesInstances.length; i++) {
    if (gamesInstances[i].gameId == action.data.gameId) {
      gameInstanceIndex = i;
      break;
    }
  }
  if (gameInstanceIndex == -1) return state;

  //TODO move out of here
  const gi = gamesInstances[gameInstanceIndex];

  if (gi.playerInGame) {
    showGameRefunded(
      "Game Refunded",
      action.data.gameTitle ? decodeBase64(action.data.gameTitle) : "Game has been refunded!",
      action.data.gameInstanceId,
      gi.gameSmallLogoImage,
      'Your bets have been refunded', '');
  }

  return deleteGame(state, action);
}

function createGame(state, action) {
  if (state.session.filteredGames == null)
    return state;

  const gamesCount = getASArray(state.session.filteredGames);

  if (gamesCount == 0) {
    //document.location.reload();
  }

  if (state.session.favoriteGames && getASArray(state.session.favoriteGames)) {
    addGameToFav(getASArray(state.session.favoriteGames), action.data);
  }

  action.data.gameLobby = new GameLobbyDto();
  action.data.gameTitle = action.data.gameTitle ? decodeBase64(action.data.gameTitle) : action.data.gameTitle;
  action.data.gameDescription = action.data.gameDescription ? decodeBase64(action.data.gameDescription) : action.data.gameDescription;
  action.data.gameInfo = action.data.gameInfo ? decodeBase64(action.data.gameInfo) : action.data.gameInfo;
  action.data.oneClickStartInfo = action.data.oneClickStartInfo != null ? decodeBase64(action.data.oneClickStartInfo) : null;
  action.data.oneClickGameLobbyInfo = action.data.oneClickGameLobbyInfo != null ? decodeBase64(action.data.oneClickGameLobbyInfo) : null;
  action.data.oneClickSuccessInfo = action.data.oneClickSuccessInfo != null ? decodeBase64(action.data.oneClickSuccessInfo) : null;

  const gameInstanceIndex = getASArray(state.session.filteredGames)
    .findIndex(
      g => g.gameInstanceId == action.data.gameInstanceId
    );

  mainGameInstanceId = action.data.gameId == mainGameId ? action.data.gameInstanceId : state.session.mainGameInstanceId;

  console.log("new game instance id is " + mainGameInstanceId);

  if (gameInstanceIndex != -1) {
    const game = getASArray(state.session.filteredGames)[gameInstanceIndex];
    action.data.playerInGame = game.playerInGame;
    action.data.playerPlaysInGame = game.playerPlaysInGame ? game.playerPlaysInGame : 0;
    action.data.lastJoinTimeMs = game.lastJoinTimeMs;

    return update(state,
      {
        session: {
          mainGameInstanceId: { $set: mainGameInstanceId },
          filteredGames: {
            [gameInstanceIndex]: { $set: action.data }
          }
        }
      });
  } else {
    action.data.playerInGame = (state.session.player && action.data.playerIds) ? action.data.playerIds.includes(state.session.player.id) : false;
    action.data.playerPlaysInGame = 0;
    return update(state,
      {
        session: {
          mainGameInstanceId: { $set: mainGameInstanceId },
          filteredGames: {
            $push: [action.data]
          }
        }
      });
  }
}

function saveFavGameSuccess(state, action) {
  let myGameIndex;

  for (let i = 0; i < getASArray(state.session.filteredGames).length; i++) {
    let g = getASArray(state.session.filteredGames)[i];
    if (g.gameId == action.result.gameId && g.gameStatus == "OPEN") {
      myGameIndex = i;
      break;
    }
  }

  return update(state, {
    session: {
      filteredGames: {
        [myGameIndex]: {
          favGame: { $set: true }
        }
      },
      favoriteGames: {
        $push: [action.result]
      }
    }
  });
}

function getCountriesSuccess(state, action) {
  if (!action || !action.result) {
    return state;
  }
  return update(state, {
    loading: { $set: false },
    session: {
      countries: { $set: getASArray(action.result) }
    }
  });
}

function getProvincesSuccess(state, action) {
  return update(state, {
    loading: { $set: false },
    session: {
      provinces: { $set: getASArray(action.result) }
    }
  });
}

function gameProgressive(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  let gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return (game.gameId == action.data.gameId) && game.gameStatus == 'OPEN';
  });

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              progressiveAmount: { $set: action.data.progressiveAmount }
            }
          }
        },
        progressiveWon: { $set: action.data.progressiveWon },
        progressiveMatchedNeeded: { $set: action.data.progressiveMatchedNeeded }
      });
  } catch (error) {
    return state;
  }
}

function gameStart(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: 'STARTED' }
            }
          }
        }
      });
  } catch (error) {
    return state;
  }
}

function registrationStart(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: 'OPEN' }
            }
          }
        }
      });
  } catch (error) {
    return state;
  }
}

function registrationEnd(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  const isGameReadyToPlay = (idx) => {
    if (idx == -1) return false;

    const game = getASArray(state.session.filteredGames)[idx];

    if (game.gameStatus == "STARTED")
      return true;

    if (game.tournament == false) {
      return game.gameStatus == "READY";
    } else {
      if (game.gameStatus == 'READY' || game.gameStatus == 'CLOSED') {
        const countdown = game.tournamentStartTime - serverTimeDelta - new Date().getTime();

        if (countdown > 30000) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  if (gameInstanceIndex != -1) {
    let newState = update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gameStatus: { $set: "CLOSED" }
            }
          }
        }
      });

    if (gamesInstances[gameInstanceIndex].playerInGame) {
      newState = update(newState, {
        modal: {
          showPlayGame: { $set: isGameReadyToPlay(gameInstanceIndex) }
        },
        modalGameInstanceId: { $set: gamesInstances[gameInstanceIndex].gameInstanceId }
      });
    }
    return newState;
  } else {
    return state;
  }
}

function gameResultsSuccess(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.result.gameInstanceId;
  });
  /*
    let allPlays = [];
    let myPlays = [];

    console.log("Game instance index " + gameInstanceIndex);

    for (let i=0; i < getASArray(action.result.playersList).length; i++)
    {
      const _player = getASArray(action.result.playersList)[i];
      getASArray(action.result.playersList)[i].screenName = decodeBase64(_player.screenName);

      for (let j=0; j < getASArray(_player.plays).length; j++)
      {
        const obj = getASArray(_player.plays)[j];
        let item = new PlayItem();

        item.screenName = _player.screenName;
        item.country = _player.country;
        item.play = obj.play;
        item.play2nd = obj.play2nd;
        item.playId = obj.id;
        item.playIdFormatted = j+1;
        item.gameInstanceId = action.result.gameInstanceId;
        item.init(null, action.result.gameResult.gameConfig.gameRule.contains('raffleGame') == false);

        if (state.session.player && _player.playerId == state.session.player.id) {
          item.isMyPlay = true;
          myPlays.push(item);
        }
        allPlays.push(item);
      }
    }
  */
  // If game is a replay (i.e doesn't exist in the active list)
  if (gameInstanceIndex == -1) {
    const existingGameIndex = gamesInstances.findIndex(function (game) {
      return game.gameId == action.result.gameResult.gameConfig.gameId;
    });

    // We populate the object with existing game configuration
    let g = gamesInstances[existingGameIndex];
    if (!g) {
      g = new GameDto();
      g.gameLobby = new GameLobbyDto();
    } else {
      g = update(g, { isCloned: { $set: true } });
    }
    g.results = action.result;
    g.gameInstanceId = action.result.gameInstanceId;
    g.gameStatus = 'REPLAY1';
    g.gameRule = action.result.gameResult.gameConfig.gameRule;
    /*g.allPlays = allPlays;
    g.myPlays = myPlays;*/
    g.isPlayable = action.result.gameResult ? true : false;
    g.replay = true;

    var startTimeMs = g.results.gameResult.startTime;
    var endTimeMs = g.results.gameResult.endTime;
    var gamePlayTotal = endTimeMs - startTimeMs;

    g.results.gameResult.startTime = Date.now() + 5000;
    g.results.gameResult.endTime = g.results.gameResult.startTime + gamePlayTotal;

    console.log(new Date(g.results.gameResult.startTime));

    // hacks
    g.payoutCurrencyId = g.results.gameResult.gameConfig.payoutCurrencyId;
    g.gameSmallLogoImage = g.results.gameResult.gameConfig.gameSmallLogoImage;
    g.gameBackgroundImage = g.results.gameResult.gameConfig.gameBackgroundImage;
    g.gamePlayoutBackgroundImage = g.results.gameResult.gameConfig.gamePlayoutBackgroundImage;
    g.payoutPercentage = g.results.gameResult.gameConfig.payoutPercentage;
    g.gameTitle = g.results.gameResult.gameConfig.gameTitle ? decodeBase64(g.results.gameResult.gameConfig.gameTitle) : "";
    g.gamePlayMax = g.results.gameResult.gameConfig.gamePlayMax;
    g.gamePlayCurrent = g.results.gameResult.gameConfig.gamePlayCurrent;
    g.gamePlaySize = g.results.gameResult.gameConfig.gamePlaySize;
    g.gamePlaySize2nd = g.results.gameResult.gameConfig.gamePlaySize2nd;
    g.jackpot = g.results.gameResult.gameConfig.progressive;
    g.progressiveAmount = g.results.gameResult.gameConfig.progressiveAmount;
    g.gameFeePerPlay = g.results.gameResult.gameConfig.gameFeePerPlay;
    g.gamePlayCost = g.results.gameResult.gameConfig.gamePlayCost;
    g.gamePlayCurrent = action.result.gameResult.playsInGame;//allPlays.length;
    g.payoutFormula = g.results.gameResult.gameConfig.payoutFormula;
    g.prizePool = g.results.gameResult.finalPrizePool;
    g.sequenceNumber = g.results.gameResult.sequenceNumber;
    g.showSequenceNumber = g.results.gameResult.gameConfig.showSequenceNumber;
    g.prizesAvailable = getASArray(g.results.payoutList).length;
    g.replay = true;

    /* Sort draw ascending - commented out since it violates UKGC rules
    if (g.gameRule.indexOf('oneDraw') > -1) {
      const drawResults = g.results.gameResult.gameDrawResults;
      for (let n = 0; n < drawResults.source.length; n++) {
        g.results.gameResult.gameDrawResults.source[n].generatedGameDraw = drawResults.source[n].generatedGameDraw.split(',').map(n => parseInt(n)).sort((a, b) => a - b).join(',');
      }
    }
    */
    // hacks
    console.log('game instance not found, adding', g);


    if (g.gameRule.startsWith("raffleGame") &&
      typeof window.reverseRaffleDraws != "undefined" &&
      window.reverseRaffleDraws == true) {

      // reverse draw results
      g.results.gameResult.gameDrawResults.source =
        [...g.results.gameResult.gameDrawResults.source].reverse();
      // reverse payout list
      g.results.payoutList.source =
        [...g.results.payoutList.source].reverse();

    }

    return update(state,
      {
        session: {
          filteredGames: {
            $push: [g]
          }
        }
      });
  } else {
    // handle multiple replays of the same instance
    if (gamesInstances[gameInstanceIndex].gameStatus == 'REPLAY1') {
      const instance = gamesInstances[gameInstanceIndex];
      var startTimeMs = instance.results.gameResult.startTime;
      var endTimeMs = instance.results.gameResult.endTime;
      var gamePlayTotal = endTimeMs - startTimeMs;

      startTimeMs = Date.now() + 5000;
      endTimeMs = startTimeMs + gamePlayTotal;

      instance.results.gameResult.startTime = Date.now() + 5000;
      instance.results.gameResult.endTime = instance.results.gameResult.startTime + gamePlayTotal;
      instance.isPlayable = true;
      instance.lastUpdateTimeMs = new Date().getTime();
      instance.prizesAvailable = getASArray(instance.results.payoutList).length;

      console.log(new Date(instance.results.gameResult.startTime));
      console.log('game instance found with status REPLAY1, adding to index ' + gameInstanceIndex, instance);

      // reverse draws
      instance.results.gameResult.gameDrawResults.source =
        [...gameResults.gameResult.gameDrawResults.source].reverse();
      // reverse payoutList
      instance.results.payoutList.source =
        [...gameResults.payoutList.source].reverse();

      return update(state,
        {
          session: {
            filteredGames: {
              [gameInstanceIndex]: { $set: instance }
            }
          }
        }
      );
    }
    console.log('game instance found, adding to index ' + gameInstanceIndex, action.result);

    let gameResults = action.result;
    let g = gamesInstances[gameInstanceIndex];

    if (g.gameRule.startsWith("raffleGame") &&
      typeof window.reverseRaffleDraws != "undefined" &&
      window.reverseRaffleDraws == true) {

      // reverse draw results using spread copy
      gameResults.gameResult.gameDrawResults.source =
        [...gameResults.gameResult.gameDrawResults.source].reverse();
      // reverse payoutList
      gameResults.payoutList.source =
        [...gameResults.payoutList.source].reverse();

    }

    const prizesAvailable = getASArray(gameResults.payoutList).length;

    /*
    if (g.gameRule.indexOf('oneDraw') > -1) {
      const drawResults = gameResults.gameResult.gameDrawResults;
      for (let n = 0; n < drawResults.source.length; n++) {
        gameResults.gameResult.gameDrawResults.source[n].generatedGameDraw = drawResults.source[n].generatedGameDraw.split(',').map(n => parseInt(n)).sort((a, b) => a - b).join(',');
      }
    }*/

    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              results: { $set: gameResults },
              prizesAvailable: { $set: prizesAvailable },
              /*allPlays : { $set: allPlays },
              myPlays : { $set: myPlays },*/
              isPlayable: { $set: action.result.gameResult ? true : false },
              lastUpdateTimeMs: { $set: new Date().getTime() }
            }
          }
        }
      });
  }
}

function getPlayersSuccess(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.result.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  getASArray(action.result.players).forEach((o, i, a) => {
    a[i].firstName = o.firstName ? decodeBase64(o.firstName) : o.firstName;
    a[i].lastName = o.lastName ? decodeBase64(o.lastName) : o.lastName;
    a[i].screenName = o.screenName ? decodeBase64(o.screenName) : o.screenName;
    a[i].username = o.username ? decodeBase64(o.username) : o.username;
  });

  return update(state,
    {
      session: {
        filteredGames: {
          [gameInstanceIndex]: {
            gameLobby: {
              gamePlayerInfo: { $set: action.result.players }
            }
          }
        }
      }
    });
}

function getPayoutSuccess(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.result.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  return update(state,
    {
      session: {
        filteredGames: {
          [gameInstanceIndex]: {
            bonoboPayout: { $set: getASArray(action.result.payoutInfo).length > 0 ? getASArray(action.result.payoutInfo)[0].rankPrize : 0 },
            prizesAvailable: { $set: action.result.prizesAvailable ? action.result.prizesAvailable : getASArray(action.result.payoutInfo).length },
            gameLobby: {
              gamePayoutInfo: { $set: action.result.payoutInfo }
            }
          }
        }
      },
      cachedPayouts: {
        [action.result.gameId]: { $set: action.result.payoutInfo }
      },
    });
}

function updateGame(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  try {
    if (!action.data.prizePool)
      action.data.prizePool = gamesInstances[gameInstanceIndex].prizePool;

    if (!action.data.bonoboPayout && action.data.bonoboPayout != 0)
      action.data.bonoboPayout = gamesInstances[gameInstanceIndex].bonoboPayout;

    action.data.previousPlays = gamesInstances[gameInstanceIndex].gamePlayCurrent;

    return update(state, {
      session: {
        filteredGames: {
          [gameInstanceIndex]: {
            gamePlayCurrent: { $set: action.data.playsInGame },
            gamePlayerCurrent: { $set: action.data.playersInGame },
            prizePool: { $set: action.data.prizePool },
            bonoboPayout: { $set: action.data.bonoboPayout },
            previousPlays: { $set: action.data.previousPlays }
          }
        }
      }
    });
  } catch (error) {
    console.log(error);
    return state;
  }
}

function updateGamePlaysPlayers(state, action) {
  const gamesInstances = getASArray(state.session.filteredGames);
  const gameInstanceIndex = gamesInstances.findIndex(function (game) {
    return game.gameInstanceId == action.data.gameInstanceId;
  });

  if (gameInstanceIndex == -1) return state;

  try {
    return update(state,
      {
        session: {
          filteredGames: {
            [gameInstanceIndex]: {
              gamePlayCurrent: { $set: action.data.playsInGame },
              gamePlayerCurrent: { $set: action.data.playersInGame }
            }
          }
        }
      });
  } catch (error) {
    return state;
  }
}

function claimInGameBonusSuccess(state, action) {
  const accounts = getASArray(state.session.playerAccounts);

  if (action.result.event && action.result.event.accounts && getASArray(action.result.event.accounts)) {
    const updatedAccount = getASArray(action.result.event.accounts)[0];

    const accountIndex = accounts.findIndex(function (account) {
      return (account.accountId == updatedAccount.accountId && account.accountCurrency == updatedAccount.accountCurrency);
    });

    return update(state,
      {
        session: {
          playerAccounts: {
            [accountIndex]: {
              accountBalance: { $set: parseFloat(updatedAccount.accountBalance) }
            }
          }
        }
      });
  } else {
    return state;
  }
}

function getMainAdsSuccess(state, action) {
  return update(state, {
    ads: {
      $set: getASArray(action.result)
    }
  });
}

function updateGameConflated(state, action) {
  let updated = update(state, {});
  const gameUpdated = action.data;

  Object.keys(gameUpdated).forEach(function (key, index) {
    if (gameUpdated[key]) {
      state = updateGame(state, { data: gameUpdated[key] });
    }
  });
  return state;
}

export function showReconnecting() {
  return {
    type: SHOW_RECONNECTING
  };
}

export function showProcessing() {
  return {
    type: SHOW_PROCESSING
  };
}

export function hideProcessing() {
  return {
    type: HIDE_PROCESSING
  };
}

export function showPrizeDetails(prizeItem) {
  return {
    type: SHOW_PRIZE_DETAILS,
    data: { prizeItem: prizeItem }
  };
}

export function hidePrizeDetails() {
  return {
    type: HIDE_PRIZE_DETAILS
  };
}

export function showLiveGames() {
  return {
    type: SHOW_LIVE_GAMES
  };
}

export function hideLiveGames() {
  return {
    type: HIDE_LIVE_GAMES
  };
}

export function showOneClickBuy(gameId, gameInstanceId, continueSessionId, paymentOptionId) {
  return {
    type: SHOW_ONE_CLICK_BUY,
    data: { gameId: gameId, gameInstanceId: gameInstanceId, continueSessionId: continueSessionId, paymentOptionId: paymentOptionId }
  };
}

export function hideOneClickBuy() {
  return {
    type: HIDE_ONE_CLICK_BUY
  };
}

export function showOneClickBuyInstant(gameId, gameInstanceId) {
  return {
    type: SHOW_ONE_CLICK_BUY_INSTANT,
    data: { gameId: gameId, gameInstanceId: gameInstanceId }
  };
}

export function hideOneClickBuyInstant() {
  return {
    type: HIDE_ONE_CLICK_BUY_INSTANT
  };
}

export function showRealityCheck() {
  return {
    type: SHOW_REALITY_CHECK
  };
}

export function hideRealityCheck() {
  return {
    type: HIDE_REALITY_CHECK
  };
}

export function showOneClickNumberPick(pick, index, callback, drawRangeMax) {
  return {
    type: SHOW_ONECLICK_NUMBER_PICK,
    data: { pick: pick, pickIndex: index, pickChangeCallback: callback, drawRangeMax: drawRangeMax }
  };
}

export function hideOneClickNumberPick() {
  return {
    type: HIDE_ONECLICK_NUMBER_PICK
  };
}

export function showAdPopup(ad, adInfo) {
  return {
    type: SHOW_AD_POPUP,
    data: { ad: ad, adInfo: adInfo }
  };
}

export function hideAdPopup() {
  return {
    type: HIDE_AD_POPUP
  };
}

export function showLogin() {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  return {
    type: SHOW_LOGIN
  };
}

export function hideLogin() {
  return {
    type: HIDE_LOGIN
  };
}

export function showRegister() {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  return {
    type: SHOW_REGISTER
  };
}

export function hideRegister() {
  return {
    type: HIDE_REGISTER
  };
}

export function showFullRegister() {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  return {
    type: SHOW_FULL_REG
  };
}

export function hideFullRegister() {
  return {
    type: HIDE_FULL_REG
  };
}

export function showForgotPassModal() {
  return {
    type: SHOW_FORGOT_PASS
  };
}

export function hideForgotPassModal() {
  return {
    type: HIDE_FORGOT_PASS
  };
}

export function showForgotConfModal() {
  return {
    type: SHOW_FORGOT_CONFIRM
  };
}

export function hideForgotConfModal() {
  return {
    type: HIDE_FORGOT_CONFIRM
  };
}

export function showDepositModal() {
  return {
    type: SHOW_DEPOSIT
  };
}

export function hideDepositModal() {
  return {
    type: HIDE_DEPOSIT
  };
}

export function showDepositCreditCardModal() {
  return {
    type: SHOW_DEPOSIT_CC
  };
}

export function hideDepositCreditCardModal() {
  return {
    type: HIDE_DEPOSIT_CC
  };
}

export function showRules(desc) {
  return {
    type: SHOW_RULES,
    data: { gameDesc: desc }
  };
}

export function showPayout(desc) {
  return {
    type: SHOW_PAYOUT,
    // data: { gameDesc: desc }
  };
}


export function hideRules() {
  return {
    type: HIDE_RULES
  };
}

export function hidePayout() {
  return {
    type: HIDE_PAYOUT
  };
}

export function updateBonoboTicker(data) {
  return {
    type: BONOBO_TICKER,
    data: data
  }
}

export function showPhoneValidation() {
  return {
    type: SHOW_PHONE_VALIDATION
  };
}

export function hidePhoneValidation() {
  return {
    type: HIDE_PHONE_VALIDATION
  };
}

export function showPlayGame(gameInstanceId) {
  return {
    type: SHOW_PLAY_GAME,
    data: { modalGameInstanceId: gameInstanceId }
  };
}

export function hidePlayGame() {
  return {
    type: HIDE_PLAY_GAME
  };
}

export function showCancelSubscription(subscription) {
  return {
    type: SHOW_CANCEL_SUBSCRIPTION,
    data: { subscription: subscription }
  };
}

export function hideCancelSubscription() {
  return {
    type: HIDE_CANCEL_SUBSCRIPTION
  };
}

export function showError() {
  return {
    type: SHOW_ERROR
  };
}

export function serverConnected() {
  return {
    type: SERVER_CONNECTED
  };
}

export function serverDisconnected() {
  return {
    type: SERVER_DISCONNECTED
  };
}

export function serverDisconnectedNow() {
  return {
    type: SERVER_DISCONNECTED_NOW
  }
}

export function initLoad() {
  return {
    type: INIT_LOAD
  };
}

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (dsclient) => dsclient.loadPlayer()
  };
}

export function loadGames(filter) {
  return {
    types: [LOAD_GAMES, LOAD_GAMES_SUCCESS, LOAD_GAMES_FAIL],
    promise: (dsclient) => dsclient.loadGames(filter)
  };
}

export function loadGameTypes(language) {
  return {
    types: [LOAD_GAME_TYPES, LOAD_GAME_TYPES_SUCCESS, LOAD_GAME_TYPES_FAIL],
    promise: (dsclient) => dsclient.loadGameTypes(language)
  };
}

export function loadGameTypeInstances(language, page, gameId, gameInstanceId, dateStartedFrom, dateEndedTo) {
  return {
    types: [LOAD_GAME_TYPE_INSTANCES, LOAD_GAME_TYPE_INSTANCES_SUCCESS, LOAD_GAME_TYPE_INSTANCES_FAIL],
    promise: (dsclient) => dsclient.loadGameTypeInstances(language, page, gameId, gameInstanceId, dateStartedFrom, dateEndedTo)
  };
}

export function loadAllGameTypeInstances(language, gameId) {
  return {
    types: [LOAD_ALL_GAME_TYPE_INSTANCES, LOAD_ALL_GAME_TYPE_INSTANCES_SUCCESS, LOAD_ALL_GAME_TYPE_INSTANCES_FAIL],
    promise: (dsclient) => dsclient.loadAllGameTypeInstances(language, gameId)
  };
}

export function pingme() {
  return {
    types: [PING, PING_SUCCESS, PING_FAIL],
    promise: (dsclient) => dsclient.pingme()
  };
}

export function login(username, password) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (dsclient) => dsclient.login(username, password)
  };
}

export function loginWhitelabel(sessionId) {
  return {
    types: [LOGIN_WHITELABEL, LOGIN_WHITELABEL_SUCCESS, LOGIN_WHITELABEL_FAIL],
    promise: (dsclient) => dsclient.loginWhitelabel(sessionId)
  };
}

export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: (dsclient) => dsclient.logout()
  };
}

export function loadGameLobby(gameInstanceId) {
  return {
    types: [LOAD_GAME_LOBBY, LOAD_GAME_LOBBY_SUCCESS, LOAD_GAME_LOBBY_FAIL],
    promise: (dsclient) => dsclient.getGameLobby(gameInstanceId)
  };
}

export function joinGame(request) {
  return {
    types: [JOIN_GAME, JOIN_GAME_SUCCESS, JOIN_GAME_FAIL],
    promise: (dsclient) => dsclient.joinGame(request)
  };
}

export function arePlaysAvailableForGame(gameInstanceId, plays) {
  const request = new CheckPlaysRequest();
  request.gameInstanceId = gameInstanceId;
  request.plays = plays;

  return {
    types: [PLAYS_AVAILABLE, PLAYS_AVAILABLE_SUCCESS, PLAYS_AVAILABLE_FAIL],
    promise: (dsclient) => dsclient.arePlaysAvailableForGame(request)
  };
}

export function joinGamePerm(request) {
  return {
    types: [JOIN_GAME, JOIN_GAME_PERM_SUCCESS, JOIN_GAME_PERM_FAIL],
    promise: (dsclient) => dsclient.joinGamePerm(request)
  };
}

export function loadResults(gameInstanceId, justMe) {
  return {
    types: [GAME_RESULTS, GAME_RESULTS_SUCCESS, GAME_RESULTS_FAIL],
    promise: (dsclient) => dsclient.getGameResults(gameInstanceId, justMe)
  };
}

export function confirmCrosswordEnd(gameInstanceId) {
  return {
    types: [CONFIRM_CROSSWORD_END, CONFIRM_CROSSWORD_END_SUCCESS, CONFIRM_CROSSWORD_END_FAIL],
    promise: (dsclient) => dsclient.confirmCrosswordEnd(gameInstanceId)
  }
}

export function loadPlayers(gameInstanceId) {
  return {
    types: [GAME_PLAYERS, GAME_PLAYERS_SUCCESS, GAME_PLAYERS_FAIL],
    promise: (dsclient) => dsclient.getGamePlayers(gameInstanceId)
  };
}

export function loadPayout(gameInstanceId, gameId, tournament) {
  return {
    types: [GAME_PAYOUT, GAME_PAYOUT_SUCCESS, GAME_PAYOUT_FAIL],
    promise: (dsclient) => dsclient.getGamePayout(gameInstanceId, gameId, tournament)
  };
}

export function searchGameHistory(search) {
  return {
    types: [SEARCH_GAME_HISTORY, SEARCH_GAME_HISTORY_SUCCESS, SEARCH_GAME_HISTORY_FAIL],
    promise: (dsclient) => dsclient.searchGameHistory(search)
  };
}

export function getGameHistoryDetail(gameInstanceId) {
  return {
    types: [GET_GAME_HISTORY, GET_GAME_HISTORY_SUCCESS, GET_GAME_HISTORY_FAIL],
    promise: (dsclient) => dsclient.getGameHistoryDetail(gameInstanceId)
  };
}

export function isSubscriptionActive(gameId) {
  return {
    types: [SUBSCRIPTION_ACTIVE, SUBSCRIPTION_ACTIVE_SUCCESS, SUBSCRIPTION_ACTIVE_FAIL],
    promise: (dsclient) => dsclient.isSubscriptionActive(gameId)
  };
}

export function hasPlayerGameSubscriptions() {
  return {
    types: [HAS_SUBSCRIPTIONS, HAS_SUBSCRIPTIONS_SUCCESS, HAS_SUBSCRIPTIONS_FAIL],
    promise: (dsclient) => dsclient.hasPlayerGameSubscriptions()
  }
}

export function loadSubscriptions(search) {
  return {
    types: [LOAD_SUBSCRIPTIONS, LOAD_SUBSCRIPTIONS_SUCCESS, LOAD_SUBSCRIPTIONS_FAIL],
    promise: (dsclient) => dsclient.getPlayerGameSubscriptions(search)
  };
}

export function getPayGoSubscriptionDetails(subscriptionId) {
  return {
    types: [LOAD_SUBSCRIPTION_DETAILS, LOAD_SUBSCRIPTION_DETAILS_SUCCESS, LOAD_SUBSCRIPTION_DETAILS_FAIL],
    promise: (dsclient) => dsclient.getPayGoGameSubscriptionDetails(subscriptionId)
  };
}

export function searchPayGoSubscriptionTransactions(search) {
  return {
    types: [LOAD_SUBSCRIPTION_TRANSACTIONS, LOAD_SUBSCRIPTION_TRANSACTIONS_SUCCESS, LOAD_SUBSCRIPTION_TRANSACTIONS_FAIL],
    promise: (dsclient) => dsclient.searchPayGoSubscriptionTransactions(search)
  };
}

export function cancelSubscription(id) {
  return {
    types: [CANCEL_SUBSCRIPTION, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_FAIL],
    promise: (dsclient) => dsclient.removePlayerGameSubscription(id)
  };
}

export function changeBackground(id, url) {
  return {
    type: CHANGE_BACKGROUND,
    data: { id: id, url: url }
  };
}

export function saveLobbyGames(gameList) {
  return {
    type: SAVE_LOBBY_GAMES,
    data: gameList
  };
}

export function loadCountries() {

  return {
    types: [LOAD_COUNTRIES, LOAD_COUNTRIES_SUCCESS, LOAD_COUNTRIES_FAIL],
    promise: (dsclient) => dsclient.getCountries()
  };
}

export function toggleAllSounds(data) {
  return {
    type: TOGGLE_ALL_SOUNDS,
    data: data
  }
}

export function loadProvinces(country) {

  return {
    types: [LOAD_PROVINCES, LOAD_PROVINCES_SUCCESS, LOAD_PROVINCES_FAIL],
    promise: (dsclient) => dsclient.getStateProvincesForCountry(country)
  };
}

export function doPlayerRegistration(user) {

  return {
    types: [DO_PLAYER_REGISTRATION, DO_PLAYER_REGISTRATION_SUCCESS, DO_PLAYER_REGISTRATION_FAIL],
    promise: (dsclient) => dsclient.playerRegistration(user)
  };
}

export function doPlayerRegistrationFull(user) {

  return {
    types: [DO_PLAYER_REGISTRATION_FULL, DO_PLAYER_REGISTRATION_FULL_SUCCESS, DO_PLAYER_REGISTRATION_FULL_FAIL],
    promise: (dsclient) => dsclient.playerRegistrationFull(user)
  };
}

export function doCheckUsername(username) {

  return {
    types: [DO_CHECK_USERNAME, DO_CHECK_USERNAME_SUCCESS, DO_CHECK_USERNAME_FAIL],
    promise: (dsclient) => dsclient.checkUsername(username)
  };
}

export function doCheckEmail(email) {

  return {
    types: [DO_CHECK_EMAIL, DO_CHECK_EMAIL_SUCCESS, DO_CHECK_EMAIL_FAIL],
    promise: (dsclient) => dsclient.checkEmail(email)
  };
}

export function doCheckGovernmentID(governmentId) {

  return {
    types: [DO_CHECK_GOVERNMENT_ID, DO_CHECK_GOVERNMENT_ID_SUCCESS, DO_CHECK_GOVERNMENT_ID_FAIL],
    promise: (dsclient) => dsclient.checkGovernmentID(governmentId)
  };
}

export function doCheckPassword(password) {

  return {
    types: [DO_CHECK_PASSWORD, DO_CHECK_PASSWORD_SUCCESS, DO_CHECK_PASSWORD_FAIL],
    promise: (dsclient) => dsclient.checkPassword(password)
  };
}

export function validateCoupon(depositAmount, currencyId, couponCode) {
  return {
    types: [DO_CHECK_COUPON, DO_CHECK_COUPON_SUCCESS, DO_CHECK_COUPON_FAIL],
    promise: (dsclient) => dsclient.validateCoupon(depositAmount, currencyId, couponCode)
  };
}

export function getDropdownItems(type, languageId) {
  return {
    types: [GET_DROPDOWN_ITEMS, GET_DROPDOWN_ITEMS_SUCCESS, GET_DROPDOWN_ITEMS_FAIL],
    promise: (dsclient) => dsclient.getDropdownItems(type, languageId)
  };
}

export function doRequestResetPW(usernameorpass) {

  return {
    types: [DO_REQUEST_RESET, DO_REQUEST_RESET_SUCCESS, DO_REQUEST_RESET_FAIL],
    promise: (dsclient) => dsclient.requestResetPW(usernameorpass)
  };
}

export function resetPassword(key, password) {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    promise: (dsclient) => dsclient.resetPassword(key, password)
  };
}

export function doGetCountryCodeByIp(ip) {

  return {
    types: [DO_GET_COUNTRY_BY_IP, DO_GET_COUNTRY_BY_IP_SUCCESS, DO_GET_COUNTRY_BY_IP_FAIL],
    promise: (dsclient) => dsclient.getCountryCodeByIp(ip)
  };
}

export function doCheckPostalCode(postalCode, countryId) {

  return {
    types: [DO_CHECK_POSTAL_CODE, DO_CHECK_POSTAL_CODE_SUCCESS, DO_CHECK_POSTAL_CODE_FAIL],
    promise: (dsclient) => dsclient.checkPostalCode(postalCode, countryId)
  };
}

export function confirmEmail(token) {
  return {
    types: [CONFIRM_EMAIL, CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_FAIL],
    promise: (dsclient) => dsclient.confirmEmail(token)
  };
}

export function requestEmailConfirmation() {
  return {
    types: [REQUEST_EMAIL_CONF, REQUEST_EMAIL_CONF_SUCCESS, REQUEST_EMAIL_CONF_FAIL],
    promise: (dsclient) => dsclient.requestEmailConfirmation()
  };
}

export function requestIdVerification() {
  return {
    types: [REQUEST_ID_VERIFICATION, REQUEST_ID_VERIFICATION_SUCCESS, REQUEST_ID_VERIFICATION_FAIL],
    promise: (dsclient) => dsclient.requestIdVerification()
  };
}

export function saveFavoriteGame(gameId) {
  return {
    types: [SAVE_FAV_GAME, SAVE_FAV_GAME_SUCCESS, SAVE_FAV_GAME_FAIL],
    promise: (dsclient) => dsclient.saveFavoriteGame(gameId)
  };
}

export function deleteFavoriteGame(gameId) {
  return {
    types: [DELETE_FAV_GAME, DELETE_FAV_GAME_SUCCESS, DELETE_FAV_GAME_FAIL],
    promise: (dsclient) => dsclient.deleteFavoriteGame(gameId)
  };
}

export function updateEmail(email) {
  return {
    types: [UPDATE_EMAIL, UPDATE_EMAIL_SUCCESS, UPDATE_EMAIL_FAIL],
    promise: (dsclient) => dsclient.updateEmail(email)
  };
}

export function updatePhones(phone, mobile) {
  return {
    types: [UPDATE_PHONES, UPDATE_PHONES_SUCCESS, UPDATE_PHONES_FAIL],
    promise: (dsclient) => dsclient.updatePhones(phone, mobile)
  };
}

export function updateAddress(address) {
  return {
    types: [UPDATE_ADDRESS, UPDATE_ADDRESS_SUCCESS, UPDATE_ADDRESS_FAIL],
    promise: (dsclient) => dsclient.updateAddress(address)
  };
}

export function updatePassword(oldPassword, newPassword) {
  return {
    types: [UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL],
    promise: (dsclient) => dsclient.updatePassword(oldPassword, newPassword)
  };
}

export function changePassword(newPassword) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: (dsclient) => dsclient.changePassword(newPassword)
  };
}

export function updateLanguage(language) {
  return {
    types: [UPDATE_LANGUAGE, UPDATE_LANGUAGE_SUCCESS, UPDATE_LANGUAGE_FAIL],
    promise: (dsclient) => dsclient.updateLanguage(language)
  };
}

export function updateNotifications(preferences) {
  return {
    types: [UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATIONS_FAIL],
    promise: (dsclient) => dsclient.updateNotifications(preferences)
  };
}

export function updateSelfLimits(settings) {
  return {
    types: [UPDATE_SELF_LIMITS, UPDATE_SELF_LIMITS_SUCCESS, UPDATE_SELF_LIMITS_FAIL],
    promise: (dsclient) => dsclient.updateSelfLimits(settings)
  };
}

export function updateSelfExclusion(selfExclusionDate) {
  return {
    types: [UPDATE_SELF_EXCLUSION, UPDATE_SELF_EXCLUSION_SUCCESS, UPDATE_SELF_EXCLUSION_FAIL],
    promise: (dsclient) => dsclient.updateSelfExclusion(selfExclusionDate)
  };
}

export function updateTimeout(timeoutDate) {
  return {
    types: [UPDATE_TIMEOUT, UPDATE_TIMEOUT_SUCCESS, UPDATE_TIMEOUT_FAIL],
    promise: (dsclient) => dsclient.updateTimeout(timeoutDate)
  };
}

export function getReferralsReport() {
  return {
    types: [GET_REFERRAL_REPORT, GET_REFERRAL_REPORT_SUCCESS, GET_REFERRAL_REPORT_FAIL],
    promise: (dsclient) => dsclient.getReferralsReport()
  };
}

export function sendPlayerInvite(email, customText) {
  return {
    types: [SEND_PLAYER_INVITE, SEND_PLAYER_INVITE_SUCCESS, SEND_PLAYER_INVITE_FAIL],
    promise: (dsclient) => dsclient.sendPlayerInvite(email, customText)
  };
}

export function checkToken(key) {
  return {
    types: [CHECK_TOKEN, CHECK_TOKEN_SUCCESS, CHECK_TOKEN_FAIL],
    promise: (dsclient) => dsclient.checkToken(key)
  };
}

export function getPlayerAccounts() {
  return {
    types: [GET_ACCOUNTS, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_FAIL],
    promise: (dsclient) => dsclient.getPlayerAccounts()
  };
}

export function getDepositRequests() {
  return {
    types: [GET_DEPOSIT_REQUESTS, GET_DEPOSIT_REQUESTS_SUCCESS, GET_DEPOSIT_REQUESTS_FAIL],
    promise: (dsclient) => dsclient.getDepositRequests()
  };
}

export function cancelDepositRequest(orderId) {
  return {
    types: [CANCEL_DEPOSIT_REQUEST, CANCEL_DEPOSIT_REQUEST_SUCCESS, CANCEL_DEPOSIT_REQUEST_FAIL],
    promise: (dsclient) => dsclient.cancelDepositRequest(orderId)
  };
}

export function getPendingWithdrawals() {
  return {
    types: [GET_PENDING_WITHDRAWALS, GET_PENDING_WITHDRAWALS_SUCCESS, GET_PENDING_WITHDRAWALS_FAIL],
    promise: (dsclient) => dsclient.getPendingWithdrawals()
  };
}

export function searchTransactions(currencyId, days, maxResults, page) {
  return {
    types: [SEARCH_TRANSACTIONS, SEARCH_TRANSACTIONS_SUCCESS, SEARCH_TRANSACTIONS_FAIL],
    promise: (dsclient) => dsclient.searchTransactions(currencyId, days, maxResults, page)
  };
}

export function getDepositPopup(deposit) {
  return {
    types: [GET_DEPOSIT_POPUP_STEP, GET_DEPOSIT_POPUP_STEP_SUCCESS, GET_DEPOSIT_POPUP_STEP_FAIL],
    promise: (dsclient) => dsclient.getDepositPopup(deposit)
  };
}

export function getDepositStep() {
  return {
    types: [GET_DEPOSIT_STEP, GET_DEPOSIT_STEP_SUCCESS, GET_DEPOSIT_STEP_FAIL],
    promise: (dsclient) => dsclient.getDepositStep()
  };
}

export function getWithdrawalStep(payment) {
  return {
    types: [GET_WITHDRAWAL_STEP, GET_WITHDRAWAL_STEP_SUCCESS, GET_WITHDRAWAL_STEP_FAIL],
    promise: (dsclient) => dsclient.getWithdrawalStep(payment)
  };
}

export function getWithdrawalStep2(payment) {
  return {
    types: [GET_WITHDRAWAL_STEP2, GET_WITHDRAWAL_STEP2_SUCCESS, GET_WITHDRAWAL_STEP2_FAIL],
    promise: (dsclient) => dsclient.getWithdrawalStep2(payment)
  };
}

export function withdrawalSubmit(payment) {
  return {
    types: [WITHDRAWAL_SUBMIT, WITHDRAWAL_SUBMIT_SUCCESS, WITHDRAWAL_SUBMIT_FAIL],
    promise: (dsclient) => dsclient.payoutSubmit(payment)
  };
}

export function withdrawalCancel(orderId, submitIp) {
  return {
    types: [WITHDRAWAL_CANCEL, WITHDRAWAL_CANCEL_SUCCESS, WITHDRAWAL_CANCEL_FAIL],
    promise: (dsclient) => dsclient.payoutCancel(orderId, submitIp)
  };
}

export function withdrawalConfirm(payment) {
  return {
    type: WITHDRAWAL_CONFIRM,
    data: payment
  };
}

export function paymentOptions(payment) {
  return {
    types: [SAVED_PAYMENT_OPTIONS, SAVED_PAYMENT_OPTIONS_SUCCESS, SAVED_PAYMENT_OPTIONS_FAIL],
    data: payment,
    promise: (dsclient) => dsclient.payoutListRecurringDetails(payment)
  };
}

export function requestPhoneValidation(phoneType) {
  return {
    types: [REQUEST_PHONE_VALIDATION, REQUEST_PHONE_VALIDATION_SUCCESS, REQUEST_PHONE_VALIDATION_FAIL],
    promise: (dsclient) => dsclient.requestPhoneValidation(phoneType)
  };
}

export function submitPhoneValidation(code) {
  return {
    types: [SUBMIT_PHONE_VALIDATION, SUBMIT_PHONE_VALIDATION_SUCCESS, SUBMIT_PHONE_VALIDATION_FAIL],
    promise: (dsclient) => dsclient.submitPhoneValidation(code)
  };
}

export function createSupportCase(playerId, memberId, email, name, message, lang) {
  return {
    types: [CREATE_SUPPORT_CASE, CREATE_SUPPORT_CASE_SUCCESS, CREATE_SUPPORT_CASE_FAIL],
    promise: (dsclient) => dsclient.createSupportCase(playerId, memberId, email, name, message, lang)
  };
}

export function getGameLastWinningDraws(gameId, gameInstanceId) {
  return {
    types: [GET_GAME_LAST_WINNING_DRAWS, GET_GAME_LAST_WINNING_DRAWS_SUCCESS, GET_GAME_LAST_WINNING_DRAWS_FAIL],
    promise: (dsclient) => dsclient.getGameLastWinningDraws(gameId, gameInstanceId)
  };
}

export function trackReferralCode(refStr) {
  return {
    types: [TRACK_REFERRAL_CODE, TRACK_REFERRAL_CODE_SUCCESS, TRACK_REFERRAL_CODE_FAIL],
    promise: (dsclient) => dsclient.trackReferralCode(refStr)
  };
}

export function claimInGameBonus(bonusId, gameInstanceId, gameId, playId, uid) {
  return {
    types: [CLAIM_IN_GAME_BONUS, CLAIM_IN_GAME_BONUS_SUCCESS, CLAIM_IN_GAME_BONUS_FAIL],
    promise: (dsclient) => dsclient.claimInGameBonus(bonusId, gameInstanceId, gameId, playId, uid)
  };
}

export function getMainAds(clientType, screenSize) {
  return {
    types: [GET_MAIN_ADS, GET_MAIN_ADS_SUCCESS, GET_MAIN_ADS_FAIL],
    promise: (dsclient) => dsclient.getMainAds(clientType, screenSize)
  };
}

export function getBonoboPayout(gameInstanceId, gameId, tournament) {
  return {
    types: [GET_BONOBO_PAYOUT, GET_BONOBO_PAYOUT_SUCCESS, GET_BONOBO_PAYOUT_FAIL],
    promise: (dsclient) => dsclient.getBonoboPayout(gameInstanceId, gameId, tournament)
  };
}

export function getPlayerAccountBalance(currencyId) {
  return {
    types: [GET_ACCOUNT_BALANCE, GET_ACCOUNT_BALANCE_SUCCESS, GET_ACCOUNT_BALANCE_FAIL],
    promise: (dsclient) => dsclient.getPlayerAccountBalance(currencyId)
  };
}
