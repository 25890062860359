import { createSelector } from "@reduxjs/toolkit";
import { formatDecimal, formatMoney, getASArray } from "./Utils";
import { Translate } from "react-localize-redux";
import React from "react";
import PromisePortal from "../thirdparty/react-promise-portal/PromisePortal";
import PopupJustOk from "../components/Modals/Popups/PopupJustOk";
import { showFullRegister } from "../reducers/lottorace";
import PopupWithAction from "../components/Modals/Popups/PopupWithAction";
import CountdownTimer from "../components/CountdownTimer";
import { getTemplateIcon } from "./TemplateUtils";

export const lottoRaceGamesSelector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(g => g.gameRule.startsWith('regularGame')));

export const lotteryGamesSelector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(g => g.gameRule.startsWith('oneDrawGame')));

export const rafflesGamesSelector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(g => g.gameRule.startsWith('raffleGame')));

export const bingoGamesSelector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(g => g.gameRule.startsWith('bingoGame')));

export const tab1Selector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(window.tab1Filter));

export const tab2Selector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(window.tab2Filter));

export const tab3Selector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(window.tab3Filter));

export const tab4Selector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(window.tab4Filter));

export const tab5Selector = createSelector(
    state => getASArray(state.lottorace.session.filteredGames),
    allGames => allGames.filter(window.tab5Filter));

export const filteredGamesSelector = (state, gameType) => {
    switch (gameType) {
        case 'tab1': return tab1Selector(state);
        case 'tab2': return tab2Selector(state);
        case 'tab3': return tab3Selector(state);
        case 'tab4': return tab4Selector(state);
        case 'tab5': return tab5Selector(state);
        default: return tab1Selector(state);
    }
}

export function hasEnoughMoney(game, lines, currency, accounts) {
    console.log(lines, currency, accounts);
    let cost = 0;
    if (currency == game.currencyId) {
        cost = lines * (game.gameFeePerPlay + game.gamePlayCost);
        if (getPrimaryBalance(accounts, currency) >= cost)
            return true;
    } else {
        cost = lines * (game.gameFeePerPlaySecondary + game.gamePlayCostSecondary)
        if (getSecondaryBalance(accounts, game.currencyIdSecondary) >= cost)
            return true;
    }
    return false;
}

export function gamePrizesWidget(game) {
    if (isBingoGame(game)) {
        return (
            <span className={"prize-pool"}>
                {game.drawRangeMax} Balls
            </span>
        )
    } else {
        return (
            <>
                <span className={"prize-pool"}>
                    {formatMoney(game.prizePool, game.payoutCurrencyId, false, false, 99999)}
                </span>
                <img src={getTemplateIcon("prizes")} className="img-fluid img-prizes" />
                {game.jackpot &&
                    <span>
                        &nbsp;+&nbsp;<img src="/static/img/jackpot.png" className={"img-fluid currency-type-BPM currency"} />
                    </span>}
            </>
        )
    }
}

export function gameStatusWidget(game) {
    if (isTournament(game)) {
        return <CountdownTimer endTime={game.tournamentStartTime}
            initialTimeRemaining={game.tournamentStartTime - window.globalConfig.serverTimeDelta - new Date().getTime()} />
    } else
        if (isLottoRaceGame(game)) {
            return (
                <>
                    <div className="text-left">{game.gamePlayCurrent}/{game.gamePlayMax} Plays</div>
                    <div className="players text-left">Starts when full</div>
                </>
            )
        } else
            if (isLotteryGame(game)) {
                return (
                    <>
                        <div className="text-left text-smaller-75">Starts on Join</div>
                        <div className="players text-left">Up to {formatDecimal(game.gamePlayMax)} Lines</div>
                    </>
                )
            } else
                if (isBingoGame(game)) {
                    return (
                        <>
                            <div className="text-left text-smaller-75">Starts on Join</div>
                            <div className="players text-left">Up to {game.gamePlayMax} Cards</div>
                        </>
                    )
                }
}

export function getPrizePool(game) {
    if (isRaffleGame(game)) {
        if (game.bonoboItem.prizeItem) {
            return (
                <div>
                    {game.bonoboItem.prizeItem.name}
                </div>);
        } else {
            return (
                <div>
                    {formatMoney(game.prizePool, game.payoutCurrencyId)}
                </div>);
        }
    }
    var prizes;
    if (!game.tournament) {
        prizes = game.prizePool;
    } else if (game.tournament) {
        if (game.rollover) {
            if (game.guaranteedPrizePool > game.prizePool) {
                prizes = game.guaranteedPrizePool;
            } else {
                prizes = game.prizePool;
            }
        } else {
            const realPrizePool = game.gamePlayCurrent * game.gamePlayCost;
            if (game.estimatedPrizePool > game.guaranteedPrizePool) {
                prizes = game.estimatedPrizePool > realPrizePool ? game.estimatedPrizePool : realPrizePool;
            } else {
                prizes = game.guaranteedPrizePool > realPrizePool ? game.guaranteedPrizePool : realPrizePool;
            }
        }
    }

    if (isLotteryGameExt()) {
        prizes = game.prizePool + (game.progressiveAmount ? game.progressiveAmount : 0);
    }

    return <span>{formatMoney(prizes, game.payoutCurrencyId)}</span>
}

export function hasPrePaidSubscription(game) {
    return game != null && game.subscription == true;
}

export function isPrimaryCurrency(game, currency) {
    return game != null && game.currencyId == currency;
}

export function getPrimaryBalance(playerAccounts, currencyId) {
    return getASArray(playerAccounts)
        .find(a => a.accountCurrency == currencyId && a.accountId == 43)
        .accountBalance;
}

export function getSecondaryBalance(playerAccounts, currencyId) {
    return getASArray(playerAccounts)
        .find(a => a.accountCurrency == currencyId && a.accountId == 43)
        .accountBalance;
}

export function isRaffleGame(game) {
    return game && game.gameRule?.contains('raffleGame');
}

export function isSpinnerGame(game) {
    return game && game.gameRule?.contains('spinnerGame');
}

export function isManualRaffleGame(game) {
    return game && game.gameRule?.contains('raffleGame') && game.manualBet == true;
}

export function isLotteryGameExt(game) {
    return game && game.gameRule?.contains('oneDrawGameExt');
}

export function isLotteryGameSatellite(game) {
    return game && game.gameRule.contains('oneDrawGame') && game.payoutFormula == 'OneDraw10kSatellite';
}

export function isLotteryPermGame(game) {
    return game && game.gameRule.contains("oneDrawGamePerm");
}

export function isLotteryPickX(game) {
    return game && game.gameRule?.contains('oneDrawGameExt');
}

export function isLotteryMultiPickX(game) {
    return game && game.gameRule?.contains('oneDrawGameExtMulti');
}

export function isLotteryPermutation(game) {
    return game && game.gameRule?.contains('oneDrawGamePerm');
}

export function isBingoGame(game) {
    return game && game.gameRule?.contains('bingoGame');
}

export function isPermGame(game) {
    return game && game.gameRule?.contains('oneDrawGamePerm');
}

export function isCrosswordGame(game) {
    return game && game.gameRule?.contains('crosswordGame');
}

export function isScratchcardPlayoutGame(game) {
    return game && game.gamePlayout === 'scratchcard';
};

export function isCrosswordPlayoutGame(game) {
    return game && game.gamePlayout === 'crossword';
};

export function isScratchcardCrosswordLayout(game) {
    return game &&
        game.gameRule.contains('crosswordGame') &&
        game.gamePlayout === 'scratchcard';
};

export function isScratchcardDefaultLayout(game) {
    return game &&
        game.gameRule.contains('scratchcardGame') &&
        game.gamePlayout === 'scratchcard';
};

export function isScratchcardGame(game) {
    return game && game.gameRule?.contains('scratchcardGame');
}

export function isAnyLotteryGame(game) {
    return game && game.gameRule?.contains('oneDrawGame');
}

export function isLotteryGame(game) {
    return game && game.gameRule?.contains('oneDrawGame') && !isLotteryGameExt(game);
}

export function isLottoRaceGame(game) {
    return game && game.gameRule?.contains('regularGame');
}

export function isDynamicScratchcardGame(game) {
    return game && game.gameRule?.contains('dynamicScratchcardGame');
}

export function isTournament(game) {
    return game && game.tournament;
}

export function isInstaplay(game) {
    return !isTournament(game);
}

export function hasProgressive(game) {
    return game && (!isRaffleGame(game) && !isLotteryGameExt(game) && game.jackpot);
}

export function arraySizeNonNull(array) {
    return array.filter(function (el) {
        return el != null && el != -1;
    }).length;
}

export function arraySizeNonZero(array) {
    console.log(array);
    return array.filter(function (el) {
        return el != null && el != 0;
    }).length;
}

export function arraySizeNonEmpty(array) {
    console.log(array);
    return array.filter(function (el) {
        return el != null && el != '' && el != '#';
    }).length;
}

export function goToGameLobby(game, history) {
    if (isBingoGame(game)) {
        history.push('/bingo/lobby?gid=' + game.gameInstanceId + '&g=' + game.gameId);
    } else
        if (isLotteryGame(game)) {
            history.push('/lobby/lottery?id=' + game.gameInstanceId + '&gid=' + game.gameId);
        } else
            if (isRaffleGame(game)) {
                history.push('/lobby/raffle?id=' + game.gameInstanceId + '&gid=' + game.gameId);
            } else {
                history.push('/lobby/lottorace?id=' + game.gameInstanceId + '&gid=' + game.gameId);
            };
}

export function isGameReady(game) {
    return game != null && (game.gameStatus == "READY" || game.gameStatus == "STARTED" || game.gameStatus == "REPLAY" || game.gameStatus == "REPLAY1");
}

export function canJoinWith(game, bets) {
    return game != null && bets <= (Math.min(game.playerPlayMax - game.playerPlaysInGame, game.gamePlayMax - game.gamePlayCurrent));
}

export function betMax(game) {
    const max = Math.min(game.playerPlayMax - game.playerPlaysInGame, game.gamePlayMax - game.gamePlayCurrent);
    return max > 0 ? max : 1;
}

export function betHalf(game) {
    const max = betMax(game);
    const half = Math.floor(max / 2);
    return half > 0 ? (half != 2 ? half : (Math.round((max - 2) / 2 + 2))) : 1;
}

export function isGameOpen(game) {
    return game != null && game.gameStatus == 'OPEN';
}

export function getOddsNumber(game) {
    return game.payoutPercentage >= 1 ?
        game.payoutPercentage :
        Math.floor(game.gamePlayMax / Math.round(game.payoutPercentage * game.gamePlayMax));
}

export function getNth(num) {
    if (num < 0) return "";
    var rankStr = num + "";
    var last2Dig;
    if (rankStr.length >= 2) {
        last2Dig = rankStr.substring(rankStr.length - 2);
        if (last2Dig == "11" || last2Dig == "12" || last2Dig == "13") {
            return <span>{num}<sup><Translate id={"generic.all"} /></sup></span>;
        }
    }

    var lastDig = rankStr.substring(rankStr.length - 1);
    if (lastDig == "1") return <span>1<sup><Translate id={"generic.first"} /></sup></span>;
    if (lastDig == "2") return <span>2<sup><Translate id={"generic.second"} /></sup></span>;
    if (lastDig == "3") return <span>3<sup><Translate id={"generic.all"} /></sup></span>;
    else return <span>{num}<sup><Translate id={"generic.all"} /></sup></span>;
};

export function doResize(el, parent) {
    if (el instanceof HTMLCollection) {
        for (let i = 0; i < el.length; i++) {
            resizeElement(el[i], parent);
        };
    } else {
        resizeElement(el, parent);
    }
}

function resizeElement(el, parent) {
    console.log(el);
    const scale = Math.min(
        parent.offsetWidth / el.outerHeight,
        parent.offsetHeight / el.outerWidth
    );

    el.style.transform = "translate(-50%, -50%) " + "scale(" + scale + ")";
}

export function resizeBingoCards(cards, parent, totalCards) {
    if (cards instanceof HTMLCollection) {
        for (let i = 0; i < cards.length; i++) {
            fillDiv(cards[i], parent, totalCards);
        };
    } else {
        fillDiv(cards, parent, totalCards);
    }
}

export function fillDiv(div, parent, totalCards) {
    var currentWidth = div.offsetWidth;
    var currentHeight = div.offsetHeight;

    var cardOffsetHeight = 0;
    var cardOffsetWidth = 0;
    var cardBetweenPadding = 20;

    if (totalCards == 1) {

    } else
        if (totalCards == 2) {
            cardOffsetWidth += cardOffsetWidth / 2 - cardBetweenPadding;
        } else
            if (totalCards == 3 || totalCards == 4) {
                cardOffsetWidth += cardOffsetWidth / 2 - cardBetweenPadding;
                cardOffsetHeight += cardOffsetHeight / 2 - cardBetweenPadding;
            }

    var availableHeight = parent.clientHeight - cardOffsetHeight;
    var availableWidth = parent.clientWidth - cardOffsetWidth;

    var scaleX = availableWidth / currentWidth;
    var scaleY = availableHeight / currentHeight;

    scaleX = Math.min(scaleX, scaleY);
    scaleY = scaleX;

    var translationX = Math.round((availableWidth - (currentWidth * scaleX)) / 2);
    var translationY = Math.round((availableHeight - (currentHeight * scaleY)) / 2);

    console.log("translate(" + translationX + "px, " + translationY + "px) scale(" + scaleX + ")");
    //div.style.transformOrigin = "0 0";
    div.style.transform = "translate(" + translationX + "px, " + translationY + "px) scale(" + scaleX + ")";
}

function checkSegment(seg) {
    if (seg != 'A' && seg != 'B') {
        return true;
    } else {
        return false;
    }
}

function hasFunds(currencyId, session, entryFee) {
    for (var i = 0; i < getASArray(session.playerAccounts).length; i++) {
        var acc = getASArray(session.playerAccounts)[i];
        if (acc && acc.accountCurrency == currencyId && acc.accountId == 43 && parseFloat(acc.accountBalance) >= entryFee) {
            return true;
        }
    }

    return false;
}

function hasDeposits(playerSegment) {
    switch (playerSegment) {
        case 'A', 'A1', 'B', 'C':
            return false;
        default:
            return true;
    }
}

export function allowJoin(currentGame, plays = 1, translate, history) {
    const session = window.store.getState().lottorace.session;

    const playerSegment = session.player.verification.playerSegment;

    if (playerSegment == '') return false;

    if (playerSegment == 'A' || playerSegment == 'A1') {
        const result = showPopupJustOk(
            "Not available in your country",
            window.globalConfig.siteName + " is not currently available in your country.  We will notify you if this changes in the future");

        return false;
    };

    if (currentGame.gamePlayCost == 0 && !hasDeposits(playerSegment) && session.configuration.freerollNoDeposits == false) {
        const result = showPopupWithAction(
          translate("popup.freerolls_disabled.title"),
          translate("popup.freerolls_disabled.text"),
          "Deposit",
          window.globalConfig.enableFacebook ? "/chips" : "/cashier", history);

        return false;
    }

    if (session.configuration['EMAIL_ON_FULL_REGISTRATION'] == "true" && session.player.verification.emailVerified == false && checkSegment(playerSegment) == true) {
        console.log('Trigger email verification!');
        return;
    }

    if (playerSegment == 'B') {
        window.store.dispatch(showFullRegister());
        return false;
    }

    if (!hasFunds(currentGame.currencyId, session, (currentGame.gamePlayCost + currentGame.gameFeePerPlay) * plays)) {
        const result = showPopupWithAction(
            translate("popup.no_money.title"),
            translate("popup.no_money.text"),
            "Deposit",
            window.globalConfig.enableFacebook ? "/chips" : "/cashier", history);

        return false;
    }

    switch (playerSegment) {
        case 'A':
            //ineligible jurisdiction
            //retVal = false;
            return false;
        case 'A1':
            //inactive player
            //retVal =  false;
            return false;
        case 'C':
            return true;
        case 'E':
            return true;
        case 'F':
            return true;
        case 'G':
            return true;
        default:
            return true;
    }
}

export const showPopupJustOk = async (title, body, link, history) => {
    const result = await PromisePortal.show(PopupJustOk, {
        title: title,
        body: body
    });

    if (link && result.cancelled == false) {
        history.push(link);
    }
}

export const showPopupWithAction = async (title, body, actionButton, link, history) => {
    const result = await PromisePortal.show(PopupWithAction, {
        title: title,
        body: body,
        actionButton: actionButton,
        actionButtonClass: "btn-primary"
    });

    if (link && result.data == 'ACTION') {
        history.push(link);
    }
}

export const showIDVerificationPopup = (translate, history, configuration, player) => {
    if (configuration.emailVerifiedEnabled == true && player.verification.emailVerified == false) {
        showEmailVerificationPopup(translate, player?.details.primaryEmail, true);
    } else {
        window.showEmailConfirmation(translate('myaccount.verifyID.title'), translate('myaccount.verifyID.info'));

        $('#regResend').click(function () {
            $(this).trigger('notify-hide');
            history.push ? history.push('/') : history('/');
        });

        $('#regOk').click(function () {
            $(this).trigger('notify-hide');
            history.push ? history.push('/account/verification') : history('/account/verification');
        });
    }
}

export const showEmailVerificationPopup = (translate, email, from_id = false) => {
    window.showEmailConfirmation(translate('myaccount.verifyEmail.title'), translate('myaccount.verifyEmail.info', { email: email }));
    $('#regResend').text("Cancel");
    $('#regOk').text("Send Email");

    $('#regOk').click(function () {
        $(this).trigger('notify-hide');

        window.apiClient.requestEmailConfirmation().then((action) => {
            setTimeout(() => {
                window.showEmailConfirmation(translate('myaccount.emailSent.title'), translate('myaccount.emailSent.info', { email: email }));

                $('#regResend').text("Resend Email");
                $('#regOk').text("Close");

                $('#regOk').click(function () {
                    $(this).trigger('notify-hide');
                });

                $('#regResend').click(function () {
                    $(this).trigger('notify-hide');
                    setTimeout(() => showEmailVerificationPopup(translate, email), 500);
                });
            }, 500);

        });
    });

    $('#regResend').click(function () {
        $(this).trigger('notify-hide');
    });
}
