/* eslint-disable no-template-curly-in-string,no-useless-escape */
export default {
  "locales": ["en-US"],
  "messages": {
    "game.general.game_id": "Game ID",
    "main.instaplay.title": "Instaplay",
    "tutorial.screen.text9": "Manual Play",
    "tutorial.screen.text8": "You can view how many plays you have in this game here",
    "tutorial.screen.text7": "My Plays",
    "withdraw.confirm.amount": "Amount to withdraw",
    "withdrawal.status.text.withdrawPendingWithdrawal": "You can only have 1 pending withdrawal at any given time. Please cancel your previous withdrawal request or wait for it to be processed prior to making a second request.",
    "tutorial.screen.text2": "Total prizes to be won. Can be estimated or guaranteed. Estimated prize pools will be adjusted to the actual value before the game starts or when the estimate is exceeded.",
    "tutorial.screen.text1": "This is the Prize Pool",
    "main.nav.open_games": "Open Games",
    "oneclick.v1.confirm.dd.bank_details": "Bank Details",
    "tutorial.screen.text6": "Displays the number of of players and plays in the game",
    "playout.new.raffle.starting.prize_count": "This Raffle has ${prizes} Prizes",
    "tutorial.screen.text5": "Players List",
    "game.general.card": "Card",
    "registration.quick.email": "Email",
    "tutorial.screen.text4": "Displays the odds for winning a prize and the estimated first place prize. ",
    "general.scan_qr": "Scanning for Ticket QR Code",
    "myaccount.verified_on": "Verified on ",
    "tutorial.screen.text3": "Game Payout",
    "oneclick.prize.prize_pool_gtd": "GTD. Prize Pool",
    "myaccount.infopage.idnotverified": "Your ID is NOT verified",
    "respgaming.week": "One Week",
    "game.general.play": "Play",
    "oneclick.v1.success.print.button": "&nbsp;Print&nbsp;&nbsp;",
    "general.manual_play": "Manual Play",
    "drawer.serkha_bumper_2020": "Serkha Bumper, 2020",
    "myaccount.notify.promotions": "Promotions",
    "pendingwithdrawal.title1": "You may only have 1 pending withdrawal at any time",
    "game.crossword.loading.join_success": "Card Purchased!",
    "game.general.popup_curr_playing": "This Game is currently playing",
    "cashier.addfunds": "Add Funds",
    "contact.title.1": "Send us a message",
    "gameplayout.hide_all_lines": "Winners will be shown here <br/>after each draw.",
    "contact.title.2": "Ticket created",
    "cashierhistory.last10": "Last 10 Days",
    "contact.title.3": "Ticket not created",
    "oneclick.v1.ticket_cost.text": "Each ticket is ${ticketCost}",
    "respgaming.selfexclusion.title": "Self Exclusion",
    "game.crossword.end.luck_next_time": "Better luck next time!",
    "footer.nolicense": "This is a private game and is not operating under a license",
    "numpick.subscriptions.bet.success": "This play will be repeated for the next ${count} games!",
    "withdrawal.status.text.withdrawCountryDisabled": " is not currently available in your country. We will notify you if this changes in the future. ",
    "respgaming.edit.daily.limit": "Daily Deposit",
    "myaccount.email.updated": "Your email has been updated",
    "myaccount.button.pleaseverify": "Please Verify",
    "ticket.check.error.not_a_win": "This is not a winning ticket",
    "game.general.confirm_purchase": "Please confirm your purchase",
    "numpick.confirm.raffle.bets_placed": "${count} Tickets bought!",
    "myaccount.title.manage": "Manage your account",
    "oneclick.v1.dd.account_number": "Account Number",
    "generic.players": "Players",
    "gamelobby.live_description": "PLAY HERE NOW",
    "soundconfig.savesettings": "Save Settings",
    "oneclick.v1.userinfo.salutation.invalid": "Please select your title",
    "general.max_plays": "Max Plays",
    "main.top.nav.title.my_stats.link.wagers": "Lifetime Wagers",
    "home.login": "Login to the Demo!",
    "main.raffle.title": "Lottery & Raffle",
    "referrals.subtitle.nobonus": "It's more fun playing together!",
    "oneclick.v1.game_starts_on.text": "Draw on ${startDate}",
    "respgaming.selfexclusion.edit.info.yes": "Your account is disabled until ${date}",
    "myaccount.update_number.subtitle": "Your contact number must be verified at all times before you can play. Upon updating your contact number you must verify it before you can resume play.",
    "completed.out_of": "out of",
    "deposit.tooltip.fpp": "Your player reward points balance",
    "picker.game_not_available": "Game no longer available",
    "game.crossword.playout.letters_left": "Letters left to scratch",
    "general.satellite": "Satellite",
    "gameover.lottery": "Jackpot",
    "oneclick.v1.addressinfo.postal.placeholder": "Postal/Zip Code",
    "gameover.no_winning_bets": "No winning plays in this game",
    "playout.two_ways_to_win": "Two ways to win!",
    "changepassword.request.emailsent": "A password reset link has been sent to your email address.",
    "gameover.raffle.cash_item.grand_prize": "Grand Prize Winner!",
    "generic.datetimeformat.word.long": "MMM d yyyy hh:mma",
    "oneclick.v1.success.games_rules.text": "Please see game rules on<br/>${gameRulesUrl}",
    "general.prize": "Prize",
    "respgaming.reality.adjustfrequency": "Adjust Frequency",
    "oneclick.success.line_4": "You will be entered in the following game(s) starting from ${subscriptionStartDate}",
    "completed.no_wins": "No Wins",
    "numpick.confirm": "Confirm your ",
    "game.crossword.details.playing": "Playing",
    "gamelobby.favorite": "Favorite",
    "oneclick.v1.userinfo.password.invalid": "Password must be at least 6 characters with least 1 Alphabet and 1 Number",
    "generic.third": "rd",
    "numpick.confirm.raffle.x_bet": "You now have ${count} ticket",
    "numpick.subscription.bets": "Repeat these plays in ",
    "picker.pay": "Pay",
    "playout.new.draw": "Draw ${draw}",
    "generic.all": "th",
    "myaccount.verifyPhone.title": "Phone Verification",
    "maintenance3.body1": "This page will automatically refresh when scheduled maintenance has been completed.",
    "withdraw.success.title": "Your withdrawal request of ${amount} is now complete!",
    "maintenance3.body2": "Thank you for your patience!",
    "regisgtration.full.title1": "Step 1/2",
    "regisgtration.full.please_complete": "Complete Your Registration",
    "regisgtration.full.title2": "Step 2/2",
    "history.game.select": "Select Game",
    "oneclick.v1.success.footer.line2.text": "Proceeds will be to the benefit of ${siteName} and the operation of this game.",
    "generic.pick": "Pick",
    "picker.rejoin": "Rejoin",
    "general.minutes": "Minutes",
    "main.gameItem.starts_when": "Starts when you",
    "numpick.subscription.bet": "Repeat this play in ",
    "general.general": "General",
    "game.crossword.playout.pop_to_reveal": "Pop to reveal your letters",
    "cashier.history": "Cashier History",
    "generic.datetimeformat.word.tz.long": "MMM dd yyyy @ hh:mma zzzz",
    "playout.jackpot.won": "Jackpot Won!",
    "generic.form.email.error": "Email is already in use",
    "numpick.join.error.tryagain": "Would you like to try again?",
    "gameover.jackpot.finished": "Game is Over",
    "completed.free_entry": "1 free entry",
    "deposit.status.text.depositLimitsReached": "You have reached your deposit limit. You may deposit again in 24 hours.",
    "changepassword.reset.passwordupdatemessage": "We've made some improvements to our site and we need you to create a new password!",
    "game.crossword.details.choose_card": "Select your Card",
    "contact.email": "Your email",
    "scan_ticket.ticket_id.tip": "The Ticket ID from your printed ticket",
    "generic.send": "Send",
    "oneclick.v1.payments.select.text": "Select Payment",
    "deposit.status.text.depositPhoneNotVerified": "You must verify your phone number in order to continue",
    "gameover.try_again": "Sorry",
    "myaccount.change_password.subtitle": " Your password must be a combination of letters and at least one number. Keep your password safe and protect your account.",
    "locale.short": "en",
    "oneclick.success.line_2": "An email receipt was sent to ${email}",
    "main.lotteryRaffleGames.desc": "Great selection of Lottery & Raffle games",
    "oneclick.success.line_3": "Thank you for playing, ${firstName}",
    "playout.new.raffle.ended.all_paid": "All prizes have been paid out",
    "respgaming.hours": "24 Hours",
    "deposit.title": "How much would you like to deposit?",
    "game.crossword.playout.letters_left_pop": "Letters left to pop",
    "game.crossword.words_and_won": "words and won",
    "oneclick.success.line_1": "Success!",
    "oneclick.v1.success.games_watch_live.text": "Watch LIVE on",
    "game.general.cancel": "Cancel",
    "gamelobby.payout.onedraw1": "Match all numbers for Jackpot, ${count} ways to win!",
    "respgaming.reality.logged": "You've been logged in for",
    "main.gameItem.matchSymbols": "Match ${noSymbols} symbols to win",
    "game.bingo.details.waiting_for": "Waiting for",
    "gamelobby.payout.onedraw2": "${count}+ Matched pays!",
    "gamelobby.raffle.description.default.second.multiple_prizes": " one of the ${prizesAvailable} Guaranteed Prizes!",
    "payout.no_winners_yet": "No winners yet",
    "drawer.thuensum_quick_cash": "Thuensum Quick Cash",
    "registration.full.invalid.postalcode": "Invalid Postal Code",
    "myaccount.infopage.title": "Update your name and birthdate",
    "game.bingo.player.no_wins": "No Wins",
    "numpick.confirm.x_bets": "You now have ${count} plays",
    "regisgtration.full.dob": "Date of Birth",
    "registration.quick.confirm_password": "Confirm Password",
    "main.gameItem.progressive": "Jackpot Available",
    "gameover.lottery.prize_won": "${count} prize won",
    "myaccount.verifyID.title": "Verify your ID",
    "deposit.paymentmethod": "Payment Method",
    "game.crossword.you_matched": "You matched",
    "withdraw.confirm.address": "Address",
    "general.add_manual_play": "Add Manual Play",
    "myaccount.account_number": "Player ID. ${id}",
    "respgaming.selfexclusion.edit.date": "Pick a date",
    "numpick.pickXnum": "Pick ${y} numbers",
    "game.general.ticket": "Ticket",
    "gamelobby.favorite_added": "+ Added to Favorites",
    "generic.datetimeformat.tournament.less_than_7": "EEE @ h:mma",
    "modal.login.sign_up": "Sign Up for ${siteName}",
    "numpick.pay_with": "Pay with:",
    "respgaming.timeout.title": "Time out",
    "locale": "en_US",
    "gameover.jackpot.not_won": "was not won",
    "history.loading": "Loading Replay History",
    "generic.continue": "Continue",
    "completed_games.subscription.played_games": "Games Joined",
    "game.crossword.details.scratch_letters": "Scratch Letters",
    "gamelobby.lottery.regular.description.default": "Enter the ${gameTitle} for just ${playCost} to win",
    "game.bingo.payout.pay_bingo": "If you win on multiple patterns on one card, the best one will be paid.",
    "modal.login.sign_up_now": "Sign up Now!",
    "picker.waiting_next_game": "Waiting for next game...",
    "respgaming.daily.limit": "Daily Deposit Limit",
    "modal.login.forgot": "Reset Password",
    "game.crossword.cardpick.win": "Win",
    "gamelobby.progressive_match": "Match ${y} in one draw and win!",
    "cashierhistory.last31": "Last 31 Days",
    "completed_games.previous": "< Previous",
    "oneclick.v1.add_to_cart.text": "Add to Cart",
    "payout.max_payout": "Max Payout",
    "referrals.check_status.button": "Check Status",
    "myaccount.change_password.title2": "Your password has been changed",
    "picker.purchase_failed": "Purchase Failed",
    "myaccount.change_password.title1": "Change your password",
    "general.third": "Third",
    "myaccount.notify.game_wins": "Game Wins",
    "playout.new.raffle.winner.prize_won_valued": "Valued at ${prize}",
    "popup.you_have_no_bets": "You have no plays in game",
    "game.bingo.details.game_start_in": "Game will start in",
    "mbob.start_new_request": "You can start a new request to deposit via mBoB",
    "drawer.announcements": "Announcements",
    "numpick.random": "Random",
    "numpick.subscription.x": "next ${count} games",
    "ticket.check.error.invalid_ticket_reprinted": "This ticket has been re-printed",
    "drawer.terms_conditions": "Terms & Conditions",
    "regisgtration.full.gender": "Gender",
    "oneclick.v1.userinfo.first_name.placeholder": "First Name",
    "gameover.raffle.in_prizes": "In Prizes",
    "cashier.deplimits.daily": "Daily:",
    "completed.description": "View a list of completed games",
    "payout.total_gtd_prizes": "Total Gtd. Prizes",
    "myaccount.demo": "Demo",
    "withdraw.success.pendingwithdrawal": "You now have 1 pending withdrawal!",
    "game.general.onecard": "1 Card",
    "picker.save": "Save",
    "playout.jackpot_won_by": "Jackpot won by",
    "gamelobby.progressive_tooltip_onedraw": "Available on the 1st draw",
    "gameover.raffle.prizes.word": "prizes",
    "numpick.subscription.fail": "Game subscription not added. No extra charges were made.",
    "myaccount.transactions.total_debits": "Total Debits: ${debits}",
    "referrals.section3.title": "Send quick email invite",
    "tutorial.button.next": "Next",
    "cashier.prizes.title": "My Prizes",
    "myaccount.mycashier": "My Cashier",
    "oneclick.v1.addressinfo.email.tips": "We'll never share your email with anyone else.",
    "changepassword.invalidkey.body.line1": "The password reset link you are using is either invalid or expired. Please try again.",
    "game.bingo.payout.autodaub": "Called numbers will be autodaubed for you",
    "picker.add_bet": "Add Bet",
    "deposit.status.tile.depositDisabled": "Not available in your country",
    "game.general.loading_game": "Loading Game",
    "gameover.lottery.paid_out": "Paid Out",
    "playout.game_starting": "Game Starting",
    "subscriptions_page.updated_on": "Updated on ${date}",
    "game.bingo.loading.join_success.plural": "${cards} Cards Purchased",
    "gameplayout.nowinners-draw": "No Winners This Draw",
    "playout.jackpot.won2": "Matched ${count} numbers in ONE draw and won!",
    "account.appsettings.pleasereload": "Please reload the application",
    "playout.jackpot_tooltip": "Jackpot can be won by matching all the numbers on the 1st draw",
    "playout.you_won": "Congratulations!!!<br/>You won ${amount}",
    "generic.game_sequence": "Game No. ${seq}",
    "myaccount.infopage.idverified": "Your ID is verified",
    "respgaming.reality.acceptcontinue": "Accept and Continue",
    "validation.maxlength.144": "Must be no more than 144 characters",
    "drawer.tender": "Tender",
    "main.gameItem.button.openingsoon": "Opening Soon",
    "soundconfig.individual": "This game includes sounds which require a high speed connection for the best experience.\n\nThey are turned off by default. You can change your sound settings by clicking on the sound menu from the main navigation menu",
    "main.nav.button.starting": "Starting",
    "game.crossword.cardpick.crossword": "Crossword",
    "referrals.section2.button": "Copy Link",
    "regisgtration.full.gov_id_2": "Government ID 2",
    "main.gameItem.button.bet.free": "Free Entry",
    "regisgtration.full.gov_id_1": "CID/Permit No.",
    "withdrawal.status.text.withdrawNotEnoughFunds": "The minimum withdrawal is ${min}. You currently do not have enough funds to continue. ",
    "main.4.desc": "Great selection of Lottery & Raffle games",
    "myaccount.transactions.next": "Next >",
    "oneclick.v1.success.header.line1.text": "Ticket successfully issued",
    "completed_games.dropdown.text": "in the last ${days} days",
    "numpick.subscription.raffle.bets": "Repeat these tickets in ",
    "popup.back_to": "Back to Lobby",
    "registration.full.last_name.details": "Please enter name as it appears on your ID document",
    "generic.cancel": "Cancel",
    "myaccount.title.playing_since": "Playing since ",
    "gamelobby.raffle.tickets_sold": "${tickets} Tickets Sold",
    "game.crossword.loading.try_again": "Try Again",
    "phone.validation.no.info": "This phone number cannot be validated at this time",
    "completedgames.won_satellite": "Won ${entries} entry(s) to ${game}",
    "Timeout.timeoutDate[past]": "The date entered is in the past",
    "playout.new.raffle.starting.single.prize.value": "${prizeName}<br/>Valued at ${prizeValue}",
    "numpick.subscription.bets.success": "These plays will be repeated for the next ${count} game!",
    "main.gameItem.starts_in": "Starts",
    "numpick.confirm.raffle.bet_placed": "${count} Ticket bought!",
    "myaccount.withdraw": "Withdraw",
    "deposit.coupon.placeholder": "Enter Coupon Code",
    "generic.these": "These",
    "myaccount.please_verify": "Please Verify",
    "oneclick.v1.userinfo.salutation.choose": "Choose a title",
    "main.nav.button.sold_out": "Sold Out",
    "picker.no_game_found": "No game found",
    "game.crossword.end.not_winner": "This ticket is not a winner",
    "main.gameItem.button.raffle.my_bet": "My ticket",
    "payout.disclaimer": "All values are estimated assuming no ties in each rank. The values may differ slightly if there are a large number of ties in each position.",
    "playgame.gamestarting": " is starting!",
    "changepassword.instructions.title": "Instructions sent!",
    "validation.min.length.6": "Must be at least 6 characters",
    "myaccount.verifyPhone.info": "Launch phone verification flow.",
    "oneclick.v1.previous_pick.button": "Previous<br/>Pick",
    "validation.min.length.3": "Must be at least 3 characters",
    "game.general.game_start": "Loading Game",
    "main.instaGames.desc": "Games start when full",
    "gamelobby.enter_now": "Enter Now",
    "oneclick.v1.dd.account_name": "Account Holder",
    "game.general.your_bet": "Your Bet",
    "main.gameItem.1inX": "1 in ${count} Wins!",
    "game.bingo.payout.outside_frame": "Outside Frame",
    "payout.into": "Into",
    "game.general.game": "Game",
    "main.raffle.desc": "Great selection of Lottery & Raffle games",
    "payout.entry": "Entry",
    "regisgtration.full.name": "Name",
    "general.first": "First",
    "oneclick.v1.confirm.tickets.text": "${tickets} Tickets in ${gameName}",
    "generic.by": "by",
    "phone.validation.no": "Phone not verified",
    "deposit.newpaymentmethod": "New payment option",
    "oneclick.v1.userinfo.login.text": "Already have an account?",
    "oneclick.v1.header.purchase": "Purchase",
    "oneclick.v1.success.footer.sweepstakes.text": "This free sweepstakes game is operated by ${operatedBy}. No purchase is necessary. You may be asked to answer a skill testing question when redeeming your prize.",
    "soundconfig.raffle": "Raffle Full Sound",
    "general.prizes": "Prizes",
    "oneclick.v1.userinfo.birthday.invalid": "Please select your age",
    "regisgtration.full.select_occupation": "Select or type occupation",
    "withdrawal.status.text.withdrawMustDeposit": "Sorry, but you must have at least 1 deposit before you can make a withdrawal.",
    "scan_ticket.no_devices": "No scanning device detected, manually search for a ticket above",
    "generic.bet_lowcase": "play",
    "generic.clear": "Clear",
    "referrals.section2.title": "Share your referral link with friends:",
    "game.general.two": "Two",
    "gameplayout.please_wait": "Please Wait",
    "lastdraw.title": "Last Winning Draw",
    "myaccount.email.title": "Update your email",
    "game.crossword.play_again": "Play Again",
    "picker.price_per_line": "Price Per Line",
    "cashier.prizes.collect.won_on": "Won on ${dateWon}",
    "myaccount.password.hasnotbeenchanged": "Password hasn't been changed since registration",
    "picker.pick_more": "You can pick ${numbers} more numbers",
    "registration.quick.errors.username": "Invalid username! Alphanumerics or underscore only",
    "maintenance2.title": "You were disconnected!",
    "popup.no_money.text": "You do not have sufficient funds to place your bet. Please deposit to continue.",
    "registration.popup.later": "Later",
    "oneclick.v1.userinfo.birthdate.placeholder": "Birthdate (dd/MM/yyyy)",
    "playout.new.raffle.winner_is.cash.line2": "CASH PRIZE is",
    "game.bingo.payout.full_box": "Full Box",
    "referrals.send.email.validation": "Not a valid email",
    "registration.quick.existing.password": "Current Password",
    "soundconfig.scratchcard": "Scratchcard Full Sound",
    "game.crossword.end.win": "win",
    "login.error.103": "Incorrect credentials. If you forgot your password, please reset your password using the link below.",
    "login.error.102": "You are not authorized to access this resource.",
    "login.error.105": "Your account is expired.",
    "login.error.104": "You cannot re-authenticate in the same session.",
    "regisgtration.full.next": "Next >",
    "login.error.107": "You've restricted yourself from playing. Contact support for help.",
    "login.error.106": "Your account is locked.",
    "login.error.109": "User and/or password are not in the correct format.",
    "cashier.prizes.collect.locations.title": "Available Locations",
    "login.error.108": "You don't have a temporary password.",
    "validation.maxlength.x": "Must be no more then ${max} characters",
    "game.crossword.details.to_win_prizes": "to win Prizes",
    "login.error.101": "You are not logged in.",
    "phone.validation.call.info": "If you did not receive a call, please click call again.",
    "drawer.welcome_to": "Welcome to",
    "respgaming.weekly.limit": "Weekly Deposit Limit",
    "oneclick.prize.prize_pool_est": "EST. Prize Pool",
    "phone.validation.verifing": "Verifying: ",
    "main.gameItem.button.soldout": "Sold Out",
    "oneclick.v1.addressinfo.email.invalid": "Doesn't look like a valid email",
    "oneclick.v1.userinfo.first_name.invalid": "Doesn't look like a cool first name",
    "general.every": "Every",
    "main.gameItem.button.startingsoon": "Starting Soon",
    "mbob.date_created": "Date Created",
    "general.sixth": "Sixth",
    "home.get_access": "Get access to the demo",
    "oneclick.v1.total_tickets.text": "Total Tickets",
    "drawer.replays": "Replays",
    "oneclick.v1.next.button": "&nbsp;&nbsp;Next&nbsp;&nbsp;",
    "oneclick.v1.quick_pick.button": "Quick<br/>Pick",
    "playgame.gameplaying": " is playing!",
    "referrals.send.email.success": "Invite sent to ${email}",
    "gameover.sorry": "Sorry, no wins",
    "login.error.115": "Password must be changed.",
    "login.error.110": "User is not in the correct format.",
    "login.error.Server.Processing": "Your credentials are not valid.",
    "account.appsettings.http": "HTTP",
    "general.cash": "Cash",
    "login.error.111": "Password is not in the correct format.",
    "subscriptions_page.completed": "Completed",
    "picker.started": "Started",
    "contact.ticket_not_created": "We could not create the ticket for you",
    "oneclick.v1.dd.account_name.invalid": "Please enter a name between 3 and 18 characters",
    "oneclick.v1.header.info": "Info",
    "withdrawal.cancel.success.text": "Money has been refunded",
    "withdrawal.status.title.withdrawMustDeposit": "No Deposits",
    "cashier.prizes.collect.subtitle": "Please contact us at the location below to redeem your prize.",
    "game.crossword.left_to_scratch": "left to scratch",
    "footer": "LottoRace is a registered trademark of <a href='http://www.bonoboplc.com' target='_new'>Bonobo PLC<\/a>. Copyright 2008-2018, Bonobo PLC. Patented Multi-Draw game. All rights reserved. Bonobo PLC, 69 Athol Street, Douglas, IM1 1JE, Isle of Man. This site is licensed and regulated by the UK Gambling Commission. Bonobo PLC only provides gaming facilities to persons in Great Britain under Licence issued by the UK Gambling Commission. <a href='https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39086' target='_new'>Licence Details<\/a>.",
    "numpick.join.error.title": "Sorry, no plays placed",
    "gameover.thank_you": "Thank you for playing",
    "gameplayout.raffle.allbets": "Tickets sold",
    "playout.jackpot.restarted": "The jackpot has been restarted and will be available on the next game. No additional jackpots can be won during this game.",
    "ingamerewards.bonobo.tip": "Bonobo Winner! <br> You don't like sharing your first place!",
    "playout.jackpot.you_matched": "You matched",
    "playout.hang_on": "Hang on... We are bringing you up to speed!",
    "respgaming.until": "until",
    "myaccount.transactions": "Transactions",
    "game.crossword.end.play_again": "Play again",
    "gameover.jackpot.won_by": "won<br/>by {$winner}",
    "game.bingo.payout.loading": "Loading Payouts...",
    "myaccount.update_address.title2": "Your address has been updated",
    "myaccount.update_address.title1": "Update your address",
    "game.crossword.cardpick.payout": "Available Prizes",
    "game.general.sorry_no_win": "Sorry, you didn't win",
    "game.general.now": "Now",
    "picker.buy_590": "Buy Tickets for 5/90 Game",
    "myaccount.verifyAddress.title": "Verify your address",
    "popup.country_disabled.title": "Not available in your country",
    "soundconfig.crossword": "Crossword Full Sound",
    "scan_ticket.secure_key.placeholder": "SecureKey",
    "referrals.section1.button": "${amount} Bonus Awarded!",
    "history.col.date_started": "Date Started",
    "subscriptions.balance": "Balance",
    "generic.this": "This",
    "main.gameItem.guarPrizePool": "Guaranteed Prize Pool",
    "oneclick.v1.confirm.title": "Please check your details carefully",
    "oneclick.v1.userinfo.account.disabled": "I don't want to create an account",
    "oneclick.v1.userinfo.login.button": "Login",
    "modals.reconnecting": "Reconnecting...",
    "cashier.deplimits.monthly": "Monthly:",
    "maintenance3.title": "${site} is upgrading!",
    "oneclick.prize.jackpot": "Jackpot",
    "withdrawal.status.title.withdrawPendingWithdrawal": "Pending Withdrawal",
    "oneclick.v1.confirm.payment_type.text": "Payment Type",
    "registration.popup.reg_complete": "Registration Complete",
    "respgaming.timeout.edit.instruction": "You  will  not  be  able  to  play  or  deposit  until after   your   request   expires.   This   action cannot be reversed.",
    "withdrawal.status.text.withdrawBalanceTooLow": "The minimum withdrawal is ${min}. You currently do not have enough funds to continue. ",
    "game.general.player": "Player",
    "referrals.email_friends.button": "Email Friends",
    "myaccount.notify.edit.withdrawals": "On Withdrawal",
    "oneclick.v1.confirm.subscriptions.text": "And in the next ${subscriptions} games",
    "playout.jackpot.nice": "Nice!",
    "generic.loading": "Loading...",
    "oneclick.v1.payments.total_due.text": "Total Due",
    "oneclick.v1.success.header.line2.text": "An email receipt was sent to ${emailAddress}",
    "phone.validation.yes.info": "Your phone has been verified<br/><br/>Thank your and good luck!",
    "changepassword.request.instructionssent": "Instructions Sent",
    "drawer_sounds": "Sounds",
    "respgaming.timeout.edit.title.yes": "Time out is enabled",
    "main.gameItem.topPrize": "Top Prize",
    "maintenance2.body2": "Thank you for your patience!",
    "maintenance2.body1": "This page will automatically refresh when connection has been restored.",
    "gameplayout.raffle.allbets2": "${tickets} Tickets sold",
    "mybets.detail.no_sequence.text": "Your tickets in ${gameTitle}, are:",
    "gamelobby.raffle.play.button": "${gameCost} per Entry",
    "respgaming.reality.cashierhistory": "Cashier History",
    "completed_games.replay": "Replay",
    "playout.jackpot.you_won": "You Won",
    "gameplayout.place_available": " place available!",
    "drawer.fortnightly_scheme": "Fortnightly Scheme",
    "playout.new.raffle.winner.winning_play": "The winning play is",
    "gameover.paid_to": " paid to",
    "oneclick.v1.success.paid_with_balance.text": "Paid from balance",
    "cashier.prizes.collect.cash_option": "Cash option of <strong>${cashValue}<\/strong>",
    "modal.login.login_now": "Login now!",
    "soundconfig.title": "Advanced Sounds",
    "maintenance1.title": "${site} is upgrading!",
    "picker.waiting_for_bets": "Waiting for bets...",
    "myaccount.infopage.subtitle.text1": "Your ID can only be updated and verified by submitting relevant documents. Click ",
    "myaccount.infopage.subtitle.text2": "here",
    "myaccount.infopage.subtitle.text3": " to receive instructions on how to update or verify your ID.",
    "game.general.four": "Four",
    "playout.new.raffle.ended.thank_you": "Thank you for playing!",
    "playout.new.replay": "This is a replay. All prizes have already been paid!",
    "cashier.respgaming": "Responsible Gaming",
    "game.bingo.details.game_will_start": "Game will start",
    "main.gameItem.jackpot": "Available Jackpot",
    "soundconfig.general": "Some games include sounds which require a high speed connection for the best experience.\n\nThey are turned off by default. \n\nYou can change your sound settings by clicking on the sound menu from the main navigation menu",
    "game.cashier.to_view_limits": "To view your current limits",
    "oneclick.v1.addressinfo.country.placeholder": "Select country",
    "respgaming.enabled": "Is Enabled",
    "general.vip": "VIP",
    "cashier.accounts.bonus": "Bonus",
    "game.general.play_to_win_noexc": "Play to win",
    "scan_ticket.search.fail": "We could not search this ticket",
    "game.bingo.loading.join_success": "${cards} Card Purchased",
    "respgaming.selfexclusion.edit.instruction": "You  will  not  be  able  to  play  or  deposit  until after   your   request   expires.   This   action cannot be reversed.",
    "referrals.list.item.not_registered": "Not Registered",
    "withdrawal.validation.mustdeposit": "You must first deposit using a valid Credit Card.",
    "gamelobby.wins": "Wins",
    "deposit.bill.descriptor.27": "This transaction will appear as 7StarBall N.V. on your statement.",
    "completed_games.prepaid.subtitle": "For Pre-Paid subscription ${id}",
    "drawer.t30_new_game": "T30 New Game",
    "deposit.amount.placeholder": "Enter amount in ${currency}",
    "popup.no_money.title": "Not Enough Funds",
    "Timeout.timeout_over_max": "Selected date must be up to 6 weeks from today!",
    "subscriptions_page.purchased_on": "Purchased on ${date}",
    "gamelobby.raffle.entries": "Tickets",
    "oneclick.v1.subscriptions.title": "Games to Repeat",
    "playout.new.raffle.winner.prize_won": "YOU WON THE <span className='text-uppercase'>${prizeName}<\/span>",
    "respgaming.reality.frequency": "Update the frequency at which you are notified of the time spent on the site",
    "registration.quick.errors.state": "Please select state",
    "soundconfig.lottery": "Lottery Full Sound",
    "drawer.thuensum_scratch": "Thuensum Scratch",
    "general.add_max_plays": "Add Max Plays",
    "numpick.subscription.active.action": "Click here to manage it",
    "picker.p4.title": "Step 4 | Choose wager",
    "gamelobby.one_in": "1 in",
    "oneclick.v1.userinfo.username.invalid": "Username must be up to 24 alphanumeric characters",
    "referrals.list.col3": "Invite Friends",
    "referrals.list.col2": "${count} Players Collected",
    "generic.form.password.error": "Password is not correct",
    "playout.new.raffle.drawing.cash": "CASH",
    "referrals.list.col1": "${count} Players Registered",
    "myaccount.button.update": "Update",
    "gamelobby.raffle.payout.list.other": "${rankNumber} Place:",
    "playout.new.raffle.starting.prizes_value": "Valued at<br/>${prizesValue}",
    "generic.bet": "Play",
    "history.col.players": "Players",
    "general.ball": "Ball",
    "myaccount.emailSent.title": "Email Sent",
    "pagenotfound.title": "404 Page not found!",
    "game.bingo.cardpick.pick_card": "Pick your cards",
    "game.bingo.details.game_ended": "Game has ended",
    "generic.won": "won",
    "gameover.lottery.cash_item.won_line_agg": "${totalPlays} lines won!",
    "gameover.raffle.prizes_won": "Prizes Awarded",
    "picker.line": "Line",
    "game.bingo.popup.leave_sub": "If you leave now you can only see the results of the game once it's completed.",
    "gameover.raffle.total_won": "Total Won",
    "general.on": "On",
    "regisgtration.full.invalid.occupation": "You must select an occupation",
    "gameover.raffle.won.prize": "${plays} prize",
    "playout.new.raffle.next_prize.value": "${prizeName}<br/>Valued at ${prizeValue}",
    "game.general.game_lobby": "Game Lobby",
    "deposit.status.tile.depositNotActivated": "Account not enabled",
    "general.loading_page": "Loading Page",
    "myaccount.notify.edit.deposits": "On Deposit",
    "gamelobby.lottery.description.default.second.multiple_prizes": "${prizesAvailable} Available Prizes!",
    "generic.dateformat.word.short": "MMM d yyyy",
    "mbob.request_not_cancelled": "Failed cancelling request",
    "modal.login.register": "Register",
    "generic.form.phone.error": "Failed saving phone number",
    "main.favourites.desc": "Add your favorite games",
    "playout.new.raffle.winner.congratulations": "CONGRATULATIONS",
    "registration.quick.errors.phone": "Doesn't look like a phone number",
    "subscriptions.cancel.confirm": "Are you sure you want to cancel this subscription?",
    "tutorial.button.done": "Done",
    "history.col.prize_pool": "Prize Pool",
    "oneclick.v1.header.confirmation": "Confirmation",
    "history.col.actions": "Actions",
    "picker.remove": "Remove",
    "mbob.sent_payment_no": "No, I did not send the payment",
    "myaccount.email.sent.not_verified": "Check your email and click on the verification link",
    "mbob.amount": "Amount",
    "registration.quick.register": "Register",
    "gamelobby.raffle.header.multiple_prizes.mobile.text": "one of ${prizeCount}<br/>prizes worth<br/>${prizesAmount}",
    "myaccount.transactions.account": "${currency} Account - ${balance}",
    "game.crossword.letter": "Letter",
    "generic.set": "Set",
    "game.general.click": "Click",
    "payout.ranks_paid": "${count} Ranks Paid",
    "gamelobby.nowplaying": "Now Playing",
    "drawer.schemes": "Schemes",
    "phone.validation.call": "Please answer the call and listen for the confirmation code. Please enter it here.",
    "respgaming.instructions": "Set your own deposit and wager limits and play within them",
    "game.bingo.payout.double_bingo": "Double Bingo",
    "numpick.confirm.balance": "Balance: ",
    "oneclick.v1.total.text": "Total",
    "registration.quick.errors.current_pass_wrong": "Provided password does not match the current account password",
    "respgaming.edit.instructions": "Deposit   limits,   once   set,   can   only   be changed after ${days} days.<br/><br/>The account Limit is set by customer service and takes  precedence  over  your  self  imposed limits.  To  modify  your  account  limit,  please contact customer service.",
    "oneclick.v1.confirm.button": "Confirm",
    "respgaming.title": "Responsible Gaming",
    "registration.quick.errors.no_match": "Do not match",
    "general.balls": "Balls",
    "home.info2": "Register your details and get free access to our live demo.",
    "gameover.game_id": "GAME ${gameInstanceId}",
    "home.info1": "If you already have a username and password continue here:",
    "myaccount.not_avaliable_on_demo": "Not available on demo.",
    "oneclick.v1.success.paid_with_wallet.text": "Paid by ${walletName}",
    "picker.remove_or_rejoin": "You can remove the bet or rejoin the next available game",
    "drawer.my_account": "Account",
    "myaccount.subscriptions": "Subscriptions",
    "oneclick.v1.payments.pay_from_balance.text": "Pay from balance",
    "respgaming.monthly.limit": "Monthly Deposit Limit",
    "main.gameItem.when_place_bet": "Starts when you place your bet",
    "numpick.tooltip": "Each play includes a ${buyin} Buy-in + ${fee} Fee + ${progressive} Progressive Jackpot contribution",
    "gamelobby.raffle.header.mobile.text": "Play<br/>to WIN",
    "drawer.downloads": "Downloads",
    "gameover.raffle.winners_list": "WINNERS LIST",
    "oneclick.main_description": "For just <strong>${amount}<\/strong> per month you will be entered into the draw!",
    "oneclick.v1.userinfo.last_name.invalid": "Doesn't look like a famous last name",
    "drawer.procedures": "Draw Procedures",
    "respgaming.selfexclusion.edit.title.no": "Exclude yourself from play until the following date",
    "respgaming.month": "One Month",
    "gameover.raffle.players_won_prize": "${players} players won a prize",
    "myaccount.transactions.col.date": "Date",
    "oneclick.v1.success.games_rules.title": "Game Rules",
    "login.form.placeholder.user": "Email/Phone or Username",
    "regisgtration.full.day": "Day",
    "main.top.nav.title.site_info": "Site Info",
    "gameover.raffle.player_won_prize": "${players} player won a prize",
    "subscriptions.raffle.ticket": "${ticket} Ticket",
    "oneclick.v1.success.date.text": "Date: ${currentDate}",
    "game.crossword.item": "Item",
    "gamelobby.addedtofav": "+ Added to Favorites",
    "generic.datetimeformat.tournament.more_than_7": "MMM d @ h:mma",
    "completed_games.next": "Next >",
    "regisgtration.full.postal": "Postal Code",
    "withdrawal.pending.button": "View/Cancel Pending",
    "generic.form.change_password.error": "Failed changing password!",
    "modals.please_wait": "Please Wait...",
    "regisgtration.full.male": "Male",
    "contact.name": "Your name",
    "payout.bet_type": "Bet Type",
    "main.gameItem.place_your_bet": "Play Now!",
    "main.nav.button.complete_it": "Play It",
    "general.favorite_games": "Your favorite games",
    "modal.login.welcome": "Welcome to ${siteName}",
    "myaccount.change_password.instruction": "Keep your password safe and protect your account",
    "account.appsettings.connectionchanged": "Connection type has been changed to ${connectionType}",
    "playout.game_over": "Game Over",
    "oneclick.v1.failed.text": "Ooops, something went wrong. Your purchase was complete, but we were unable to join you in the game. Your funds are credited to your account with us. Please click the button below to be logged in. Once you are logged in, please manually join the game using the balance already credited to your account.<br/><br/>Sorry for the inconvenience. ",
    "withdrawal.status.title.withdrawCountryDisabled": "Not available in your country",
    "deposit.status.text.depositNotActivated": "Your account is not currently not enabled for certain actions. Please contact customer service",
    "tutorial.screen.text14": "Add this game to your Favorite games list.",
    "ResponsibleGaming.weeklyLimit.gt.limit": "Your weekly deposit limit is too high",
    "myaccount.transactions.range_net": "Range Net: ${net}",
    "tutorial.screen.text15": "Play Info",
    "tutorial.screen.text12": "Place the maximum number of plays via randomly selected picks for each line",
    "myaccount.emailSent.info": "A verification email has been sent to <strong>${email}<\/strong>.<br/><br/>Please click on the link to confirm your email address.<br/><br/>If this is not your email, please update it <a href='/#/account/email' onClick='$(\"#regOk\").trigger(\"notify-hide\");'>here<\/a>.",
    "respgaming.deposit.limit": "Deposit Limits",
    "tutorial.screen.text13": "Favorite",
    "tutorial.screen.text18": "Learn the rules for each game by clicking here",
    "myaccount.email.verified": "This email has been verified",
    "numpick.confirm.raffle.x_bets": "You now have ${count} tickets",
    "tutorial.screen.text19": "Game starts when",
    "gameover.jackpot_won": "WAS WON!!!",
    "tutorial.screen.text16": "Pick ${count} numbers between 1 and ${max} for each play in the game",
    "numpick.confirm.x_bet": "You now have ${count} play",
    "payout.estimated_payout": "Estimated Payout",
    "tutorial.screen.text17": "Rules",
    "general.scratch": "Scratch",
    "oneclick.v1.dd.account_sort_code": "Sort Code",
    "withdraw.confirm.name": "Name",
    "game.general.win_up_to": "Win up to",
    "withdrawal.validation.withdrawUnverifiedMaxExceeded": "You must verify your account to continue",
    "regisgtration.full.birthdate": "Birthdate",
    "drawer.prize_claim_procedures": "Prize Claim Procedures",
    "tutorial.button.back": "Back",
    "generic.replays": "See Past Games",
    "myaccount.verifyAddress.info": "You must verify your address to remove any restrictions on your deposit and withdrawals.<\/br><\/br>An email has been sent to you with instructions.",
    "main.nav.button.max_bets": "Max Plays",
    "tutorial.screen.text20": "Instant Games start when all players have joined and the game is full. Tournaments start at specific date and time.",
    "game.crossword.symbols": "Symbols",
    "oneclick.v1.addressinfo.city.placeholder": "City",
    "main.top.nav.title.my_stats.link.games_won.text": "${gamesWon} in 1<sup>st<\/sup> place",
    "oneclick.v1.success.footer.line3.text": "Thank you for your support.",
    "numpick.subscription.notavailable": "You cannot subscribe to future games with this currency.",
    "picker.p2.title": "Step 2 | Choose bet type",
    "gameplayout.allbets": "All Plays",
    "scan_ticket.title": "Scan Printed Ticket",
    "generic.yes": "Yes",
    "registration.quick.confirmemail": "Confirm Email",
    "game.crossword.playout.scratch_to_reveal": "Scratch to reveal your letters",
    "cashier.prizes.no_prizes": "You didn't win any prizes yet",
    "history.end_date.placeholder": "Start Date To",
    "gamelobby.raffle.insta_bet": "Ticket",
    "changepassword.invalidkey.title": "Invalid or expired link",
    "oneclick.v1.confirm.details.text": "Personal Details",
    "main.2.title": "Lottery",
    "gameplayout.nowinners": "No Winners This Game",
    "regisgtration.full.address": "Address",
    "gamelobby.paysfixed": "Pays Top ${count}",
    "deposit.header": "Deposit",
    "ticket.check.error.already_cancelled": "Provided Ticket is cancelled",
    "ticket.check.error.invalid_code": "Provided SecureKey is not valid",
    "game.bingo.payout.letter_h": "Letter H",
    "game.crossword.symbol": "Symbol",
    "gameplayout.raffle.mybets": "My Tickets",
    "main.nav.contact_us": "Contact Us",
    "numpick.tooltip.secondary": "Each play includes a ${buyin} Buy-in + ${fee} (Fee + Progressive)",
    "referrals.send.title_success": "Invite Sent!",
    "myaccount.password.lastchanged": "Password last updated on ",
    "ResponsibleGaming.monthlyLimit.gt.limit": "Your monthly deposit limit is too high",
    "gamelobby.invite_friends": "Invite Friends",
    "gamelobby.featuredgames": "Instant Games",
    "main.1.title": "Lottery",
    "general.favorites": "Favorites",
    "oneclick.prize.lotto.text": "Match ${first}${second} for Jackpot",
    "registration.quick.errors.young": "You must be at least 18 to register",
    "game.general.cards": "Cards",
    "withdraw.info": "Please enter an amount between ${min} and ${max}",
    "gameover.raffle.prize_item.valued_at": "Valued at ${amount}",
    "playout.new.jackpot.back.button": "Back to Game!",
    "game.bingo.payout.turtle": "Turtle",
    "changepassword.title": "Change Password",
    "cashier.nopending.withdrawal": "No Pending Withdrawal",
    "picker.p3.title": "Step 3 | Pick numbers",
    "numpick.confirm.bet_placed": "${count} Play Placed!",
    "payout.jackpot_not_won": "Jackpot Not Won",
    "cashier.wageringreq": "Wagering Requirement: ",
    "gameover.you_won": "You won",
    "generic.confirm": "Confirm",
    "generic.dateformat.number.short": "MM/dd/yyyy",
    "gamelobby.raffle.regular.description.default": "Enter the ${gameTitle} raffle for just ${playCost} to win",
    "myaccount.confirm_password.title": "Confirmation Required",
    "numpick.join.error.footer": "We could not join you into this game",
    "payout.remaining_prizes": "Remaining Prizes",
    "mbob.balance_customer_service": "If you still have not seen the balance in your account, please contact our customer service",
    "history.no_games": "No past games found",
    "drawer.logout": "Logout",
    "main.top.nav.title.my_stats": "My Stats",
    "tutorial.screen.text10": "Pick your custom line by picking each number on your play",
    "generic.raffle.bets_lowcase": "tickets",
    "myaccount.transactions.paging": "${count} transactions found (page ${pageStart} of ${pageEnd})",
    "tutorial.screen.text11": "Max Play",
    "game.crossword.cardpick.match": "Match",
    "gamelobby.not_joinable_line_2": "Contact Us",
    "gamelobby.not_joinable_line_1": "Not Available",
    "respgaming.amount.required": "Amount required",
    "withdrawal.cancel.success.title": "Withdrawal canceled",
    "main.nav.button.open": "Open",
    "numpick.youhave": "You have",
    "general.digit": "Digit",
    "picker.p1.title": "Step 1 | Choose your game",
    "main.3.title": "Tournaments ",
    "respgaming.selfexclusion.btn": "Set Self Exclusion",
    "cashier.prizes.valued_at": "Valued at",
    "ticket.check.error.already_expired": "Provided Ticket is expired",
    "regisgtration.full.submit": "Submit!",
    "generic.capslock.warning": "WARNING: Caps Lock is ON",
    "oneclick.v1.addressinfo.email.placeholder": "Email",
    "phone.validation.codesent": "A confirmation code was sent to your device. Please enter it here.",
    "mbob.deposit_req_subtitle": "All your pending deposit requests are listed here. Please complete the deposits in mBob or cancel them below.",
    "numpick.raffle.confirm_bets": "Confirm your ${count} tickets:",
    "phone.validation.codesent.placeholder": "Enter the code",
    "changepassword.reset.updaterequired": "Password Update Required",
    "completed.page_XY": "Page ${x} of ${y}",
    "numpick.balance": "Balance:",
    "game.general.game_starting_in": "Game starting in",
    "tutorial.screen.text2_alt": "Jackpot prize for a full match. Can be estimated or guaranteed. Estimated values be adjusted to the actual value before the game starts or when the estimate is exceeded.",
    "main.gameItem.maxplays": "Max Plays",
    "general.per_card": "Per Card",
    "withdrawal.status.title.withdrawNotEnabled": "Withdrawal disabled",
    "gameover.button.play_again": "Play Again",
    "modal.login.login": "Login",
    "subscriptions.cancel.info": "Any unused balance will be refunded back into your account",
    "registration.popup.resend": "Resend",
    "gamelobby.entries": "Entries",
    "numpick.raffle.confirm3": "You maxed out your tickets!",
    "numpick.subscription.raffle.bets.success": "These tickets will be repeated for the next ${count} game!",
    "playout.new.raffle.next_prize.title": "Next Available Prize is",
    "lottery.subtitle": "View past winning numbers",
    "numpick.raffle.confirm2": "Would you like to place more tickets?",
    "main.1.desc": "Great selection of Lottery games",
    "cashier.prizes.collect.account": "Reference your Player ID. <strong>${accountNo}<\/strong>",
    "myaccount.verifyEmail.info": "You must confirm your email before accessing this feature.<\/br><\/br><strong>${email}<\/strong><br/><br/>Is this the correct spelling of your email?<br/><br/>If yes, press 'Send Email' to receive your verification email.<br/><br/>If this is not your email, please update it <a href='/#/account/email' onClick='$(\"#regOk\").trigger(\"notify-hide\");'>here<\/a>.",
    "regisgtration.full.back": "< Back",
    "oneclick.v1.payments.empty.text": "No payment methods available. Please contact support.",
    "game.general.three": "Three",
    "game.crossword.loading.good_luck": "Good Luck!",
    "playgame.nowstarting": "NOW STARTING!",
    "general.all": "All",
    "gamelobby.manual_bet": "Manual Play",
    "oneclick.confirm.check1": "I want to receive marketing emails",
    "oneclick.v1.addressinfo.email.taken": "It looks like you already might have an account. If this is your email address, sign in to your account, or request a password reset if you forgot your account info",
    "cashier.prizes.cash_option": "Cash Option of",
    "game.general.six": "Six",
    "main.top.nav.title.contact.link": "Ask a question",
    "oneclick.payment.BALANCE": "Paying from balance",
    "oneclick.payment.Test": "Test Payment Method",
    "oneclick.payment.Test": "Test Payment Method",
    "game.bingo.payout.single_bingo": "Single Bingo",
    "playout.new.raffle.winner.cash_won": "YOU WON ${prize}",
    "referrals.list.item.bonus": "${amount} Bonus Collected",
    "withdrawal.status.title.withdrawNotWageredEnough": "Wagering Requirement",
    "oneclick.v1.success.paid_with_visa.text": "Paid by VISA",
    "registration.popup.complete": "Complete",
    "myaccount.transactions.previous": "< Previous",
    "game.general.popup_already_completed": "This Game is already completed",
    "general.goto_home": "Go to Home Page",
    "picker.picked_all": "You have picked all numbers!",
    "regisgtration.full.occupation": "Occupation",
    "subscriptions.cancel.title": "Cancel Subscription?",
    "emailconf.btn2": "Login Now!",
    "deposit.coupon.required": "Coupon code is required",
    "drawer.publications": "Publications",
    "main.gameItem.estPrizePool": "Estimated Prize Pool",
    "emailconf.btn1": "Play ${site}!",
    "drawer.photo_gallery": "Photo Gallery",
    "playout.new.raffle.drawing.draw_for": "Drawing for ",
    "phone.validation.yes": "Phone verified",
    "mbob.try_again_later": "Sorry, could not cancel this deposit request at this time.\nPlease contact customer service",
    "main.4.title": "Lottery & Raffle",
    "popup.waiting_for": "Waiting for a new game",
    "drawer.rules_and_reg": "Rules and Regulations",
    "gameover.button.lobby": "Go to Lobby",
    "deposit.terms.text": "I have read and understand Section 3.11.1 of the Bonobo Deposit Protection Policy",
    "oneclick.v1.raffle.add_tickets.button": "${ticketCount} Tickets - ${ticketsCost}",
    "gamelobby.progressive_tooltip_multidraw": "Available on draws 1 to ${draws}!",
    "payout.payout": "Payout",
    "scan_ticket.result.winning": "Winning Ticket (${winnings})",
    "numpick.subscriptions.raffle.bets.success": "These tickets will be repeated for the next ${count} games!",
    "gamelobby.raffle.insta_bets": "Tickets",
    "myaccount.transactions.total_credits": "Total Credits: ${credits}",
    "cashier.prizes.tooltip": "How many prizes you haven't claimed yet",
    "gamelobby.raffle.header.one_prize.mobile.text": "a Grand Prize worth<br/>${prizesAmount}",
    "main.gameItem.started": "Started",
    "oneclick.v1.payments.loading.text": "Loading available payments",
    "picker.bet": "Bet",
    "gamelobby.raffle.paysfixed": "${count} Prizes Available",
    "game.general.balance_after": "Balance After",
    "myaccount.transactions.col.status": "Order Status",
    "main.gameItem.button.bet": "Play",
    "kyc.description": "In order to verify your account we require the following documents.<br/> <br/>Please take a picture from each and upload.<br/><br/>Images only are allowed.",
    "cashier.welcome": "Welcome to your Cashier",
    "respgaming.enable": "Enable",
    "deposit.coupon.error.50": "Coupon is not found",
    "registration.quick.errors.already_exists": "Already exists",
    "deposit.coupon.error.52": "Coupon is expired",
    "deposit.coupon.error.51": "Coupon is inactive",
    "game.general.none": "None",
    "deposit.coupon.error.54": "Max usage reached by player",
    "Timeout.timeoutDate.sixweeks": "Selected date must be up to 6 weeks from today!",
    "deposit.coupon.error.53": "Max usage reached",
    "deposit.coupon.error.56": "Deposit amount too low",
    "history.clear.button": "Clear",
    "deposit.coupon.error.55": "Invalid usage",
    "regisgtration.full.select_birthdate": "Enter your birthday",
    "playout.jackpot.numbers": "numbers in ONE draw!",
    "validation.minlength.144": "Must be at least 144 characters",
    "oneclick.v1.success.footer.license_by.text": "Licensed By ${licensedBy}",
    "playout.new.raffle.winner_is.line1": "And the winner of the",
    "cashier.prizes.collect.prize_id": "Prize ID ${prizeId}",
    "gamelobby.pick_embedded": "Pick ${format} numbers",
    "myaccount.update_address.address_not_verified": "This address is NOT verified",
    "gameover.replay": "This is a replay. Prizes already paid.",
    "main.top.nav.title.my_stats.subtitle": "Your game activity",
    "game.general.join_fail": "Failed to join game",
    "game.general.luck_next_game": "Good luck next game",
    "generic.game": "game",
    "ResponsibleGaming.dailyLimit.gt.monthlyLimit": "Daily limits cannot be greater than monthly limits",
    "completed_games.status_cost_no_net": "Total Buy-in: ${buyin}",
    "drawer.board_directors": "Board of Directors",
    "numpick.raffle.toomanybets": "There is only room for ${count} of your tickets. Would you like to buy these ${count} tickets?",
    "playout.new.jackpot.reset": "The jackpot has been restarted and will be available on the next game. No additional jackpots can be won during this game.",
    "oneclick.v1.userinfo.salutation.other": "Other",
    "payout.paid_from_pool": "Paid from pool",
    "emailconf.body": "Thank you for confirming your email! You are now ready to deposit and play on ${site}",
    "general.fourth": "Fourth",
    "payout.prizes": "Prizes",
    "gamelobby.raffle.freeroll.description.default": "Enter the ${gameTitle} raffle for FREE to win",
    "completed.result": "Result",
    "gamelobby.raffle.payout.grand_prize": "Grand Prize ${prizeName}",
    "myaccount.notify.deposits": "Deposits",
    "oneclick.v1.userinfo.salutation.mrs": "Mrs",
    "subscriptions.raffle.tickets": "${ticket} Tickets",
    "completed.games": "Completed Games",
    "main.nav.view_subscriptions": "My Subscriptions",
    "main.top.nav.title.my_account": "My Account",
    "main.tournament.title": "Tournaments ",
    "main.gameItem.button.raffle.my_bets": "My Tickets",
    "oneclick.payment.BillPocket": "Credit Card",
    "gameover.lottery.cash_item.won_line": "Won on ${playsWon}/${totalPlays} lines!",
    "picker.numbers": "Numbers",
    "gameover.raffle.prize.word": "prize",
    "cashier.prizes": "Prizes",
    "myaccount.verified": "Verified",
    "picker.instant_game": "Instant Game",
    "mbob.order_id": "Order ID",
    "referrals.send.subtitle_success": "Invite another friend",
    "deposit.coupon.error.57": "No discount available",
    "referrals.section3.button": "Email Invite",
    "history.subtitle": "Replay a past game",
    "home.get_access_button": "Get Access",
    "main.0.title": "Instaplay",
    "gameover.next_game": "Next game is OPEN",
    "numpick.subscription.raffle.bet": "Repeat this ticket in ",
    "registration.full.birthdate.details": "Your birthdate must match your Birthday on your ID document",
    "cashier.deplimits": "Deposit Limits",
    "scan_ticket.ticket_id.placeholder": "Ticket ID",
    "generic.bet.free": "Free",
    "generic.datetimeformat.number.tz.long": "MM/dd/yyyy @ hh:mma zzzz",
    "history.col.instance_id": "Instance ID",
    "playout.new.jackpot.win.other": "${amount} Jackpot<br/>was won by ${count} Play(s)",
    "picker.starts": "Starts",
    "footer.licensed": "Licensed By: ${memberLicensedBy} | License Number: ${memberLicenseNumber}",
    "game.crossword.lefttoscratch": "left to scratch",
    "deposit.coupon.error.61": "Fraud warning",
    "deposit.coupon.error.60": "You must enter an amount",
    "game.crossword.letters": "Letters",
    "deposit.coupon.error.62": "Coupon is not for selected currency",
    "main.nav.button.watch_it": "Watch It",
    "gameover.raffle.won.prizes": "${plays} prizes",
    "gameover.winner": "Winner",
    "subscriptions.left": "Games joined",
    "referrals.list.total": "${count} Invitations sent",
    "general.bonus": "Bonus",
    "validation.maxlength.64": "Must be no more than 64 characters",
    "history.search.button": "Search",
    "game.crossword.cardpick.game_cost": "Price per card: ",
    "deposit.previouspayment": "Previously used method",
    "myaccount.notify.withdrawals": "Withdrawals",
    "footer.game.operation": "This game is operated by ${operatedBy} and the proceeds will be to the benefit of ${siteName} and the operation of this game. Thank you for your support!",
    "maintenance1.body1": "This page will automatically refresh when scheduled maintenance has been completed.",
    "withdrawal.validation.withdrawAmountInvalid": "Amount is not valid",
    "generic.lottorace": "LottoRace",
    "cashier.net_deposits": "Net Deposits: ${deposits}",
    "respgaming.edit.monthly.limit": "Monthly Deposit",
    "oneclick.v1.dd.sort_code.invalid": "Sort code format is XX-XX-XX or XXXXXX",
    "popup.freerolls_disabled.title": "Must deposit first",
    "respgaming.reality.check": "Reality Check",
    "playout.jackpot.not_won": "Jackpot Not Won!",
    "picker.bet_pays": "Bet Pays",
    "gamerules.title": "Game Rules",
    "playout.new.raffle.drawing.current_prize": "${prizeName}<br/>Valued at ${prizeValue}",
    "withdraw.title": "How would you like to receive your ${amount} withdrawal payment?",
    "oneclick.prize.lotorace.text": "1 in ${odds} WIns, Guaranteed",
    "ResponsibleGaming.weeklyLimit.gt.monthlyLimit": "Weekly limits cannot be greater than monthly limits",
    "game.general.five": "Five",
    "maintenance1.body2": "Thank you for your patience!",
    "main.gameItem.odds1": "Match ${count} for Jackpot",
    "validation.maxlength.50": "Must be no more than 50 characters",
    "gamelobby.raffle.header.text": "Play to WIN",
    "playout.new.raffle.starting.single.cash.value": "${amount}<br/>CASH",
    "main.gameItem.button.watch": "Watch",
    "picker.bets": "Bets",
    "scan_ticket.secure_key.tip": "The SecureKey present in the top section of your printed ticket",
    "drawer.vacancy": "Vacancy",
    "regisgtration.full.phone": "Mobile Phone",
    "game.general.jackpot": " Jackpot",
    "main.top.nav.title.my_stats.link.games_won": "Games Won",
    "picker.select_bet_type": "Select Bet Type",
    "respgaming.input.placeholder": "Enter amount",
    "myaccount.button.change": "Change",
    "gameover.raffle.won.word": "won",
    "mbob.deposit_request": "Deposit Request",
    "generic.rules": "Rules",
    "myaccount.email.subtitle": "Your email address must be verified at all times before you can play. Upon updating your email you must verify it before you can resume play.",
    "respgaming.notset": "Not Set",
    "game.crossword.loading.back_lobby": "Back to Lobby",
    "main.nav.view_completed": "My Completed Games",
    "oneclick.v1.addressinfo.phone.invalid": "Is this really your phone number?",
    "oneclick.v1.payments.loading_small.text": "Loading...",
    "referrals.send.email.error": "Email is already registered",
    "main.gameItem.button.closed": "Closed",
    "regisgtration.full.year": "Year",
    "home.welcome": "Welcome to the LottoRace Betting Platform Demo!",
    "numpick.tooltip.onedraw": "Each play costs ${cost}",
    "game.bingo.details.next_game": "Next game",
    "gameover.raffle.thank_you": "THANKS FOR PLAYING!",
    "playout.new.jackpot.win.me": "Congratulations!!!<br/>You WON ${amount}",
    "contact.ticket_created": "A confirmation email has been sent to your email.<br/><br/>Your confirmation code is <strong>${ticket}<\/strong><br/><br/> We will contact your within 48 hours.<br/><br/>",
    "main.gameItem.button.my_bet": "My Play",
    "numpick.confirm.total": "Total: ",
    "SelfExclusion.selfExclusionDate[past]": "The date entered is in the past",
    "gameplayout.younowin": "You did not win.",
    "account.appsettings.connectiontype.subtitle": "If you experience connection issues, please use the HTTP connection type",
    "phone.validation.title": "Verify your number",
    "registration.quick.password": "Password",
    "respgaming.timeout.edit.title.no": "Restrict yourself from play until the following date",
    "withdrawal.status.text.withdrawNotWageredEnough": "You have not met the wagering requirements for the bonus which you received. You must wager ${amount} more to satisfy your wagering requirements.",
    "respgaming.selfexclusion.instruction2": "You can  temporarily self-exclude yourself from playing until a specified date. You will not be able to play or deposit until after your request expires. Please note that this action cannot be reversed.",
    "myaccount.update_address.subtitle1": "Your registered address needs to be verified. Any changes to your address will require additional verifications.",
    "myaccount.update_address.subtitle2": "All deposit methods should use this address as the registered address for the payment.",
    "playout.new.raffle.winner_is.valued_at": "${sponsor}<br/>Valued at ${prize}",
    "registration.popup.thank_you2": "Thank you for registering.<br><br> Please make your first deposit to start playing.",
    "registration.popup.thank_you1": "Thank you for registering.<br><br> Before you can play you must confirm your email address.<br><br> Please check your email and click on the verification link sent to you.",
    "cashier.prizes.collect.collected": "Collected on ${dateCollected}",
    "oneclick.v1.payments.every_week.text": "Every week thereafter",
    "cashier.prizes.collect.valued_at": "Valued at <strong>${prizeValue}<\/strong>",
    "mybets.detail.with_sequence.text": "Your tickets in ${gameTitle} Game No. ${seq} are:",
    "soundconfig.bingo": "Bingo Full Sound",
    "game.crossword.details.ended": "Ended",
    "generic.lottery": "Lottery",
    "soundconfig.allsounds": "All Sounds",
    "oneclick.v1.plays.text": "Plays",
    "gameover.jackpot.next": "Next Jackpot is ${amount}!",
    "validation.maxlength.24": "Must be no more than 24 characters",
    "completedgames.nowins": "No Wins",
    "generic.next": "Next >",
    "main.nav.button.pending": "Pending",
    "validation.maxlength.20": "Must be no more than 20 characters",
    "game.crossword.you_won": "You won",
    "ResponsibleGaming.dailyLimit.gt.limit": "Your daily deposit limit is too high",
    "gameplayout.message.loading": "Loading Game...",
    "game.bingo.payout.right_diagonal": "Right Diagonal",
    "oneclick.prize.fixed_winners_2.text": "${prizes} Prizes worth ${amount}",
    "regisgtration.full.country": "Country",
    "main.instaGames.title": "Instaplay",
    "oneclick.v1.success.header.line3.text": "Thank you for playing, ${firstName}",
    "picker.pick_more_number": "You can pick 1 more number",
    "withdraw.confirm.title": "Please review and confirm your withdrawal request:",
    "playout.new.raffle.next_prize.prizes_left": "${prizesLeft} more prizes remaining",
    "cashier.prizes.won_on": "Won on ${date}",
    "ingamerewards.mltiwin.tip": "MultiWin! <br> You won on multiple lines!",
    "oneclick.v1.addressinfo.address.invalid": "Not a good address",
    "cashier.prizes.collected_on": "Collected on ${date}",
    "game.bingo.payout.corners": "Corners",
    "gameover.raffle.no_prizes": "No players won a prize",
    "payout.cash_prize": "Cash Prize of ${prizeAmount}",
    "playout.jackpot_win": "Jackpot Win!",
    "main.gameItem.prizePool": "Guaranteed Prize Pool",
    "drawer.lottery_act": "Lottery Act",
    "game.bingo.payout.checker_board": "Checker Board",
    "withdrawal.status.title.withdrawNotEnoughFunds": "Insufficient funds",
    "deposit.validation.genericError": "You cannot deposit at this time",
    "main.top.nav.title.my_account.subtitle": "Quick links to manage your account with us",
    "numpick.toomanybets": "There is only room for ${count} of your plays. Would you like to place these ${count} plays?",
    "oneclick.v1.processing.retry_deposit": "Retry Deposit",
    "registration.quick.errors.tandc": "You must accept terms and conditions",
    "gameover.winners": "Winners",
    "main.lottery.title": "Lottery",
    "gameover.raffle.try_again": " ",
    "generic.resend": "Resend",
    "picker.waiting_new_game": "Waiting for new game...",
    "subscriptions.bet": "${count} play each game",
    "gameplayout.mybets": "My Plays",
    "history.game_instance.placeholder": "Game Instance ID",
    "playout.new.raffle.winner.cash_prize": "CASH PRIZE",
    "oneclick.v1.addressinfo.states.loading": "Loading states/provinces...",
    "oneclick.v1.success.footer.license_no.text": "License No. ${licenseNo}",
    "game.general.popup_curr_playing_sub": "You can view the results in just a minute in your completed games section. Go ahead and play another one.",
    "oneclick.addressinfo.optional": "(Optional)",
    "phone.validation.code": "Enter confirmation code",
    "referrals.send.email.placeholder": "Enter email address",
    "oneclick.v1.confirm.confirm.button": "&nbsp;&nbsp;Confirm&nbsp;&nbsp;",
    "respgaming.timeout.title.instruction": "Temporarily restrict yourself from playing for up to 6 weeks.",
    "myaccount.update_notifications.subtitle": "You can control which notification get sent to you.",
    "cashier.prizes.claim.button": "Claim Prize",
    "cashierhistory.title": "Cashier History",
    "game.general.one": "One",
    "main.favourites.empty": "You don't have any favorite games!",
    "completed.won_free_entries": "Won ${entries} free entries",
    "withdrawal.cancel.text": "Please confirm you want to cancel this withdrawal",
    "game.bingo.payout.hollow_diamond": "Hollow Diamond",
    "main.0.desc": "Games start when full",
    "oneclick.v1.header.payment": "Payment",
    "gamelobby.removedfromfav": "- Removed from Favorites",
    "deposit.coupon.amount.required": "Must be a valid deposit amount",
    "validation.maxlength.32": "Must be no more than 32 characters",
    "main.nav.how_to_play": "How To Play",
    "oneclick.v1.userinfo.confirm_password.invalid": "Passwords don't match",
    "payout.grand_prize": "Grand Prize",
    "gamelobby.payout": "Payout",
    "respgaming.edit.title": "Update Self Limits",
    "game.bingo.payout.drawboard": "Drawboard",
    "game.general.here": "Here",
    "account.appsettings.connectiontype": "Connection Type",
    "oneclick.v1.success.total_paid.text": "Total Paid: ${totalPaid}",
    "cashier.deplimits.weekly": "Weekly:",
    "game.general.players": "Players",
    "drawer.lottorace": "LottoRace",
    "ticket.check.error.game_not_ended": "The game has not finished yet",
    "referrals.section1.title.no_invites": "Get started and invite your friends",
    "oneclick.v1.confirm.details.born.text": "Born",
    "referrals.popup.btn": "OK",
    "history.col.progressive_pool": "Progressive Pool",
    "completedgames.won": "${amount} Won",
    "regisgtration.full.select_province": "Select Province",
    "respgaming.timeout.edit.info.yes": "Your account is disabled until ${date}",
    "deposit.status.title.depositPhoneNotVerified": "Please verify your number",
    "game.general.popup.leave": "Are you sure you want to leave?",
    "withdraw.confirm.method": "Payout Method",
    "numpick.join_new_if_full": "Join me in next game if this game is full",
    "picker.back": "Back",
    "oneclick.v1.raffle.add_ticket.button": "${ticketCount} Ticket - ${ticketsCost}",
    "oneclick.v1.addressinfo.city.invalid": "Is that a city?",
    "picker.ok": "Ok",
    "playout.new.lottery.prizepoo": "Jackpot",
    "generic.bets_lowcase": "plays",
    "subscriptions.description": "View or cancel your subscriptions at any time. See game rules for subscriptions terms.",
    "registration.quick.tandc": "Terms and conditions",
    "oneclick.v1.maxed_out.text": "You maxed out your tickets",
    "drawer.about_us": "About Us",
    "regisgtration.full.last_name": "Last Name",
    "withdrawal.validation.withdrawNeedFullRegistration": "You need to complete full registration.",
    "mybets.detail.with_sequence.plural.text": "Your ticket in ${gameTitle} Game No. ${seq} is:",
    "cashier.cashout": "Cash Out",
    "game.crossword.playout.prizes": "Prizes",
    "completed.no_games": "You haven't played any games yet",
    "generic.games": "games",
    "oneclick.v1.addressinfo.countries.loading": "Loading countries...",
    "numpick.confirm.bets_placed": "${count} Plays Placed!",
    "regisgtration.full.select_country": "Select Country",
    "drawer.like_us": "Like Us",
    "drawer.faq": "FAQ",
    "generic.favorite": "Favorite",
    "popup.country_disabled.text": "${siteName} is not currently available in your country.  We will notify you if this changes in the future",
    "game.general.play_again": "Play again",
    "regisgtration.full.invalid.gov_id_1": "You must enter your Government ID",
    "playout.new.raffle.drawing.grand_prize": "GRAND PRIZE",
    "general.none": "None",
    "drawer.privacy_policy": "Privacy Policy",
    "game.general.buy": "Buy",
    "numpick.confirm3": "You maxed out your plays!",
    "numpick.confirm_bets": "Confirm your ${count} plays:",
    "general.page_not_exist": "Oops. This page does not exist",
    "numpick.confirm1": "Someone will win, Guaranteed!",
    "gamelobby.bet_max": "MAX",
    "numpick.confirm2": "Would you like to place more plays?",
    "changepassword.request.body.line1": "${siteName} can help you reset your password!",
    "oneclick.payment.Barclays": "Credit Card",
    "myaccount.transactions.col.amount": "Amount",
    "kyc.document.name.1": "Picture of Passport",
    "registration.quick.errors.country": "Please select country",
    "changepassword.request.body.line2": "Please enter your phone number, email address or username associated with your account.<br/><br/>We will send you instructions to the email address associated with your ${siteName} account.",
    "game.crossword.round_over": "Round over",
    "kyc.document.name.2": "Picture of ID",
    "kyc.document.name.3": "Picture of Address",
    "numpick.subscription.raffle.bet.success": "This ticket will be repeated for the next ${count} game!",
    "registration.quick.iagreeto": "I am at least 18 years old, and have read and accept the",
    "oneclick.v1.header.payout": "Payout",
    "soundconfig.lottorace": "LottoRace Full Sound",
    "code": "en_US",
    "main.lotteryGames.title": "Lottery",
    "mbob.deposit_requests": "Deposit Requests",
    "game.crossword.details.to_begin_playing": "Good Luck!",
    "picker.confirm": "Confirm",
    "withdraw.payments.col3": "Processing Time",
    "myaccount.notify.edit.game_wins": "On Game Wins",
    "withdraw.payments.col4": "Processing Fee",
    "main.2.desc": "Great selection of Lottery games",
    "drawer.login": "Login",
    "completed_games.status_cost": "Total Buy-in: ${buyin} | Net ${net}",
    "game.crossword.cardpick.uncover": "Words Completed",
    "oneclick.v1.addressinfo.postal.invalid": "Please enter a valid postal/zip code",
    "mbob.pending": "Pending",
    "oneclick.v1.header.address": "Address",
    "gameover.raffle.players.word": "players",
    "withdraw.payments.col1": "Payment Method",
    "regisgtration.full.state": "State",
    "withdraw.payments.col2": "Min/Max",
    "oneclick.prize.prize_pool": "Prize Pool",
    "playout.new.jackpot.line2": "Matched ${count}/${size} numbers in ONE draw and WON!",
    "game.crossword.details.game_has": "Game has",
    "SelfExclusion.selfExclusionDate.sixmonths": "The date must be a minimum of 6 months from today",
    "myaccount.update_address.address_verified": "This address has been verified",
    "oneclick.v1.addressinfo.country.invalid": "Please select a country",
    "playout.new.jackpot.line1": "${count} Jackpot Winner(s) on Draw ${draw}",
    "cashier.accounts.subscriptions": "Subscriptions",
    "gameover.lottery.no_wins.line1": "Nobody won in this game",
    "cashierhistory.info": "Select an account and a date range",
    "myaccount.verifyEmail.title": "Verify your email",
    "game.bingo.payout.outside_diamond": "Outside Diamond",
    "subscriptions.title": "Manage your subscriptions",
    "oneclick.v1.success.info.title": "Your personal info",
    "playout.new.lottorace.prizepoo": "Gtd. Prize Pool",
    "changepassword.request.title": "Forgot your password?",
    "ticket.check.error.invalid": "Provided Ticket is not valid",
    "gameover.lottery.no_wins.line2": "Good luck in your next game!",
    "generic.first": "st",
    "respgaming.selfexclusion.info": "Restrict yourself from playing",
    "game.general.play_to_win": "Play to win!",
    "mbob.status": "Status",
    "oneclick.v1.no_game.description": "Next game will be available in a few moments!",
    "registration.error.113": "Too many registrations from this IP",
    "picker.pays": "Pays",
    "registration.popup.please_complete": "Please complete your registration to start playing",
    "playout.draw": "Draw ",
    "game.bingo.details.calling_ball": "Calling Ball",
    "drawer.home": "Home",
    "gameplayout.over_x_played": "Over ${maxPlays} lines played",
    "game.bingo.details.when_buy_cards": "when you buy your cards",
    "oneclick.v1.confirm.edit.button": "Edit",
    "gameover.raffle.game_winners_list": "Game ${id} WINNERS LIST",
    "main.nav.login": "Login",
    "main.nav.past_tickets": "View Past Tickets",
    "completed.time": "Time",
    "lottery.title": "Winning Numbers",
    "oneclick.v1.subscriptions.text": "Subscriptions",
    "referrals.popup.title": "Link copied to clipboard!",
    "respgaming.edit.updated": "Self imposed limits have been updated",
    "payout.prize": "Prize",
    "gameover.prizes_paid": "Prizes already paid.",
    "oneclick.v1.tickets.text": "Tickets",
    "picker.lines": "Lines",
    "account.appsettings.reload": "Reload",
    "completedgames.won_entry": "Won ${entries} entry into ${gameTitle}",
    "game.bingo.payout.column": "Column",
    "generic.bets": "Plays",
    "registration.quick.errors.required": "Required",
    "myaccount.birthday_none": "Birthday not set",
    "myaccount.transactions.no_tx": "You haven't made any deposits or withdrawals",
    "myaccount.update_notifications.title2": "Notification Settings updated",
    "myaccount.update_notifications.title1": "Update your notification settings",
    "payout.1stdrawjackpot": "1st Draw Jackpot",
    "oneclick.v1.userinfo.username.taken": "Sorry, username is already used",
    "withdraw.amount.placeholder": "Enter amount in ",
    "numpick.subscriptions.bets.success": "These plays will be repeated for the next ${count} games!",
    "oneclick.v1.addressinfo.phone.tips": "We'll never share your phone with anyone else.",
    "regisgtration.full.city": "City",
    "gameover.raffle.prize_item.grand_prize": "Grand Prize Winner!",
    "numpick.subscriptions.raffle.bet.success": "This ticket will be repeated for the next ${count} games!",
    "mbob.sent_payment_yes": "Yes, I sent the payment",
    "gamelobby.progressive_jackpot": "Progressive Jackpot",
    "gameplayout.allprizes": "All prizes have been paid!",
    "oneclick.v1.userinfo.confirm_password.placeholder": "Confirm Password",
    "myaccount.change_password.last_updated2": "Password hasn't been changed since registration",
    "myaccount.confirm_password.subtitle": "You have requested a change that requires authentication to continue.",
    "respgaming.wager.limit": "Weekly Wagering Limit",
    "game.general.cost_per_card": "Cost per Card",
    "gamelobby.raffle.header.one_prize.text": "a Grand Prize worth ${prizesAmount}",
    "myaccount.change_password.last_updated1": "Last updated on ",
    "gamelobby.payout.fixed1": "Top ${count} Win!",
    "drawer.scan_ticket": "Scan Ticket",
    "main.lottery.desc": "Great selection of Lottery games",
    "playgame.nowplaying": "NOW PLAYING!",
    "completed.won_free_entry": "Won 1 free entry",
    "cashier.prizes.collect.title": "Collect your Prize",
    "drawer.cashier": "Cashier",
    "game.crossword.cardpick.choose_ticket": "Choose your lucky card!",
    "main.gameItem.prizePoolSm": "Gtd. Prize Pool",
    "oneclick.v1.userinfo.password.placeholder": "Password",
    "completed_games.no_games": "You haven't played any games yet",
    "completed_games.paygo.subtitle": "For Pay-Go subscription ${id}",
    "deposit.status.tile.depositLimitsReached": "Deposits Limit Reached",
    "respgaming.edit.weekly.limit": "Weekly Deposit",
    "history.replay.button": "Replay",
    "oneclick.v1.subscription.text": "Subscription",
    "oneclick.v1.confirm.dd.account_holder": "Account Holder",
    "oneclick.v1.dd.account_number.invalid": "Please enter a valid account number",
    "game.crossword.items": "Items",
    "game.crossword.playout.words_to_win": "or more words to win",
    "gamelobby.raffle.header.multiple_prizes.text": "one of ${prizeCount} prizes worth ${prizesAmount}",
    "game.bingo.payout.cross": "Cross",
    "game.general.confirm": "Confirm",
    "payout.multiplier": "Multiplier",
    "referrals.section1.title": "${count1}/${count2} Registered friends are playing",
    "main.3.desc": "Games start per schedule",
    "withdrawal.status.title.withdrawBalanceTooLow": "Insufficient funds",
    "drawer.welcome": "Welcome",
    "game.general.total": "Total",
    "phone.validation.codesent.info": "If you did not receive the confirmation code, please click resend again.",
    "playgame.nowplaying.info": "Would you like to watch it?",
    "changepassword.instructions.body.line1": "If the email you entered was associated with a valid account, a password reset link has been sent to your email!",
    "ticket.check.error.already_cashed": "Provided Ticket is already cashed",
    "login.form.placeholder.password": "Password",
    "main.gameItem.topXWin": "Top ${count} Win!",
    "modal.login.already": "Already have an account?",
    "oneclick.v1.add_ticket.button": "Add ${ticketCount} for ${ticketsCost}",
    "game.bingo.payout.inside_frame": "Inside Frame",
    "gameplayout.message.not_loggedin": "Not Logged In",
    "cashier.pending.withdrawal": "1 Pending Withdrawal",
    "completedgames.won_entries": "Won ${entries} entries into ${gameTitle}",
    "generic.update": "Update",
    "game.crossword.playout.words": "Words",
    "general.fifth": "Fifth",
    "myaccount.confirm_password.subtitle1": "Please enter your password to confirm.",
    "gamelobby.insta_bet": "Play",
    "oneclick.v1.processing.waiting.text": "Waiting for confirmation",
    "gamelobby.players.none": "Be the first to enter the game",
    "history.start_date.placeholder": "Start Date From",
    "registration.quick.errors.username.already_exists": "The username you entered is already registered! If this is your username please login below.  Note: ﻿You can login using your username, email or phone number.",
    "withdrawal.status.text.withdrawNotEnabled": "Your account is not currently not enabled for certain actions. Please contact customer service.",
    "subscriptions.completed": "Games joined",
    "generic.bet_max": "Play Max",
    "gameover.jackpot_winner": "Jackpot Winner!",
    "picker.game": "Game",
    "cashier.prizes.collect.pending": "Available to collect",
    "payout.paid_to": "Paid ${amount} to ${playerNumber} ${player} so far",
    "history.title": "Replay Games",
    "popup.game_is_starting": "This game is starting soon...",
    "myaccount.gamehistory": "Game History",
    "subscriptions.bets": "${count} plays each game",
    "deposit.tooltip.subscription": "The current balance of your pending game subscriptions",
    "main.top.nav.title.my_account.link.responsible": "Responsible Gaming",
    "oneclick.v1.confirm.dd.sort_code": "Sort Code",
    "generic.messages.wait_logging": "Please wait, we are logging you in!",
    "respgaming.reality.option": "Choose one of the options below or adjust the frequency of these reminders in your account area",
    "referrals.subtitle": "When they sign up and start playing, you'll receive a free ${amount}.",
    "referrals.list.item.no_bonus": "No Bonus Collected",
    "gameover.lottery.prizes_won": "${count} prizes won",
    "numpick.raffle.confirm_bet": "Confirm your ${count} ticket:",
    "game.bingo.payout.inside_diamond": "Inside Diamond",
    "changepassword.button": "Change",
    "regisgtration.full.select_state": "Select State",
    "registration.quick.errors.email": "Invalid email address",
    "game.crossword.matched": "Matched",
    "subscriptions.status2": "Completed",
    "main.top.nav.title.my_account.link.cashier": "Cashier",
    "referrals.send.description": "When they sign up and start playing, you'll receive a free ${amount}.",
    "subscriptions.status1": "Canceled",
    "payout.jackpot_won_by": "Won by ${player}",
    "subscriptions_page.cancelled": "Cancelled",
    "picker.cancel": "Cancel",
    "deposit.tooltip.bonus": "The current balance of your pending bonuses",
    "game.general.your_balance": "Your Balance",
    "payout.entries": "Entries",
    "numpick.touch_to_change": "Touch a number below to change",
    "oneclick.v1.addressinfo.postal.not_found": "Could not find address. Please enter manually below.",
    "gamelobby.one_time_purchase": "One Time Purchase",
    "gamelobby.progressive_tooltip": "Match ${format} numbers in one draw to win ${amount}. ",
    "payout.balls_matched": "Balls Matched",
    "gamelobby.raffle.payout.list.cash": " Cash",
    "main.nav.button.watching": "Watching",
    "myaccount.update_number.title2": "Your contact number has been updated",
    "playout.jackpot_won_by_lines": "Jackpot won by ${y} lines",
    "myaccount.update_number.title1": "Update your contact number",
    "picker.balance": "Balance",
    "generic.previous": "< Previous",
    "main.top.nav.title.my_account.link.account": "Account Info",
    "oneclick.v1.success.back.button": "&nbsp;&nbsp;Back to Lobby&nbsp;&nbsp;",
    "game.general.play_now": "Play now",
    "game.general.pay_with": "Pay With",
    "popup.freerolls_disabled.text": "You cannot play FreeRolls without depositing first. Please deposit to continue.",
    "gamelobby.raffle.payout.list.more": "+ ${prizes} guaranteed prizes!",
    "modal.login.new_to_lottorace": "New to ${siteName}?",
    "numpick.confirm_bet": "Confirm your ${count} play:",
    "numpick.tooltip.nojp": "Each play includes a ${buyin} Buy-in + ${fee} Fee",
    "respgaming.deposit.limit.instructions": "",
    "changepassword.request.ok": "Ok",
    "registration.quick.username": "Username",
    "changepassword.request.button": "Send Instructions",
    "oneclick.prize.fixed_winners.lotto.text": "Pays Top ${prizes}",
    "completed.page": "Page",
    "general.winning_draw": "Winning Draw",
    "validation.min.length": "Must be at least ${x} characters",
    "gamelobby.lottery.description.default.second.one_prize": " the Grand Prize!",
    "general.play_now": "Play Now",
    "generic.second": "nd",
    "playout.backtogame": "Back to Game!",
    "soundconfig.scratchcard.anim": "Scratchcard Full Animations",
    "playout.new.raffle.starting.about_to_start": "<strong>${gameTitle}<\/strong><br/>is about to start",
    "playgame.nowstarting.info": "Would you like to watch it?",
    "drawer.share": "Invite a Friend",
    "gameover.lottery.cash_item.won.word": "Won",
    "gamelobby.raffle.description.default.second.one_prize": " the Grand Prize!",
    "referrals.send.title": "Invite your friends",
    "drawer.settings": "Settings",
    "myaccount.verifyID.info": "You must verify your ID to remove any restrictions on your withdrawals.<\/br><\/br>Please click Verify to start or resume the process.",
    "oneclick.v1.userinfo.salutation.mr": "Mr",
    "gamelobby.raffle.header.multiple_prizes.mobile.text.nobreak": "${prizeCount} prizes worth ${prizesAmount}",
    "generic.tutorial": "Tutorial",
    "oneclick.v1.userinfo.salutation.ms": "Ms",
    "picker.wager": "Wager",
    "game.crossword.game_over": "Game over",
    "game.crossword.no_wins": "You have no wins",
    "scan_ticket.result.loser": "No Wins",
    "drawer.how_to_play": "How To Play",
    "main.lotteryRaffleGames.title": "Lottery & Raffle",
    "cashier.accounts.points": "Points",
    "myaccount.update_number.number_verified": "Mobile phone has been verified",
    "referrals.send.btn2": "Send Invite",
    "referrals.send.btn3": "I'm done",
    "generic.back": "Go Back",
    "playout.new.raffle.winner_is.prize.line2": "${prizeName} is",
    "game.crossword.details.game_is": "Game is",
    "referrals.send.btn1": "Cancel",
    "account.appsettings": "Application Settings",
    "picker.total_wager": "Total Wager",
    "emailconf.title": "Email Confirmation",
    "respgaming.timeout.btn": "Set Time out",
    "gamelobby.raffle.players.none": "Enter this raffle now",
    "drawer.our_team": "Our Team",
    "oneclick.v1.success.footer.line1.text": "This game is operated by ${operatedBy}",
    "mbob.request_cancelled": "Your request has been cancelled",
    "general.payout.on_this_card": "On this card",
    "respgaming.selfexclusion.instruction": "You can restrict yourself from playing for a certain amount of time. Once enabled, this cannot be reversed.",
    "game.bingo.payout.left_diagonal": "Left Diagonal",
    "playout.new.raffle.next_prize.prize_left": "${prizesLeft} more prize remaining",
    "main.instaplay.desc": "Games start when full",
    "myaccount.email.not_verified": "This email is NOT verified",
    "registration.popup.deposit": "Deposit",
    "deposit.bill.descriptor.1": "This transaction will appear as Bonobo PLC on your statement.",
    "payout.get_cash": "Get cash in hand!",
    "completed.thanks": "Thank you for playing",
    "general.time_on_site": "Time on Site",
    "oneclick.v1.previous.button": "Previous",
    "myaccount.transactions.col.transaction": "Transaction",
    "numpick.subscription.1": "next game",
    "numpick.subscription.0": "Just this game",
    "registration.quick.errors.password": "Minimum 6 characters at least 1 Alphabet and 1 Number",
    "validation.minlength.x": "Must be at least ${min} characters",
    "generic.player": "Player",
    "withdrawal.cancel.title": "Cancel withdrawal?",
    "generic.search": "Search",
    "popup.game_is_loading": "Loading Game",
    "gameplayout.wonby": " won by ",
    "changepassword.placeholder": "Enter username, phone or email",
    "picker.done": "Done",
    "gameplayout.updating_results": "Updating Results",
    "mbob.allow_hours_deposit": "Please allow 1-2 hours for your deposit to be reflected in your account",
    "general.off": "Off",
    "main.top.nav.title.my_stats.link.games_played": "Games Played",
    "registration.quick.phone": "Phone",
    "numpick.my_bets": "My Bets: ",
    "registration.quick.errors.email_change.already_exists": "This email is already associated with an existing account.<br/>If this is your email, you may already have an account. Please log out of this account and login with the correct email address.",
    "numpick.subscription.active": "A subscription is active for this game",
    "main.top.nav.title.my_stats.note": "*Stats do not update in real time",
    "ticket.check.error.invalid_used_original": "This ticket has been re-printed",
    "general.second": "second",
    "subscriptions_page.active": "Active",
    "completed_games.paging": "${totalGames} games played (page ${pageStart} of ${pageEnd})",
    "oneclick.prize.fixed_winners.raffle.text": "${prizes} Prizes in this game",
    "tutorial.button.exit": "Exit",
    "oneclick.v1.addressinfo.state.placeholder": "Select state/province",
    "oneclick.v1.no_game.title": "Game does not accept any more tickets",
    "picker.play": "Play",
    "gameover.next_game.tournament": "Next game:",
    "contact.helper": "How may we help you",
    "pendingwithdrawal.title": "Pending Withdrawal",
    "withdraw.title2": "Withdraw from your account",
    "myaccount.update_number.number_not_verified": "Mobile phone is NOT verified",
    "registration.full.first_name.details": "Please enter name as it appears on your ID document",
    "oneclick.v1.confirm.dd.account_number": "Account Number",
    "playout.new.raffle.starting.single": "The GRAND PRIZE is",
    "game.crossword.popup.leave_sub": "You did not scratch all the letters. You can resume this game from your Active Games.",
    "gameover.congratulations": "Congratulations",
    "picker.add_line": "Add Line",
    "phone.validation.invalidcode": "Invalid code",
    "validation.minlength.2": "Must be at least 2 characters",
    "main.top.nav.title.contact": "Contact",
    "mbob.cancel_request": "Cancel Request",
    "generic.no_thanks": "No Thanks",
    "oneclick.v1.addressinfo.address.placeholder": "Address",
    "picker.max_win": "Max Win",
    "validation.minlength.6": "Must have at least 6 characters",
    "myaccount.mygames": "My Games",
    "validation.minlength.3": "Must have at least 3 characters",
    "game.general.popup_already_completed_sub": "You can see the results of your completed games here. Go ahead an play another one.",
    "regisgtration.full.first_name": "First Name",
    "ingamerewards.perfectgame.tip": "Perfect Game! <br> You won everything!",
    "oneclick.v1.userinfo.salutation.miss": "Miss",
    "drawer.language": "Language",
    "registration.error.28": "We could not register you at this time",
    "gamelobby.insta_bets": "Play",
    "mbob.have_sent_payment": "Have you sent the payment from mBoB?",
    "oneclick.v1.addressinfo.email.taken_active": "Hi, you already have an active subscription!",
    "game.general.you_won": "You won",
    "main.gameItem.game_didnt_run": "Drawing Winning Numbers, Please wait.",
    "myaccount.notify.edit.promotions": "On Promotions",
    "playout.new.raffle.all_paid": "All prizes have been paid out",
    "modal.login.rememberme": "Remember Me",
    "picker.next": "Next",
    "generic.datetimeformat.number.long": "MM/dd/yyyy @ hh:mma",
    "phone.validation.call.placeholder": "Enter the code",
    "registration.error.20": "We could not register you at this time",
    "picker.close": "Close",
    "playout.jackpot_next": "Jackpot Next Game!",
    "cashier.prizes.details": "${count} Unclaimed",
    "main.gameItem.starts_when_full": "Starts when full",
    "generic.no": "No",
    "oneclick.v1.confirm.ticket.text": "${tickets} Ticket in ${gameName}",
    "game.bingo.payout.payout_rules": "Payout Rules",
    "referrals.title": "Invite your friends!",
    "drawer.sign_up": "Sign Up",
    "ResponsibleGaming.weeklyBetLimitTooSoon": "You must wait 30 days since the last update",
    "registration.quick.errors.email.already_exists": "The email you entered is already registered! If this is your email please login below.  Note: ﻿You can login using your username, email or phone number.",
    "game.bingo.payout.dark_star": "Dark Star",
    "game.bingo.payout.ttt": "Tic Tac Toe",
    "numpick.join.error5": "You already joined with max plays",
    "playout.new.raffle.drawing.rank_x": "${rank} Place",
    "gamelobby.pays": "Pays ${count}",
    "numpick.join.error6": "You've exceeded your weekly wagering limit",
    "oneclick.v1.ticket.text": "Ticket",
    "numpick.join.error3": "Game is no longer available",
    "numpick.join.error4": "Could not join with that many lines",
    "oneclick.v1.userinfo.username.placeholder": "Username",
    "popup.games_loading": "Loading Games",
    "gamelobby.raffle.payout.list.first": "Grand Prize:",
    "numpick.join.error9": "This site cannot accept plays at this time (Code 234)",
    "numpick.join.error10": "Please try your bet again (Code 300)",
    "myaccount.notify.updates": "Notify me on Account Updates",
    "numpick.join.error7": "Your account status doesn't allow joining games",
    "numpick.join.error8": "Could not confirm plays at this time",
    "regisgtration.full.exists.gov_id_1": "This ID is currently associated with another account. If this is your ID, please contact support at customer.care@bll.bt",
    "picker.okay": "Okay",
    "game.general.game_starting": "Game starting",
    "gameover.raffle.prize_won": "Prize Awarded",
    "picker.purchase_complete": "Purchase Complete",
    "numpick.join.error1": "You don't have this currency",
    "numpick.join.error2": "You do not have enough funds",
    "completed.free_entries": "${entries} free entries",
    "main.lotteryGames.desc": "Great selection of Lottery games",
    "payout.rank": "Rank",
    "regisgtration.full.month": "Month",
    "respgaming.edit.wager.limit": "Weekly Wagering",
    "deposit.title2": "How would you like to deposit your ${amount}",
    "registration.quick.errors.phone.already_exists": "The phone number you entered is already registered! If this is your phone number please login below.  Note: ﻿You can login using your username, email or phone number.",
    "myaccount.transactions.won_free_entrys": "Won Free Entry(s)",
    "respgaming.selfexclusion.edit.title.yes": "Self-Exclusion has been enabled",
    "general.select": "Select",
    "main.favourites.title": "Favorites",
    "main.general.no_games": "No Games Available!",
    "history.col.bets": "Bets",
    "generic.datetimeformat.word.extralong": "MMM dd yyyy @ hh:mma zzzz",
    "deposit.validation.processorLimitsError": "You must enter an amount between ${min} and ${max}",
    "completed.game": "Game",
    "game.bingo.payout.row": "Row",
    "regisgtration.full.female": "Female",
    "oneclick.v1.userinfo.account.enabled": "I want to create an account",
    "deposit.protection.text": "Deposit Protection?",
    "oneclick.v1.addressinfo.state.invalid": "Please select a state",
    "mbob.cannot_process_sent_payment": "Your request cannot be processed if you already sent the payment",
    "account.appsettings.websocket": "Websocket",
    "ResponsibleGaming.dailyLimit.gt.weeklyLimit": "Daily limits cannot be greater than weekly limits",
    "deposit.tooltip.trust": "The current balance you have available for play",
    "oneclick.v1.play.text": "Play",
    "gameover.jackpot_not_won": "Jackpot was not won",
    "main.nav.sign_up": "Sign Up",
    "cashier.accounts.withdrawals": "Withdrawals",
    "oneclick.v1.payments.total.payment.text": "Total payment now",
    "ticket.check.error.not_started": "The game has not started yet",
    "generic.raffle.bet_lowcase": "ticket",
    "oneclick.v1.failed.contact": "Please contact support (Error code ${code})",
    "oneclick.v1.addressinfo.phone.placeholder": "Phone",
    "mbob.confirm_cancel": "Confirm Cancellation",
    "oneclick.v1.payments.pay_with.text": "Pay with ${paymentName}",
    "oneclick.v1.userinfo.last_name.placeholder": "Last Name",
    "subscriptions.empty": "You don't have any subscriptions",
    "payout.prize_type": "Prize Type",
    "main.gameItem.button.my_bets": "My Plays",
    "myaccount.transactions.last_x_days": "Last ${days} days",
    "oneclick.v1.success.paid_with_dd.text": "Paid by Direct Debit",
    "cashier.prizes.subtitle": "You have ${count} prizes wating for you!",
    "referrals.popup.description": "Now you can paste the link anywhere you like.<br/><br/>Try sharing it with your friends on Twitter, Facebook or WhatsApp.",
    "deposit.status.text.depositDisabled": "{$siteName} is not currently available in your country.  We will notify you if this changes in the future. ",
    "myaccount.transactions.col.refid": "Reference ID",
    "generic.ok": "Ok",
    "numpick.subscription.tooltip": "Prepay for future games and be automatically joined into each upcoming game.<br/>You can view, manage or cancel your subscriptions at any time.",
    "changepassword.reset.receiveemail": "Click below to receive your password reset link to your registered email address.",
    "gameover.raffle.player.word": "player",
    "gameplayout.winnings": "My Winnings",
    "main.tournament.desc": "Games start per schedule",
    "drawer.contact_us": "Contact Us",
    "numpick.subscription.bet.success": "This play will be repeated for the next ${count} game!",
    "oneclick.v1.confirm.subscription.text": "And the next game",
    "payout.paid_already_players": "Paid already to players",
    "withdraw.withdrawal.amount": "Withdrawal amount",
    "withdraw.receiving.amount": "Receiving amount",
    "general.play.exists": "Play already exists in this game!",
    "regigstration.january":"January",
    "regigstration.february":"February",
    "regigstration.march":"March",
    "regigstration.april":"April",
    "regigstration.may":"May",
    "regigstration.june":"June",
    "regigstration.july":"July",
    "regigstration.august":"August",
    "regigstration.september":"September",
    "regigstration.october":"October",
    "regigstration.november":"November",
    "regigstration.december":"December",
    "general.loading": "Loading",
    "general.checking": "Checking",
    "oneclick.bet.one": "1 subscription for ${cost} per month",
    "oneclick.bet.half": "${betHalf} subscriptions for ${cost} per month",
    "oneclick.bet.max": "${betMax} subscriptions for ${cost} per month",
    "oneclick.pick.subscriptions" : "Pick your subscriptions",
    "oneclick.pick.subscriptions.howmany": "How many subscriptions would you like?",
    "oneclick.bet.eachentry": "Each entry is ${cost} per month",
    "general.ticket": " Ticket",
    "oneclick.pick.numbers": "Pick your numbers",
    "oneclick.addbetsforcost": "Add ${bets} for ${cost}",
    "oneclick.pick.subscriptions.max" : "You have the maximum number of subscriptions",
    "oneclick.user.details": "Please enter your details",
    "oneclick.user.greatname": "Hi, ${name}! Great name!",
    "oneclick.user.yourdetails":"Your Details",
    "oneclick.address.details": "Almost there, just need your details",
    "oneclick.wallet.launching":"Please wait, launching wallet page",
    "oneclick.entries.howmany": "How many entries would you like?",
    "oneclick.raffle.max.tickets": "Max ${tickets} tickets",
    "oneclick.raffle.regenerate": "Regenerate",
    "oneclick.raffle.generate": "Generate",
    "oneclick.raffle.random.number": "Random Number",
    "oneclick.email.sent": "Email Sent!",
    "oneclick.send.details": "Send Details" ,
    "oneclick.request.password.reset": "Request Password Reset",
    "oneclick.available.balance": "Available Balance in your account",
    "oneclick.pay.from.balance":"I want to pay ${amount} from my balance",
    "oneclick.dont.use.balance":"Do not use my balance",
    "oneclick.new.card":"New Card",
    "oneclick.existing.card":"Existing Card",
    "oneclick.address.need": "Almost there, just need your address",
    "oneclick.confirm.pay": "Confirm & Pay",
    "oneclick.tc":"T&Cs",
    "oneclick.terms.read":"I confirm that I have read and accept the ${termsLink}. I am over 18 or older.",
    "oneclick.terms.accept":"You must accept the T&Cs",
    "oneclick.privacy.read":"I confirm that I've read the ${privacyLink}",
    "oneclick.privacy.confirm":"You must confirm you read the Privacy Policy",
    "oneclick.active.promotion":"Active Promotion",
    "oneclick.subscription.monthly.payment":"Subscription Monthly Payment",
    "oneclick.16.or.over":"Players must be 16 or over. You can cancel your Direct Debit at any time. After your transaction is complete, you will be sent a confirmation of your Direct Debit setup to the email address your provided. For more information see",
    "oneclick.18.or.over":"Players must be 18 or over. You can cancel your Direct Debit at any time. After your transaction is complete, you will be sent a confirmation of your Direct Debit setup to the email address your provided. For more information see",
    "oneclick.direct.debit.guarantee":"Direct Debit Guarantee",
    "oneclick.first.payment.due":"Please note: your first payment is due on",
    "oneclick.future.payments": "All future payments will be taken on or around the",
    "oneclick.of.each.month":"of each month",
    "oneclick.prize.money.paid":"Your prize money will be paid into this account",
    "oneclick.start.checkout":"Start Checkout",
    "oneclick.stripe.checkout":"Please click the Checkout button below to begin your Stripe flow",
    "oneclick.resumed.payment":"You have resumed the payment flow for",
    "oneclick.you.all.draws":"You will be in all draws from",
    "oneclick.amount.per.month":"for ${amount} per month",
    "oneclick.scotland.lottery":"Thanks for joining Scotland’s best new lottery!",
    "oneclick.welcome.email": "Keep an eye out for your welcome email which you should receive shortly confirming your subscription.",
    "oneclick.everytime.you.win":"Every time you win, we will send you an email and credit your bank account within 7 days with any cash prize.",
    "oneclick.by.subscribing": "By subscribing, you’re not only giving yourself a chance to win big, but also giving back to the people most in need across Scotland.",
    "oneclick.more.info":"For more information or to manage your account, email us at help@thescotto.com and we’ll be more than happy to help.",
    "oneclick.good.bit" :"Now for the good bit… Get ready for the next draw to see what prizes could be coming your way soon!",
    "oneclick.good.luck":"Good Luck!",
    "oneclick.failed.process.purchase":"Sorry, we could not process this purchase",
    "oneclick.cookie.policy":"Cookie Policy",
    "oneclick.game.about.to.start":"Game is about to start!",
    "oneclick.mbc.license":"MBC ELM Ltd is licensed and regulated in Great Britain by the Gambling Commission under account number 45374. See license status",
    "oneclick.copyright.mbc":"Copyright © MBC ELM Limited. All rights reserved.",
    "login.log.in":"Log In",
    "login.logged.as":"You are currently logged in as ${name}",
    "login.no.need.alwaysin": "No need to log in if you’re an Always IN! player - contact our Customer Service Team on 0330 123 3951 with any queries.",
    "oneclick.5.win":"5 numbers win up to",
    "oneclick.4.bonus":"4 numbers + the bonus ball ",
    "oneclick.4.win":"4 numbers",
    "oneclick.3.win":"3 numbers",
    "oneclick.2.win":"2 numbers",
    "oneclick.bonus.win":"Bonus Ball",
    "oneclick.friday.entry":"Entry into Friday Raffle",
    "oneclick.friday.entries":"${entries} Entries into Friday Raffle",
    "oneclick.simply.choose":"Simply choose your numbers between 1 and 49, or, if you are feeling lucky, opt for a Fast Pick and we'll generate a random line for you.",
    "oneclick.curr.deposit.limits":"Your current deposit limits do not allow you wager more than",
    "oneclick.curr.deposit.limits2":"in this calendar month. Please select fewer lines.",
    "oneclick.no.weeks": "Number of weeks",
    "oneclick.draws.plural":"Draw(s)",
    "oneclick.both":"Both",
    "oneclick.prize.capping":"* Prize capping may apply. See ",
    "oneclick.req.pw.reset":"Request Password Reset",
    "oneclick.retry.test":"Retry (Test Only)"
  }
}
