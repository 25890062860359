import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import dsclientMiddleware from '../middleware/dsclientMiddleware';
import rootReducer from '../reducers/index';

export default function configureStore(initialState, dsclient, history) {

  let createStoreWithMiddleware;

  const middlewareAPI = dsclientMiddleware(dsclient);

  if (process.env.NODE_ENV !== 'production') {
    createStoreWithMiddleware = compose(
      responsiveStoreEnhancer,
      applyMiddleware(
        thunk,
        middlewareAPI,
        require("redux-logger").createLogger({ collapsed: true })
      ),
      typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : []
    )(createStore);
  } else {
    createStoreWithMiddleware = compose(
      responsiveStoreEnhancer,
      applyMiddleware(
        middlewareAPI,
        thunk
      )
    )(createStore);
  }

  //const store = createStoreWithMiddleware(connectRouter(history)(rootReducer), initialState);
  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(() => {
      const nextRootReducer = combineReducers({
        ...require('../reducers/index').default
      })
      store.replaceReducer(require('../reducers'))
    })
  }

  return store;
}
