const initialState = {

};

function gamelobbyReducer(state = initialState, action = {}) {
  switch (action.type) {

    default:
      return state;
  }
}

export default gamelobbyReducer;
