import { getASArray } from "../helpers/Utils";

let update = require('immutability-helper');;

const START_GAME = 'START_GAME';
const PRE_DRAW = 'PRE_DRAW';
const DRAW_NUM = 'DRAW_NUM';
const POST_DRAW = 'POST_DRAW';
const END_GAME = 'END_GAME';
const UPDATE_CURRENT_DRAW_NUM = 'UPDATE_CURRENT_DRAW_NUM';
const UPDATE_SHOW_DRAW = 'UPDATE_SHOW_DRAW';
const UPDATE_MY_WINNINGS = 'UPDATE_MY_WINNINGS';
const UPDATE_ALL_WINNINGS = 'UPDATE_ALL_WINNINGS';
const UPDATE_COUNTDOWN = 'UPDATE_COUNTDOWN';
const RESET_GAME_PLAYOUT = 'RESET_GAME_PLAYOUT';
const FAST_FORWARD = 'FAST_FORWARD';
const SHOW_ALL_LINES_UPDATING = 'SHOW_ALL_LINES_UPDATING';
const SHOW_PLAYOUT_UPDATING = 'SHOW_PLAYOUT_UPDATING';
const DO_SYNC = 'DO_SYNC';
const UPDATE_MODEL = 'UPDATE_MODEL';
const PLAY_NEXT_DRAW_SOUND = 'PLAY_NEXT_DRAW_SOUND';
const SET_CURRENT_GAME = 'SET_CURRENT_GAME';
const SET_CURRENT_GAME_PAYOUT = 'SET_CURRENT_GAME_PAYOUT';
const RESET_BALL = 'RESET_BALL';
const POST_DRAW_FF = 'POST_DRAW_FF';
const PROGRESSIVE_WON = 'PROGRESSIVE_WON';
const PROGRESSIVE_WON_THIS_DRAW = 'PROGRESSIVE_WON_THIS_DRAW';
const UPDATE_PLAYOUT_STATE = 'UPDATE_PLAYOUT_STATE';

const LUCKY_BALL = 'LUCKY_BALL';
const BONOBO_WIN = 'BONOBO_WIN';
const MULTI_WIN = 'MULTI_WIN';
const PERFECT_WIN = 'PERFECT_WIN';

const initialState = {
  currentDrawNum: 1,
  currentBall: 0,
  drawHistory: [],
  currentDraw: [],
  gamePlaySize: 0,
  countdown: 0,
  showDraw: false,
  gameOver: false,
  allPlays: [],
  myPlays: [],
  luckyBallArray: [],
  myCurrentWinnings: 0,
  isPlayingOut: false,
  currentGame: null,
  winnersList: [],
  winnersCount: 0,
  totalWinnings: 0,
  drawEnd: false,
  currentRankToWinTxt: 0,
  currentRankToWin: 0,
  currentDrawWinnerBets: 0,
  currentDrawWinnings: 0,
  fastForward: false,
  curSlot: 0,
  showAllLinesUpdating: false,
  showPlayoutUpdating: false,
  syncGame: false,
  updateModel: true,
  playNextDrawSound: false,
  previousWinnersCount: 0,
  progressiveAmountWon: -1,
  progressiveWon: false,
  pogressiveWinCount: 0,
  progressiveWonThisDraw: false,
  progressiveWinningDraw: "",
  progressiveAmountWonByMe: 0,
  showLuckyBall: 0,
  showBonoboWin: 0,
  showMultiWin: 0,
  showPerfectWin: 0,
  playoutTransition: 'cross-fade'
};

var _state;

function playoutReducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PLAYOUT_STATE:
      return updateGameProgress(state, action);
    case POST_DRAW_FF:
      return handlePostDrawFF(state, action);
    case PRE_DRAW:
      return handleDrawNum(state, action);
    case RESET_BALL:
      return handleResetBall(state, action);
    case PLAY_NEXT_DRAW_SOUND:
      return handlePlayNextDrawSound(state, action);
    case SET_CURRENT_GAME:
      return handleSetCurrentGame(state, action);
    case SET_CURRENT_GAME_PAYOUT:
      return handleSetCurrentGamePayout(state, action);
    case START_GAME:
      return handleStartGame(state, action);
    case DO_SYNC:
      return handleDoSync(state, action);
    case DRAW_NUM:
      return handleDrawNum(state, action);
    case POST_DRAW:
      return handlePostDraw(state, action);
    case END_GAME:
      return handleEndGame(state, action);
    case UPDATE_COUNTDOWN:
      return handleUpdateCountdown(state, action);
    case UPDATE_CURRENT_DRAW_NUM:
      return handleUpdateCurrentDrawNum(state, action);
    case UPDATE_SHOW_DRAW:
      return handleUpdateShowDraw(state, action);
    case UPDATE_MY_WINNINGS:
      return handleUpdateMyWinnings(state, action);
    case UPDATE_ALL_WINNINGS:
      return handleUpdateAllWinnings(state, action);
    case RESET_GAME_PLAYOUT:
      return handleResetState(state, action);
    case FAST_FORWARD:
      return handleFastForward(state, action);
    case SHOW_ALL_LINES_UPDATING:
      return handleShowAllLinesUpdating(state, action);
    case SHOW_PLAYOUT_UPDATING:
      return handleShowPlayoutUpdating(state, action);
    case UPDATE_MODEL:
      return handleUpdateModel(state, action);
    case PROGRESSIVE_WON:
      return handleProgressiveWon(state, action);
    case PROGRESSIVE_WON_THIS_DRAW:
      return handleProgressiveWonThisDraw(state, action);
    case LUCKY_BALL:
      return showLuckyBall(state, action);
    case BONOBO_WIN:
      return showBonoboWin(state, action);
    case MULTI_WIN:
      return showMultiWin(state, action);
    case PERFECT_WIN:
      return showPerfectWin(state, action);
    default:
      return state;
  }
}

export default playoutReducer;

//handle event functions

function showLuckyBall(state, action) {
  return update(state,
    {
      showLuckyBall: { $set: action.data.count }
    });
}

function showBonoboWin(state, action) {
  //const bonoboAllLinesIndex = state.allPlays.findIndex(function(p) { return p.playId = action.data.playId} );
  //const bonoboMyLinesIndex = state.myPlays.findIndex(function(p) { return p.playId = action.data.playId} );

  return update(state,
    {
      showBonoboWin: { $set: action.data.myLine == true ? action.data.playId : 0 },
      /*allPlays :
        {
          [bonoboAllLinesIndex] : {
            hasBonobo: {$set: true}
          }
        },
      myPlays :
        {
          [bonoboMyLinesIndex] : {
            hasBonobo: {$set: true}
          }
        }*/
    });
}

function showMultiWin(state, action) {
  return update(state,
    {
      showMultiWin: { $set: action.data }
    });
}

function showPerfectWin(state, action) {
  return update(state,
    {
      showPerfectWin: { $set: action.data }
    });
}

function handleResetState(state, action) {
  const len = state.winnersList.length;
  return update(initialState, {
    winnersList: { $splice: [[0, len]] },
    previousWinnersCount: { $set: 0 },
    currentGame: { $set: null },
    progressiveWonThisDraw: { $set: false },
    progressiveWinningDraw: { $set: "" },
    progressiveAmountWonByMe: { $set: 0 }
  });
}

function handleProgressiveWonThisDraw(state, action) {
  return update(state, {
    progressiveWonThisDraw: { $set: action.data.progressiveWonThisDraw },
    progressiveWinningDraw: { $set: action.data.progressiveWinningDraw },
    progressiveAmountWonByMe: { $set: action.data.amount },
    progressiveAmountWon: { $set: action.data.progressiveAmountWon }
  });
}

function handleResetBall(state, action) {
  return update(state, {
    showDraw: { $set: false },
    fastForward: { $set: false },
    showPlayoutUpdating: { $set: false },
    syncGame: { $set: false },
    showLuckyBall: { $set: 0 }
  }
  );
}

function handleUpdateModel(state, action) {
  const _um = !state.updateModel;
  return update(state, {
    updateModel: { $set: _um }
  });
}

function handleProgressiveWon(state, action) {
  const winnersList = state.winnersList;

  const winnerIndex = winnersList.findIndex(function (winner) {
    return (winner.playerDisplayName == action.data.winner.playerDisplayName && action.data.winner.jackpot);
  });

  let extCount;
  const wnnr = winnersList[winnerIndex];
  if (wnnr) {
    extCount = wnnr.count;
    extCount++;
  }

  if (winnerIndex > -1) {
    return update(state, {
      progressiveAmountWon: { $set: action.data.progAmountWon },
      progressiveWon: { $set: true },
      pogressiveWinCount: { $set: action.data.pogressiveWinCount },
      winnersList: {
        [winnerIndex]: {
          count: { $set: extCount }
        }
      }
    });
  } else {
    return update(state, {
      progressiveAmountWon: { $set: action.data.progAmountWon },
      progressiveWon: { $set: true },
      pogressiveWinCount: { $set: action.data.pogressiveWinCount },
      winnersList: { $push: [action.data.winner] }
    });
  }


}

function handleSetCurrentGame(state, action) {
  return update(state, {
    currentGame: { $set: action.data }
  });
}

function handleSetCurrentGamePayout(state, action) {
  return update(state, {
    currentGame: {
      gameLobby: {
        gamePayoutInfo: { $set: action.data }
      }
    }
  })
}

function handlePlayNextDrawSound(state, action) {
  return update(state, {
    playNextDrawSound: { $set: action.data }
  }
  );
}

function handleUpdateShowDraw(state, action) {
  return update(state, {
    showDraw: { $set: action.data }
  }
  );
}

function handleDoSync(state, action) {
  return update(state, {
    syncGame: { $set: action.data },
    showLuckyBall: { $set: 0 },
    showBonoboWin: { $set: 0 },
    showMultiWin: { $set: 0 },
    showPerfectWin: { $set: 0 }
  }
  );
}

function handleShowPlayoutUpdating(state, action) {
  return update(state, {
    showPlayoutUpdating: { $set: action.data }
  }
  );
}

function handleShowAllLinesUpdating(state, action) {
  return update(state, {
    showAllLinesUpdating: { $set: action.data }
  }
  );
}

function handleFastForward(state, action) {

  return update(state, {
    fastForward: { $set: action.data }
  }
  );
}

function handleUpdateMyWinnings(state, action) {

  let winnings = state.myCurrentWinnings;
  let totalWinnings = state.totalWinnings;
  let winnersCount = state.winnersCount;
  let previousWinnersCount = state.winnersCount;

  winnings += action.data.myWinnings;
  totalWinnings += action.data.totalWinnings;
  winnersCount += action.data.totalWinners;

  return update(state, {
    myCurrentWinnings: { $set: winnings },
    totalWinnings: { $set: totalWinnings },
    previousWinnersCount: { $set: previousWinnersCount },
    winnersCount: { $set: winnersCount },
    winnersList: { $push: action.data.winnersList }
  }
  );
}

function handleUpdateAllWinnings(state, action) {
  return update(state, {
    gameOver: { $set: true },
    currentGame: { $set: action.data.currentGame },
    myCurrentWinnings: { $set: action.data.myWinnings },
    totalWinnings: { $set: action.data.totalWinnings },
    winnersList: { $set: action.data.winnersList },
    winnersCount: { $set: action.data.totalWinners }
  }
  );
}

function handleUpdateCurrentDrawNum(state, action) {
  return update(state, {
    currentDrawNum: { $set: action.data.drawNumber },
    curSlot: { $set: 0 }
  }
  );
}

function handleUpdateCountdown(state, action) {
  return update(state, {
    countdown: { $set: action.data },
    playoutState: { $set: 'countdown' }
  }
  );
}

function handleStartGame(state, action) {
  return update(
    state, {
    gamePlaySize: { $set: action.data.gamePlaySize },
    allPlays: { $set: action.data.allPlays },
    myPlays: { $set: action.data.myPlays },
    isPlayingOut: { $set: true },
    luckyBallArray: { $set: action.data.luckyBallArray }
  }
  );
}

function handleEndGame(state, action) {
  return update(
    state, {
    gameOver: { $set: true }
  }
  );
}



function handlePostDraw(state, action) {

  const len = state.currentDraw.length;
  const currDraw = state.currentDraw;
  const currentDrawNum = state.currentDrawNum;
  const currDrawStr = currDraw.toString();
  const currGame = state.currentGame;
  let cT = 0;
  let cW = 0;
  let cDWB = 0;
  let _currentDrawWinnings = 0;
  const _allLines = state.allPlays;

  const drawResults = getASArray(currGame.results.gameResult.gameDrawResults);

  for (let j = 0; j < currentDrawNum; j++) {
    const dr = drawResults[j];

    if (dr.drawWinnerCount > 0) {
      cT++;
      cW += dr.drawWinnerCount;
    }
  }

  const _curDraw = drawResults[currentDrawNum - 1];
  for (let i = 0; i < getASArray(_curDraw.drawResults).length; i++) {
    const entry = getASArray(_curDraw.drawResults)[i];
    for (let line = 0; line < _allLines.length; line++) {
      const pl = _allLines[line];
      if (entry.playId == pl.playId) {
        if (pl.play.indexOf("agg") > -1) {
          cDWB += parseInt(pl.play.split("#")[2]);
        } else {
          cDWB++;
        }
        _currentDrawWinnings += pl.prize;
      }
    }
  }

  const lastDraw = (currentDrawNum == drawResults.length);

  return update(
    state, {
    drawHistory: { $push: [{ "Draw 1": currDrawStr }] },
    currentDraw: { $splice: [[0, len]] },
    drawEnd: { $set: true },
    drawLast: { $set: lastDraw },
    currentRankToWinTxt: { $set: cT },
    currentRankToWin: { $set: cW },
    currentDrawWinnerBets: { $set: cDWB },
    currentDrawWinnings: { $set: _currentDrawWinnings },
    showAllLinesUpdating: { $set: true }
  }
  );
}

function handlePostDrawFF(state, action) {
  const currGame = state.currentGame;
  let cDWB = 0;
  let _currentDrawWinnings = 0;
  const _allLines = state.allPlays;
  const dr = getASArray(currGame.results.gameResult.gameDrawResults)[currentDrawNum - 1];
  let cT = state.currentRankToWinTxt;
  let cW = state.currentRankToWin;

  for (let i = 0; i < getASArray(dr.drawResults).length; i++) {
    const entry = getASArray(dr.drawResults)[i];
    for (let line = 0; line < _allLines.length; line++) {
      const pl = _allLines[line];
      if (entry.playId == pl.playId) {
        cDWB++;
        _currentDrawWinnings += pl.prize;
      }
    }
  }

  if (dr.drawWinnerCount > 0) {
    cT++;
    cW += dr.drawWinnerCount;
  }
  return update(
    state, {
    currentRankToWinTxt: { $set: cT },
    currentRankToWin: { $set: cW },
    currentDrawWinnerBets: { $set: cDWB },
    currentDrawWinnings: { $set: _currentDrawWinnings },
  }
  );
}

function handleDrawNum(state, action) {
  //TODO - check this out - not done in React Way
  return update(
    state, {
    currentBall: { $set: action.data.drawNumber },
    currentDraw: { $push: [action.data.drawNumber] },
    curSlot: { $set: action.data.curSlot },
    drawEnd: { $set: false },
  }
  );
}

function updateGameProgress(state, action) {
  console.log("updateGameProgress(state, action)")
  // console.log(new Date());
  // console.log('---------------- ' + action.data.playoutState + '--------------------');
  // console.log('---------------- ' + state.curSlot + '--------------------');
  let transition = 'cross-fade';

  // coundown to drawing
  if (state.playoutState == 'countdown' && action.data.playoutState == 'rng_started') {
    transition = 'page-up';
  } else
    // drawing to winner is
    if (action.data.playoutState == 'rng_started' && state.curSlot >= 3) {
      transition = 'carousel-swap';
    } else
      // congratulations to next prize
      if (state.playoutState == 'draw_ended' && action.data.playoutState == 'draw_post_ended') {
        transition = 'roll-up';
      } else {
        transition = 'cross-fade';
      }

  // console.log('---------------- ' + transition + '--------------------');
  // dont run draw_post_ended if it is on the last draw!
  if (state.drawLast == true && action.data.playoutState == 'draw_post_ended') {
    return update(
      state, {
      playoutTransition: { $set: transition }
    }
    );
  }

  return update(
    state, {
    playoutState: { $set: action.data.playoutState },
    playoutTransition: { $set: transition }
  }
  );
}

export function setCurrentGame(currentGame) {
  return {
    type: SET_CURRENT_GAME,
    data: { currentGame: currentGame }
  };
}

export function setCurrentGamePayout(payoutInfo) {
  return {
    type: SET_CURRENT_GAME_PAYOUT,
    data: payoutInfo
  }
}

export function resetPlayout() {
  return {
    type: RESET_GAME_PLAYOUT
  };
}