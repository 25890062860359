import React from "react";
import Modal from "react-bootstrap4-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

function PopupWithAction(props) {
	console.log({ props });

	const handleClose = () => {
		props.cancel(props.componentKey);
	}

	const handleAction = () => {
		if (props.complete) {
			props.complete(props.componentKey, 'ACTION');
		}
	}

	return (
		<Modal id="popupJustOk" wrapperProps={{ id: "popupWithAction" }}
			dialogClassName={"modal-dialog-centered_"} backdrop="static" visible={true}>
			<div className="modal-secondary-content-two">
				<div className="d-flex modal-header">
					<h5 className="modal-title">
						<FontAwesomeIcon icon={faInfoCircle} size={"sm"} />&nbsp;{props.modalProps.title}
					</h5>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleClose()}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div className="modal-body">
					{props.modalProps.body}
				</div>

				<div className="modal-footer justify-content-center">
					<button id="popupCloseBtn" type="button" className={"btn btn-md " + (props.modalProps.closeButtonClass ? props.modalProps.closeButtonClass : "btn-white")}
						onClick={() => handleClose()}>
						{props.modalProps.closeButton ? props.modalProps.closeButton : "Close"}
					</button>
					<button id="popupActionBtn" type="button" className={"btn btn-md " + (props.modalProps.actionButtonClass ? props.modalProps.actionButtonClass : "btn-primary")}
						onClick={() => handleAction()}>
						{props.modalProps.actionButton}
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default PopupWithAction;
