import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { formatMoney, getASArray, getBonus, getPlural } from "../../../../helpers/Utils";
import { Fragment } from "react";
import React from "react";

const LotteryPermPayout = ({ currentGame }) => {

  const getGamePlaySizeBonus = () => {
    if (currentGame.gameRule == 'oneDrawGameBonusV2') {
      return 1;
    } else {
      return currentGame.gamePlaySize2nd;
    }
  }

  let sortedPayoutInfo = [];
  let ordering = {};
  let sortOrder = ["First Number Drop", "Direct 2", "Direct 3", "Direct 4", "Direct 5", "Perm 2", "Perm 3", "Perm 4", "Perm5", "Banker Against All"];
  for (var i = 0; i < sortOrder.length; i++) {
    ordering[sortOrder[i]] = i;
  };

  sortedPayoutInfo = getASArray(currentGame.gameLobby.gamePayoutInfo).sort((a, b) => (ordering[a.meta.rankTitle] - ordering[b.meta.rankTitle]));


  return (
    <div className="row py-3 px-2">
      <div className={classNames("col-md-8")}>
        <div className="text-center">
          <div className="text-bigger-50 text-light font-weight-bold pb-2">
            <Translate id="payout.estimated_payout" defaultMessage="Estimated Payout" />&nbsp;
            <br className="payout-ranks-break" />
            {currentGame.gameLobby && currentGame.gameLobby.gamePayoutInfo &&
              (<Translate id="payout.ranks_paid" data={{ count: getASArray(currentGame.gameLobby.gamePayoutInfo).filter(p => p.rankPrize != 0).length }} defaultMessage="{count} Ranks Paid" />)}
          </div>

          {currentGame.gameLobby && getASArray(currentGame.gameLobby.gamePayoutInfo) &&
            <div className="table-responsive payout-list-container-perm">
              <table className="w-100 bg-white text-dark">
                <thead className="bg-dark text-light text-bigger-10">
                  <tr>
                    <th className="px-2 text-left">
                      <Translate id="payout.bet_type" defaultMessage="Bet Type" />
                    </th>
                    <th className="px-2 text-left">
                      <Translate id="payout.max_payout" defaultMessage="Max Payout" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {sortedPayoutInfo.map(function (p, i) {
                    return (
                      <tr key={i} className={classNames({ "bg-white": i % 2 == 0, "bg-light": i % 2 != 0 }, "text-bigger-20")}>
                        <td className="px-2 text-left">
                          {p.meta.rankTitle}
                        </td>
                        <td className="px-2 text-left">{`${formatMoney(p.rankPrize)}x`}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>}
        </div>
        <div className="w-100 text-smaller-75 text-light">

        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex justify-content-center">
          <img src="./static/img/smiley_face.png" className="img-fluid" />
        </div>
      </div>
    </div>
  )
}

export default LotteryPermPayout;
