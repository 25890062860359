import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { createResponsiveStateReducer } from "redux-responsive";

import loading from "./loading";
import {default as lr_blaze} from "./lottorace";
import {default as lr_rest} from "./lottorace_rest";
import numberpick from "./numberpick";
import playout from "./playout";
import gamelobby from "./gamelobby";
import mainlobby from "./mainlobby";

const lottorace = (apiType == 3 || apiType == 4) ? lr_rest : lr_blaze;

const rootReducer = combineReducers({
  form,
  loading,
  lottorace,
  numberpick,
  playout,
  gamelobby,
  mainlobby,
  //localize,
  browser: createResponsiveStateReducer({
    extraSmall: 575,
    small: 767,
    medium: 991,
    large: 1199,
    extraLarge: 1400,
  }, {
    extraFields: () => ({
      width: window.innerWidth,
      height: window.innerHeight
    }),
  })
});

export default rootReducer;
