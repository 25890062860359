import React from "react";
import "./Modals.scss";

import PromisePortal from "../thirdparty/react-promise-portal/PromisePortal";

const ModalContainer = () => {
    return (
        <React.Suspense fallback={<span />}>
            <PromisePortal />
        </React.Suspense>
    );
};

export default ModalContainer;
