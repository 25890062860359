import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";


const MaintenanceMessage = ({...props}) =>
{
	const message = useSelector((state) => state.lottorace.session?.configuration?.MAINTENANCE_MESSAGE);

	if (message && message != "") {
		return (
			<div className="container alert-container">
			<div className="row">
				<div className="col">
					<div className="alert alert-warning alert-dismissible alert-bonobo fade show m-0 py-0" role="alert" style={{display: "block"}}>
						<div className="d-flex align-items-center">
							<div><FontAwesomeIcon icon={["fal", "bell-on"]} className={"mr-2"} size={"lg"}/></div>
							<div className="flex-fill">
								<h5 className="alert-heading">Platform Message</h5>
								<span dangerouslySetInnerHTML={{__html: message}}></span>
							</div>
						</div>
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
			</div>
		)
	} else {
		return <span></span>
	}
}

MaintenanceMessage.propTypes = {

};

export default MaintenanceMessage;
