const templatedIcons = {
    "lobby_bg": {
        "blue-theme": "url(/static/media/lobby_bg2.b3e5b765.png), linear-gradient(180deg, #0b2f57 0%, #025e9e 100%)",
        "cartoon-theme": "url(/static/media/lobby_bg_2.69c76cad.png), linear-gradient(180deg, #33AFDB 0%, #ffffff 100%)",
        "sweet-blue-theme": "url(/static/media/BackgroundLandscape.cce73bcc.png), linear-gradient(180deg, #0b2f57 0%, #025e9e 100%)",
    },
    "first": {
        "blue-theme": "/static/images/ranking_medal_gold.png",
        "cartoon-theme": "/static/images/templates/cartoon/badge/first.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/1-Text.png"
    },
    "second": {
        "blue-theme": "/static/images/ranking_medal_silver.png",
        "cartoon-theme": "/static/images/templates/cartoon/badge/second.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/2-Text.png"
    },
    "third": {
        "blue-theme": "/static/images/ranking_medal_bronze.png",
        "cartoon-theme": "/static/images/templates/cartoon/badge/third.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/3-Text.png"
    },
    "trophy": {
        "blue-theme": "/static/images/ranking_icon_trophy.png",
        "cartoon-theme": "/static/images/templates/cartoon/badge/Cup.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/Crown.png"
    },
    "coins": {
        "blue-theme": "/static/images/reward_icon_gold.png",
        "cartoon-theme": "/static/images/templates/cartoon/icons/Basic.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/CoinBasic.png"
    },
    "tickets": {
        "blue-theme": "/static/img/BTT_icon.png",
        "cartoon-theme": "/static/images/templates/cartoon/icons/Basic.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/CoinBasic.png"
    },
    "old-coins": {
        "blue-theme": "/static/img/bpm_icon.png",
        "cartoon-theme": "/static/img/bpm_icon.png",
        "sweet-blue-theme": "/static/img/bpm_icon.png"
    },
    "prizes": {
        "blue-theme": "/static/images/common_icon_golds.png",
        "cartoon-theme": "/static/images/templates/cartoon/icons/Sack.png",
        "sweet-blue-theme": "/static/images/templates/sweet/common/icons/CoinBag.png"
    },
    "prevButton": {
        "blue-theme": "/static/images/btn_icon_arrow_prev.png",
        "cartoon-theme": "/static/images/templates/cartoon/buttons/LeftButton.png",
        "sweet-blue-theme": "/static/images/templates/cartoon/buttons/LeftButton.png",
    },
    "nextButton": {
        "blue-theme": "/static/images/btn_icon_arrow_next.png",
        "cartoon-theme": "/static/images/templates/cartoon/buttons/RightButton.png",
        "sweet-blue-theme": "/static/images/templates/cartoon/buttons/RightButton.png",
    },
    "backButton": {
        "blue-theme": "/static/images/top_btn_icon_back.png",
        "cartoon-theme": "/static/images/templates/cartoon/icons/ArrowLeft.png",
        "sweet-blue-theme": "/static/images/templates/cartoon/icons/ArrowLeft.png",
    },
    "plusButton": {
        "blue-theme": "/static/images/top_status_btn_get_green.png",
        "cartoon-theme": "/static/images/templates/cartoon/buttons/ButtonMore.png",
        "sweet-blue-theme": "/static/images/templates/cartoon/buttons/ButtonMore.png",
    },
    "settingsButton": {
        "blue-theme": "/static/images/top_btn_icon_setting.png",
        "cartoon-theme": "/static/images/templates/cartoon/icons/Settings.png",
        "sweet-blue-theme": "/static/images/templates/cartoon/icons/Settings.png",
    },
    "shieldLevel": {
        "blue-theme": "/static/images/character_lv_bg.png",
        "cartoon-theme": "/static/images/templates/cartoon/icons/Shield.png",
        "sweet-blue-theme": "/static/images/templates/cartoon/icons/Shield.png",
    }
}

export function getTemplateIcon(icon) {
    const theme = window.localStorage.getItem("theme");

    return templatedIcons[icon][theme];
}
