//import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'howler';

import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';

import configureStore from './store/configureStore';
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBars, faSync, faTimes, faTimesCircle, faHome, faGamepad, faArrowCircleDown,
  faArrowCircleUp, faBullseye, faTrophy, faChevronRight, faChevronDown, faChevronUp, faCircle,
  faMoneyBill, faHeart, faExclamationCircle, faExclamation, faComment, faVolumeUp, faVolumeOff,
  faSignInAlt, faSignOutAlt, faUserPlus, faSpinner, faMinus, faPlus, faTicketAlt, faInfo,
  faInfoCircle, faUnlockAlt, faCheckSquare, faWifiSlash, faSearchMinus, faSearchPlus, faDesktop,
  faCog, faCheck, faRandom, faTrash, faHandPointUp, faClone, faStar, faComments, faPlayCircle, faEdit, faLockAlt, faBug, faGear
} from '@fortawesome/pro-solid-svg-icons';

import {
  faMoneyCheckAlt, faHandPaper, faInfoSquare, faEnvelope, faUserCircle, faChartLine,
  faAward, faUsers, faUser, faUserTie, faMapMarker, faPhone, faKey, faFlag, faQuestionCircle, faTachometerSlow,
  faVideo, faScanner, faGift, faShoppingCart, faVenus, faMars, faSmile, faCommentAltSmile, faGlobe, faPrint, faAlarmClock, faArrowsH, faShareAlt,
  faRetweet, faPaste, faArrowRight, faGamepad as faGamePadLight, faRecycle, faUniversalAccess, faClock, faPaperPlane, faBellOn, faMegaphone
} from '@fortawesome/pro-light-svg-icons';

import { faFacebook, faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';

import * as serviceWorker from './serviceWorker';

import App from './containers/App';

import DataServicesClient from './helpers/DataServicesClient';
import DataServicesClientV2 from "./helpers/DataServicesClientV2";
//import RSocketServicesClient from "./helpers/RSocketServicesClient";

import ScrollToTop from "./components/ScrollToTop";
import ErrorBoundary from "./components/ErrorBoundary";

require('dotenv').config();

library.add(faBars, faSync, faTimes, faTimesCircle, faBullseye, faHome, faArrowCircleDown,
  faArrowCircleUp, faGamepad, faTrophy, faQuestionCircle, faChevronRight, faChevronDown, faChevronUp, faTimesCircle, faUsers, faCircle,
  faMoneyBill, faHeart, faExclamationCircle, faExclamation, faPhone, faEnvelope, faComment, faVolumeUp, faVolumeOff, faSignInAlt, faSignOutAlt,
  faUserPlus, faSpinner, faMinus, faPlus, faTicketAlt, faFacebook, faTwitter, faWhatsapp, faInstagram, faUser, faMapMarker, faKey, faFlag, faInfo,
  faInfoCircle, faUnlockAlt, faCheckSquare, faCheck, faHandPaper, faInfoSquare, faUserCircle, faChartLine, faMoneyCheckAlt, faWifiSlash,
  faAward, faTachometerSlow, faSearchMinus, faSearchPlus, faVideo, faDesktop, faCog, faScanner, faGift, faShoppingCart, faVenus,
  faMars, faSmile, faCommentAltSmile, faGlobe, faPrint, faAlarmClock, faRandom, faTrash, faClone, faArrowsH, faStar, faShareAlt, faRetweet,
  faComments, faPaste, faArrowRight, faPlayCircle, faEdit, faLockAlt, faHandPointUp, faGamePadLight, faRecycle, faUniversalAccess, faClock,
  faUserTie, faPaperPlane, faBellOn, faBug, faMegaphone);

let version = "1.0.30";
window.btxVersion = version;

if (process.env.NODE_ENV == 'production') {
  console.log = () => { };
}

if (typeof logAccount !== 'undefined' && logAccount !== null && window.LogRocket !== 'undefined') {
  window.LogRocket.init(logAccount, { release: version });
}

if (typeof msClarity !== 'undefined' && msClarity == true) {
  try {
    window.clarity("set", "release", version);
  } catch (e) {
    console.log('Could not set Clarity release info', e);
  }
}

const isLiveMode = sessionStorage.getItem('live') || getParameterByName("live");
const slimView = sessionStorage.getItem("slimView");
const defaultGlobalFilter = localStorage.getItem('globalFilter');

if (isLiveMode == 'true') {
  switchMain = true;
  sessionStorage.setItem('live', true);
}
if (slimView == 'true') {
  switchMain = true;
  mainGameId = sessionStorage.getItem("slimViewGameId");
}

if (defaultGlobalFilter == null || typeof defaultGlobalFilter === 'string') {
  localStorage.setItem('globalFilter', JSON.stringify(window.mainTabs.map(t => t.defaultFilter)));
}

if (!String.prototype.contains) {
  String.prototype.contains = function (s) {
    return this.indexOf(s) > -1
  }
}

const initialState = {

};

// commented out bc disabled import rest services client
const dsclient = window.apiType == 4 ? null /*new RSocketServicesClient()*/ : window.apiType == 2 ? new DataServicesClientV2() : new DataServicesClient();

const store = configureStore(initialState, dsclient, null);
window.store = store;

dsclient.setStore(store);
window.apiClient = dsclient;

window.verifyIDPopupShown = false;
window.oneClickWeeklyGame = false;

const render = () => {
  ReactDOM.render(
    <Provider store={store} key={Math.random()}>
      <ErrorBoundary>
        <LocalizeProvider >
          <Router>
            <ScrollToTop>
              <StrictMode>
                <App />
              </StrictMode>
            </ScrollToTop>
          </Router>
        </LocalizeProvider>
      </ErrorBoundary>
    </Provider>
    , document.getElementById('root')
  );
}

render();

// Webpack Hot Module Replacement API
if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./containers/App', () => {
    render();
  });
}

const onUpdateSW = () => {
  showMetroDialog(
    "New Version Available!",
    "Please click Update to get the latest version!",
    "[Update#btn-info]",
    btn => {
      if (btn == "Update") {
        window.location.href = siteRoot;
      }
    }
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register({onUpdate: onUpdateSW, onSuccess: onSuccessSW});
serviceWorker.unregister();
