import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translate } from "react-localize-redux";

const LoaderStatus = ({ ...props }) => {
  return (
    <section className="jumbotron mb-0">
      <div className="container">
        <div id="mainContent" className="rounded bonobo-loader">
          <div className="row px-5">
            <div className="col-sm-12 text-center text-light">
              <h4 className="py-4">{props.children}</h4>
              <div className="spinner-border text-light mb-4" role="status">
                <span className="sr-only"><Translate id="general.loading" />...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

LoaderStatus.propTypes = {

};

export default LoaderStatus;
