import React, { Component, Suspense, lazy } from 'react';
import { Redirect } from "react-router";
import LoaderStatus from "../components/LoaderStatus";
import { Route, Switch } from 'react-router-dom';
import TransitionGroup from "react-transition-group/TransitionGroup";
import CustomPage from "../containers/CustomPage";
import MaintenancePage from "../containers/MaintenancePage";
import queryString from "query-string";
import { Translate } from 'react-localize-redux';

function retryChunk(fn, retriesLeft = 3, interval = 1500) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryChunk(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}


const firstChild = props => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
};

const publicPaths = {
  "/": true, "/html5": true, "/lobby": true, "/raffle": true, "/payout": true, "/playout": true, "/emailconfirmation": true,
  "/replay": true, "/contact": true, "/howtoplay": true, "/terms": true, "/privacy": true, "/scan": true, "/password": true
};

const EnhancedRoute = ({ component: Component, ...rest }) => (

  <Route {...rest} render={(props) => {
    const { lottorace: { loggedIn, init, MAINTENANCE_ENABLED, changePassword } } = window.store.getState();

    if (window.updateAvailable == true) {
      showMetroDialog("<i class='fa fa-exclamation-circle'></i>&nbsp;New Version Available!", 'Please click Update to get the latest version!', '[Update#btn-info]', (btn) => {
        if (btn == 'Update') {
          window.location.href = window.siteRoot;
        }
      });
    }

    const pathname = props.location.pathname;

    if (MAINTENANCE_ENABLED == "true" && pathname != "/maintenance") {
      return (<Redirect to='/maintenance' />)
    } else
      if (pathname == '/emailconfirmation') {
        const key = queryString.parse(props.location.search).key;
        return <EmailConfirmation {...props} key={key} />
      } else
        if (pathname == '/password') {
          const key = queryString.parse(props.location.search).key;
          return <ChangePasswordPage {...props} key={key} changeNow={changePassword == true ? changePassword : null} />
        } else
          if ((!loggedIn || !init) && !publicPaths[pathname]) {
            return <Redirect to='/?login=true' />;
          } else {
            if (changePassword == true && pathname != "/password") {
              return <Redirect to='/password?changeNow=true' />;
            }
            return <Component {...props} />
          }
  }} />
)

const NoMatch = (props) => {
  return (<section className="jumbotron">
    <div className="container">
      <div id="mainContent" className="rounded">
        <div className="row">
          <div className="col-sm-12 text-center text-light">
            <h4 className="py-4">
              <Translate id="general.page_not_exist" />
            </h4>
            <br />
            <button className="btn btn-primary" onClick={() => props.history.push('/')}>
              <Translate id="general.goto_home" />
            </button>
            <br /><br />
          </div>
        </div>
      </div>
    </div>
  </section>)
}

const BingoLobbyContainer = lazy(() => retryChunk(() => import("../games/bingo/html/GameLobbyBingo")));
const BingoPlayoutContainer = lazy(() => retryChunk(() => import("../games/bingo/html/GamePlayoutBingo")));
const BingoGameOverContainer = lazy(() => retryChunk(() => import("../containers/GamePlayout/GameOverPage/GameOverPageBingo")));
const Crossword = lazy(() => retryChunk(() => import("../games/crossword/Crossword")));
const NewScratchcard = lazy(() => retryChunk(() => import("../games/scratchcard/NewScratchcard")));
const GamesPage = lazy(() => retryChunk(() => import('../containers/Home/index')));
const GameLobbyMainPage = lazy(() => retryChunk(() => import('../containers/GameLobby/GameLobbyMain')));
const GameLobbyRaffleMainPage = lazy(() => retryChunk(() => import('../containers/GameLobby/GameLobbyRaffleMain')));
const HistoryGamesPage = lazy(() => retryChunk(() => import('../containers/HistoryGamesPage')));
const LotteryNumbersPage = lazy(() => retryChunk(() => import('../containers/LotteryNumbersPage')));
const SubscriptionHistoryGamesPage = lazy(() => retryChunk(() => import('../containers/SubscriptionGamesPage')));
const SubscriptionDetailsPage = lazy(() => retryChunk(() => import('../containers/SubscriptionDetailsPage')));
const GameLobbyPage = lazy(() => retryChunk(() => import('../containers/GameLobby/index')));
const GameLobbyPage590 = lazy(() => retryChunk(() => import('../containers/GameLobby/GameLobbyPage590')));
const NewGameLobbyPage = lazy(() => retryChunk(() => import('../containers/NewPickPage/NewGameLobbyPage')));
const GameLobbyRafflePage = lazy(() => retryChunk(() => import('../containers/GameLobby/GameLobbyRafflePage')));
const GameNumberPickPage = lazy(() => retryChunk(() => import('../containers/GameLobby/NumberPickPage/index')));
const GamePlayoutPage = lazy(() => retryChunk(() => import('../containers/GamePlayout/index')));
const GamePlayersPage = lazy(() => retryChunk(() => import('../containers/GameLobby/PlayersPage/GamePlayersPage')));
const GamePayoutPage = lazy(() => retryChunk(() => import('../containers/GameLobby/PayoutPage/GamePayoutPage')));
const GameMyBetsPage = lazy(() => retryChunk(() => import('../containers/GameLobby/MyBetsPage/GameMyBetsPage')));
const GamePermMyBetsPage = lazy(() => retryChunk(() => import('../containers/GameLobby/MyBetsPage/GamePermMyBetsPage')));
const GameOverPageLottorace = lazy(() => retryChunk(() => import('../containers/GamePlayout/GameOverPage/GameOverPageLottoRace')));
const GameOverPageLottery = lazy(() => retryChunk(() => import('../containers/GamePlayout/GameOverPage/GameOverPageLottery')));
const GameOverPageRaffle = lazy(() => retryChunk(() => import('../containers/GamePlayout/GameOverPage/GameOverPageRaffle')));
const GameOverPageRaffleExt = lazy(() => retryChunk(() => import('../containers/GamePlayout/GameOverPage/GameOverPageRaffleExt')));
const CompletedGamesPage = lazy(() => retryChunk(() => import('../containers/CompletedGamesPage')));
const SubscriptionsPage = lazy(() => retryChunk(() => import('../containers/SubscriptionsPage')));
const CashierPage = lazy(() => retryChunk(() => import('../containers/Cashier/CashierPage')));
const CashierHistoryPage = lazy(() => retryChunk(() => import('../containers/Cashier/HistoryPage/CashierHistoryPage')));
const DepositRequestsPage = lazy(() => retryChunk(() => import('../containers/Cashier/HistoryPage/DepositRequestsPage')));
const CashierResponsibleGamingPage = lazy(() => retryChunk(() => import('../containers/Cashier/ResponsibleGaming/RespGamingPage')));
const CashierResponsibleGamingLimitsPage = lazy(() => retryChunk(() => import('../containers/Cashier/ResponsibleGaming/RespGamingEditPage')));
const CashierResponsibleGamingTimeoutPage = lazy(() => retryChunk(() => import('../containers/Cashier/Timeout/TimeoutPage')));
const CashierResponsibleGamingRealityCheckPage = lazy(() => retryChunk(() => import('../containers/Cashier/RealityCheck/RealityCheckPage')));
const CashierResponsibleGamingExclusionPage = lazy(() => retryChunk(() => import('../containers/Cashier/SelfExclusion/SelfExclusionPage')));
const CashierResponsibleGamingExclusionSetPage = lazy(() => retryChunk(() => import('../containers/Cashier/SelfExclusion/SelfExclusionEditPage')));
const DepositPage = lazy(() => retryChunk(() => import('../containers/Cashier/Deposit/DepositPage')));
const DepositPageNew = lazy(() => retryChunk(() => import('../containers/Cashier/Deposit/DepositPageNew')));
// const WithdrawPage = lazy(() => retryChunk(() => import('../containers/Cashier/Withdrawal/WithdrawPage')));
const WithdrawPageNew = lazy(() => retryChunk(() => import('../containers/Cashier/Withdrawal/WithdrawPageNew')));
const WithdrawPendingPage = lazy(() => retryChunk(() => import('../containers/Cashier/Withdrawal/Pending/PendingWithdrawalsPage')));
const PrizeListPage = lazy(() => retryChunk(() => import('../containers/Cashier/Prizes/PrizeListPage')));
const PrizeCollectPage = lazy(() => retryChunk(() => import('../containers/Cashier/Prizes/PrizeCollectInstructions')));
const HowToPlayPage = lazy(() => retryChunk(() => import('../containers/HowToPlay/HowToPlayPage')));
const PrivacyPage = lazy(() => retryChunk(() => import('../containers/Privacy/PrivacyPage')));
const TermsPage = lazy(() => retryChunk(() => import('../containers/Terms/TermsPage')));
const ContactPage = lazy(() => retryChunk(() => import('../containers/ContactUsPage')));
const AccountPage = lazy(() => retryChunk(() => import('../containers/Account/AccountPage')));
const NewPickPage = lazy(() => retryChunk(() => import('../containers/NewPickPage/NewPickPage')));
const AccountAddressPage = lazy(() => retryChunk(() => import('../containers/Account/AddressPage/AddressPage')));
const AccountEmailPage = lazy(() => retryChunk(() => import('../containers/Account/EmailPage/EmailPage')));
const AccountPhonePage = lazy(() => retryChunk(() => import('../containers/Account/PhonePage/PhonePage')));
const AccountInfoPage = lazy(() => retryChunk(() => import('../containers/Account/InfoPage/InfoPage')));
const AccountPasswordPage = lazy(() => retryChunk(() => import('../containers/Account/PasswordPage/UpdatePasswordPage')));
const ApplicationSettingsPage = lazy(() => retryChunk(() => import('../containers/Account/ApplicationSettingsPage')));
const AccountNotificationsPage = lazy(() => retryChunk(() => import('../containers/Account/NotificationsPage/NotificationsPage')));
const ChangePasswordPage = lazy(() => retryChunk(() => import('../containers/ChangePasswordPage')));
const ScanTicketPage = lazy(() => retryChunk(() => import('../containers/ScanTicketPage')));
const EmailConfirmation = lazy(() => retryChunk(() => import('../containers/EmailConfirmationPage')));
const CreditsPage = lazy(() => retryChunk(() => import('../containers/CreditsPage')));
const ReferralsPage = lazy(() => retryChunk(() => import('../containers/ReferralsPage')));
const ReferralsListPage = lazy(() => retryChunk(() => import('../containers/ReferralsListPage')));
const ReferralsSendPage = lazy(() => retryChunk(() => import('../containers/ReferralsSendPage')));
const PlayerVerificationPage = lazy(() => retryChunk(() => import('../containers/PlayerVerificationPage')));

export default (props) => {
  return (
    <Suspense fallback={<LoaderStatus>
      <Translate id="general.loading_page" />
    </LoaderStatus>}>
      {!props.configuration &&
        <Switch location={props.location}>
          <Route path={"/maintenance"} exact component={MaintenancePage} />
          <Route path={"/idle"} exact component={MaintenancePage} />
        </Switch>}

      {props.configuration &&
        <Switch location={props.location}>
          {/*<Route path={"/"} exact component={ShutdownPage}/>*/}

          {props.configuration.homeOverridePageId != null &&
            <Route path={"/"} exact children={({ match, ...rest }) => (
              <TransitionGroup component={firstChild}>
                {match && <CustomPage {...rest} pageId={props.configuration.homeOverridePageId} />}
              </TransitionGroup>
            )} />}

          {props.configuration.homeOverridePageId == null && window.switchMain == false &&
            <Route path={"/"} exact children={({ match, ...rest }) => (
              <TransitionGroup component={firstChild}>
                {match && <GamesPage {...rest} />}
              </TransitionGroup>
            )} />}

          {props.configuration.homeOverridePageId == null && window.switchMain == true &&
            <Route path={"/"} exact children={({ match, ...rest }) => (
              <TransitionGroup component={firstChild}>
                {match && <GameLobbyMainPage {...rest} />}
              </TransitionGroup>
            )} />}

          <Route path={"/slim"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <Redirect to={'/'} {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/p/:pageLink"} render={({ match }) => (
            <CustomPage pageLink={match.params.pageLink} />
          )} />

          <Route path={"/replay"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <HistoryGamesPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/draws"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <LotteryNumbersPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/subHistory"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <SubscriptionHistoryGamesPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/subDetails"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <SubscriptionDetailsPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/lobby"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameLobbyPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/permlobby"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameLobbyPage590 {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/newlobby"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <NewGameLobbyPage {...rest} />}
            </TransitionGroup>
          )} />


          <Route path={"/bingo/lobby"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <BingoLobbyContainer {...rest} />}
            </TransitionGroup>
          )} />

          <EnhancedRoute path={"/bingo/playout"} exact component={BingoPlayoutContainer} />

          <EnhancedRoute path={"/bingo/gameover"} exact component={BingoGameOverContainer} />

          <Route path={"/crossword"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <Crossword {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/scratchcard"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <NewScratchcard {...rest} />}
            </TransitionGroup>
          )} />


          <Route path={"/raffle"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameLobbyRafflePage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/raffles"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameLobbyRaffleMainPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/pick"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameNumberPickPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/players"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GamePlayersPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/bets"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameMyBetsPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/permbets"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GamePermMyBetsPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/payout"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GamePayoutPage {...rest} />}
            </TransitionGroup>
          )} />

          <Route path={"/history"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <CompletedGamesPage {...rest} />}
            </TransitionGroup>
          )} />
          <Route path={"/subscriptions"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <SubscriptionsPage {...rest} />}
            </TransitionGroup>
          )} />
          <Route path={"/gameOver"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameOverPageLottorace {...rest} />}
            </TransitionGroup>
          )} />
          <Route path={"/gameOverLottery"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameOverPageLottery {...rest} />}
            </TransitionGroup>
          )} />
          <Route path={"/gameOverRaffle"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameOverPageRaffle {...rest} />}
            </TransitionGroup>
          )} />
          <Route path={"/gameOverRaffleExt"} exact children={({ match, ...rest }) => (
            <TransitionGroup component={firstChild}>
              {match && <GameOverPageRaffleExt {...rest} />}
            </TransitionGroup>
          )} />

          <EnhancedRoute path={"/contact"} exact component={ContactPage} />
          <EnhancedRoute path={"/howtoplay"} exact component={HowToPlayPage} />
          <EnhancedRoute path={"/privacy"} exact component={PrivacyPage} />
          <EnhancedRoute path={"/terms"} exact component={TermsPage} />
          <EnhancedRoute path={"/playout"} exact component={GamePlayoutPage} />
          <EnhancedRoute path={"/cashier"} exact component={CashierPage} />
          <EnhancedRoute path={"/cashier/history"} exact component={CashierHistoryPage} />
          <EnhancedRoute path={"/depositrequests"} exact component={DepositRequestsPage} />
          <EnhancedRoute path={"/cashier/prizes"} exact component={PrizeListPage} />
          <EnhancedRoute path={"/cashier/prizes/prize"} exact component={PrizeCollectPage} />
          <EnhancedRoute path={"/cashier/responsiblegaming"} exact component={CashierResponsibleGamingPage} />
          <EnhancedRoute path={"/cashier/responsiblegaming/limits"} exact component={CashierResponsibleGamingLimitsPage} />
          <EnhancedRoute path={"/cashier/responsiblegaming/timeout"} exact component={CashierResponsibleGamingTimeoutPage} />
          <EnhancedRoute path={"/cashier/responsiblegaming/realitycheck"} exact component={CashierResponsibleGamingRealityCheckPage} />
          <EnhancedRoute path={"/cashier/responsiblegaming/exclusion"} exact component={CashierResponsibleGamingExclusionPage} />
          <EnhancedRoute path={"/cashier/responsiblegaming/exclusion/set"} exact component={CashierResponsibleGamingExclusionSetPage} />
          {/* <EnhancedRoute path={"/deposit"} exact component={DepositPage} /> */}
          <EnhancedRoute path={"/deposit"} exact component={DepositPageNew} />
          {/* <EnhancedRoute path={"/withdraw"} exact component={WithdrawPage} /> */}
          <EnhancedRoute path={"/withdraw"} exact component={WithdrawPageNew} />
          <EnhancedRoute path={"/withdraw/pending"} exact component={WithdrawPendingPage} />
          <EnhancedRoute path={"/account"} exact component={AccountPage} />
          <EnhancedRoute path={"/permpick"} exact component={NewPickPage} />
          <EnhancedRoute path={"/account/info"} exact component={AccountInfoPage} />
          <EnhancedRoute path={"/account/address"} exact component={AccountAddressPage} />
          <EnhancedRoute path={"/account/email"} exact component={AccountEmailPage} />
          <EnhancedRoute path={"/account/phone"} exact component={AccountPhonePage} />
          <EnhancedRoute path={"/account/password"} exact component={AccountPasswordPage} />
          <EnhancedRoute path={"/account/settings"} exact component={ApplicationSettingsPage} />
          <EnhancedRoute path={"/account/notifications"} exact component={AccountNotificationsPage} />
          <EnhancedRoute path={"/account/verification"} exact component={PlayerVerificationPage} />
          <EnhancedRoute path={"/password"} exact component={ChangePasswordPage} />
          <EnhancedRoute path={"/scan"} exact component={ScanTicketPage} />
          <EnhancedRoute path={"/emailconfirmation"} exact component={EmailConfirmation} />
          <EnhancedRoute path={"/referrals"} exact component={ReferralsPage} />
          <EnhancedRoute path={"/referrals/list"} exact component={ReferralsListPage} />
          <EnhancedRoute path={"/referrals/send"} exact component={ReferralsSendPage} />
          <Route path={"/maintenance"} exact component={MaintenancePage} />
          <Route path={"/idle"} exact component={MaintenancePage} />
          <Route path={"/credits"} exact component={CreditsPage} />
          <Route component={NoMatch} />
        </Switch>}
    </Suspense>
  )
}
