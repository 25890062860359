import { encryptString } from "../helpers/Utils";
import ReconnectingWebSocket from "reconnecting-websocket";
require('./amf/dataservices-client');
require('./amf/dataservices-dto');

let loggedIn = false;
let connected = false;
let connectedPush = false;

let connectRetry = 0;
let websocket = false;

let amfChannel;
let amfChannelPush;
let channelSet;
let channelSetPush;

let gameTopicConsumer;
let lobbyTopicConsumer;
let cashierTopicConsumer;

let remoteObject;
let unsecuredObject;

let currentResolve = {};
let currentReject;

var serverTime;
var pingStartTime;

var gameListSnap = {};
var gamesToDelete = {};

var update = require('immutability-helper');

var internalPlayerId;

const POLLING_INTERVAL = 3000;

window.AdvancedWebSocket = ReconnectingWebSocket;

/*
 * This silly underscore is here to avoid a mysterious "ReferenceError: ApiClient is not defined" error.
 * See Issue #14. https://github.com/erikras/react-redux-universal-hot-example/issues/14
 *
 * Remove it at your own risk.
 */
class DataServicesClientV2 {

  constructor() {
    flex.client.log.Log.setEnabled(false);
    this.initDS();
    this.pingCycle();
    setInterval(this.purgeGameList, 3000);
  }

  initDS = (reuseSession = false) => {
    console.log('Initializing DataServices. Reuse session = ' + reuseSession);

    if (!window.defaultConnectionType) {
      window.defaultConnectionType = "http";
    }

    let overrideConnectionType = localStorage.getItem("overrideConnectionType");

    if (overrideConnectionType == null) {
      overrideConnectionType = window.defaultConnectionType;
    }

    connected = false;
    loggedIn = false;

    if (overrideConnectionType == "http") {
      cashierTopicConsumer = null;
      gameTopicConsumer = null;
      lobbyTopicConsumer = null;
    }

    remoteObject = null;
    unsecuredObject = null;
    amfChannel = null;
    channelSet = null;

    if (overrideConnectionType == "http") {
      amfChannel = new flex.client.channels.Channel(
        'my-amf',
        apiBackup,
        flex.client.channels.Channel.HttpMode.POLLING);
      amfChannel.setHttpPollingIntervalMillis(POLLING_INTERVAL);
      amfChannel.setConnectTimeoutMillis(10000);
      amfChannel.setRequestTimeoutMillis(15000);

      channelSet = new flex.client.channels.advanced.AdvancedChannelSet([amfChannel]);

      websocket = false;
    } else
      if (overrideConnectionType == "websocket") {
        amfChannel = new flex.client.channels.Channel(
          'my-amf-no-polling',
          apiBackup,
          flex.client.channels.Channel.HttpMode.REGULAR);
        amfChannel.setConnectTimeoutMillis(10000);
        amfChannel.setRequestTimeoutMillis(15000);

        channelSet = new flex.client.channels.ChannelSet([amfChannel]);

        websocket = true;
      }

    channelSet.addEventListener(flex.client.channels.events.ChannelEvent.CONNECT,
      (channelEvent) => {
        const result = channelEvent;
        if (channelEvent.isConnected()) {
          connected = true;
          this.reduxStore.dispatch({ type: 'SERVER_CONNECTED', data: result });
          if (reuseSession == true) {
            this.reduxStore.dispatch({ type: 'LOGIN_BACKGROUND' });
          }
        }
      });

    channelSet.addEventListener(flex.client.channels.events.ChannelEvent.DISCONNECT,
      (channelEvent) => {
        const result = channelEvent;
        connected = false;
        console.log('ChannelEvent: ', result);
        if (websocket == true) {
          setTimeout(() => {
            this.initDS(true)
          }, 5000);
          this.reduxStore.dispatch({ type: 'HIDE_PROCESSING' });
          this.reduxStore.dispatch({ type: 'SERVER_DISCONNECTED', data: result });
        }
      });

    channelSet.addEventListener(flex.client.channels.events.ChannelFaultEvent.FAULT,
      (channelEvent) => {
        const result = channelEvent;
        connected = false;
        console.log('ChannelEvent: ', result);
        if (websocket == false) {
          setTimeout(() => {
            this.initDS(true)
          }, 1000);
          this.reduxStore.dispatch({ type: 'HIDE_PROCESSING' });
          this.reduxStore.dispatch({ type: 'SERVER_DISCONNECTED', data: result });
        }
      });

    if (overrideConnectionType == "http") {
      cashierTopicConsumer = new flex.client.messaging.Consumer('cashier-topic');
      cashierTopicConsumer.setChannelSet(channelSet);
      cashierTopicConsumer.setResubscribeAttempts(500);
      cashierTopicConsumer.setResubscribeIntervalMillis(3000);

      lobbyTopicConsumer = new flex.client.messaging.Consumer('lobby-topic');
      lobbyTopicConsumer.setChannelSet(channelSet);
      lobbyTopicConsumer.setResubscribeAttempts(500);
      lobbyTopicConsumer.setResubscribeIntervalMillis(3000);

      lobbyTopicConsumer.addEventListener(flex.client.messaging.events.MessageEvent.MESSAGE, this.lobbyMessageHandler);
      lobbyTopicConsumer.addEventListener(flex.client.messaging.events.MessageFaultEvent.FAULT,
        function (faultEvent) {
          console.log("lobbyTopicConsumer " + faultEvent.getError().faultString);
        }
      );
      lobbyTopicConsumer.addEventListener(flex.client.messaging.events.MessageAckEvent.ACKNOWLEDGE,
        function (ackEvent) {

        }
      );
    }

    // Create RemoteObject
    remoteObject = new flex.client.rpc.remoting.RemoteObject('processEndpoint');

    // Set its ChannelSet
    remoteObject.setChannelSet(channelSet);
    remoteObject.setRequestTimeoutMillis(15000);

    // Setup Result and Fault Event listeners
    remoteObject.addEventListener(flex.client.rpc.events.ResultEvent.RESULT,
      (resultEvent) => {
        this.handleRemoteObject(resultEvent);
        //NProgress.done();
      });

    remoteObject.addEventListener(flex.client.rpc.events.FaultEvent.FAULT,
      (faultEvent) => {
        this.handleRemoteObjectFault(faultEvent);
        //NProgress.done();
      });

    unsecuredObject = new flex.client.rpc.remoting.RemoteObject('unsecuredEndpoint');
    unsecuredObject.setChannelSet(channelSet);
    unsecuredObject.setRequestTimeoutMillis(15000);

    // Setup Result and Fault Event listeners
    unsecuredObject.addEventListener(flex.client.rpc.events.ResultEvent.RESULT,
      (resultEvent) => {
        this.handleRemoteObject(resultEvent);
      });

    unsecuredObject.addEventListener(flex.client.rpc.events.FaultEvent.FAULT,
      (faultEvent) => {
        this.handleRemoteObjectFault(faultEvent);
      });

    if (overrideConnectionType == "websocket" && reuseSession == false) {
      this.initPushDS(reuseSession);
    } else {
      console.log("Not re-initializing websocket push");
    }
  }

  initPushDS = (reuseSession) => {
    console.log('Initializing DataServices WebSocket PUSH. Reuse session = ' + reuseSession);

    connectedPush = false;
    cashierTopicConsumer = null;
    gameTopicConsumer = null;
    lobbyTopicConsumer = null;
    amfChannelPush = null;
    channelSetPush = null;

    amfChannelPush = new flex.client.channels.Channel(
      'my-nio-amf-websocket',
      api,
      null);
    amfChannelPush.setConnectTimeoutMillis(3000);
    amfChannelPush.setRequestTimeoutMillis(3000);

    channelSetPush = new flex.client.channels.advanced.AdvancedChannelSet([amfChannelPush]);
    channelSetPush.setHeartbeatIntervalMillis(5000);

    channelSetPush.addEventListener(flex.client.channels.events.ChannelEvent.CONNECT,
      (channelEvent) => {
        console.log('**** WS CONNECT () ****', channelEvent);
        if (channelEvent.isConnected()) {
          connectedPush = true;
        }
      });

    channelSetPush.addEventListener(flex.client.channels.events.ChannelEvent.DISCONNECT,
      (channelEvent) => {
        connectedPush = false;
        console.log('**** WS DISCONNECTED (RETRY ' + connected + ') ****', channelEvent);
      });

    channelSetPush.addEventListener(flex.client.channels.events.ChannelFaultEvent.FAULT,
      (channelEvent) => {
        connectedPush = false;
        console.log('**** WS FAULTED () ****', channelEvent);
        this.initPushDS(true);
      });

    cashierTopicConsumer = new flex.client.messaging.Consumer('cashier-topic');
    cashierTopicConsumer.setChannelSet(channelSetPush);
    cashierTopicConsumer.setResubscribeAttempts(0);
    //cashierTopicConsumer.setResubscribeIntervalMillis(3000);

    lobbyTopicConsumer = new flex.client.messaging.Consumer('lobby-topic');
    lobbyTopicConsumer.setChannelSet(channelSetPush);
    lobbyTopicConsumer.setResubscribeAttempts(0);
    //lobbyTopicConsumer.setResubscribeIntervalMillis(3000);

    lobbyTopicConsumer.addEventListener(flex.client.messaging.events.MessageEvent.MESSAGE, this.lobbyMessageHandler);
    lobbyTopicConsumer.addEventListener(flex.client.messaging.events.MessageFaultEvent.FAULT,
      function (faultEvent) {

      }
    );
    lobbyTopicConsumer.addEventListener(flex.client.messaging.events.MessageAckEvent.ACKNOWLEDGE,
      function (ackEvent) {

      }
    );

    if (reuseSession) {
      this.subscribeLobby();
      this.subscribeCashier();
    }
  }

  purgeGameList = () => {
    if (connected && Object.keys(gameListSnap).length != 0) {
      this.reduxStore.dispatch({ type: 'GAME_UPDATE_GLOBAL', data: gameListSnap });
      gameListSnap = {};
    }
  }

  attemptReconnect = () => {
    this.pingme();
    if (!connected) {
      setTimeout(this.attemptReconnect(), 5000);
    }
  }

  pingCycle = () => {
    if (connected == false) {
      if (this.reduxStore) {
        this.reduxStore.dispatch({ type: 'SERVER_CONNECTING' });
      }
      pingStartTime = new Date().getTime();
      this.pingme();
    }

    setTimeout(() => {
      console.log("Ping cycle run!");
      this.pingCycle()
    }, 5000);
  }

  setStore = (store) => {
    this.reduxStore = store;
  };

  pingme = () => new Promise((resolve, reject) => {
    console.log('pinging');
    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('ping');
    } else {
      unsecuredObject.invoke('ping');
    }
    currentResolve.ping = resolve;
    currentReject = reject;
  });

  login = (user, password, rememberMe) => new Promise((resolve, reject) => {
    const customUser = user + "#" + window.mid + "#" + window.clientIP;

    // this is a workaround a bug in DS where when login fails, the authenticated flag gets set anyway
    // we re-initialize the entire DS structure again for a 'clean' start
    if (channelSet.isAuthenticated()) {
      this.initDS(false);
    }

    if (channelSet.isAuthenticated()) {
      console.log('channelset already authenticated');
      let responder1 = new flex.client.rpc.AsyncResponder(
        (data) => {
          this.login(customUser, password, rememberMe);
        }
      );
      let token1 = channelSet.logout();
      token1.addResponder(responder1);
    } else {
      let token = channelSet.login(customUser, password);
      let responder = new flex.client.rpc.AsyncResponder(
        function result(data) {
          if (data.qb == 'success') {
            sessionStorage.setItem('auth_username', customUser.split('#')[0]);
            let pass = encryptString(password);
            sessionStorage.setItem('auth_password', pass);

            if (sessionStorage.getItem('live')) {
              sessionStorage.removeItem('live');
              window.switchMain = false;
            }
            loggedIn = true;
            resolve(data);
          } else {
            console.log('login reject', data);
            console.log(reject);
            reject(data);
          }
        },
        function fault(info) {
          console.log('login fault', info);
          reject(info);
        },
        token);
      token.addResponder(responder);
    }
  });

  loginWhitelabel = (sessionId) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getWhitelabelCredentials', sessionId, mid + "");
    currentResolve.getWhitelabelCredentials = resolve;
    currentReject = reject;
  });

  logout = () => new Promise((resolve, reject) => {
    sessionStorage.removeItem('auth_username');
    sessionStorage.removeItem('auth_password');
    sessionStorage.removeItem('sessionStartTime');
    sessionStorage.removeItem('lastRealityCheckTime');

    try {
      if (apiType == 1) {
        lobbyTopicConsumer.unsubscribe();
        cashierTopicConsumer.unsubscribe();
      } else
        if (apiType == 2) {
          lobbyTopicConsumer.unsubscribe();
          cashierTopicConsumer.unsubscribe();
        }
    } catch (e) {

    }

    try {
      let token = channelSet.logout();

      token.addResponder(new flex.client.rpc.AsyncResponder(
        function result(data) {
          if (websocket == true || data.qb == 'success') {
            this.loggedIn = false;
            resolve(data);
          }
        },
        function fault(info) {
          channelSet.disconnect();
          reject(info);
        },
        token));
    } catch (e) {
      console.log(e);
    }
  });

  loadPlayer = () => new Promise((resolve, reject) => {

    let req = new InitRequestDto();

    if (sessionStorage.getItem('refStr')) {
      req.refStr = sessionStorage.getItem('refStr');
    }
    req.filter = new GameFilterDto();
    req.filter.memberId = mid;
    req.filter.languageId = window.localStorage.getItem("language");

    console.log(req);

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('init', req);
    } else {
      unsecuredObject.invoke('init', req);
    }

    currentResolve.init = resolve;
    currentReject = reject;
  });

  loadGames = (filter) => new Promise((resolve, reject) => {

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getFilteredGames', filter);
    } else {
      unsecuredObject.invoke('getFilteredGames', filter);
    }

    currentResolve.getFilteredGames = resolve;
    currentReject = reject;
  });

  loadGameTypes = (language) => new Promise((resolve, reject) => {

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGameTypes', mid + "", language);
    } else {
      unsecuredObject.invoke('getGameTypes', mid + "", language);
    }

    currentResolve.getGameTypes = resolve;
    currentReject = reject;
  });

  loadGameTypeInstances = (language, page, gameId, gameInstanceId, dateStartedFrom, dateEndedTo) => new Promise((resolve, reject) => {

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGameTypeInstances', mid + "", language, page, gameId, gameInstanceId, dateStartedFrom, dateEndedTo);
    } else {
      unsecuredObject.invoke('getGameTypeInstances', mid + "", language, page, gameId, gameInstanceId, dateStartedFrom, dateEndedTo);
    }

    currentResolve.getGameTypeInstances = resolve;
    currentReject = reject;
  });

  loadAllGameTypeInstances = (language, gameId) => new Promise((resolve, reject) => {
    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getAllGameTypeInstances', mid + "", language, gameId);
    } else {
      unsecuredObject.invoke('getAllGameTypeInstances', mid + "", language, gameId);
    }
    currentResolve.getAllGameTypeInstances = resolve;
    currentReject = reject;
  });

  getGamesCostPerMonth = (games) => new Promise((resolve, reject) => {

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGamesCostPerMonth', games);
    } else {
      unsecuredObject.invoke('getGamesCostPerMonth', games);
    }

    currentResolve.getGamesCostPerMonth = resolve;
    currentReject = reject;
  });

  getGamesCostPerMonthAndSchedule = (games) => new Promise((resolve, reject) => {

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGamesCostPerMonthAndSchedule', games);
    } else {
      unsecuredObject.invoke('getGamesCostPerMonthAndSchedule', games);
    }

    currentResolve.getGamesCostPerMonthAndSchedule = resolve;
    currentReject = reject;
  });

  trackReferralCode = (refStr) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('trackReferralCode', refStr);
    currentResolve.trackReferralCode = resolve;
    currentReject = reject;
  });

  getGameLobby = (gameInstanceId) => new Promise((resolve, reject) => {
    const languageToUse = window.localStorage.getItem("language") || "en_US";

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGameLobby', gameInstanceId + "", languageToUse);
    } else {
      unsecuredObject.invoke('getGameLobby', gameInstanceId + "", languageToUse);
    }

    currentResolve.getGameLobby = resolve;
    currentReject = reject;
  });

  getBonoboPayout = (gameInstanceId, gameId, tournament) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getBonoboPayout', gameInstanceId, gameId, tournament);
    currentResolve.getBonoboPayout = resolve;
    currentReject = reject;
  });

  getPlayerAccounts = () => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getPlayerAccounts');
    currentResolve.getPlayerAccounts = resolve;
    currentReject = reject;
  });

  getPlayerPrizeItems = () => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getPlayerPrizeItems');
    currentResolve.getPlayerPrizeItems = resolve;
    currentReject = reject;
  });

  getPlayerPrizeItemAgents = (id) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getPlayerPrizeItemAgents', id);
    currentResolve.getPlayerPrizeItemAgents = resolve;
    currentReject = reject;
  });

  confirmEmail = (token) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('submitEmailConfirmation', token);
    currentResolve.submitEmailConfirmation = resolve;
    currentReject = reject;
  });

  checkTicketStatus = (ticket, code) => new Promise((resolve, reject) => {

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('checkTicketStatus', ticket, code);
    } else {
      unsecuredObject.invoke('checkTicketStatus', ticket + '', code + '');
    }

    currentResolve.checkTicketStatus = resolve;
    currentReject = reject;
  });

  checkUsername = (username) => new Promise((resolve, reject) => {
    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });
    unsecuredObject.invoke('checkUsername', username + "#" + window.mid);
    currentResolve.checkUsername = resolve;
    currentReject = reject;
  });

  checkEmail = (email) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('checkEmail', email + "#" + window.mid);
    currentResolve.checkEmail = resolve;
    currentReject = reject;
  });

  checkPhone = (phone) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('checkPhone', phone + "#" + window.mid);
    currentResolve.checkPhone = resolve;
    currentReject = reject;
  });

  checkGovernmentID = (governmentId) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('checkGovernmentID', governmentId + "#" + window.mid);
    currentResolve.checkGovernmentID = resolve;
    currentReject = reject;
  });

  checkUsernameWithActiveSubscription = (username) => new Promise((resolve, reject) => {
    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });
    unsecuredObject.invoke('checkUsernameWithActiveSubscription', username);
    currentResolve.checkUsernameWithActiveSubscription = resolve;
    currentReject = reject;
  });

  checkEmailWithActiveSubscription = (email) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('checkEmailWithActiveSubscription', email + "#" + window.mid);
    currentResolve.checkEmailWithActiveSubscription = resolve;
    currentReject = reject;
  });

  getPlayerGameSubscriptionPlays = (subscriptionId) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getPlayerGameSubscriptionPlays', subscriptionId);
    currentResolve.getPlayerGameSubscriptionPlays = resolve;
    currentReject = reject;
  });

  checkPassword = (password) => new Promise((resolve, reject) => {
    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('checkPassword', password);
    } else {
      unsecuredObject.invoke('checkPassword', password);
    }
    currentResolve.checkPassword = resolve;
    currentReject = reject;
  });

  requestResetPW = (usernameorpassword) => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    unsecuredObject.invoke('requestResetPassword', usernameorpassword);
    currentResolve.requestResetPassword = resolve;
    currentReject = reject;
  });

  resetPassword = (key, password) => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    unsecuredObject.invoke('resetPassword', key, password);
    currentResolve.resetPassword = resolve;
    currentReject = reject;
  });

  requestEmailConfirmation = () => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    unsecuredObject.invoke('requestEmailConfirmation');
    currentResolve.requestEmailConfirmation = resolve;
    currentReject = reject;
  });

  requestIdVerification = () => new Promise((resolve, reject) => {

    remoteObject.invoke('requestIdVerification');
    currentResolve.requestIdVerification = resolve;
    currentReject = reject;
  });

  playerRegistration = (user) => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    unsecuredObject.invoke('playerRegistration', user);
    currentResolve.playerRegistration = resolve;
    currentReject = reject;
  });

  playerRegistrationFull = (user) => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    remoteObject.invoke('playerRegistrationFull', user);
    currentResolve.playerRegistrationFull = resolve;
    currentReject = reject;
  });

  getStateProvincesForCountry = (country) => new Promise((resolve, reject) => {


    remoteObject.invoke('getStateProvincesForCountry', country);
    currentResolve.getStateProvincesForCountry = resolve;
    currentReject = reject;
  });

  purchaseTicket = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('purchaseTicket', request);
    currentResolve.purchaseTicket = resolve;
    currentReject = reject;
  });

  trackPendingJoin = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('trackPendingJoin', request);
    currentResolve.trackPendingJoin = resolve;
    currentReject = reject;
  });

  registerAndLoginBeforeDeposit = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('registerAndLoginBeforeDeposit', request);
    currentResolve.registerAndLoginBeforeDeposit = resolve;
    currentReject = reject;
  });

  registerAndTrackPendingJoin = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('registerAndTrackPendingJoin', request);
    currentResolve.registerAndTrackPendingJoin = resolve;
    currentReject = reject;
  });

  checkDepositRequest = (orderId, playerId) => new Promise((resolve, reject) => {
    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('checkDepositRequest', orderId, playerId);
    } else {
      unsecuredObject.invoke('checkDepositRequest', orderId, playerId);
    }
    currentResolve.checkDepositRequest = resolve;
    currentReject = reject;
  });

  joinGame = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('joinGame', request);
    currentResolve.joinGame = resolve;
    currentReject = reject;
  });

  arePlaysAvailableForGame = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('arePlaysAvailableForGame', request);
    currentResolve.arePlaysAvailableForGame = resolve;
    currentReject = reject;
  });


  joinGamePerm = (request) => new Promise((resolve, reject) => {
    remoteObject.invoke('joinGamePerm', request);
    currentResolve.joinGamePerm = resolve;
    currentReject = reject;
  });

  joinGameCancel = gameInstanceId => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: "SHOW_PROCESSING" });

    remoteObject.invoke("joinGameCancel", gameInstanceId);
    currentResolve.joinGameCancel = resolve;
    currentReject = reject;
  });

  getGameResults = (gameInstanceId, justMe) => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGameResults', gameInstanceId, justMe);
    } else {
      unsecuredObject.invoke('getGameResults', gameInstanceId, false);
    }

    currentResolve.getGameResults = resolve;
    currentReject = reject;
  });

  getCountryCodeByIp = (ip) => new Promise((resolve, reject) => {
    remoteObject.invoke('getCountryCodeByIp', ip);
    currentResolve.getCountryCodeByIp = resolve;
    currentReject = reject;
  });

  getCountry = (countryId) => new Promise((resolve, reject) => {
    remoteObject.invoke('getCountry', countryId);
    currentResolve.getCountry = resolve;
    currentReject = reject;
  });

  saveFavoriteGame = (gameId) => new Promise((resolve, reject) => {
    remoteObject.invoke('saveFavoriteGame', gameId);
    currentResolve.saveFavoriteGame = resolve;
    currentReject = reject;
  });

  deleteFavoriteGame = (id) => new Promise((resolve, reject) => {
    remoteObject.invoke('deleteFavoriteGame', id);
    currentResolve.deleteFavoriteGame = resolve;
    currentReject = reject;
  });

  checkPostalCode = (postalCode, countryId) => new Promise((resolve, reject) => {
    remoteObject.invoke('checkPostalCode', postalCode, countryId);
    currentResolve.checkPostalCode = resolve;
    currentReject = reject;
  });

  getAddressByPostalCode = (postalCode, countryCode) => new Promise((resolve, reject) => {
    remoteObject.invoke('getAddressByPostalCode', postalCode, countryCode);
    currentResolve.getAddressByPostalCode = resolve;
    currentReject = reject;
  });

  getAddressByPostalCodeEncoded = (postalCode, countryCode) => new Promise((resolve, reject) => {
    remoteObject.invoke('getAddressByPostalCodeEncoded', postalCode, countryCode);
    currentResolve.getAddressByPostalCodeEncoded = resolve;
    currentReject = reject;
  });

  getGamePlayers = (gameInstanceId) => new Promise((resolve, reject) => {

    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGamePlayers', gameInstanceId);
    } else {
      unsecuredObject.invoke('getGamePlayers', gameInstanceId);
    }

    currentResolve.getGamePlayers = resolve;
    currentReject = reject;
  });

  getGamePayout = (gameInstanceId, gameId, tournament) => new Promise((resolve, reject) => {
    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getGamePayoutInfo', gameInstanceId, gameId, tournament);
    } else {
      unsecuredObject.invoke('getGamePayoutInfo', gameInstanceId, gameId, tournament);
    }

    currentResolve.getGamePayoutInfo = resolve;
    currentReject = reject;
  });

  searchGameHistory = (search) => new Promise((resolve, reject) => {
    remoteObject.invoke('searchGameHistory', search);
    currentResolve.searchGameHistory = resolve;
    currentReject = reject;
  });

  getGameHistoryDetail = (gameInstanceId) => new Promise((resolve, reject) => {
    remoteObject.invoke('getGameHistoryDetail', gameInstanceId);
    currentResolve.getGameHistoryDetail = resolve;
    currentReject = reject;
  });

  isSubscriptionActive = (gameId) => new Promise((resolve, reject) => {
    remoteObject.invoke('isSubscriptionActive', gameId);
    currentResolve.isSubscriptionActive = resolve;
    currentReject = reject;
  });

  getPlayerGameSubscriptions = (search) => new Promise((resolve, reject) => {
    remoteObject.invoke('getPlayerGameSubscriptions', search);
    currentResolve.getPlayerGameSubscriptions = resolve;
    currentReject = reject;
  });

  getPayGoGameSubscriptionDetails = (subscriptionId) => new Promise((resolve, reject) => {

    remoteObject.invoke('getPayGoGameSubscriptionDetails', subscriptionId);

    currentResolve.getPayGoGameSubscriptionDetails = resolve;
    currentReject = reject;
  });

  searchPayGoSubscriptionTransactions = (search) => new Promise((resolve, reject) => {

    remoteObject.invoke('searchPayGoSubscriptionTransactions', search);

    currentResolve.searchPayGoSubscriptionTransactions = resolve;
    currentReject = reject;
  });

  removePlayerGameSubscription = (id) => new Promise((resolve, reject) => {

    remoteObject.invoke('removePlayerGameSubscription', id);

    currentResolve.removePlayerGameSubscription = resolve;
    currentReject = reject;
  });

  getCountries = () => new Promise((resolve, reject) => {

    remoteObject.invoke('getCountries', window.mid);

    currentResolve.getCountries = resolve;
    currentReject = reject;
  });

  updateEmail = (email) => new Promise((resolve, reject) => {

    remoteObject.invoke('updatePlayerEmail', email);

    currentResolve.updatePlayerEmail = resolve;
    currentReject = reject;
  });

  updatePhones = (phone, mobile) => new Promise((resolve, reject) => {

    remoteObject.invoke('updatePlayerPhones', phone, mobile);

    currentResolve.updatePlayerPhones = resolve;
    currentReject = reject;
  });

  updateAddress = (address) => new Promise((resolve, reject) => {

    remoteObject.invoke('updatePlayerAddress', address);

    currentResolve.updatePlayerAddress = resolve;
    currentReject = reject;
  });

  updateLanguage = (lang) => new Promise((resolve, reject) => {

    remoteObject.invoke('updatePlayerLocale', lang);

    currentResolve.updatePlayerLocale = resolve;
    currentReject = reject;
  });

  updatePassword = (oldPassword, newPassword) => new Promise((resolve, reject) => {

    remoteObject.invoke('changePassword', oldPassword, newPassword);

    currentResolve.changePassword = resolve;
    currentReject = reject;
  });

  changePassword = (newPassword) => new Promise((resolve, reject) => {

    remoteObject.invoke('changePassword2', newPassword);

    currentResolve.changePassword2 = resolve;
    currentReject = reject;
  });

  updateNotifications = (preferences) => new Promise((resolve, reject) => {

    remoteObject.invoke('updateContactPreference', preferences);

    currentResolve.updateContactPreference = resolve;
    currentReject = reject;
  });

  updateSelfLimits = (settings) => new Promise((resolve, reject) => {

    remoteObject.invoke('updateSelfLimits', settings);

    currentResolve.updateSelfLimits = resolve;
    currentReject = reject;
  });

  updateSelfExclusion = (selfExclusionDate) => new Promise((resolve, reject) => {

    remoteObject.invoke('updateSelfExclusion', selfExclusionDate);

    currentResolve.updateSelfExclusion = resolve;
    currentReject = reject;
  });

  updateTimeout = (timeoutDate) => new Promise((resolve, reject) => {

    remoteObject.invoke('updateTimeout', timeoutDate);

    currentResolve.updateTimeout = resolve;
    currentReject = reject;
  });

  getReferralsReport = () => new Promise((resolve, reject) => {

    remoteObject.invoke('getReferralsReport');

    currentResolve.getReferralsReport = resolve;
    currentReject = reject;
  });

  sendPlayerInvite = (email, customText) => new Promise((resolve, reject) => {

    remoteObject.invoke('sendPlayerInvite', email, customText);

    currentResolve.sendPlayerInvite = resolve;
    currentReject = reject;
  });

  checkToken = (key) => new Promise((resolve, reject) => {

    unsecuredObject.invoke('checkToken', key);

    currentResolve.checkToken = resolve;
    currentReject = reject;
  });

  getOfflineEvents = () => new Promise((resolve, reject) => {

    unsecuredObject.invoke('getOfflineEvents');

    currentResolve.getOfflineEvents = resolve;
    currentReject = reject;
  });

  getDepositRequests = () => new Promise((resolve, reject) => {

    remoteObject.invoke('getDepositRequests');

    currentResolve.getDepositRequests = resolve;
    currentReject = reject;
  });

  cancelDepositRequest = (orderId) => new Promise((resolve, reject) => {

    remoteObject.invoke('cancelDepositRequest', orderId);

    currentResolve.cancelDepositRequest = resolve;
    currentReject = reject;
  });


  getPendingWithdrawals = () => new Promise((resolve, reject) => {

    remoteObject.invoke('getPendingWithdrawals');

    currentResolve.getPendingWithdrawals = resolve;
    currentReject = reject;
  });

  searchTransactions = (currencyId, days, maxResults, page) => new Promise((resolve, reject) => {

    remoteObject.invoke('searchTransactions', currencyId, days, maxResults, page);

    currentResolve.searchTransactions = resolve;
    currentReject = reject;
  });

  getBanksList = (paymentMethodOptionId) => new Promise((resolve, reject) => {
    remoteObject.invoke('getBanksList', paymentMethodOptionId);

    currentResolve.getBanksList = resolve;
    currentReject = reject;
  });

  getDepositPopup = (deposit) => new Promise((resolve, reject) => {
    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('getDepositPopup', deposit);
    } else {
      unsecuredObject.invoke('getDepositPopup', deposit);
    }

    currentResolve.getDepositPopup = resolve;
    currentReject = reject;
  });

  getDepositStep = () => new Promise((resolve, reject) => {
    remoteObject.invoke('getDepositStep');
    currentResolve.getDepositStep = resolve;
    currentReject = reject;
  });

  directDebitRequest = (deposit) => new Promise((resolve, reject) => {
    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('directDebitRequest', deposit);
    } else {
      unsecuredObject.invoke('directDebitRequest', deposit);
    }

    currentResolve.directDebitRequest = resolve;
    currentReject = reject;
  });

  depositRequestInfo = (deposit) => new Promise((resolve, reject) => {
    this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' });

    if (channelSet.isAuthenticated()) {
      remoteObject.invoke('depositRequestInfo', deposit);
    } else {
      unsecuredObject.invoke('depositRequestInfo', deposit);
    }

    currentResolve.depositRequestInfo = resolve;
    currentReject = reject;
  });

  currencyConversionV2 = (fromCurrency, toCurrency, amount, inverse) =>
    new Promise((resolve, reject) => {


      let req = new InitRequestDto();
      req.fromCurrency = fromCurrency;
      req.toCurrency = toCurrency;
      req.amount = amount;

      currentResolve.currencyConversionV2 = resolve;
      currentReject = reject;

      remoteObject.invoke('currencyConversionV2', fromCurrency, toCurrency, amount, inverse);
    });

  getWithdrawalStep = (payment) => new Promise((resolve, reject) => {
    remoteObject.invoke('getWithdrawalStep', payment);
    currentResolve.getWithdrawalStep = resolve;
    currentReject = reject;
  });

  getWithdrawalStep2 = (payment) => new Promise((resolve, reject) => {
    remoteObject.invoke('getWithdrawalStep2', payment);
    currentResolve.getWithdrawalStep2 = resolve;
    currentReject = reject;
  });

  payoutSubmit = (payment) => new Promise((resolve, reject) => {
    remoteObject.invoke('payoutSubmit', payment);
    currentResolve.payoutSubmit = resolve;
    currentReject = reject;
  });

  payoutCancel = (orderId, submitIp) => new Promise((resolve, reject) => {
    remoteObject.invoke('payoutCancel', orderId, submitIp);
    currentResolve.payoutCancel = resolve;
    currentReject = reject;
  });

  payoutListRecurringDetails = (payment) => new Promise((resolve, reject) => {
    remoteObject.invoke('payoutListRecurringDetails', payment);
    currentResolve.payoutListRecurringDetails = resolve;
    currentReject = reject;
  });

  requestPhoneValidation = (phoneType) => new Promise((resolve, reject) => {
    remoteObject.invoke('requestPhoneValidation', phoneType);
    currentResolve.requestPhoneValidation = resolve;
    currentReject = reject;
  });

  submitPhoneValidation = (code) => new Promise((resolve, reject) => {
    remoteObject.invoke('submitPhoneValidation', code);
    currentResolve.submitPhoneValidation = resolve;
    currentReject = reject;
  });

  createSupportCase = (playerId, memberId, email, name, message, lang) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('createSupportCase', playerId, memberId, email, name, message, lang);
    currentResolve.createSupportCase = resolve;
    currentReject = reject;
  });

  getGameLastWinningDraws = (gameId, gameInstanceId) => new Promise((resolve, reject) => {
    const languageToUse = window.localStorage.getItem("language") || "en_US";
    unsecuredObject.invoke('getGameLastWinningDraws', gameId + "", gameInstanceId != null ? gameInstanceId + "" : null, languageToUse);
    currentResolve.getGameLastWinningDraws = resolve;
    currentReject = reject;
  });

  claimInGameBonus = (bonusId, gameInstanceId, gameId, playId, uid) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('claimInGameBonusNew', bonusId, gameInstanceId, gameId, playId, uid);
    currentResolve.claimInGameBonusNew = resolve;
    currentReject = reject;
  });

  hasPlayerGameSubscriptions = () => new Promise((resolve, reject) => {
    unsecuredObject.invoke('hasPlayerGameSubscriptions');
    currentResolve.hasPlayerGameSubscriptions = resolve;
    currentReject = reject;
  });

  getSubscriptionDates = (paymentMethodOptionId, campaignCode) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getSubscriptionDates', paymentMethodOptionId + '', campaignCode + '');
    currentResolve.getSubscriptionDates = resolve;
    currentReject = reject;
  });

  getSubscriptionPromotion = (campaignCode, games) => new Promise((resolve, reject) => {
    const languageToUse = window.localStorage.getItem("language") || "en_US";
    unsecuredObject.invoke('getSubscriptionPromotion', campaignCode, languageToUse, games, window.mid);
    currentResolve.getSubscriptionPromotion = resolve;
    currentReject = reject;
  });

  sendEmailSubscriptionDetails = (email) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('sendEmailSubscriptionDetails', email);
    currentResolve.sendEmailSubscriptionDetails = resolve;
    currentReject = reject;
  });

  getMainAds = (clientType, screenSize) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getMainAds', clientType, screenSize, window.mid);
    currentResolve.getMainAds = resolve;
    currentReject = reject;
  });

  getCampaignAds = (clientType, screenSize, campaignCode) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getCampaignAds', clientType, screenSize, window.mid, campaignCode);
    currentResolve.getCampaignAds = resolve;
    currentReject = reject;
  });

  validateCoupon = (depositAmount, currencyId, couponCode) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('validateCoupon', depositAmount, currencyId, couponCode);
    currentResolve.validateCoupon = resolve;
    currentReject = reject;
  });

  getDropdownItems = (type, languageId) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getDropdownItems', type, window.mid, languageId);
    currentResolve.getDropdownItems = resolve;
    currentReject = reject;
  });

  confirmCrosswordEnd = (gameInstanceId) => new Promise((resolve, reject) => {
    //this.reduxStore.dispatch({ type: 'SHOW_PROCESSING' })
    unsecuredObject.invoke('confirmCrosswordEnd', gameInstanceId);
    currentResolve.confirmCrosswordEnd = resolve;
    currentReject = reject;
  });

  getPlayerAccountBalance = (currencyId) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getPlayerAccountBalance', currencyId);
    currentResolve.getPlayerAccountBalance = resolve;
    currentReject = reject;
  });

  getLastVerificationRequest = () => new Promise((resolve, reject) => {
    unsecuredObject.invoke('getLastVerificationRequest');
    currentResolve.getLastVerificationRequest = resolve;
    currentReject = reject;
  });

  postVerificationRequestReply = (noteText) => new Promise((resolve, reject) => {
    unsecuredObject.invoke('postVerificationRequestReply', noteText);
    currentResolve.postVerificationRequestReply = resolve;
    currentReject = reject;
  });

  postVerificationRequestSubmit = () => new Promise((resolve, reject) => {
    unsecuredObject.invoke('postVerificationRequestSubmit');
    currentResolve.postVerificationRequestSubmit = resolve;
    currentReject = reject;
  });

  postPendingForm = (id, emailAddress, formType, formIndex, formData, formCompleted) => new Promise((resolve, reject) => {
    let request = new PendingForm();
    request.id = id;
    request.emailAddress = emailAddress;
    request.formType = formType;
    request.formIndex = formIndex;
    request.formData = formData;
    request.formCompleted = formCompleted;
    request.memberId = window.mid;

    unsecuredObject.invoke('postPendingForm', request);

    currentResolve.postPendingForm = resolve;
    currentReject = reject;
  });

  checkMaximumWagers = () => new Promise((resolve, reject) => {
    unsecuredObject.invoke('checkMaximumWagers');
    currentResolve.checkMaximumWagers = resolve;
    currentReject = reject;
  });

  cashierMessageHandler = (messageEvent) => {

  };

  lobbyMessageHandler = (messageEvent) => {

    let jsonEvent;
    if (apiType == 1) {
      const message = messageEvent.getMessage();
      jsonEvent = JSON.parse(JSON.stringify(message.getBody()));
    } else
      if (apiType == 2) {
        //jsonEvent = JSON.parse(messageEvent.body);
        //jsonEvent._CLASS = '';
        const message = messageEvent.getMessage();
        jsonEvent = JSON.parse(JSON.stringify(message.getBody()));
      }

    // Game Events
    if (jsonEvent._CLASS.indexOf('bonobo.desktop.vo.game') > -1 || jsonEvent.eventType == 0) {
      this.handleGameEvent(jsonEvent);
    } else
      if (jsonEvent._CLASS.indexOf('bonobo.desktop.vo.player.events') > -1 || jsonEvent.eventType == 1) {
        this.handlePlayerEvent(jsonEvent);
      } else
        if (jsonEvent._CLASS.indexOf('bonobo.desktop.vo.player.notifications') > -1 || jsonEvent.eventType == 3) {
          this.handlePlayerNotification(jsonEvent);
        } else
          if (jsonEvent._CLASS.indexOf('bonobo.desktop.vo.client') > -1 || jsonEvent.eventType == 2) {
            this.handleClientEvent(jsonEvent);
          }
    //NProgress.done();
  };

  handleRemoteObject(resultEvent) {
    //NProgress.done();
    if (resultEvent.target.name != 'ping') {
      this.reduxStore.dispatch({ type: 'HIDE_PROCESSING' });
    }
    var result = resultEvent.getResult();

    if (resultEvent.target.name === 'init') {
      try {
        this.subscribeLobby();

        if (channelSet.isAuthenticated() && result && result.player) {
          internalPlayerId = result.player.id;
          this.subscribeCashier();
        }
      } catch (e) {
        console.log(e);
      }

      currentResolve.init(result);
    } else if (resultEvent.target.name === 'getWhitelabelCredentials') {
      currentResolve.getWhitelabelCredentials(result);
    } else if (resultEvent.target.name === 'getFilteredGames') {
      currentResolve.getFilteredGames(result);
    } else if (resultEvent.target.name === 'getPlayerAccounts') {
      currentResolve.getPlayerAccounts(result);
    } else if (resultEvent.target.name === 'getPlayerPrizeItems') {
      currentResolve.getPlayerPrizeItems(result);
    } else if (resultEvent.target.name === 'getPlayerPrizeItemAgents') {
      currentResolve.getPlayerPrizeItemAgents(result);
    } else if (resultEvent.target.name === 'trackReferralCode') {
      currentResolve.trackReferralCode(result);
    } else if (resultEvent.target.name === 'getGameLobby') {
      this.subscribeGameLobby(result.gameDetails.gameInstanceid);
      currentResolve.getGameLobby(result);
    } else if (resultEvent.target.name === 'purchaseTicket') {
      if (result.exitCode == 0) {
        currentResolve.purchaseTicket(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'registerAndLoginBeforeDeposit') {
      if (result != -1) {
        currentResolve.registerAndLoginBeforeDeposit(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'registerAndTrackPendingJoin') {
      if (result != -1) {
        currentResolve.registerAndTrackPendingJoin(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'trackPendingJoin') {
      if (result != -1) {
        currentResolve.trackPendingJoin(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'joinGame') {
      if (result.exitCode == 0) {
        currentResolve.joinGame(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'joinGamePerm') {
      if (result.exitCode == 0) {
        currentResolve.joinGamePerm(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === "checkDepositRequest") {
      currentResolve.checkDepositRequest(result);
    } else if (resultEvent.target.name === "arePlaysAvailableForGame") {
      currentResolve.arePlaysAvailableForGame(result);
    } else if (resultEvent.target.name === "currencyConversionV2") {
      currentResolve.currencyConversionV2(result);
    } else if (resultEvent.target.name === "joinGameCancel") {
      currentResolve.joinGameCancel(result);
    } else if (resultEvent.target.name === 'getGameResults') {
      currentResolve.getGameResults(result);
    } else if (resultEvent.target.name === 'getGamePlayers') {
      currentResolve.getGamePlayers(result);
    } else if (resultEvent.target.name === 'getGamePayoutInfo') {
      currentResolve.getGamePayoutInfo(result);
    } else if (resultEvent.target.name === 'getBonoboPayout') {
      currentResolve.getBonoboPayout(result);
    } else if (resultEvent.target.name === 'searchGameHistory') {
      currentResolve.searchGameHistory(result);
    } else if (resultEvent.target.name === 'getGameHistoryDetail') {
      currentResolve.getGameHistoryDetail(result);
    } else if (resultEvent.target.name === 'isSubscriptionActive') {
      currentResolve.isSubscriptionActive(result);
    } else if (resultEvent.target.name === 'getPlayerGameSubscriptions') {
      currentResolve.getPlayerGameSubscriptions(result);
    } else if (resultEvent.target.name === 'getPayGoGameSubscriptionDetails') {
      currentResolve.getPayGoGameSubscriptionDetails(result);
    } else if (resultEvent.target.name === 'searchPayGoSubscriptionTransactions') {
      currentResolve.searchPayGoSubscriptionTransactions(result);
    } else if (resultEvent.target.name === 'removePlayerGameSubscription') {
      currentResolve.removePlayerGameSubscription(result);
    } else if (resultEvent.target.name === 'getCountries') {
      currentResolve.getCountries(result);
    } else if (resultEvent.target.name === 'getStateProvincesForCountry') {
      currentResolve.getStateProvincesForCountry(result);
    } else if (resultEvent.target.name === 'playerRegistration') {
      currentResolve.playerRegistration(result);
    } else if (resultEvent.target.name === 'playerRegistrationFull') {
      currentResolve.playerRegistrationFull(result);
    } else if (resultEvent.target.name === 'ping') {
      if (window.serverTimeDelta == -1) {
        serverTime = resultEvent.qb;

        var currentTime = new Date().getTime();
        var serverLatency = currentTime - pingStartTime;
        currentTime = currentTime + (serverLatency);

        //console.log('Server Time', new Date(serverTime));
        //console.log('Client Time', new Date(currentTime));

        //var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
        //  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        //console.log('UTC Time', new Date(utc_timestamp));

        window.serverTimeDelta = serverTime - currentTime;
        console.log('Delta Time', window.serverTimeDelta + 'ms');

        //showInfoMessage('Connected to ' + siteName + ' servers!');
      } else {
        this.reduxStore.dispatch({ type: 'SERVER_CONNECTED' });
        console.log("ping success (" + window.serverTimeDelta + ")");
      }
      currentResolve.ping(result);
    } else if (resultEvent.target.name === 'checkUsername') {
      currentResolve.checkUsername(result);
    } else if (resultEvent.target.name === 'checkTicketStatus') {
      currentResolve.checkTicketStatus(result);
    } else if (resultEvent.target.name === 'checkEmail') {
      currentResolve.checkEmail(result);
    } else if (resultEvent.target.name === 'checkPhone') {
      currentResolve.checkPhone(result);
    } else if (resultEvent.target.name === 'checkGovernmentID') {
      currentResolve.checkGovernmentID(result);
    } else if (resultEvent.target.name === 'checkPassword') {
      if (result == "1") {
        currentResolve.checkPassword(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'checkEmailWithActiveSubscription') {
      currentResolve.checkEmailWithActiveSubscription(result);
    } else if (resultEvent.target.name === 'getPlayerGameSubscriptionPlays') {
      currentResolve.getPlayerGameSubscriptionPlays(result);
    } else if (resultEvent.target.name === 'checkUsernameWithActiveSubscription') {
      currentResolve.checkUsernameWithActiveSubscription(result);
    } else if (resultEvent.target.name === 'requestResetPassword') {
      currentResolve.requestResetPassword(result);
    } else if (resultEvent.target.name === 'resetPassword') {
      currentResolve.resetPassword(result);
    } else if (resultEvent.target.name === 'getCountryCodeByIp') {
      currentResolve.getCountryCodeByIp(result);
    } else if (resultEvent.target.name === 'checkPostalCode') {
      currentResolve.checkPostalCode(result);
    } else if (resultEvent.target.name === 'getAddressByPostalCode') {
      currentResolve.getAddressByPostalCode(result);
    } else if (resultEvent.target.name === 'getAddressByPostalCodeEncoded') {
      currentResolve.getAddressByPostalCodeEncoded(result);
    } else if (resultEvent.target.name === 'submitEmailConfirmation') {
      if (result == true) {
        currentResolve.submitEmailConfirmation(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'requestEmailConfirmation') {
      currentResolve.requestEmailConfirmation(result);
    } else if (resultEvent.target.name === 'requestIdVerification') {
      currentResolve.requestIdVerification(result);
    } else if (resultEvent.target.name === 'saveFavoriteGame') {
      currentResolve.saveFavoriteGame(result);
    } else if (resultEvent.target.name === 'deleteFavoriteGame') {
      currentResolve.deleteFavoriteGame(result);
    } else if (resultEvent.target.name === 'updatePlayerEmail') {
      currentResolve.updatePlayerEmail(result);
    } else if (resultEvent.target.name === 'updatePlayerPhones') {
      currentResolve.updatePlayerPhones(result);
    } else if (resultEvent.target.name === 'updatePlayerAddress') {
      currentResolve.updatePlayerAddress(result);
    } else if (resultEvent.target.name === 'updatePlayerLocale') {
      currentResolve.updatePlayerLocale(result);
    } else if (resultEvent.target.name === 'changePassword') {
      if (result == true) {
        currentResolve.changePassword(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'changePassword2') {
      currentResolve.changePassword2(result);
    } else if (resultEvent.target.name === 'updateContactPreference') {
      currentResolve.updateContactPreference(result);
    } else if (resultEvent.target.name === 'updateSelfLimits') {
      if (result == 'success') {
        currentResolve.updateSelfLimits(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'updateSelfExclusion') {
      if (result == 'success') {
        currentResolve.updateSelfExclusion(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'updateTimeout') {
      if (result == 'success') {
        currentResolve.updateTimeout(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'getReferralsReport') {
      currentResolve.getReferralsReport(result);
    } else if (resultEvent.target.name === 'sendPlayerInvite') {
      if (result == true) {
        currentResolve.sendPlayerInvite(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'checkToken') {
      if (result == true) {
        currentResolve.checkToken(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'getOfflineEvents') {
      currentResolve.getOfflineEvents(result);
    } else if (resultEvent.target.name === 'getDepositRequests') {
      currentResolve.getDepositRequests(result);
    } else if (resultEvent.target.name === 'cancelDepositRequest') {
      currentResolve.cancelDepositRequest(result);

    } else if (resultEvent.target.name === 'getPendingWithdrawals') {
      currentResolve.getPendingWithdrawals(result);
    } else if (resultEvent.target.name === 'searchTransactions') {
      currentResolve.searchTransactions(result);
    } else if (resultEvent.target.name === 'getBanksList') {
      currentResolve.getBanksList(result);
    } else if (resultEvent.target.name === 'getDepositPopup') {
      currentResolve.getDepositPopup(result);
    } else if (resultEvent.target.name === 'directDebitRequest') {
      currentResolve.directDebitRequest(result);
    } else if (resultEvent.target.name === 'depositRequestInfo') {
      currentResolve.depositRequestInfo(result);
    } else if (resultEvent.target.name === 'getDepositStep') {
      currentResolve.getDepositStep(result);
    } else if (resultEvent.target.name === 'getWithdrawalStep') {
      currentResolve.getWithdrawalStep(result);
    } else if (resultEvent.target.name === 'getWithdrawalStep2') {
      currentResolve.getWithdrawalStep2(result);
    } else if (resultEvent.target.name === 'payoutSubmit') {
      if (result == true || result?.success == true) {
        currentResolve.payoutSubmit(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'payoutCancel') {
      if (result == true) {
        currentResolve.payoutCancel(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'payoutListRecurringDetails') {
      currentResolve.payoutListRecurringDetails(result);
    } else if (resultEvent.target.name === 'requestPhoneValidation') {
      if (result.indexOf("#") > -1) {
        currentResolve.requestPhoneValidation(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'submitPhoneValidation') {
      if (result == '1') {
        currentResolve.submitPhoneValidation(result);
      } else {
        currentReject(result);
      }
    } else if (resultEvent.target.name === 'createSupportCase') {
      currentResolve.createSupportCase(result);
    } else if (resultEvent.target.name === 'getGameLastWinningDraws') {
      currentResolve.getGameLastWinningDraws(result);
    } else if (resultEvent.target.name === 'claimInGameBonusNew') {
      currentResolve.claimInGameBonusNew(result);
    } else if (resultEvent.target.name === 'getGameTypes') {
      currentResolve.getGameTypes(result);
    } else if (resultEvent.target.name === 'getGameTypeInstances') {
      currentResolve.getGameTypeInstances(result);
    } else if (resultEvent.target.name === 'getAllGameTypeInstances') {
      currentResolve.getAllGameTypeInstances(result);
    } else if (resultEvent.target.name === 'getGamesCostPerMonth') {
      currentResolve.getGamesCostPerMonth(result);
    } else if (resultEvent.target.name === 'getGamesCostPerMonthAndSchedule') {
      currentResolve.getGamesCostPerMonthAndSchedule(result);
    } else if (resultEvent.target.name === 'getSubscriptionDates') {
      currentResolve.getSubscriptionDates(result);
    } else if (resultEvent.target.name === 'getSubscriptionPromotion') {
      currentResolve.getSubscriptionPromotion(result);
    } else if (resultEvent.target.name === 'sendEmailSubscriptionDetails') {
      currentResolve.sendEmailSubscriptionDetails(result);
    } else if (resultEvent.target.name === 'getMainAds') {
      currentResolve.getMainAds(result);
    } else if (resultEvent.target.name === 'getCampaignAds') {
      currentResolve.getCampaignAds(result);
    } else if (resultEvent.target.name === 'validateCoupon') {
      currentResolve.validateCoupon(result);
    } else if (resultEvent.target.name === 'getDropdownItems') {
      currentResolve.getDropdownItems(result);
    } else if (resultEvent.target.name === 'confirmCrosswordEnd') {
      currentResolve.confirmCrosswordEnd(result);
    } else if (resultEvent.target.name === 'getPlayerAccountBalance') {
      currentResolve.getPlayerAccountBalance(result);
    } else if (resultEvent.target.name === 'getLastVerificationRequest') {
      currentResolve.getLastVerificationRequest(result);
    } else if (resultEvent.target.name === 'postVerificationRequestReply') {
      currentResolve.postVerificationRequestReply(result);
    } else if (resultEvent.target.name === 'postVerificationRequestSubmit') {
      currentResolve.postVerificationRequestSubmit(result);
    } else if (resultEvent.target.name === 'postPendingForm') {
      currentResolve.postPendingForm(result);
    } else if (resultEvent.target.name === 'hasPlayerGameSubscriptions') {
      currentResolve.hasPlayerGameSubscriptions(result);
    } else if (resultEvent.target.name === 'checkMaximumWagers') {
      currentResolve.checkMaximumWagers(result);
    }
  }

  handleRemoteObjectFault(faultEvent) {
    this.reduxStore.dispatch({ type: 'HIDE_PROCESSING' });
    console.log("errrrooorrr ");
    console.log(faultEvent);

    trackEvent('dsFaultEvent', faultEvent);

    if (faultEvent.Vc) {
      if (faultEvent.Vc == "34") {
        /*if (window.location.href.indexOf('crossword') > -1 || window.location.href.indexOf('scratchcard') > -1 || window.location.href.indexOf('bingo') > -1) {
          console.log('logged out, reload url ' +  window.location.href);
          window.location.reload();
        } else {
          showMetroDialog('Session Timeout', "You were signed out due to inactivity. Please click OK to log back in.", '[OK#btn-info]',
            function () {
              window.location.href = siteRoot;
            });
        }*/
      } else
        if (faultEvent.Vc == "101") {
          this.reduxStore.dispatch({ type: 'SHOW_RECONNECTING' });
          setTimeout(function () {
            window.location.href = siteRoot;
          }, 3000);
        }
    }

    if (faultEvent.target.name == "getGameResults") {

    } else
      if (faultEvent.target.name == "joinGame" || faultEvent.target.name == "joinGamePerm") {
        if (faultEvent.Xc.indexOf('BulkheadFullException') > -1) {
          //this.reduxStore.dispatch({ type: 'JOIN_GAME_FAIL_RETRY', error: faultEvent });
        }
        this.reduxStore.dispatch({ type: 'JOIN_GAME_FAIL', error: faultEvent });
      } else
        if (faultEvent.Vc && (faultEvent.Vc == "34" ||
          faultEvent.Vc == 'Client.Error.MessageSend' ||
          faultEvent.Vc == 'Client.Error.RequestTimeout' ||
          faultEvent.Vc == 'Channel.Connect.Failed' ||
          faultEvent.Vc == 'Server.Processing.DuplicateSessionDetected')) {
          setTimeout(() => {
            this.initDS(true);
          }, 1000);
          this.reduxStore.dispatch({ type: 'HIDE_PROCESSING' });
          this.reduxStore.dispatch({ type: 'SHOW_RECONNECTING' });
        }

    if (currentReject)
      currentReject(faultEvent);
  }

  handleClientEvent(messageEvent) {
    if (messageEvent._CLASS === 'bonobo.desktop.vo.client.events.ClientInvalidateEvent' || messageEvent.type == 6) {
      this.reduxStore.dispatch({ type: 'PLAYER_BANNED', data: messageEvent });
    } else
      if (messageEvent._CLASS === 'bonobo.desktop.vo.client.events.ClientInvalidateEvent' || messageEvent.type == 6) {
        this.reduxStore.dispatch({ type: 'PLAYER_BANNED', data: messageEvent });
      } else
        if (messageEvent._CLASS === 'bonobo.desktop.vo.client.events.ClientLoginEvent' || messageEvent.type == 1) {
          try {
            /*if (loggedIn && messageEvent.playerId == this.reduxStore.getState().lottorace.session.player.id) {
              sessionStorage.removeItem('auth_password');
              this.logout();
              this.reduxStore.dispatch({ type: 'LOGOUT', data: messageEvent });
            }*/
          } catch (e) {

          }
        }
  }

  handlePlayerNotification(messageEvent) {
    this.reduxStore.dispatch({ type: 'PRIVATE_MESSAGE', data: messageEvent });
  }

  handlePlayerEvent(messageEvent) {
    if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerJoinsGameEvent' || messageEvent.type == 1) {
      this.reduxStore.dispatch({ type: 'PLAYER_JOIN', data: messageEvent });
    } else
      if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerBalanceUpdateEvent' || messageEvent.type == 4) {
        this.reduxStore.dispatch({ type: 'BALANCE_UPDATE', data: messageEvent });
      } else
        if (messageEvent._CLASS === 'bonobo.desktop.vo.player.notifications.SystemPlayerNotification') {
          this.reduxStore.dispatch({ type: 'PRIVATE_MESSAGE', data: messageEvent });
          //showGenericMessage("Private Message", "<h3>" + messageEvent.title + "</h3>" + messageEvent.message);
        } else
          if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerSegmentUpdateEvent' || messageEvent.type == 26) {
            this.reduxStore.dispatch({ type: 'PLAYER_SEGMENT', data: messageEvent });
          } else
            if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerEmailVerificationEvent' || messageEvent.type == 30) {
              this.reduxStore.dispatch({ type: 'PLAYER_EMAIL_VERIFICATION', data: messageEvent });
            } else
              if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerIdVerificationEvent' || messageEvent.type == 29) {
                this.reduxStore.dispatch({ type: 'PLAYER_ID_VERIFICATION', data: messageEvent });
              } else
                if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerPhoneVerifiedEvent' || messageEvent.type == 28) {
                  this.reduxStore.dispatch({ type: 'PLAYER_PHONE_VERIFICATION', data: messageEvent });
                } else
                  if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerAddressVerificationEvent' || messageEvent.type == 31) {
                    this.reduxStore.dispatch({ type: 'PLAYER_ADDRESS_VERFICATION', data: messageEvent });
                  } else
                    if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerOrderEvent' || messageEvent.type == 2) {
                      this.reduxStore.dispatch({ type: 'PLAYER_ORDER', data: messageEvent });
                    } else
                      if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerCouponAppliedEvent' || messageEvent.type == 27) {
                        this.reduxStore.dispatch({ type: 'PLAYER_COUPON', data: messageEvent });
                      } else
                        if (messageEvent._CLASS === 'bonobo.desktop.vo.player.events.PlayerReloadVerificationRequestEvent' || messageEvent.type == 33) {
                          this.reduxStore.dispatch({ type: 'PLAYER_RELOAD_VERIFICATION_REQUEST', data: messageEvent });
                        }
  }

  handleGameEvent(messageEvent) {

    if (messageEvent.memberIds && messageEvent.memberIds.length > 0) {
      if (messageEvent.memberIds.includes(mid) == false) {
        return;
      }
    } else
      if (messageEvent.memberId && messageEvent.memberId != mid) return;

    //console.log(messageEvent);

    if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameCreateEvent' || messageEvent.type == 0) {
      this.reduxStore.dispatch({ type: 'GAME_CREATE', data: messageEvent });
    } else
      if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameUpdateEvent' || messageEvent.type == 1) {
        //this.reduxStore.dispatch({type: 'GAME_UPDATE', data: messageEvent});
        this.processGameList(messageEvent);
      } else
        if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameEndEvent' || messageEvent.type == 5) {
          this.reduxStore.dispatch({ type: 'GAME_END', data: messageEvent });
        } else
          if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameFullEvent' || messageEvent.type == 10) {
            this.reduxStore.dispatch({ type: 'GAME_FULL', data: messageEvent });
          } else
            if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameReadyEvent' || messageEvent.type == 3) {
              this.reduxStore.dispatch({ type: 'GAME_READY', data: messageEvent });
            } else
              if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameStartEvent' || messageEvent.type == 4) {
                this.reduxStore.dispatch({ type: 'GAME_START', data: messageEvent });
              } else
                if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameDeleteEvent' || messageEvent.type == 9) {
                  this.spliceGameList(messageEvent);
                  this.reduxStore.dispatch({ type: 'GAME_DELETE', data: messageEvent });
                } else
                  if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameRegistrationStartEvent' || messageEvent.type == 7) {
                    this.reduxStore.dispatch({ type: 'GAME_REGISTRATION_START', data: messageEvent });
                  } else
                    if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameRegistrationEndEvent' || messageEvent.type == 8) {
                      this.reduxStore.dispatch({ type: 'GAME_REGISTRATION_END', data: messageEvent });
                    } else
                      if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameProgressiveEvent' || messageEvent.type == 6) {
                        this.reduxStore.dispatch({ type: 'GAME_PROGRESSIVE', data: messageEvent });
                      } else
                        if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameRefundedEvent' || messageEvent.type == 11) {
                          this.reduxStore.dispatch({ type: 'GAME_REFUNDED', data: messageEvent });
                        } else
                          if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameRescheduledEvent' || messageEvent.type == 12) {
                            this.reduxStore.dispatch({ type: 'GAME_RESCHEDULED', data: messageEvent });
                          } else
                            if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameFailRetryEvent' || messageEvent.type == 14) {
                              this.reduxStore.dispatch({ type: 'GAME_FAIL_RETRY', data: messageEvent });
                            } else
                              if (messageEvent._CLASS === 'bonobo.desktop.vo.game.events.GameMaintenanceEvent' || messageEvent.type == 15) {
                                console.log(messageEvent);
                                this.reduxStore.dispatch({ type: 'GAME_MAINTENANCE', data: messageEvent });
                              }
  }

  subscribeLobby = () => {
    if (apiType == 1) {
      if (!lobbyTopicConsumer.isSubscribed()) {
        if (typeof lobbySubtopicEnabled !== 'undefined' && lobbySubtopicEnabled == true) {
          lobbyTopicConsumer.setSubtopic(mid + '');
        }
        lobbyTopicConsumer.subscribe();
      }
    } else
      if (apiType == 2) {
        if (!lobbyTopicConsumer.isSubscribed()) {
          if (typeof lobbySubtopicEnabled !== 'undefined' && lobbySubtopicEnabled == true) {
            lobbyTopicConsumer.setSubtopic(mid + '');
          }
          lobbyTopicConsumer.subscribe();
        }
      }
  };

  subscribeCashier = () => {
    if (apiType == 1) {
      if (!cashierTopicConsumer.isSubscribed()) {
        cashierTopicConsumer.setSubtopic(internalPlayerId + '');
        cashierTopicConsumer.subscribe();
      }
    } else
      if (apiType == 2) {
        if (!cashierTopicConsumer.isSubscribed()) {
          cashierTopicConsumer.setSubtopic(internalPlayerId + '');
          cashierTopicConsumer.subscribe();
        }
      }
  };

  subscribeGameLobby = (gameInstanceId) => {
    console.log("subscribeGameLobby");
    /*gameTopicConsumer.addSubscription(gameInstanceId + '');
    if (!gameTopicConsumer.isSubscribed()) {
      gameTopicConsumer.subscribe();
    }*/
  };

  unsubscribeGameLobby = (gameInstanceId) => {
    console.log("unsubscribeGameLobby");
    //gameTopicConsumer.removeSubscription(gameInstanceId + '');
  }

  waitFor = (condition, callback) => {
    if (!condition()) {
      window.setTimeout(this.waitFor.bind(null, condition, callback), 100); /* this checks the flag every 100 milliseconds*/
    } else {
      callback();
    }
  }

  processGameList = (e) => {
    if (gameListSnap[e.gameInstanceId]) {
      var upd = update(gameListSnap[e.gameInstanceId],
        {
          playsInGame: { $set: e.playsInGame },
          playersInGame: { $set: e.playersInGame },
          bonoboPayout: { $set: e.bonoboPayout },
          prizePool: { $set: e.prizePool }
        });
      gameListSnap[e.gameInstanceId] = upd;
    } else {
      gameListSnap[e.gameInstanceId] = e;
    }
  }

  spliceGameList = (e) => {
    delete gameListSnap[e.gameInstanceId];
  }
}

export default DataServicesClientV2;
