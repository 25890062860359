import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { formatMoney, getASArray } from "../../../../helpers/Utils";
import { Fragment } from "react";
import React from "react";
import { format as dateFormat } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RafflePayout = ({ currentGame, handlePrizeDetailsClick, navigate }) => {

  const getNth = (num) => {
    if (num < 0) return "";
    var rankStr = num + "";
    var lastDig = rankStr.substring(rankStr.length - 1);
    if (rankStr == "11") return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
    if (rankStr == "12") return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
    if (rankStr == "13") return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
    if (lastDig == "1") return (<span>{num}<sup><Translate id={"generic.first"} /></sup></span>);
    if (lastDig == "2") return (<span>{num}<sup><Translate id={"generic.second"} /></sup></span>);
    if (lastDig == "3") return (<span>{num}<sup><Translate id={"generic.third"} /></sup></span>);
    else return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
  }

  // console.log(getASArray(currentGame.gameLobby.gamePayoutInfo));

  return (
    <div className="row py-3">
      <div className={classNames("col-md-12")}>
        <div className="text-left">
          {getASArray(currentGame.gameLobby.gamePayoutInfo) &&
            <div id="payoutInfo" className="container-fluid raffle-payout-table">
              {getASArray(currentGame.gameLobby.gamePayoutInfo).map(function (p, i) {
                return (
                  <div key={p.rankNumber} className="card">
                    <div className="card-header" id={"heading" + i}>
                      <div className="row">
                        <div className="col-md-6 align-self-center py-4">
                          {p.prizeItem &&
                            <img src={p.prizeItem.imageUrl} className="img-fluid" />}

                          {!p.prizeItem &&
                            <img src={'/static/img/playout/cash.png'} className="img-fluid" />}
                        </div>
                        <div className="col-md-6 align-self-center">
                          {p.rankNumber == 1 &&
                            <div className="text-bigger-50"><Translate id={"payout.grand_prize"} /></div>}

                          {p.rankNumber > 1 &&
                            <div className="text-bigger-40"><Translate id={"gamelobby.raffle.payout.list.other"} data={{ rankNumber: getNth(p.rankNumber) }} /></div>}

                          {p.prizeItem &&
                            <div className="text-bigger-20">
                              <div className="font-weight-bold payoutPrizeItemName">
                                <span dangerouslySetInnerHTML={{ __html: p.prizeItem.name }} />
                              </div>
                              <div className="payoutPrizeItemDescription">
                                <span dangerouslySetInnerHTML={{ __html: p.prizeItem.description }} />
                              </div>
                              <div className="payoutPrizeItemSponsor">
                                <span dangerouslySetInnerHTML={{ __html: p.prizeItem.sponsorText }} />
                              </div>
                            </div>}

                          {!p.prizeItem &&
                            <div className="text-bigger-20 font-weight-bold">
                              <Translate id={"payout.cash_prize"} data={{ prizeAmount: formatMoney(p.rankPrize, currentGame.payoutCurrencyId) }} />
                            </div>}

                          <div>
                            <button className="btn btn-success mt-3" onClick={navigate.bind(this, '/lobby?id=' + currentGame.gameInstanceId + "&gid=" + currentGame.gameId)}>
                              <Translate id={"gamelobby.enter_now"} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <a data-toggle="collapse" data-target={"#collapse" + i} aria-expanded="true" aria-controls={"collapse" + i} className={'mb-n4'} style={{ zIndex: 1 }}>
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={"circle"} fixedWidth={true} className={'text-primary'} size={'lg'} transform="grow-6" />
                            <FontAwesomeIcon icon={"chevron-down"} fixedWidth={true} className={'text-light'} size={'lg'} transform="shrink-6" />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div id={"collapse" + i} className="collapse" aria-labelledby={"heading" + i} data-parent="#payoutInfo">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12">
                            {p.prizeItem &&
                              <div dangerouslySetInnerHTML={{ __html: p.prizeItem.detailsText }}></div>}
                            {!p.prizeItem &&
                              <div><Translate id={"payout.get_cash"} /></div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>}
        </div>
      </div>
    </div>
  )
}

export default RafflePayout;
