/* eslint-disable */
/** Copyright 2011 Adobe Systems Incorporated. All Rights Reserved. */
(function() {
  function f(b) {
    throw b;
  }
  var xAuthToken = null;

  var g = void 0,
    h = null;

  function j() {
    return function() {}
  }

  function m(b) {
    return function(c) {
      this[b] = c
    }
  }

  function n(b) {
    return function() {
      return this[b]
    }
  }
  var o, aa = this;

  function ca() {}

  function p(b, c) {
    var d = b.split("."),
      e = aa;
    !(d[0] in e) && e.execScript && e.execScript("var " + d[0]);
    for (var i; d.length && (i = d.shift());) !d.length && c !== g ? e[i] = c : e = e[i] ? e[i] : e[i] = {}
  }

  function s(b, c) {
    function d() {}
    d.prototype = c.prototype;
    b.e = c.prototype;
    b.prototype = new d
  };

  function t(b) {
    this.p = b;
    this.Jc = h
  }
  t.prototype.ec = function(b) {
    this.Jc != h && this.Jc.Ve(b)
  };
  t.prototype.dc = function(b) {
    this.xd(!0, b)
  };
  t.prototype.xd = function(b, c) {
    b ? this.p.eb(c) : this.Jc != h && this.Jc.Ic(c)
  };

  function ea(b) {
    t.call(this, b)
  }
  s(ea, t);
  ea.prototype.dc = function(b) {
    this.xd(!0, b)
  };
  ea.prototype.ec = function(b) {
    if (b.status == 200) {
      try {
        ea.e.ec.call(this, b);
        var c = this.p,
          b = c.fb(b.body)[0].data;
        c.a.z().sd(b.getHeader(u.Xa));
        b.F("DSMessagingVersion") && fa(c.a, b.getHeader("DSMessagingVersion"));
        ga(c.a)
      } catch (e) {
        this.dc("Connect attempt failed. Server returned an invalid response!")
      }
    } else this.dc("Connect attempt failed. Server returned Http status code: " + b.status)
  };

  function ha(b) {
    this.cd = b
  }
  ha.prototype.cc = n("cd");
  p("flex.messaging.events.Event", ha);
  p("flex.messaging.events.Event.prototype.getType", ha.prototype.cc);

  function v(b, c) {
    this.cd = b;
    this.k = c
  }
  s(v, ha);

  function ia(b) {
    return new v("message", b)
  }
  v.prototype.Nd = n("k");
  p("flex.client.messaging.events.MessageEvent", v);
  p("flex.client.messaging.events.MessageEvent.MESSAGE", "message");
  p("flex.client.messaging.events.MessageEvent.prototype.getMessage", v.prototype.Nd);

  function w(b, c, d) {
    v.call(this, b, d);
    this.tc = c
  }
  s(w, v);
  w.prototype.Zb = j();
  w.prototype.og = n("tc");
  p("flex.client.rpc.events.AbstractEvent", w);
  p("flex.client.rpc.events.AbstractEvent.prototype.callTokenResponders", w.prototype.Zb);
  p("flex.client.rpc.events.AbstractEvent.prototype.getToken", w.prototype.og);

  function x(b, c) {
    c < 0 && f(new RangeError(b + " cannot be less than zero."))
  }

  function ja(b, c) {
    y(c) || f(Error(b + " cannot be 'undefined' or 'null'."))
  }

  function y(b) {
    return b != h && b != g
  }

  function ka(b) {
    if (!y(b)) return h;
    for (var c = [], d = 0; d < b.length; d++) c.push(b.charCodeAt(d));
    return c
  }

  function la(b, c) {
    for (var d in b)
      if (b[d] === c) return d;
    return h
  }

  function ma(b) {
    if (!y(b)) return 0;
    var c = 0,
      d;
    for (d in b) b.hasOwnProperty(d) && c++;
    return c
  }

  function na(b) {
    if (typeof b == "string") return b;
    else if (typeof b.Td == "function") return b = b.Td(), typeof b == "string" ? b : hashObject(b);
    else if (b instanceof Array) return oa(b);
    else if (typeof b.toString == "function") return b.toString();
    else try {
        return String(b)
      } catch (c) {
        return Object.prototype.toString.call(b)
      }
  }

  function oa(b) {
    for (var c = "", d = 0; d < b.length; d++) {
      d != 0 && (c += ",");
      var e = b[d];
      c += window.ActiveXObject && e instanceof ActiveXObject && e.xml != g ? e.xml : window.Document && e instanceof Document && window.DOMParser ? (new XMLSerializer).serializeToString(e) : e instanceof Array ? oa(e) : e
    }
    return c
  }
  p("flex.client.util.ClientUtil", j());
  p("flex.client.util.ClientUtil.blockNegativeValue", x);
  p("flex.client.util.ClientUtil.blockUninitialized", ja);
  p("flex.client.util.ClientUtil.isInitialized", y);
  p("flex.client.util.ClientUtil.getBytes", ka);
  p("flex.client.util.ClientUtil.getEnumString", la);
  p("flex.client.util.ClientUtil.getLength", ma);

  function z() {
    this.T = {}
  }
  o = z.prototype;
  o.addEventListener = function(b, c) {
    typeof b != "string" && f(Error("'eventType' is not of type String."));
    typeof this.T[b] == "undefined" && (this.T[b] = []);
    this.T[b].push(c)
  };
  o.sf = function(b, c) {
    typeof b != "string" && f(Error("'eventType' is not of type String."));
    c instanceof Array || f(Error("'listeners' is not of instance of Array."));
    for (var d = 0; d < c.length; d++) this.addEventListener(b, c[d])
  };
  o.dispatchEvent = function(b) {
    if (!b.target) b.target = this;
    y(b.cc()) || f(Error("Event object does not have 'type' property set."));
    if (this.T[b.cc()] instanceof Array)
      for (var c = this.T[b.cc()], d = 0, e = c.length; d < e; d++) c[d].call(this, b)
  };
  o.tg = function(b) {
    b = this.T[b];
    return b instanceof Array && b.length > 0
  };
  o.removeEventListener = function(b, c) {
    if (this.T[b] instanceof Array)
      for (var d = this.T[b], e = 0, i = d.length; e < i; e++)
        if (d[e] === c) {
          d.splice(e, 1);
          break
        }
  };
  o.Nf = function(b) {
    b = this.T[b];
    return typeof b == "undefined" ? [] : b
  };
  p("flex.messaging.events.EventDispatcher", z);
  z.prototype.addEventListener = z.prototype.addEventListener;
  z.prototype.addEventListeners = z.prototype.sf;
  z.prototype.dispatchEvent = z.prototype.dispatchEvent;
  z.prototype.getEventListeners = z.prototype.Nf;
  z.prototype.hasEventListener = z.prototype.tg;
  z.prototype.removeEventListener = z.prototype.removeEventListener;

  function pa(b, c, d) {
    this.Xe = b;
    this.Oe = c;
    this.cf = d
  }
  pa.prototype.result = function(b) {
    this.Xe != h && this.Xe(b, this.cf)
  };
  pa.prototype.j = function(b) {
    this.Oe != h && this.Oe(b, this.cf)
  };
  p("flex.client.rpc.AsyncResponder", pa);

  function qa() {}
  p("flex.client.events.PropertyChangeEventKind", qa);
  qa.DELETE = "delete";
  qa.UPDATE = "update";

  function ra(b, c, d, e, i, k) {
    this.cd = b;
    this.of = c;
    this.rf = d;
    this.qf = e;
    this.pf = i;
    this.bd = k
  }
  s(ra, ha);

  function A(b, c, d, e) {
    return new ra("propertyChange", "update", c, d, e, b)
  }
  o = ra.prototype;
  o.Yf = n("of");
  o.gd = n("rf");
  o.ag = n("qf");
  o.$f = n("pf");
  o.bc = n("bd");
  p("flex.client.events.PropertyChangeEvent", ra);
  p("flex.client.events.PropertyChangeEvent.PROPERTY_CHANGE", "propertyChange");
  p("flex.client.events.PropertyChangeEvent.createUpdateEvent", A);
  p("flex.client.events.PropertyChangeEvent.prototype.getKind", ra.prototype.Yf);
  p("flex.client.events.PropertyChangeEvent.prototype.getProperty", ra.prototype.gd);
  p("flex.client.events.PropertyChangeEvent.prototype.getOldValue", ra.prototype.ag);
  p("flex.client.events.PropertyChangeEvent.prototype.getNewValue", ra.prototype.$f);
  p("flex.client.events.PropertyChangeEvent.prototype.getSource", ra.prototype.bc);
  var ta = sa();

  function sa() {
    return function(b) {
      function c() {
        var b = 2091639 * d + k * 2.3283064365386963E-10;
        d = e;
        e = i;
        return i = b - (k = b | 0)
      }
      var d = 0,
        e = 0,
        i = 0,
        k = 1;
      b.length == 0 && (b = [+new Date]);
      for (var l = ua(), d = l(" "), e = l(" "), i = l(" "), r = 0; r < b.length; r++) d -= l(b[r]), d < 0 && (d += 1), e -= l(b[r]), e < 0 && (e += 1), i -= l(b[r]), i < 0 && (i += 1);
      l = h;
      c.Ph = function() {
        return c() * 4294967296
      };
      c.Nh = function() {
        return c() + (c() * 2097152 | 0) * 1.1102230246251565E-16
      };
      c.version = "Alea 0.9";
      c.Ih = b;
      return c
    }(Array.prototype.slice.call(arguments))
  }

  function ua() {
    function b(b) {
      for (var b = b.toString(), e = 0; e < b.length; e++) {
        c += b.charCodeAt(e);
        var i = 0.02519603282416938 * c;
        c = i >>> 0;
        i -= c;
        i *= c;
        c = i >>> 0;
        i -= c;
        c += i * 4294967296
      }
      return (c >>> 0) * 2.3283064365386963E-10
    }
    var c = 4022871197;
    b.version = "Mash 0.9";
    return b
  };

  function va() {}
  var xa = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"];

  function ya() {
    return za() + za() + "-" + za() + "-" + za() + "-" + za() + "-" + za() + za() + za()
  }

  function Aa(b) {
    if (b == h || b.length != 16) return h;
    for (var c = "", d = 0; d < 16; d++) {
      if (d == 4 || d == 6 || d == 8 || d == 10) c += "-";
      c += xa[(b[d] & 240) >>> 4];
      c += xa[b[d] & 15]
    }
    return c.toString()
  }

  function Ba(b) {
    if (!Ca(b)) return h;
    for (var c = Array(16), d = 0, e = 0; e < b.length; e++)
      if (b.charAt(e) != "-") {
        var i = Da(b.charAt(e));
        e++;
        var k = Da(b.charAt(e));
        c[d++] = (i << 4 | k) & 255
      }
    return c
  }

  function Da(b) {
    var c = b.charCodeAt(0);
    if (c >= 0)
      if (c <= 57) return c - 48;
      else if (c >= 65 && c <= 70) return c - 65 + 10;
    f(Error("UUIDUtils#getCharValue: Invalid UUID character [" + b + "] passed"))
  }

  function Ca(b) {
    if (b == h || b.length != 36) return !1;
    for (var c = 0; c < 36; c++) {
      var d = b.charAt(c),
        e = b.charCodeAt(c);
      if (c == 8 || c == 13 || c == 18 || c == 23) {
        if (d != "-") return !1
      } else if (e < 48 || e > 70 || e > 57 && e < 65) return !1
    }
    return !0
  }

  function za() {
    return ((1 + ta()) * 65536 | 0).toString(16).substring(1).toUpperCase()
  }
  p("flex.messaging.util.UUIDUtils", va);
  va.createUUID = ya;
  va.isUID = Ca;
  var Ea = {};

  function u() {}
  u.Xa = "DSId";
  u.gf = "DSDstClientId";
  u.Ce = "DSEndpoint";
  u.lf = "DSValidateEndpoint";
  u.Hb = "DSPriority";
  u.ff = 4;
  u.De = "DSRemoteCredentials";
  u.hf = "DSRemoteCredentialsCharset";
  u.Oc = "DSRequestTimeoutMillis";
  u.kf = "sync";
  u.jf = "DSStatusCode";
  o = u.prototype;
  o.S = j();
  o.G = j();
  o.$b = j();
  o.$ = j();
  o.h = j();
  o.ic = j();
  o.Sd = j();
  o.he = j();
  o.Rd = j();
  o.ge = j();
  o.u = j();
  o.Na = j();
  o.pa = j();
  o.td = j();
  o.getHeader = j();
  o.g = j();
  o.F = j();
  p("flex.messaging.messages.Message", u);
  p("flex.messaging.messages.Message._FLEX_CLIENT_ID_HEADER", u.Xa);
  p("flex.messaging.messages.Message._DESTINATION_CLIENT_ID_HEADER", u.gf);
  p("flex.messaging.messages.Message._ENDPOINT_HEADER", u.Ce);
  p("flex.messaging.messages.Message._VALIDATE_ENDPOINT_HEADER", u.lf);
  p("flex.messaging.messages.Message._PRIORITY_HEADER", u.Hb);
  p("flex.messaging.messages.Message._DEFAULT_PRIORITY", u.ff);
  p("flex.messaging.messages.Message._REMOTE_CREDENTIALS_HEADER", u.De);
  p("flex.messaging.messages.Message._REMOTE_CREDENTIALS_CHARSET_HEADER", u.hf);
  p("flex.messaging.messages.Message._REQUEST_TIMEOUT_MILLIS_HEADER", u.Oc);
  p("flex.messaging.messages.Message._SYNC_HEADER", u.kf);
  p("flex.messaging.messages.Message._STATUS_CODE_HEADER", u.jf);
  p("flex.messaging.messages.Message.prototype.getClientId", u.prototype.S);
  p("flex.messaging.messages.Message.prototype.setClientId", u.prototype.G);
  p("flex.messaging.messages.Message.prototype.getDestination", u.prototype.$b);
  p("flex.messaging.messages.Message.prototype.setDestination", u.prototype.$);
  p("flex.messaging.messages.Message.prototype.getMessageId", u.prototype.h);
  p("flex.messaging.messages.Message.prototype.setMessageId", u.prototype.ic);
  p("flex.messaging.messages.Message.prototype.getTimestamp", u.prototype.Sd);
  p("flex.messaging.messages.Message.prototype.setTimestamp", u.prototype.he);
  p("flex.messaging.messages.Message.prototype.getTimeToLive", u.prototype.Rd);
  p("flex.messaging.messages.Message.prototype.setTimeToLive", u.prototype.ge);
  p("flex.messaging.messages.Message.prototype.getBody", u.prototype.u);
  p("flex.messaging.messages.Message.prototype.setBody", u.prototype.Na);
  p("flex.messaging.messages.Message.prototype.getHeaders", u.prototype.pa);
  p("flex.messaging.messages.Message.prototype.setHeaders", u.prototype.td);
  p("flex.messaging.messages.Message.prototype.getHeader", u.prototype.getHeader);
  p("flex.messaging.messages.Message.prototype.setHeader", u.prototype.g);
  p("flex.messaging.messages.Message.prototype.headerExists", u.prototype.F);

  function B() {
    this.messageId = this.destination = this.clientId = h;
    this.timeToLive = this.timestamp = 0;
    this._messageIdBytes = this._clientIdBytes = this.body = this.headers = h
  }
  o = B.prototype;
  o.u = n("body");
  o.Na = m("body");
  o.S = n("clientId");
  o.G = function(b) {
    this.clientId = b;
    this._clientIdBytes = h
  };
  o.$b = n("destination");
  o.$ = m("destination");
  o.h = function() {
    this.messageId == h && (this.messageId = ya());
    return this.messageId
  };
  o.ic = function(b) {
    this.messageId = b;
    this._messageIdBytes = h
  };
  o.Sd = n("timestamp");
  o.he = m("timestamp");
  o.Rd = n("timeToLive");
  o.ge = m("timeToLive");
  o.pa = function() {
    this.headers == h && (this.headers = {});
    return this.headers
  };
  o.td = function(b) {
    if (b != h)
      for (var c in b) this.g(c, b[c])
  };
  o.getHeader = function(b) {
    return this.headers != h ? this.headers[b] : h
  };
  o.g = function(b, c) {
    this.headers == h && (this.headers = {});
    c == h ? delete this.headers[b] : this.headers[b] = c
  };
  o.F = function(b) {
    return this.headers != h && this.headers[b] != h
  };
  o.Qa = function(b) {
    for (var c = Fa(b), d = 0; d < c.length; d++) {
      var e = c[d],
        i = 0;
      if (d == 0)(e & 1) != 0 && (this.body = b.n()), (e & 2) != 0 && (this.clientId = b.n()), (e & 4) != 0 && (this.destination = b.n()), (e & 8) != 0 && (this.headers = b.n()), (e & 16) != 0 && (this.messageId = String(b.n())), (e & 32) != 0 && (this.timestamp = Number(b.n())), (e & 64) != 0 && (this.timeToLive = Number(b.n())), i = 7;
      else if (d == 1) {
        if ((e & 1) != 0) this._clientIdBytes = b.n(), this.clientId = Aa(this._clientIdBytes);
        if ((e & 2) != 0) this._messageIdBytes = b.n(), this.messageId = Aa(this._messageIdBytes);
        i = 2
      }
      if (e >> i != 0)
        for (; i < 6; i++)(e >> i & 1) != 0 && b.n()
    }
  };
  o.Ra = function(b) {
    var c = 0;
    this._clientIdBytes == h && this.clientId != h && this.clientId instanceof String && (this._clientIdBytes = Ba(this.clientId));
    this._messageIdBytes == h && this.messageId != h && (this._messageIdBytes = Ba(this.messageId));
    this.body != h && (c |= 1);
    this.clientId != h && this._clientIdBytes == h && (c |= 2);
    this.destination != h && (c |= 4);
    this.headers != h && (c |= 8);
    this.messageId != h && this._messageIdBytes == h && (c |= 16);
    this.timestamp != 0 && (c |= 32);
    this.timeToLive != 0 && (c |= 64);
    if (this._clientIdBytes != h || this._messageIdBytes !=
      h) c |= 128;
    b.i(c);
    c = 0;
    this._clientIdBytes != h && (c |= 1);
    this._messageIdBytes != h && (c |= 2);
    c != 0 && b.i(c);
    this.body != h && b.L(this.body);
    this.clientId != h && this._clientIdBytes == h && b.L(this.clientId);
    this.destination != h && b.L(this.destination);
    this.headers != h && b.L(this.headers);
    this.messageId != h && this._messageIdBytes == h && b.L(this.messageId);
    this.timestamp != 0 && b.L(this.timestamp);
    this.timeToLive != 0 && b.L(Number(this.timeToLive));
    this._clientIdBytes != h && Ga(b, this._clientIdBytes);
    this._messageIdBytes != h && Ga(b, this._messageIdBytes)
  };

  function Fa(b) {
    for (var c = !0, d = Array(2), e = 0; c;) {
      c = b.Te();
      if (e == d.length) var i = new Array[e * 2],
        d = i = d.slice(0);
      d[e] = c;
      c = (c & 128) != 0;
      e++
    }
    return d
  }
  p("flex.messaging.messages.AbstractMessage", B);
  p("flex.messaging.messages.AbstractMessage.prototype.getBody", B.prototype.u);
  p("flex.messaging.messages.AbstractMessage.prototype.setBody", B.prototype.Na);
  p("flex.messaging.messages.AbstractMessage.prototype.getClientId", B.prototype.S);
  p("flex.messaging.messages.AbstractMessage.prototype.setClientId", B.prototype.G);
  p("flex.messaging.messages.AbstractMessage.prototype.getDestination", B.prototype.$b);
  p("flex.messaging.messages.AbstractMessage.prototype.setDestination", B.prototype.$);
  p("flex.messaging.messages.AbstractMessage.prototype.getHeader", B.prototype.getHeader);
  p("flex.messaging.messages.AbstractMessage.prototype.setHeader", B.prototype.g);
  p("flex.messaging.messages.AbstractMessage.prototype.getHeaders", B.prototype.pa);
  p("flex.messaging.messages.AbstractMessage.prototype.setHeaders", B.prototype.td);
  p("flex.messaging.messages.AbstractMessage.prototype.headerExists", B.prototype.F);
  p("flex.messaging.messages.AbstractMessage.prototype.getMessageId", B.prototype.h);
  p("flex.messaging.messages.AbstractMessage.prototype.setMessageId", B.prototype.ic);
  p("flex.messaging.messages.AbstractMessage.prototype.getTimestamp", B.prototype.Sd);
  p("flex.messaging.messages.AbstractMessage.prototype.setTimestamp", B.prototype.he);
  p("flex.messaging.messages.AbstractMessage.prototype.getTimeToLive", B.prototype.ge);
  p("flex.messaging.messages.AbstractMessage.prototype.setTimeToLive", B.prototype.Rd);

  function Ha() {}
  Ha.prototype.qa = j();
  p("flex.messaging.messages.SmallMessage", Ha);
  p("flex.messaging.messages.SmallMessage.prototype.getSmallMessage", Ha.prototype.qa);

  function C() {
    B.call(this);
    this._CLASS = "flex.messaging.messages.AsyncMessage";
    this._correlationIdBytes = this.correlationId = h
  }
  s(C, B);
  o = C.prototype;
  o.ea = n("correlationId");
  o.Ca = function(b) {
    this.correlationId = b;
    this._correlationIdBytes = h
  };
  o.qa = function() {
    return this._CLASS == "flex.messaging.messages.AsyncMessage" ? new Ia(this) : h
  };
  o.Qa = function(b) {
    C.e.Qa.call(this, b);
    for (var c = Fa(b), d = 0; d < c.length; d++) {
      var e = c[d],
        i = 0;
      if (d == 0) {
        (e & 1) != 0 && (this.correlationId = b.n());
        if ((e & 2) != 0) this._correlationIdBytes = b.n(), this.correlationId = Aa(this._correlationIdBytes);
        i = 2
      }
      if (e >> i != 0)
        for (; i < 6; i++)(e >> i & 1) != 0 && b.n()
    }
  };
  o.Ra = function(b) {
    C.e.Ra.call(this, b);
    this._correlationIdBytes == h && this.correlationId != h && (this._correlationIdBytes = Ba(this.correlationId));
    var c = 0;
    this.correlationId != h && this._correlationIdBytes == h && (c |= 1);
    this._correlationIdBytes != h && (c |= 2);
    b.i(c);
    this.correlationId != h && this._correlationIdBytes == h && b.L(this.correlationId);
    this._correlationIdBytes != h && Ga(b, this._correlationIdBytes)
  };
  p("flex.messaging.messages.AsyncMessage", C);
  C.prototype.getCorrelationId = C.prototype.ea;
  C.prototype.setCorrelationId = C.prototype.Ca;
  C.prototype.getSmallMessage = C.prototype.qa;

  function D(b) {
    C.call(this);
    this._CLASS = "flex.messaging.messages.CommandMessage";
    this.messageId = ya();
    this.timestamp = (new Date).valueOf();
    this.operation = b ? b : Ja
  }
  s(D, C);
  var Ja = 1E4,
    Ka = ["subscribe", "unsubscribe", "poll", "unused3", "client_sync", "client_ping", "unused6", "cluster_request", "login", "logout", "subscription_invalidate", "multi_subscribe", "disconnect", "trigger_connect"];
  o = D.prototype;
  o.A = n("operation");
  o.t = m("operation");
  o.qa = function() {
    return this.operation == 2 ? new Ma(this) : h
  };

  function Na(b) {
    if (b < 0 || b >= Ka.length) return "invalid." + b;
    return Ka[b]
  }
  o.Qa = function(b) {
    D.e.Qa.call(this, b);
    for (var c = Fa(b), d = 0; d < c.length; d++) {
      var e = c[d],
        i = 0;
      if (d == 0)(e & 1) != 0 && (this.operation = b.n()), i = 1;
      if (e >> i != 0)
        for (; i < 6; i++)(e >> i & 1) != 0 && b.n()
    }
  };
  o.Ra = function(b) {
    D.e.Ra.call(this, b);
    var c = 0;
    this.operation != 0 && (c |= 1);
    b.i(c);
    this.operation != 0 && b.L(this.operation)
  };
  p("flex.messaging.messages.CommandMessage", D);
  p("flex.messaging.messages.CommandMessage.prototype.getOperation", D.prototype.A);
  p("flex.messaging.messages.CommandMessage.prototype.setOperation", D.prototype.t);
  p("flex.messaging.messages.CommandMessage.prototype.getSmallMessage", D.prototype.qa);

  function Oa(b, c, d) {
    v.call(this, b, c);
    this.Fd = d
  }
  s(Oa, v);
  Oa.prototype.yf = n("k");
  Oa.prototype.Od = n("Fd");
  p("flex.client.messaging.events.MessageAckEvent", Oa);
  p("flex.client.messaging.events.MessageAckEvent.ACKNOWLEDGE", "acknowledge");
  p("flex.client.messaging.events.MessageAckEvent.prototype.getAck", Oa.prototype.yf);
  p("flex.client.messaging.events.MessageAckEvent.prototype.getRequest", Oa.prototype.Od);
  var Pa = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"];

  function Qa() {
    this.ca = [0, 0, 0];
    this.Yd = this.count = 0;
    this.yb = ""
  }

  function Ra(b) {
    b.yb += Pa[(b.ca[0] & 255) >> 2];
    b.yb += Pa[(b.ca[0] & 3) << 4 | (b.ca[1] & 240) >> 4];
    b.yb += b.count > 1 ? Pa[(b.ca[1] & 15) << 2 | (b.ca[2] & 192) >> 6] : "=";
    b.yb += b.count > 2 ? Pa[b.ca[2] & 63] : "=";
    if ((b.Yd += 4) == 76) b.yb += "\n", b.Yd = 0
  }

  function Sa(b, c) {
    var d, e;
    d == g && (d = 0);
    if (e == g) e = c.length;
    for (var i = d; i < d + e;) {
      b.ca[b.count] = c[i];
      b.count++;
      if (b.count == b.ca.length || d + e - i == 1) Ra(b), b.count = 0, b.ca[0] = 0, b.ca[1] = 0, b.ca[2] = 0;
      i++
    }
  }

  function Ta(b) {
    var c = b.yb;
    b.yb = "";
    return c
  }
  Qa.prototype.flush = function() {
    this.count > 0 && Ra(this);
    var b = Ta(this);
    this.Yd = this.count = 0;
    this.ca[0] = 0;
    this.ca[1] = 0;
    this.ca[2] = 0;
    return b
  };

  function Ua(b, c, d) {
    v.call(this, b, c);
    this.mf = c;
    this.Fd = d
  }
  s(Ua, v);

  function Va(b, c) {
    return new Ua("messageFault", b, c)
  }
  Ua.prototype.getError = n("mf");
  Ua.prototype.Od = n("Fd");
  p("flex.client.messaging.events.MessageFaultEvent", Ua);
  p("flex.client.messaging.events.MessageFaultEvent.FAULT", "messageFault");
  p("flex.client.messaging.events.MessageFaultEvent.prototype.getError", Ua.prototype.getError);
  p("flex.client.messaging.events.MessageFaultEvent.prototype.getRequest", Ua.prototype.Od);

  function E() {
    C.call(this);
    this._CLASS = "flex.messaging.messages.AcknowledgeMessage";
    this.messageId = ya();
    this.timestamp = (new Date).valueOf()
  }
  s(E, C);
  E.prototype.qa = function() {
    return this._CLASS == "flex.messaging.messages.AcknowledgeMessage" ? new F(this) : h
  };
  E.prototype.Qa = function(b) {
    E.e.Qa.call(this, b);
    for (var c = Fa(b), d = 0; d < c.length; d++) {
      var e = c[d];
      if (e >> 0 != 0)
        for (var i = 0; i < 6; i++)(e >> i & 1) != 0 && b.n()
    }
  };
  E.prototype.Ra = function(b) {
    E.e.Ra.call(this, b);
    b.i(0)
  };
  p("flex.messaging.messages.AcknowledgeMessage", E);
  E.prototype.getSmallMessage = E.prototype.qa;

  function G() {
    this._CLASS = "flex.messaging.messages.ErrorMessage";
    this.rootCause = this.faultDetail = this.faultString = this.faultCode = h
  }
  s(G, E);
  o = G.prototype;
  o.Aa = n("faultCode");
  o.aa = m("faultCode");
  o.La = n("faultString");
  o.ba = m("faultString");
  o.Ka = n("faultDetail");
  o.ia = m("faultDetail");
  o.Sa = n("rootCause");
  o.Ua = m("rootCause");
  o.qa = function() {
    return h
  };
  p("flex.messaging.messages.ErrorMessage", G);
  p("flex.messaging.messages.ErrorMessage.prototype.getFaultCode", G.prototype.Aa);
  p("flex.messaging.messages.ErrorMessage.prototype.settFaultCode", G.prototype.aa);
  p("flex.messaging.messages.ErrorMessage.prototype.getFaultString", G.prototype.La);
  p("flex.messaging.messages.ErrorMessage.prototype.setFaultString", G.prototype.ba);
  p("flex.messaging.messages.ErrorMessage.prototype.getFaultDetail", G.prototype.Ka);
  p("flex.messaging.messages.ErrorMessage.prototype.setFaultDetail", G.prototype.ia);
  p("flex.messaging.messages.ErrorMessage.prototype.getRootCause", G.prototype.Sa);
  p("flex.messaging.messages.ErrorMessage.prototype.setRootCause", G.prototype.Ua);

  function Wa(b) {
    this.vc = b
  }
  Wa.prototype.d = function(b) {
    Xa && window.console && window.console.log(this.vc + " " + b)
  };
  Wa.prototype.info = function(b) {
    Xa && window.console && window.console.info(this.vc + " " + b)
  };
  Wa.prototype.warn = function(b) {
    Xa && window.console && window.console.warn(this.vc + " " + b)
  };
  Wa.prototype.error = function(b) {
    Xa && window.console && window.console.error(this.vc + " " + b)
  };
  var Xa = !1,
    Ya = {};

  function Za(b) {
    Ya[b] || (Ya[b] = new Wa(b));
    return Ya[b]
  }
  p("flex.client.log.Log", j());
  p("flex.client.log.Log.getEnabled", function() {
    return Xa
  });
  p("flex.client.log.Log.setEnabled", function(b) {
    Xa = b
  });

  function H(b) {
    this.T = {};
    ja("destinationId", b);
    this.q = h;
    this.M = !1;
    this.Lb = this.Kb = this.ib = h;
    this.Mb = b;
    this.Nb = !1;
    this.c = h;
    this.b = Za(this.fd());
    this.rc = "";
    this.ya = $a;
    this.sc = !1;
    this.f = ab
  }
  s(H, z);
  var $a = 0,
    bb = {},
    ab = 0;
  o = H.prototype;
  o.Dc = n("M");
  o.gb = function(b, c) {
    if (this.M != b) {
      var d = A(this, "authenticated", this.M, b);
      if ((this.M = b) && this.Lb != h && this.Lb != c) {
        var e = new G;
        e.aa("Client.Authentication.Error");
        e.ba("Credentials specified do not match those used on underlying connection.");
        e.ia("Channel was authenticated with a different set of credentials than those used for this agent.");
        this.dispatchEvent(new Ea.Gh(this, e, h))
      }
      this.dispatchEvent(d)
    }
  };
  o.z = n("q");
  o.rd = function(b) {
    if (this.q != b) {
      var c = A(this, "channelSet", this.q, b);
      this.q = b;
      this.dispatchEvent(c)
    }
  };
  o.S = n("ib");
  o.G = function(b) {
    if (!(this.ib != h && this.ib == b)) {
      var c = A(this, "clientId", this.ib, b);
      this.ib = b;
      if (this.Kb != h) {
        b = this.Kb;
        this.Kb = h;
        for (var d = 0; d < b.length; d++) this.r(b[d], !1)
      }
      this.dispatchEvent(c)
    }
  };
  o.Ja = n("Mb");
  o.ub = n("c");
  o.ra = m("c");
  o.ac = n("ya");
  o.jc = function(b) {
    x("Request timeout millis", b);
    if (this.ya != b) {
      var c = A(this, "requestTimeoutMillis", this.ya, b);
      this.ya = b;
      this.dispatchEvent(c)
    }
  };
  o.zc = n("f");
  o.s = function(b) {
    if (this.f != b) {
      var c = A(this, "state", this.f, b);
      this.f = b;
      this.b.d("'" + this.c + "' state: " + la(bb, b));
      this.dispatchEvent(c)
    }
  };
  o.m = function() {
    if (!this.Nb && this.f == 2) this.Nb = !0, this.s(4), this.q.m(this)
  };
  o.ud = function(b, c) {
    if (b == h && c == h) this.rc = "";
    else {
      var d = new Qa,
        e = ka(b + ":" + c);
      Sa(d, e);
      this.rc = Ta(d);
      this.sc = !0
    }
  };
  o.w = function(b, c) {
    this.b.info("'" + this.c + "' received an acknowledgement for request message '" + c.h() + "'.");
    delete b.pa()[cb];
    this.S() == h && this.G(b.S());
    this.dispatchEvent(new Oa("acknowledge", b, c));
  };
  o.j = function(b, c) {
    this.b.error("'" + this.c + "' received a fault for request message '" + c.h() + "'.");
    this.f == 1 && this.s(ab);
    this.S() == h && this.G(b.S());
    this.dispatchEvent(Va(b, c))
  };
  o.za = function() {
    this.Nb = !1;
    this.s(2)
  };
  o.R = function() {
    this.s(ab);
    if (this.rc != h) this.sc = !0
  };
  o.X = function(b) {
    this.b.warn("'" + this.c + "' faulted: " + b.Aa() + " " + b.La() + " " + b.Ka());
    b.ta || this.s(ab);
    if (this.rc != h) this.sc = !0
  };
  o.fa = j();
  o.Bc = m("Lb");
  o.r = function(b, c) {
    y(c) || (c = !0);
    y(b.h()) || b.ic(ya());
    var d = this.S();
    if (c && d == h)
      if (this.Kb == h) this.Kb = [];
      else {
        this.Kb.push(b);
        return
      }
    b.G(d);
    this.ya > 0 && b.g(u.Oc, this.ya);
    if (this.sc && !(b instanceof D && b.A() == 13)) b.g(u.De, this.rc), this.sc = !1;
    this.f != 2 && (this.s(1), this.z() == h && f(Error("No ChannelSet has been assigned to the agent. Make sure a ChannelSet is assigned before trying to connect the agent.")), this.z().wc(this));
    this.z().send(this, b)
  };
  p("flex.client.messaging.MessageAgent", H);
  p("flex.client.messaging.MessageAgent.DEFAULT_REQUEST_TIMEOUT_MILLIS", $a);
  p("flex.client.messaging.MessageAgent.PROPERTY_AUTHENTICATED", "authenticated");
  p("flex.client.messaging.MessageAgent.PROPERTY_CHANNELSET", "channelSet");
  p("flex.client.messaging.MessageAgent.PROPERTY_CLIENT_ID", "clientId");
  p("flex.client.messaging.MessageAgent.PROPERTY_REQUEST_TIMEOUT_MILLIS", "requestTimeoutMillis");
  p("flex.client.messaging.MessageAgent.PROPERTY_STATE", "state");
  p("flex.client.messaging.MessageAgent.State", bb);
  p("flex.client.messaging.MessageAgent.State.DISCONNECTED", ab);
  p("flex.client.messaging.MessageAgent.State.CONNECTING", 1);
  p("flex.client.messaging.MessageAgent.State.CONNECTED", 2);
  p("flex.client.messaging.MessageAgent.State.RECONNECTING", 3);
  p("flex.client.messaging.MessageAgent.State.DISCONNECTING", 4);
  p("flex.client.messaging.MessageAgent.prototype.isAuthenticated", H.prototype.Dc);
  p("flex.client.messaging.MessageAgent.prototype.setAuthenticated", H.prototype.gb);
  p("flex.client.messaging.MessageAgent.prototype.getChannelSet", H.prototype.z);
  p("flex.client.messaging.MessageAgent.prototype.setChannelSet", H.prototype.rd);
  p("flex.client.messaging.MessageAgent.prototype.getClientId", H.prototype.S);
  p("flex.client.messaging.MessageAgent.prototype.setClientId", H.prototype.G);
  p("flex.client.messaging.MessageAgent.prototype.getDestinationId", H.prototype.Ja);
  p("flex.client.messaging.MessageAgent.prototype.getId", H.prototype.ub);
  p("flex.client.messaging.MessageAgent.prototype.setId", H.prototype.ra);
  p("flex.client.messaging.MessageAgent.prototype.getRequestTimeoutMillis", H.prototype.ac);
  p("flex.client.messaging.MessageAgent.prototype.setRequestTimeoutMillis", H.prototype.jc);
  p("flex.client.messaging.MessageAgent.prototype.getState", H.prototype.zc);
  p("flex.client.messaging.MessageAgent.prototype.setState", H.prototype.s);
  p("flex.client.messaging.MessageAgent.prototype.disconnect", H.prototype.m);
  p("flex.client.messaging.MessageAgent.prototype.setRemoteCredentials", H.prototype.ud);

  function I() {}
  I.ve = "flex.client.channels.advanced.AdvancedChannelSet";
  I.ye = "flex.client.messaging.Consumer";
  I.zd = "flex.client.channels.Channel";
  I.we = "flex.client.channels.ChannelSet";
  I.xe = "flex.client.channels.auth.AuthenticationAgent";
  I.ze = "flex.client.messaging.Producer";
  I.Ae = "flex.client.channels.advanced.ReliableProxyAgent";
  I.Be = "flex.client.rpc.remoting.RemoteObject";
  p("flex.client.log.LogCategories", I);
  I.ADVANCED_CHANNELSET = I.ve;
  I.CONSUMER = I.ye;
  I.CHANNEL = I.zd;
  I.CHANNELSET = I.we;
  I.CHANNELSET_AUTHENTICATIONAGENT = I.xe;
  I.PRODUCER = I.ze;
  I.RELIABLE_PROXY_AGENT = I.Ae;
  I.REMOTE_OBJECT = I.Be;

  function J(b) {
    H.call(this, b);
    this.Ib = db;
    this.ka = h;
    this.Uc = 0;
    this.kb = h;
    this.ra("Producer-" + eb++);
    this.Tb = fb;
    this.Ya = gb;
    this.Ub = hb;
    this.Za = h;
    this.C = !1;
    this.Q = h
  }
  s(J, H);
  var db = !0,
    fb = 4,
    gb = 0,
    hb = 0,
    eb = 0;
  o = J.prototype;
  o.vg = n("Ib");
  o.Qg = function(b) {
    if (this.Ib != b) {
      var c = A(this, "autoConnect", this.Ib, b);
      this.Ib = b;
      this.dispatchEvent(c)
    }
  };
  o.Jf = n("kb");
  o.Xg = function(b) {
    if (this.kb !== b) {
      var c = A(this, "defaultHeaders", this.kb, b);
      this.kb = b;
      this.dispatchEvent(c)
    }
  };
  o.cg = n("Tb");
  o.jh = function(b) {
    if (this.Tb != b) {
      var b = b < 0 ? 0 : b > 9 ? 9 : b,
        c = A(this, "priority", this.Tb, b);
      this.Tb = b;
      this.dispatchEvent(c)
    }
  };
  o.dg = n("Ya");
  o.kh = function(b) {
    b != -1 && x("Reconnect attempts", b);
    if (this.Ya != b) {
      var c = A(this, "reconnectAttempts", this.Ya, b);
      this.Ya = b;
      b == 0 && ib(this);
      this.dispatchEvent(c)
    }
  };
  o.eg = n("Ub");
  o.lh = function(b) {
    x("Reconnect interval", b);
    if (this.Ub != b) {
      (b == 0 || this.Za != h) && ib(this);
      var c = A(this, "reconnectIntervalMillis", this.Ub, b);
      this.Ub = b;
      this.dispatchEvent(c)
    }
  };
  o.Ac = n("Q");
  o.fe = m("Q");
  o.m = function() {
    this.C = !1;
    ib(this);
    J.e.m.call(this)
  };
  o.send = function(b) {
    if (this.f != H.ef && this.Ib) this.C = !0;
    if (this.kb != h)
      for (var c in this.kb) b.F(c) || b.g(c, this.kb[c]);
    this.f != H.ef && !this.Ib ? (this.C = !1, c = new G, c.aa("Client.Error.MessageSend"), c.ba("Send failed"), c.ia("The producer is not connected and the message cannot be sent."), c.Ca(b.h()), jb(this, c, b, !0)) : (this.b.info("'" + this.c + "' sending message '" + b.h() + "'."), this.r(b))
  };
  o.w = function(b, c) {
    this.Nb || (J.e.w.call(this, b, c), c instanceof D && c.A() == 13 && ib(this))
  };
  o.j = function(b, c, d) {
    d ? J.e.j.call(this, b, c) : jb(this, b, c, !1)
  };
  o.R = function(b) {
    J.e.R.call(this, b);
    this.C && !b.U && kb(this)
  };
  o.X = function(b) {
    J.e.X.call(this, b);
    this.C && !b.U && !b.ta && kb(this)
  };
  o.fd = function() {
    return I.ze
  };
  o.r = function(b, c) {
    this.Q != h && b.g("DSSubtopic", this.Q);
    if (b.F(u.Hb)) {
      var d = b.getHeader(u.Hb);
      d < 0 ? b.g(u.Hb, 0) : d > 9 && b.g(u.Hb, 9)
    } else this.Tb != 4 && b.g(u.Hb, this.Tb);
    J.e.r.call(this, b, c)
  };

  function kb(b) {
    if (b.C && b.Za == h && !(b.Ya == 0 || b.Ub == 0)) b.b.d("'" + b.c + "' starting reconnect timer."), b.Uc = 0, lb(b)
  }

  function ib(b) {
    if (b.Za != h) b.b.d("'" + b.c + "' stopping reconnect timer."), window.clearTimeout(b.Za), b.Za = h
  }

  function pb(b) {
    var c = new G;
    c.aa("Client.Error.Connect");
    c.ba("Producer connect error");
    c.ia("The producer was not able to connect to its target destination.");
    c.Ca(b.ka.h());
    return c
  }

  function qb(b) {
    var c = new D;
    c.t(13);
    c.G(b.ib);
    c.$(b.Mb);
    return c
  }

  function jb(b, c, d, e) {
    if (!b.Nb || e)
      if (d instanceof D && d.A() == 13) {
        if (b.Za == h) b.ka != h && c.ea() == b.ka.h() ? (b.C = !1, e = pb(b), e.Pg = c.Pg, b.j(e, d, !0)) : b.j(c, d, !0)
      } else b.j(c, d, !0)
  }

  function lb(b) {
    if (b.Ya != -1 && b.Uc >= b.Ya) ib(b), b.C = !1, b.j(pb(b), b.ka);
    else {
      b.b.d("'" + b.c + "' trying to reconnect: " + (b.Uc + 1) + "/" + b.Ya);
      b.Uc++;
      if (b.ka == h) b.ka = qb(b);
      b.r(b.ka, !1);
      if (b.Za == h) window.Hg = b, b.Za = window.setInterval(function() {
        lb(this.Hg)
      }, b.Ub)
    }
  }
  p("flex.client.messaging.Producer", J);
  p("flex.client.messaging.Producer.DEFAULT_AUTO_CONNECT", db);
  p("flex.client.messaging.Producer.DEFAULT_PRIORITY", fb);
  p("flex.client.messaging.Producer.DEFAULT_RECONNECT_ATTEMPTS", gb);
  p("flex.client.messaging.Producer.DEFAULT_RECONNECT_INTERVAL_MILLIS", hb);
  p("flex.client.messaging.Producer.INFINITE_RECONNECT_ATTEMPTS", -1);
  p("flex.client.messaging.Producer.PROPERTY_AUTO_CONNECT", "autoConnect");
  p("flex.client.messaging.Producer.PROPERTY_DEFAULT_HEADERS", "defaultHeaders");
  p("flex.client.messaging.Producer.PROPERTY_PRIORITY", "priority");
  p("flex.client.messaging.Producer.PROPERTY_RECONNECT_ATTEMPTS", "reconnectAttempts");
  p("flex.client.messaging.Producer.PROPERTY_RECONNECT_INTERVAL_MILLIS", "reconnectIntervalMillis");
  p("flex.client.messaging.Producer.instanceCounter", eb);
  p("flex.client.messaging.Producer.prototype.disconnect", J.prototype.m);
  p("flex.client.messaging.Producer.prototype.getDefaultHeaders", J.prototype.Jf);
  p("flex.client.messaging.Producer.prototype.getPriority", J.prototype.cg);
  p("flex.client.messaging.Producer.prototype.getReconnectAttempts", J.prototype.dg);
  p("flex.client.messaging.Producer.prototype.getReconnectIntervalMillis", J.prototype.eg);
  p("flex.client.messaging.Producer.prototype.getSubtopic", J.prototype.Ac);
  p("flex.client.messaging.Producer.prototype.setSubtopic", J.prototype.fe);
  p("flex.client.messaging.Producer.prototype.isAutoConnect", J.prototype.vg);
  p("flex.client.messaging.Producer.prototype.send", J.prototype.send);
  p("flex.client.messaging.Producer.prototype.setAutoConnect", J.prototype.Qg);
  p("flex.client.messaging.Producer.prototype.setDefaultHeaders", J.prototype.Xg);
  p("flex.client.messaging.Producer.prototype.setPriority", J.prototype.jh);
  p("flex.client.messaging.Producer.prototype.setReconnectAttempts", J.prototype.kh);
  p("flex.client.messaging.Producer.prototype.setReconnectIntervalMillis", J.prototype.lh);

  function rb() {}
  var sb = {};

  function tb(b) {
    return sb[b] ? sb[b] : h
  }

  function ub(b, c, d) {
    sb[b] = c;
    d && (sb[c] = b)
  }
  p("flex.messaging.io.ClassAliasRegistry", rb);
  rb.getClassName = tb;
  rb.registerAlias = ub;
  rb.unregisterAlias = function(b, c) {
    var d = sb[b];
    delete sb[b];
    d && c && delete sb[d]
  };

  function L() {
    this._CLASS = "flex.client.documentservices.Fault";
    this.type = this.hint = this.description = this.title = this.name = h
  }
  o = L.prototype;
  o.getName = n("name");
  o.hh = m("name");
  o.ng = n("title");
  o.vh = m("title");
  o.Kf = n("description");
  o.Yg = m("description");
  o.Tf = n("hint");
  o.bh = m("hint");
  o.cc = n("type");
  o.wh = m("type");
  p("flex.client.documentservices.Fault", L);
  p("flex.client.documentservices.Fault.prototype.getName", L.prototype.getName);
  p("flex.client.documentservices.Fault.prototype.setName", L.prototype.hh);
  p("flex.client.documentservices.Fault.prototype.getTitle", L.prototype.ng);
  p("flex.client.documentservices.Fault.prototype.setTitle", L.prototype.vh);
  p("flex.client.documentservices.Fault.prototype.getDescription", L.prototype.Kf);
  p("flex.client.documentservices.Fault.prototype.setDescription", L.prototype.Yg);
  p("flex.client.documentservices.Fault.prototype.getHint", L.prototype.Tf);
  p("flex.client.documentservices.Fault.prototype.setHint", L.prototype.bh);
  p("flex.client.documentservices.Fault.prototype.getType", L.prototype.cc);
  p("flex.client.documentservices.Fault.prototype.setType", L.prototype.wh);

  function vb() {
    this._CLASS = "flex.client.documentservices.FaultResponse";
    this.fault = this.clientParameters = this.invocationId = h
  }
  o = vb.prototype;
  o.Wf = n("invocationId");
  o.eh = m("invocationId");
  o.Ff = n("clientParameters");
  o.Tg = m("clientParameters");
  o.Pf = n("fault");
  o.Zg = m("fault");
  p("flex.client.documentservices.FaultResponse", vb);
  p("flex.client.documentservices.FaultResponse.prototype.getInvocationId", vb.prototype.Wf);
  p("flex.client.documentservices.FaultResponse.prototype.setInvocationId", vb.prototype.eh);
  p("flex.client.documentservices.FaultResponse.prototype.getClientParameters", vb.prototype.Ff);
  p("flex.client.documentservices.FaultResponse.prototype.setClientParameters", vb.prototype.Tg);
  p("flex.client.documentservices.FaultResponse.prototype.getFault", vb.prototype.Pf);
  p("flex.client.documentservices.FaultResponse.prototype.setFault", vb.prototype.Zg);

  function wb(b) {
    for (var c = b.length, d = "", e = 0; e < c; e++) {
      var i = b.charCodeAt(e);
      i < 128 ? d += String.fromCharCode(i) : i < 2048 ? (d += String.fromCharCode(192 | i >> 6), d += String.fromCharCode(128 | i & 63)) : i < 65536 ? (d += String.fromCharCode(224 | i >> 12 & 15), d += String.fromCharCode(128 | i >> 6 & 63), d += String.fromCharCode(128 | i & 63)) : i <= 1114111 ? (d += String.fromCharCode(240 | i >> 18 & 7), d += String.fromCharCode(128 | i >> 12 & 63), d += String.fromCharCode(128 | i >> 6 & 63), d += String.fromCharCode(128 | i & 63)) : f(Error("UTF8 code points cannot be greater than 0x10FFFF [0x" +
        i.toString(16) + "]"))
    }
    return d
  };
  var xb = String.fromCharCode(256);

  function yb(b) {
    if (zb(b)) {
      if (window.DOMParser) return (new XMLSerializer).serializeToString(b);
      return b.xml
    }
    f(Error("Passed object of type [" + typeof b + "] is not an XML object"))
  }

  function Ab(b) {
    if (window.DOMParser) return (new DOMParser).parseFromString(b, "text/xml");
    if (window.ActiveXObject) {
      var c = new ActiveXObject("Microsoft.XMLDOM");
      c.async = !1;
      c.loadXML(b);
      return c
    }
    f(Error("Can't recognize XML Document object"))
  }

  function zb(b) {
    if (window.Document) return b instanceof Document;
    if (window.ActiveXObject) return b instanceof ActiveXObject;
    f(Error("Passed object is of unknown type [" + typeof b + "]"))
  }

  function Bb(b, c) {
    for (var d = "", e = c.length, i = 0; i < e; i++) d += c.charCodeAt(i) == 0 ? xb : c.charAt(i);
    b.send(d)
  }
  p("flex.client.util.CrossBrowserSupport", j());
  p("flex.client.util.CrossBrowserSupport.documentToString", yb);
  p("flex.client.util.CrossBrowserSupport.stringToDocument", Ab);

  function Cb() {}

  function Db(b) {
    var b = b._CLASS,
      c = tb(b);
    return c == h ? b : c
  }

  function Eb(b) {
    var c = [],
      d;
    for (d in b) typeof b[d] != "function" && d.charAt(0) != "_" && c.push(d);
    return c
  };

  function Fb() {
    this.data = ""
  }
  var Gb = Math.pow(2, 20),
    Hb = Math.pow(2, 23),
    Ib = Math.pow(2, 52);
  o = Fb.prototype;
  o.ne = function(b) {
    this.data += this.i(b ? 1 : 0)
  };
  o.i = function(b) {
    b < 0 && (b = -(b ^ 255) - 1);
    this.data += String.fromCharCode(b & 255)
  };
  o.oe = function(b, c, d) {
    for (; c < d; c++) this.i(b[c])
  };
  o.Nc = function(b) {
    b = Jb(b);
    this.Fb(b[0]);
    this.Fb(b[1])
  };
  o.Gb = function(b) {
    b < 0 && (b = -(b ^ 65535) - 1);
    b &= 65535;
    this.data += String.fromCharCode(b >> 8 & 255);
    this.data += String.fromCharCode(b & 255)
  };
  o.Fb = function(b) {
    b < 0 && (b = -(b ^ 4294967295) - 1);
    b &= 4294967295;
    this.data += String.fromCharCode(b >> 24 & 255);
    this.data += String.fromCharCode(b >> 16 & 255);
    this.data += String.fromCharCode(b >> 8 & 255);
    this.data += String.fromCharCode(b & 255)
  };
  o.flush = function() {
    var b = this.data;
    this.data = "";
    return b
  };

  function Jb(b) {
    var c = [];
    c.push(0);
    c.push(0);
    if (b != b) return c[0] = -524288, c;
    var d = b < 0 || b === 0 && 1 / b < 0 ? -2147483648 : 0,
      b = Math.abs(b);
    if (b === Number.POSITIVE_INFINITY) return c[0] = d | 2146435072, c;
    for (var e = 0; b >= 2 && e <= 1023;) e++, b /= 2;
    for (; b < 1 && e >= -1022;) e--, b *= 2;
    e += 1023;
    if (e == 2047) return c[0] = d | 2146435072, c;
    var i;
    e == 0 ? (i = b * Hb / 2, b = Math.round(b * Ib / 2)) : (i = b * Gb - Gb, b = Math.round(b * Ib - Ib));
    c[0] = d | e << 20 & 2147418112 | i & 1048575;
    c[1] = b;
    return c
  }
  p("flex.messaging.io.DataOutputStream", Fb);
  p("flex.messaging.io.DataOutputStream.prototype.writeBoolean", Fb.prototype.ne);
  p("flex.messaging.io.DataOutputStream.prototype.writeByte", Fb.prototype.i);
  p("flex.messaging.io.DataOutputStream.prototype.writeBytes", Fb.prototype.oe);
  p("flex.messaging.io.DataOutputStream.prototype.writeDouble", Fb.prototype.Nc);
  p("flex.messaging.io.DataOutputStream.prototype.writeShort", Fb.prototype.Gb);
  p("flex.messaging.io.DataOutputStream.prototype.writeInt", Fb.prototype.Fb);
  p("flex.messaging.io.DataOutputStream.prototype.flush", Fb.prototype.flush);

  function Kb() {
    this.data = ""
  }
  s(Kb, Fb);
  Kb.prototype.flush = function() {
    var b = Kb.e.flush.call(this);
    this.reset();
    return b
  };
  Kb.prototype.reset = j();
  var Lb = function() {
    function b(c) {
      if (typeof c == "string") return c;
      else if (typeof c.Td == V) return c = c.Td(), typeof c == "string" ? c : b(c);
      else if (typeof c.toString == V) return c.toString();
      else try {
          return String(c)
        } catch (d) {
          return Object.prototype.toString.call(c)
        }
    }

    function c(b, c) {
      return b.Ld(c)
    }

    function d(b, c) {
      return typeof c.Ld == V ? c.Ld(b) : b === c
    }

    function e(b) {
      return function(c) {
        c === h ? f(Error("null is not a valid " + b)) : typeof c == "undefined" && f(Error(b + " must not be undefined"))
      }
    }

    function i(b, c, d, e) {
      this[0] = b;
      this.Y = [];
      this.Ie(c, d);
      if (e !== h) this.Qe = function() {
        return e
      }
    }

    function k(b) {
      return function(c) {
        for (var d = this.Y.length, e, i = this.Qe(c); d--;)
          if (e = this.Y[d], i(c, e[0])) switch (b) {
            case ba:
              return !0;
            case mb:
              return e;
            case Nc:
              return [d, e[1]]
          }
        return !1
      }
    }

    function l(b) {
      return function(c) {
        for (var d = c.length, e = 0, i = this.Y.length; e < i; ++e) c[d + e] = this.Y[e][b]
      }
    }

    function r(b, c) {
      var d = b[c];
      return d && d instanceof i ? d : h
    }

    function K(c, d) {
      function e(b) {
        return function() {
          for (var c = [], d = l.length; d--;) l[d][b](c);
          return c
        }
      }
      var k =
          this,
        l = [],
        wa = {},
        ba = typeof c == V ? c : b,
        mb = typeof d == V ? d : h;
      this.put = function(b, c) {
        La(b);
        nb(c);
        var d = ba(b),
          e, k = h;
        (e = r(wa, d)) ? (d = e.Pe(b)) ? (k = d[1], d[1] = c) : e.Ie(b, c): (e = new i(d, b, c, mb), l[l.length] = e, wa[d] = e);
        return k
      };
      this.get = function(b) {
        La(b);
        var c = ba(b);
        if (c = r(wa, c))
          if (b = c.Pe(b)) return b[1];
        return h
      };
      this.xc = function(b) {
        La(b);
        var c = ba(b);
        return (c = r(wa, c)) ? c.xc(b) : !1
      };
      this.Je = function(b) {
        nb(b);
        for (var c = l.length; c--;)
          if (l[c].Je(b)) return !0;
        return !1
      };
      this.clear = function() {
        l.length = 0;
        wa = {}
      };
      this.Oh = function() {
        return !l.length
      };
      this.Cg = e("keys");
      this.Ah = e("values");
      this.Y = e("getEntries");
      this.remove = function(b) {
        La(b);
        var c = ba(b),
          d = h,
          e = r(wa, c);
        if (e && (d = e.Mg(b), d !== h && !e.Y.length)) {
          a: {
            for (b = l.length; b--;)
              if (e = l[b], c === e[0]) break a;b = h
          }
          ob(l, b);delete wa[c]
        }
        return d
      };
      this.size = function() {
        for (var b = 0, c = l.length; c--;) b += l[c].Y.length;
        return b
      };
      this.Mh = function(b) {
        for (var c = k.Y(), d = c.length, e; d--;) e = c[d], b(e[0], e[1])
      };
      this.Kg = function(b) {
        for (var b = b.Y(), c, d, e, i = b.length, l = "undefined" == V; i--;) {
          c = b[i];
          d = c[0];
          c = c[1];
          if (l && (e = k.get(d))) c =
            g(d, e, c);
          k.put(d, c)
        }
      };
      this.Jh = function() {
        var b = new K(c, d);
        b.Kg(k);
        return b
      }
    }
    var V = "function",
      ob = typeof Array.prototype.splice == V ? function(b, c) {
        b.splice(c, 1)
      } : function(b, c) {
        var d, e, i;
        if (c === b.length - 1) b.length = c;
        else {
          d = b.slice(c + 1);
          b.length = c;
          e = 0;
          for (i = d.length; e < i; ++e) b[c + e] = d[e]
        }
      },
      La = e("key"),
      nb = e("value"),
      ba = 0,
      mb = 1,
      Nc = 2;
    i.prototype = {
      Qe: function(b) {
        return typeof b.Ld == V ? c : d
      },
      Pe: k(mb),
      Mf: k(Nc),
      Mg: function(b) {
        if (b = this.Mf(b)) return ob(this.Y, b[0]), b[1];
        return h
      },
      Ie: function(b, c) {
        this.Y[this.Y.length] = [b, c]
      },
      Cg: l(0),
      Ah: l(1),
      xc: k(ba),
      Je: function(b) {
        for (var c = this.Y.length; c--;)
          if (b === this.Y[c][1]) return !0;
        return !1
      }
    };
    return K
  }();

  function Mb(b, c, d, e) {
    b == h && (b = "");
    this.className = b;
    this.ld = e ? e : [];
    this.Me = c;
    this.Ne = d
  }
  Mb.prototype.gd = function(b) {
    return this.ld[b]
  };

  function Nb() {
    this.data = "";
    this.B = new Lb(na);
    this.Va = new Lb(na);
    this.Lc = new Lb(na)
  }
  s(Nb, Kb);
  o = Nb.prototype;
  o.reset = function() {
    Nb.e.reset.call(this);
    this.B.clear();
    this.Va.clear();
    this.Lc.clear()
  };
  o.L = function(b) {
    if (b == h) this.le();
    else if (b.ja) this.yd(b);
    else if (typeof b === "string" || b instanceof String) this.me(b);
    else if (typeof b === "number" || b instanceof Number) Ob(b) ? Pb(this, b) : this.kc(b);
    else if (typeof b === "boolean" || b instanceof Boolean) this.Mc(b);
    else if (b instanceof Date) return this.ke(b);
    else zb(b) ? (this.i(Qb), Rb(this, b) || Sb(this, yb(b))) : b instanceof Array ? this.je(b) : this.yd(b)
  };
  o.se = function(b) {
    var c = b.className,
      d;
    this.Lc.xc(b) ? (Tb(this, this.Lc.get(b) << 2 | 1), d = !0) : (this.Lc.put(b, this.Lc.size()), d = !1);
    if (!d) {
      d = 0;
      var e = h,
        i = b.Ne;
      if (!i && (e = b.ld, e != h)) d = e.length;
      Tb(this, 3 | (i ? 4 : 0) | (b.Me ? 8 : 0) | d << 4);
      Ub(this, c);
      if (!i && e != h)
        for (c = 0; c < d; c++) Ub(this, b.gd(c))
    }
  };
  o.re = function(b, c) {
    this.L(c)
  };
  o.qe = j();
  o.Mc = function(b) {
    b ? this.i(Vb) : this.i(Wb)
  };
  o.ke = function(b) {
    this.i(Xb);
    Rb(this, b) || (Tb(this, 1), this.Nc(b.getTime()))
  };
  o.kc = function(b) {
    this.i(Yb);
    this.Nc(b)
  };

  function Pb(b, c) {
    c >= Zb && c <= $b ? (c &= ac, b.i(bc), Tb(b, c)) : b.kc(c)
  }
  o.le = function() {
    this.i(cc)
  };
  o.me = function(b) {
    this.i(dc);
    Ub(this, b)
  };

  function Ub(b, c) {
    if (c.length == 0) Tb(b, 1);
    else {
      var d;
      b.Va.xc(c) ? (Tb(b, b.Va.get(c) << 1), d = !0) : (b.Va.put(c, b.Va.size()), d = !1);
      d || Sb(b, c)
    }
  }
  o.je = function(b) {
    this.i(ec);
    if (!Rb(this, b)) {
      var c = b.length;
      Tb(this, c << 1 | 1);
      Ub(this, "");
      typeof b[0] === "boolean" || typeof b[0] === "number" ? this.te(b, c) : this.pe(b)
    }
  };

  function Ga(b, c) {
    b.i(fc);
    if (!Rb(b, c)) {
      var d = c.length;
      Tb(b, d << 1 | 1);
      b.oe(c, 0, d)
    }
  }
  o.te = function(b, c) {
    if (typeof b[0] === "boolean")
      for (var d = 0; d < c; d++) this.Mc(b[d]);
    else if (Ob(b))
      for (d = 0; d < c; d++) Pb(this, b[d]);
    else
      for (d = 0; d < c; d++) this.kc(b[d])
  };
  o.pe = function(b) {
    for (var c = b.length, d = 0; d < c; d++) this.L(b[d])
  };
  o.yd = function(b) {
    this.i(gc);
    Rb(this, b) || this.ue(new Cb, b)
  };
  o.ue = function(b, c) {
    var d = h,
      e = c.ja != g;
    e || (d = Eb(c));
    this.se(new Mb(Db(c), !1, e, d));
    if (e) c.ja(this);
    else if (d != h && d.length > 0)
      for (var e = d.length, i = 0; i < e; i++) {
        var k = d[i];
        this.re(k, c[k])
      }
    this.qe()
  };

  function Tb(b, c) {
    c < 128 ? b.i(c) : c < 16384 ? (b.i(c >> 7 & 127 | 128), b.i(c & 127)) : c < 2097152 ? (b.i(c >> 14 & 127 | 128), b.i(c >> 7 & 127 | 128), b.i(c & 127)) : c < 1073741824 ? (b.i(c >> 22 & 127 | 128), b.i(c >> 15 & 127 | 128), b.i(c >> 8 & 127 | 128), b.i(c & 255)) : f(Error("Integer out of range: " + c))
  }

  function Sb(b, c) {
    var d = wb(c);
    Tb(b, d.length << 1 | 1);
    b.data += d
  }

  function Rb(b, c) {
    if (b.B.xc(c)) return Tb(b, b.B.get(c) << 1), !0;
    b.B.put(c, b.B.size());
    return !1
  }

  function Ob(b) {
    if (b instanceof Array) {
      for (var c = b.length, d = 0; d < c; d++)
        if (Math.ceil(b[d]) != Math.floor(b[d])) return !1;
      return !0
    }
    return Math.ceil(b) == Math.floor(b)
  };

  function hc() {
    this.data = "";
    this.Id = !1;
    this.Ia = h;
    this.de = new Lb(na);
    this.Ze = 0
  }
  s(hc, Kb);
  var ic;
  ic = [0, 0, 9];
  o = hc.prototype;
  o.reset = function() {
    hc.e.reset.call(this);
    this.de.clear();
    this.Ze = 0;
    this.Ia != h && this.Ia.reset()
  };
  o.flush = function() {
    if (this.Ia != h) {
      var b = this.Ia.flush();
      this.Ia = h;
      hc.e.flush.call(this);
      return b
    }
    return hc.e.flush.call(this)
  };
  o.L = function(b) {
    if (b == h) this.le();
    else if ("string" === typeof b || b instanceof String) this.me(b);
    else if ("number" === typeof b || b instanceof Number) this.kc(b);
    else if ("boolean" === typeof b || b instanceof Boolean) this.Mc(b);
    else if (b instanceof Date) return this.ke(b);
    else if (this.Id) {
      if (this.Ia == h) this.Ia = new Nb, this.Ia.data = this.data;
      this.Ia.i(jc);
      this.Ia.L(b)
    } else b instanceof Array ? this.je(b) : zb(b) ? (this.i(kc), lc(this, yb(b), !0, !1)) : this.yd(b)
  };
  o.se = function(b) {
    var c = h;
    if (b != h) c = b.className;
    c == h || c.length == 0 ? this.i(mc) : (this.i(nc), lc(this, c))
  };
  o.re = function(b, c) {
    lc(this, b);
    this.L(c)
  };
  o.qe = function() {
    this.oe(ic, 0, ic.length)
  };
  o.Mc = function(b) {
    this.i(oc);
    this.ne(b)
  };
  o.kc = function(b) {
    this.i(pc);
    this.Nc(b)
  };
  o.ke = function(b) {
    this.i(qc);
    this.Nc(b.getTime());
    this.Gb(b.getTimezoneOffset())
  };
  o.je = function(b) {
    "boolean" === typeof b[0] || "number" === typeof b[0] ? this.te(b) : this.pe(b)
  };
  o.yd = function(b) {
    rc(this, b) || this.ue(new Cb, b)
  };
  o.ue = function(b, c) {
    var d = h,
      d = Eb(c);
    this.se(new Mb(Db(c), !1, !1, d));
    if (d != h && d.length > 0)
      for (var e = d.length, i = 0; i < e; i++) {
        var k = d[i];
        this.re(k, c[k])
      }
    this.qe()
  };
  o.le = function() {
    this.i(sc)
  };
  o.me = function(b) {
    lc(this, b, !1, !0)
  };
  o.pe = function(b) {
    if (!rc(this, b)) {
      var c = b.length;
      this.i(tc);
      this.Fb(c);
      for (var d = 0; d < c; d++) this.L(b[d])
    }
  };
  o.te = function(b) {
    if (!rc(this, b)) {
      var c = b.length;
      this.i.write(tc);
      this.Fb(c);
      if ("boolean" === typeof b[0])
        for (var d = 0; d < c; d++) this.Mc(b[d]);
      else
        for (d = 0; d < c; d++) this.kc(b[d])
    }
  };

  function lc(b, c, d, e) {
    "string" !== typeof c && (c = "");
    var c = wb(c),
      i = c.length,
      d = d ? uc : i <= 65535 ? vc : uc;
    e && b.i(d);
    b.Gb(i);
    b.data += c
  }

  function rc(b, c) {
    var d = b.de.get(c);
    d != h ? (b.i(wc), b.Gb(d)) : b.de.put(c, b.Ze++);
    return d != h
  };

  function M(b) {
    this.data = b !== g ? b : "";
    this.O = 0
  }
  var xc = Math.pow(2, -52);
  o = M.prototype;
  o.ga = function() {
    var b = this.data.charCodeAt(this.O++) & 255;
    return b > 127 ? b - 256 : b
  };
  o.Te = function() {
    return this.data.charCodeAt(this.O++) & 255
  };
  o.md = function() {
    return this.data.charCodeAt(this.O++) & 255 ? !0 : !1
  };
  o.Gc = function() {
    var b = this.data.charCodeAt(this.O++) & 255,
      c = this.data.charCodeAt(this.O++) & 255;
    if (b === 255) {
      if (c === 248) return Number.NaN;
      if (c === 240) return Number.NEGATIVE_INFINITY
    } else if (b === 127 && c === 240) return Number.POSITIVE_INFINITY;
    var d = this.data.charCodeAt(this.O++) & 255,
      e = this.data.charCodeAt(this.O++) & 255,
      i = this.data.charCodeAt(this.O++) & 255,
      k = this.data.charCodeAt(this.O++) & 255,
      l = this.data.charCodeAt(this.O++) & 255,
      r = this.data.charCodeAt(this.O++) & 255;
    if (!b && !c && !d && !e) return 0;
    for (var K = (b <<
      4 & 2047 | c >> 4) - 1023, c = ((c & 15) << 16 | d << 8 | e).toString(2), d = c.length; d < 20; d++) c = "0" + c;
    k = ((i & 127) << 24 | k << 16 | l << 8 | r).toString(2);
    for (d = k.length; d < 31; d++) k = "0" + k;
    i = parseInt(c + (i >> 7 ? "1" : "0") + k, 2);
    if (i == 0 && K == -1023) return 0;
    return (1 - (b >> 7 << 1)) * (1 + xc * i) * Math.pow(2, K)
  };
  o.Ab = function() {
    var b = (this.O += 4) - 4,
      b = (this.data.charCodeAt(b) & 255) << 24 | (this.data.charCodeAt(++b) & 255) << 16 | (this.data.charCodeAt(++b) & 255) << 8 | this.data.charCodeAt(++b) & 255;
    return b >= 2147483648 ? b - 4294967296 : b
  };
  o.ha = function(b) {
    for (var c = ""; b-- > 0;) c += String.fromCharCode(this.ga());
    return c
  };
  o.Bb = function() {
    var b = (this.O += 2) - 2;
    return (this.data.charCodeAt(b) & 255) << 8 | this.data.charCodeAt(++b) & 255
  };
  o.Ma = function() {
    return this.ha(this.Bb())
  };
  p("flex.messaging.io.DataInputStream", M);
  p("flex.messaging.io.DataInputStream.prototype.readByte", M.prototype.ga);
  p("flex.messaging.io.DataInputStream.prototype.readUnsignedByte", M.prototype.Te);
  p("flex.messaging.io.DataInputStream.prototype.readBoolean", M.prototype.md);
  p("flex.messaging.io.DataInputStream.prototype.readDouble", M.prototype.Gc);
  p("flex.messaging.io.DataInputStream.prototype.readInt", M.prototype.Ab);
  p("flex.messaging.io.DataInputStream.prototype.readString", M.prototype.ha);
  p("flex.messaging.io.DataInputStream.prototype.readShort", M.prototype.Bb);
  p("flex.messaging.io.DataInputStream.prototype.readUTF", M.prototype.Ma);

  function yc(b, c) {
    this.l = b;
    this.message = c;
    this.pd = h
  }

  function zc(b, c) {
    if (!(c <= 0)) window.da = b, b.pd = window.setTimeout(function() {
      Ac(this.da);
      var b = this.da;
      b.l.j(Bc(b), b.message)
    }, c)
  }

  function Ac(b) {
    if (b != h && b.pd != h) window.clearTimeout(b.pd), b.pd = h
  }

  function Bc(b) {
    var c = new G;
    c.Ca(b.message.h());
    c.aa("Client.Error.RequestTimeout");
    c.ba("Request timed out.");
    c.ia("The request timeout for the sent message was reached without receiving a response from the server.");
    return c
  };

  function F(b) {
    E.call(this);
    this._CLASS = "flex.messaging.messages.AcknowledgeMessageExt";
    this._message = b
  }
  s(F, E);
  F.prototype.Ta = function(b) {
    this.Qa(b)
  };
  F.prototype.ja = function(b) {
    this._message != h ? this._message.Ra(b) : F.e.ja.call(this, b)
  };
  p("flex.messaging.messages.AcknowledgeMessageExt", F);
  p("flex.messaging.messages.AcknowledgeMessageExt.prototype.readExternal", F.prototype.Ta);
  p("flex.messaging.messages.AcknowledgeMessageExt.prototype.writeExternal", F.prototype.ja);

  function N(b, c, d) {
    w.call(this, b, c, d);
    this.$c = this.Wc = this.Vc = this.Xc = h
  }
  s(N, w);
  o = N.prototype;
  o.La = n("Xc");
  o.ba = m("Xc");
  o.Aa = n("Vc");
  o.aa = m("Vc");
  o.Ka = n("Wc");
  o.ia = m("Wc");
  o.Sa = n("$c");
  o.Ua = m("$c");

  function Cc(b, c) {
    var d = b.getError(),
      e = new N("fault", c, d);
    e.aa(d.Aa());
    e.ba(d.La());
    e.ia(d.Ka());
    e.Ua(d.Sa());
    return e
  }
  o.Zb = function() {
    if (this.tc != h) {
      var b = this.tc;
      if (b.ma != h)
        for (var c = 0; c < b.ma.length; c++) {
          var d = b.ma[c];
          d != h && d.j(this)
        }
    }
  };
  p("flex.client.rpc.events.FaultEvent", N);
  p("flex.client.rpc.events.FaultEvent.FAULT", "fault");
  p("flex.client.rpc.events.FaultEvent.prototype.getFaultCode", N.prototype.Aa);
  p("flex.client.rpc.events.FaultEvent.prototype.settFaultCode", N.prototype.aa);
  p("flex.client.rpc.events.FaultEvent.prototype.getFaultString", N.prototype.La);
  p("flex.client.rpc.events.FaultEvent.prototype.setFaultString", N.prototype.ba);
  p("flex.client.rpc.events.FaultEvent.prototype.getFaultDetail", N.prototype.Ka);
  p("flex.client.rpc.events.FaultEvent.prototype.setFaultDetail", N.prototype.ia);
  p("flex.client.rpc.events.FaultEvent.prototype.getRootCause", N.prototype.Sa);
  p("flex.client.rpc.events.FaultEvent.prototype.setRootCause", N.prototype.Ua);
  p("flex.client.rpc.events.FaultEvent.prototype.callTokenResponders", N.prototype.Zb);
  p("flex.client.rpc.events.FaultEvent.createEventFromMessageFault", Cc);

  function Dc(b, c, d, e) {
    w.call(this, b, d, e);
    this.qb = c
  }
  s(Dc, w);
  Dc.prototype.Pd = n("qb");
  Dc.prototype.Zb = function() {
    if (this.tc != h) {
      var b = this.tc,
        c = this.qb;
      if (b.qb !== c) {
        var d = A(b, "result", b.qb, c);
        b.qb = c;
        b.dispatchEvent(d)
      }
      if (b.ma != h)
        for (c = 0; c < b.ma.length; c++) d = b.ma[c], d != h && d.result(this)
    }
  };
  p("flex.client.rpc.events.ResultEvent", Dc);
  p("flex.client.rpc.events.ResultEvent.RESULT", "result");
  p("flex.client.rpc.events.ResultEvent.prototype.getResult", Dc.prototype.Pd);

  function Ec(b, c) {
    H.call(this, "dummyDestination");
    this.ra(b.c + "-AuthenticationAgent");
    this.q = b;
    this.yc = c;
    this.sb = Fc;
    this.Eb = {};
    this.yc = h
  }
  s(Ec, H);
  var Fc = 0;

  function Gc(b, c) {
    b.sb = c;
    if (c == 4) b.Eb = {}
  }
  Ec.prototype.w = function(b, c) {
    if (this.sb != 4) {
      var d = b.getHeader("DSErrorHint"),
        e = !1;
      d != h && d instanceof Boolean && (e = d);
      Ec.e.w.call(this, b, c);
      if (!e) {
        e = this.Eb[c.h()];
        delete this.Eb[c.h()];
        var d = this.q,
          i = this.sb == 1,
          k = i ? this.yc : h;
        if (i) {
          d.yc = k;
          for (var l in d.o) d.o[l].Bc(k);
          for (l in d.sa) d.sa[l].Bc(k);
          Gc(this, 2);
          d.v.gb(!0)
        } else Gc(this, 4), d.m(h), d.v.gb(!1);
        l = new Dc("result", b.u(), e, b);
        l.Zb();
        d.dispatchEvent(l)
      }
    }
  };
  Ec.prototype.j = function(b, c) {
    if (this.sb != 4) {
      Ec.e.j.call(this, b, c);
      var d = this.Eb[c.h()];
      delete this.Eb[c.h()];
      var e = this.q;
      Gc(this, 4);
      e.K = h;
      e.m(this);
      d = Cc(Va(b), d);
      d.Zb();
      e.dispatchEvent(d)
    }
  };
  Ec.prototype.fd = function() {
    return I.xe
  };

  function O(b, c, d, e, i) {
    this.cd = b;
    this.hb = c == g ? h : c;
    this.Vb = y(d) ? d : !1;
    this.U = y(d) ? e : !1;
    this.ta = y(d) ? i : !1
  }
  s(O, ha);

  function Hc(b) {
    return new O("channelConnect", b, !1, !1, !0)
  }

  function Ic(b, c, d, e) {
    return new O("channelDisconnect", b, c, d, e)
  }
  o = O.prototype;
  o.Cf = n("hb");
  o.xg = n("ta");
  o.yg = n("Vb");
  o.ee = m("Vb");
  o.zg = n("U");
  p("flex.client.channels.events.ChannelEvent", O);
  p("flex.client.channels.events.ChannelEvent.CONNECT", "channelConnect");
  p("flex.client.channels.events.ChannelEvent.DISCONNECT", "channelDisconnect");
  p("flex.client.channels.events.ChannelEvent.prototype.getChannel", O.prototype.Cf);
  p("flex.client.channels.events.ChannelEvent.prototype.isConnected", O.prototype.xg);
  p("flex.client.channels.events.ChannelEvent.prototype.isReconnecting", O.prototype.yg);
  p("flex.client.channels.events.ChannelEvent.prototype.setReconnecting", O.prototype.ee);
  p("flex.client.channels.events.ChannelEvent.prototype.isRejected", O.prototype.zg);

  function Jc(b) {
    this.T = {};
    this.k = b;
    this.qb = this.ma = h
  }
  s(Jc, z);
  o = Jc.prototype;
  o.Nd = n("k");
  o.gg = n("ma");
  o.Pd = n("qb");
  o.tf = function(b) {
    if (this.ma == h) this.ma = [];
    this.ma.push(b)
  };
  o.ug = function() {
    return this.ma != h && this.ma.length > 0
  };
  p("flex.client.rpc.AsyncToken", Jc);
  p("flex.client.rpc.AsyncToken.prototype.getMessage", Jc.prototype.Nd);
  p("flex.client.rpc.AsyncToken.prototype.getResponders", Jc.prototype.gg);
  p("flex.client.rpc.AsyncToken.prototype.getResult", Jc.prototype.Pd);
  p("flex.client.rpc.AsyncToken.prototype.addResponder", Jc.prototype.tf);
  p("flex.client.rpc.AsyncToken.prototype.hasResponder", Jc.prototype.ug);

  function P(b, c, d, e, i, k, l, r, K) {
    O.call(this, b, c, d, r, K);
    this.Vc = e;
    this.Wc = k;
    this.Xc = i;
    this.$c = l
  }
  s(P, O);

  function Kc(b, c, d, e, i, k, l, r) {
    return new P("channelFault", b, c, d, e, i, k, l, r)
  }
  P.prototype.Aa = n("Vc");
  P.prototype.Ka = n("Wc");
  P.prototype.La = n("Xc");
  P.prototype.Sa = n("$c");
  p("flex.client.channels.events.ChannelFaultEvent", P);
  p("flex.client.channels.events.ChannelFaultEvent.FAULT", "channelFault");
  p("flex.client.channels.events.ChannelFaultEvent.prototype.getFaultCode", P.prototype.Aa);
  p("flex.client.channels.events.ChannelFaultEvent.prototype.getFaultDetail", P.prototype.Ka);
  p("flex.client.channels.events.ChannelFaultEvent.prototype.getFaultString", P.prototype.La);
  p("flex.client.channels.events.ChannelFaultEvent.prototype.getRootCause", P.prototype.Sa);

  function Q(b) {
    this.T = {};
    (!y(b) || b instanceof Array && b.length == 0) && f(Error("Channels argument must not be null or empty."));
    b instanceof Array || (b = [b]);
    this.o = [];
    this.M = !1;
    this.sa = b;
    this.Jb = -1;
    this.Qc = !1;
    this.v = h;
    this.lb = Lc;
    this.Ge = !1;
    this.Pb = Mc;
    this.nc = h;
    this.c = "ChannelSet-" + Oc++;
    this.b = Za(I.we);
    this.V = [];
    this.f = Pc
  }
  s(Q, z);
  var Mc = 0,
    cb = "DSErrorHint",
    Lc = "nil",
    Qc = {},
    Pc = 0,
    Oc = 0;
  o = Q.prototype;
  o.Dc = n("M");
  o.gb = function(b, c, d) {
    if (this.M != b) {
      var e = A(this, "authenticated", this.M, b);
      this.M = b;
      if (d)
        for (var i in this.o) this.o[i].gb(b, c);
      this.dispatchEvent(e)
    }
  };
  o.Df = n("sa");
  o.wg = n("Qc");
  o.Ug = function(b) {
    if (this.Qc != b) {
      if (b)
        for (var c in this.sa) this.sa[c].c == h && f(Error("Cannot change clustered property of ChannelSet to true when it contains channels with null ids."));
      this.Qc = b
    }
  };
  o.If = n("v");
  o.Rf = n("lb");
  o.sd = m("lb");
  o.Sf = n("Pb");
  o.ah = function(b) {
    if (this.Pb != b) {
      var c = A(this, "heartbeatIntervalMillis", this.Pb, b);
      this.Pb = b;
      this.f == 2 && Rc(this);
      this.dispatchEvent(c)
    }
  };
  o.ub = n("c");
  o.ra = m("c");
  o.zc = n("f");
  o.s = function(b) {
    if (this.f != b) {
      var c = A(this, "state", this.f, b);
      this.f = b;
      this.f == 2 ? (this.gb(this.v != h && this.v.M, this.yc, !1), Rc(this)) : this.f == Pc && Sc(this);
      this.b.d("'" + this.c + "' changing state to " + la(Qc, b));
      this.dispatchEvent(c)
    }
  };
  o.wc = function(b) {
    this.o.push(b);
    b.addEventListener("channelConnect", b.za);
    b.addEventListener("channelDisconnect", b.R);
    b.addEventListener("channelFault", b.X);
    this.f == 2 && b.za(Hc(this.v))
  };
  o.m = function(b) {
    if (b == h) {
      for (var c in this.o) this.o[c].m();
      if (this.K != h) Gc(this.K, 4), this.K = h
    } else {
      var d = -1;
      for (c in this.o)
        if (this.o[c] == b) {
          d = c;
          break
        }
      if (d != -1) {
        this.o.splice(d, 1);
        b.removeEventListener("channelConnect", b.za);
        b.removeEventListener("channelDisconnect", b.R);
        b.removeEventListener("channelFault", b.X);
        if (this.f == 2 || this.f == 1) b.R(Ic(this.v, !1));
        else {
          d = this.V.length;
          for (c = 0; c < d; c++) this.V[c].agent == b && (this.V.splice(c, 1), c--, d--)
        }
        if (this.o.length == 0) this.Jb = -1, this.f == 2 && (this.s(4), this.removeEventListener("message",
          this.fa), this.v.m(this))
      }
    }
  };
  o.xf = function() {
    this.m(h)
  };
  o.Eg = function(b, c) {
    this.M && f(Error("ChannelSet is already authenticated."));
    this.K != h && this.K.sb != Fc && f(Error("ChannelSet is in the process of logging in or logging out."));
    var d = h;
    if (b != h && c != h) {
      var d = new Qa,
        e = ka(b + ":" + c);
      Sa(d, e);
      d = Ta(d)
    }
    e = new D;
    e.t(8);
    e.Na(d);
    e.$("auth");
    var i = new Jc(e);
    if (this.K == h) this.K = new Ec(this, d);
    this.K.Eb[i.k.h()] = i;
    Gc(this.K, 1);
    this.send(this.K, e);
    return i
  };
  o.Fg = function() {
    this.yc = h;
    this.K != h && (this.K.sb == 3 || this.K.sb == 1) && f(Error("ChannelSet is in the process of logging in or logging out."));
    for (var b in this.o) this.o[b].Bc(h);
    for (b in this.sa) {
      var c = this.sa[b];
      c.Bc(h);
      this.jd(c) && Tc(c)
    }
    b = new D;
    b.t(9);
    b.$("auth");
    c = new Jc(b);
    if (this.K == h) this.K = new Ec(this, h);
    this.K.Eb[c.k.h()] = c;
    Gc(this.K, 3);
    this.send(this.K, b);
    return c
  };
  o.send = function(b, c) {
    var d;
    if (this.Ge || !this.Qc) d = h;
    else {
      d = new D;
      var e = b.Ja();
      e == h && (e = "cluster_request");
      d.$(e);
      d.t(7);
      this.Ge = !0
    }
    this.f == 2 ? (Sc(this), d != h && this.r(b, d), this.r(b, c), Rc(this)) : (d != h && this.V.push({
      agent: b,
      message: d
    }), this.V.push({
      agent: b,
      message: c
    }), this.f == Pc && Uc(this))
  };
  o.za = function(b) {
    this.Jb = -1;
    for (var c = h;
         (c = this.V.shift()) != h;) this.r(c.agent, c.message);
    this.s(2);
    for (var d in this.o) this.o[d].za(b)
  };
  o.R = function(b) {
    this.s(Pc);
    if (this.o.length != 0 && !b.Vb && !b.U && Uc(this)) var b = Ic(b.hb, !0, b.U, b.ta),
      c;
    else b = Ic(b.hb, !0, b.U, b.ta), this.ed(b);
    for (c in this.o) this.o[c].R(b)
  };
  o.X = function(b) {
    this.s(Pc);
    if (this.o.length != 0 && !b.Vb && !b.U && Uc(this)) {
      var b = Kc(b.hb, !0, b.Aa(), b.La(), b.Ka(), b.Sa(), b.U, b.ta),
        c;
      for (c in this.o) this.o[c].X(b)
    } else {
      b = Kc(b.hb, b.Vb, b.Aa(), b.La(), b.Ka(), b.Sa(), b.U, b.ta);
      for (c in this.o) this.o[c].X(b);
      this.ed(b)
    }
  };
  o.fa = function(b) {
    if (this.f == 2)
      for (var c in this.o) this.o[c].fa(b)
  };
  o.ed = function(b) {
    if (!b.ta)
      for (var c = h;
           (c = this.V.shift()) != h;) {
        var d = c.message,
          e = new G;
        e.G(d.S());
        e.Ca(d.h());
        e.aa("Client.Error.MessageSend");
        e.ba("Send failed because a connection could not be established.");
        e.Ua(b);
        c.agent.j(e, d)
      }
  };

  function Uc(b) {
    if (++b.Jb >= b.sa.length) return !1;
    b.s(b.Jb == 0 ? 1 : 3);
    b.v = b.sa[b.Jb];
    b.s(b.Jb == 0 ? 1 : 3);
    b.v.wc(b);
    b.addEventListener("message", b.fa);
    return !0
  }

  function Vc(b, c) {
    if (c instanceof D && c.A() == 7) {
      var d = function(c) {
        var i = c.k.u();
        if (i != h) {
          if (i instanceof Wc) i = i.source;
          else if (!(i instanceof Array)) return;
          b.removeEventListener("acknowledge", d);
          for (var c = {}, k = i.length, l = 0; l < k; l++) {
            var r = i[l],
              K;
            for (K in r) c[K] == h && (c[K] = []), c[K].push(r[K])
          }
          K = b.z();
          for (var V in K.sa) i = K.sa[V], k = i.c, c[k] && i.af(c[k])
        }
      };
      b.addEventListener("acknowledge", d)
    }
  }
  o.jd = function(b) {
    if (!b) b = this.v;
    return b.Da == Xc
  };
  o.r = function(b, c) {
    var d;
    c instanceof D && c.A() == 13 ? (d = new E, d.G(c.S()), d.Ca(c.h()), b.w(d, c), d = !0) : d = !1;
    d || (Vc(b, c), this.v.send(b, c))
  };

  function Rc(b) {
    if (b.nc == h && b.Pb > 0) window.q = b, b.nc = window.setTimeout(function() {
      this.q.Ye()
    }, b.Pb)
  }
  o.Ye = function() {
    Sc(this);
    this.v != h && (Yc(this), Rc(this))
  };

  function Yc(b) {
    if (!b.jd() && !b.v.fc()) {
      var c = new D;
      c.t(5);
      c.g("DS<3", !0);
      b.v.qd(h, c)
    }
  }

  function Sc(b) {
    if (b.nc != h) window.clearTimeout(b.nc), b.nc = h
  }
  p("flex.client.channels.ChannelSet", Q);
  p("flex.client.channels.ChannelSet.DEFAULT_HEARTBEAT_INTERVAL_MILLIS", Mc);
  p("flex.client.channels.ChannelSet.PROPERTY_AUTHENTICATED", "authenticated");
  p("flex.client.channels.ChannelSet.PROPERTY_HEARTBEAT_INTERVAL_MILLIS", "heartbeatIntervalMillis");
  p("flex.client.channels.ChannelSet.PROPERTY_STATE", "state");
  p("flex.client.channels.ChannelSet.State", Qc);
  p("flex.client.channels.ChannelSet.State.DISCONNECTED", Pc);
  p("flex.client.channels.ChannelSet.State.CONNECTING", 1);
  p("flex.client.channels.ChannelSet.State.CONNECTED", 2);
  p("flex.client.channels.ChannelSet.State.RECONNECTING", 3);
  p("flex.client.channels.ChannelSet.State.DISCONNECTING", 4);
  p("flex.client.channels.ChannelSet.instanceCounter", Oc);
  p("flex.client.channels.ChannelSet.prototype.isAuthenticated", Q.prototype.Dc);
  p("flex.client.channels.ChannelSet.prototype.getChannels", Q.prototype.Df);
  p("flex.client.channels.ChannelSet.prototype.isClustered", Q.prototype.wg);
  p("flex.client.channels.ChannelSet.prototype.setClustered", Q.prototype.Ug);
  p("flex.client.channels.ChannelSet.prototype.getCurrentChannel", Q.prototype.If);
  p("flex.client.channels.ChannelSet.prototype.getFlexClientId", Q.prototype.Rf);
  p("flex.client.channels.ChannelSet.prototype.setFlexClientId", Q.prototype.sd);
  p("flex.client.channels.ChannelSet.prototype.getHeartbeatIntervalMillis", Q.prototype.Sf);
  p("flex.client.channels.ChannelSet.prototype.setHeartbeatIntervalMillis", Q.prototype.ah);
  p("flex.client.channels.ChannelSet.prototype.getId", Q.prototype.ub);
  p("flex.client.channels.ChannelSet.prototype.setId", Q.prototype.ra);
  p("flex.client.channels.ChannelSet.prototype.getState", Q.prototype.zc);
  p("flex.client.channels.ChannelSet.prototype.setState", Q.prototype.s);
  p("flex.client.channels.ChannelSet.prototype.connect", Q.prototype.wc);
  p("flex.client.channels.ChannelSet.prototype.disconnect", Q.prototype.m);
  p("flex.client.channels.ChannelSet.prototype.disconnectAll", Q.prototype.xf);
  p("flex.client.channels.ChannelSet.prototype.login", Q.prototype.Eg);
  p("flex.client.channels.ChannelSet.prototype.logout", Q.prototype.Fg);
  p("flex.client.channels.ChannelSet.prototype.send", Q.prototype.send);
  p("flex.client.channels.ChannelSet.prototype.internalSend", Q.prototype.r);

  function Zc(b, c, d) {
    this.bf = b;
    this.We = c;
    this.data = d
  };

  function $c(b) {
    M.call(this, b)
  }
  s($c, M);

  function ad(b) {
    var c = b[0],
      d = tb(c);
    d != h && (c = d, b[0] = c);
    b = h;
    if (c != h && c.indexOf(">") == 0) b = bd(c);
    else if (c != h && c.length > 0) try {
      b = new(eval(c)), b._CLASS = c
    } catch (e) {}
    b == h && (b = bd(c));
    return b
  }

  function bd(b) {
    var c = {};
    b != h && b.length > 0 && (c._CLASS = b);
    return c
  };

  function cd(b) {
    M.call(this, b);
    this.tb = h;
    this.Ec = []
  }
  s(cd, $c);
  o = cd.prototype;
  o.reset = function() {
    this.Ec = [];
    if (this.tb != h) this.tb = h
  };
  o.n = function() {
    return this.Hc(this.ga())
  };
  o.Hc = function(b) {
    var c;
    switch (b) {
      case pc:
        c = this.Gc();
        break;
      case oc:
        c = this.md();
        break;
      case vc:
        c = this.Ma();
        break;
      case jc:
        if (this.tb == h) this.tb = new dd(this.data), this.tb.O = this.O;
        c = this.tb.n();
        this.O = this.tb.O;
        break;
      case tc:
        b = this.Ab();
        c = Array(b);
        this.Ec.push(c);
        for (var d = 0; d < b; d++) c.push(this.n());
        break;
      case nc:
        c = ed(this, this.Ma());
        break;
      case uc:
        c = this.ha(this.Ab());
        break;
      case mc:
        c = ed(this, h);
        break;
      case kc:
        c = this.ae();
        break;
      case sc:
        c = h;
        break;
      case qc:
        c = this.$d();
        break;
      case fd:
        this.Ab();
        b = {};
        this.Ec.push(b);
        c = this.Ma();
        for (d = this.ga(); d != gd;) d != gd && (d = this.Hc(d), c != "length" && (b[c] = d)), c = this.Ma(), d = this.ga();
        c = b;
        break;
      case wc:
        c = this.Ec[this.Bb()];
        break;
      case hd:
        c = g;
        break;
      case id:
        f(Error("Unsupported type found in AMF stream."));
      case gd:
        f(Error("Unexpected object end tag in AMF stream."));
      case jd:
        f(Error("AMF record sets are not supported."));
      default:
        f(Error("Unknown type: " + b))
    }
    return c
  };
  o.$d = function() {
    var b = this.Gc(),
      c = this.Bb();
    return new Date(b + c * 6E4)
  };

  function ed(b, c) {
    var d = ad([c, h]);
    b.Ec.push(d);
    for (var e = b.Ma(), i = b.ga(); i != gd;) d[e] = b.Hc(i), e = b.Ma(), i = b.ga();
    return d
  }
  o.ae = function() {
    var b = this.ha(this.Ab());
    return Ab(b)
  };

  function kd(b) {
    this.version = b !== g && typeof b == "number" ? b : 0;
    this.headers = [];
    this.Yb = []
  };

  function ld() {
    this.W = h
  }
  ld.prototype.Xd = function(b) {
    this.W = new cd(b)
  };

  function md() {
    this.D = h;
    this.version = 3
  }
  md.prototype.Xd = function() {
    this.D = new hc;
    this.D.Id = this.version >= 3
  };
  md.prototype.setVersion = m("version");

  function od(b) {
    this.Vd = typeof b == "number" ? b : 0
  }
  od.prototype.Kc = function(b) {
    var c = new kd(3),
      d;
    for (d in b) c.Yb.push(new Zc(h, "/" + this.Vd, [b[d]])), this.Vd++;
    if (this.$e == h) this.$e = new md;
    b = this.$e;
    b.setVersion(c.version);
    b.Xd();
    try {
      b.D.Id = b.version >= 3;
      b.D.Gb(c.version);
      b.D.Gb(c.headers.length);
      for (var e in c.headers) {
        d = b;
        var i = c.headers[e];
        lc(d.D, i.name);
        d.D.ne(i.Gg);
        d.D.Fb(1);
        d.D.reset();
        d.D.L(i.data)
      }
      b.D.Gb(c.Yb.length);
      var k = "";
      for (e = 0; e < c.Yb.length; e++) {
        var i = b,
          l = c.Yb[e];
        l.bf == h ? lc(i.D, "null") : lc(i.D, l.bf);
        l.We == h ? lc(i.D, "null") : lc(i.D, l.We);
        i.D.Fb(1);
        i.D.reset();
        i.D.L(l.data);
        k += b.D.flush()
      }
    } catch (r) {
      f(Error("Amf serialization failed : " + r))
    }
    return k
  };
  od.prototype.fb = function(b) {
    var c;
    if (this.Le == h) this.Le = new ld;
    c = this.Le;
    c.Xd(b);
    try {
      var d = c.W.Bb();
      d == 1 && (d = 0);
      d != 0 && d != 3 && f(Error("Unsupported AMF version: " + d));
      for (var e = new kd(d), i = c.W.Bb(), b = 0; b < i; b++) {
        var d = c,
          k = d.W.Ma(),
          l = d.W.md();
        d.W.Ab();
        d.W.reset();
        var r = d.W.n();
        e.headers.push(new pd(k, l, r))
      }
      for (var K = c.W.Bb(), i = 0; i < K; i++) {
        var k = c,
          V = k.W.Ma(),
          ob = k.W.Ma();
        k.W.Ab();
        k.W.reset();
        var La = k.W.n();
        e.Yb.push(new Zc(V, ob, La))
      }
    } catch (nb) {
      f(Error("Amf deserialization failed : " + nb))
    }
    c = e.Yb;
    var e = [],
      ba;
    for (ba in c) e.push(c[ba]);
    return e
  };

  function qd(b) {
    this.a = b;
    this.uc = new od
  }
  o = qd.prototype;
  o.wb = ca;
  o.hd = ca;
  o.r = ca;
  o.fb = function(b) {
    return this.uc.fb(b)
  };
  o.Kc = function(b) {
    var c = [];
    if (b instanceof Array)
      for (var d = 0; d < b.length; d++) c.push(this.a.qa(b[d]));
    else c.push(this.a.qa(b));
    return this.uc.Kc(c)
  };

  function rd(b) {
    qd.call(this, b);
    this.Vd = 0;
    if (this.a.Da == Xc) this.Zd = new sd(this)
  }
  s(rd, qd);
  o = rd.prototype;
  o.wb = function() {
    var b = new D;
    b.t(5);
    b.g(u.Xa, this.a.z().lb);
    b.g("DSMessagingVersion", this.a.Cd);
    td(this, b, h, new ea(this))
  };
  o.hd = function(b) {
    ud(this.a) && (b || (b = new D, b.t(12), b.g(u.Xa, this.a.z().lb), td(this, b, h, new vd(this))), this.m(), this.a.f != wd && (xd(this.a), this.a.s(wd)))
  };
  o.r = function(b) {
    var c;
    if (c = this.a.Da == yd && !(b.message instanceof D)) a: {
      c = this.a.z().o;
      if (c != h)
        for (var d = 0; d < c.length; d++)
          if (c[d] != b.l && c[d] instanceof R && c[d].oa) {
            c = !0;
            break a
          }
      c = !1
    }
    c ? (c = [], c.push(zd(this)), c.push(b.message), c.push(zd(this)), td(this, c, new Ad(b.l, c, this.a, this.uc), new t(this))) : this.sendMessage(b)
  };
  o.fc = function() {
    return this.a.Da == Xc ? this.Zd.fc() : !1
  };
  o.sendMessage = function(b) {
    td(this, b.message, new Bd(b, this), new t(this))
  };

  function td(b, c, d, e) {
    if (d) e.Jc = d;
    c = b.Kc(c);
    b = new Cd(b.a.J);
    b.Na(c);
    b.Ud = e;
    Dd(b)
  }
  o.m = j();
  o.Wd = function() {
    this.a.Da == Xc && this.Zd.Wd()
  };
  o.Kd = function() {
    this.a.Da == Xc && this.Zd.Kd()
  };
  o.eb = function(b) {
    this.a.eb(Kc(this.a, !1, "Connection failed.", h, h, b, !1, !1))
  };

  function zd(b) {
    var c = new D;
    c.t(2);
    c.g(u.Xa, b.a.z().lb);
    return c
  }

  function sd(b) {
    this.p = b;
    this.gc = 0;
    this.zb = h
  }
  sd.prototype.fc = function() {
    return this.gc > 0
  };
  sd.prototype.Wd = function() {
    this.gc++;
    this.gc > 0 && Ed(this, this.p.a.Yc)
  };
  sd.prototype.Kd = function() {
    this.gc--;
    if (this.gc <= 0) {
      if (this.zb != h) this.p.a.b.d("'" + this.p.a.c + "' channel stopped polling."), window.clearTimeout(this.zb), this.zb = window.Z = h;
      this.gc = 0
    }
  };

  function Ed(b, c) {
    if (b.zb == h) window.Z = b, b.zb = window.setTimeout(function() {
      if (window.Z != h) {
        window.Z.p.a.b.d("'" + window.Z.p.a.c + "' channel sending poll request.");
        var b = new Fd(window.Z);
        td(window.Z.p, zd(window.Z.p), h, b)
      }
    }, c)
  }

  function Fd(b) {
    this.Z = b;
    t.call(this, b.p)
  }
  s(Fd, t);
  Fd.prototype.dc = function(b) {
    this.Z.zb = h;
    this.xd(!1, b)
  };
  Fd.prototype.ec = function(b) {
    this.Z.zb = h;
    if (b.status == 200) {
      if (Fd.e.ec.call(this, b), b = this.Z.p.fb(b.body)[0].data, b.getHeader("DSNoOpPoll") == h) {
        var c = b.u();
        if (c != h && c instanceof Array)
          for (var d = 0; d < c.length; d++) this.p.a.fa(ia(c[d]));
        else b instanceof E || f(Error("Unsupported poll response."));
        this.Z.fc() && (b = b.getHeader("DSPollWait"), b = b == h ? 0 : b, b != 0 ? Ed(this.Z, b) : Ed(this.Z, this.p.a.Yc))
      }
    } else this.dc("Connect attempt failed. Server returned Http status code: " + b.status)
  };

  function S() {
    this._CLASS = "flex.client.documentservices.DocumentReference";
    this.referenceType = 0;
    this.bytes = this.charsetName = this.text = this.fileRef = this.url = this.xml = this.contentType = h;
    this.maxInlineSize = 0;
    this.transactionBound = !1;
    this.disposalTimeout = 0;
    this.attributes = h
  }
  o = S.prototype;
  o.fg = n("referenceType");
  o.mh = m("referenceType");
  o.Hf = n("contentType");
  o.Wg = m("contentType");
  o.sg = n("xml");
  o.yh = m("xml");
  o.qg = n("url");
  o.xh = m("url");
  o.Qf = n("fileRef");
  o.$g = m("fileRef");
  o.mg = n("text");
  o.uh = m("text");
  o.Ef = n("charsetName");
  o.Sg = m("charsetName");
  o.Bf = n("bytes");
  o.Rg = m("bytes");
  o.Zf = n("maxInlineSize");
  o.pg = n("transactionBound");
  o.Lf = n("disposalTimeout");
  o.Af = n("attributes");
  o.setAttribute = function(b, c) {
    this.attributes === h && (this.attributes = {});
    this.attributes !== h && (this.attributes[b] = c)
  };
  o.getAttribute = function(b) {
    return this.attributes === h ? h : this.attributes[b]
  };
  o.removeAttribute = function(b) {
    if (this.attributes !== h) {
      delete this.attributes[b];
      var b = !0,
        c;
      for (c in this.attributes) {
        b = !1;
        break
      }
      b && (this.attributes = h)
    }
  };
  o.Dg = function() {
    if (this.attributes === h) return h;
    else {
      var b = [],
        c;
      for (c in this.attributes) b.push(c);
      return b
    }
  };
  p("flex.client.documentservices.DocumentReference", S);
  p("flex.client.documentservices.DocumentReference.REF_TYPE_URL", 1);
  p("flex.client.documentservices.DocumentReference.REF_TYPE_FILE", 2);
  p("flex.client.documentservices.DocumentReference.REF_TYPE_INLINE", 3);
  p("flex.client.documentservices.DocumentReference.prototype.getReferenceType", S.prototype.fg);
  p("flex.client.documentservices.DocumentReference.prototype.setReferenceType", S.prototype.mh);
  p("flex.client.documentservices.DocumentReference.prototype.getContentType", S.prototype.Hf);
  p("flex.client.documentservices.DocumentReference.prototype.setContentType", S.prototype.Wg);
  p("flex.client.documentservices.DocumentReference.prototype.getXML", S.prototype.sg);
  p("flex.client.documentservices.DocumentReference.prototype.setXML", S.prototype.yh);
  p("flex.client.documentservices.DocumentReference.prototype.getURL", S.prototype.qg);
  p("flex.client.documentservices.DocumentReference.prototype.setURL", S.prototype.xh);
  p("flex.client.documentservices.DocumentReference.prototype.getFileRef", S.prototype.Qf);
  p("flex.client.documentservices.DocumentReference.prototype.setFileRef", S.prototype.$g);
  p("flex.client.documentservices.DocumentReference.prototype.getText", S.prototype.mg);
  p("flex.client.documentservices.DocumentReference.prototype.setText", S.prototype.uh);
  p("flex.client.documentservices.DocumentReference.prototype.getCharsetName", S.prototype.Ef);
  p("flex.client.documentservices.DocumentReference.prototype.setCharsetName", S.prototype.Sg);
  p("flex.client.documentservices.DocumentReference.prototype.getBytes", S.prototype.Bf);
  p("flex.client.documentservices.DocumentReference.prototype.setBytes", S.prototype.Rg);
  p("flex.client.documentservices.DocumentReference.prototype.getMaxInlineSize", S.prototype.Zf);
  p("flex.client.documentservices.DocumentReference.prototype.getTransactionBound", S.prototype.pg);
  p("flex.client.documentservices.DocumentReference.prototype.getDisposalTimeout", S.prototype.Lf);
  p("flex.client.documentservices.DocumentReference.prototype.getAttributes", S.prototype.Af);
  p("flex.client.documentservices.DocumentReference.prototype.setAttribute", S.prototype.setAttribute);
  p("flex.client.documentservices.DocumentReference.prototype.getAttribute", S.prototype.getAttribute);
  p("flex.client.documentservices.DocumentReference.prototype.removeAttribute", S.prototype.removeAttribute);
  p("flex.client.documentservices.DocumentReference.prototype.listAttributes", S.prototype.Dg);
  p("flex.client.documentservices.DocumentReference.constructRequestForUpload", S.prototype.Kh);

  function Ia(b) {
    C.call(this);
    this._CLASS = "flex.messaging.messages.AsyncMessageExt";
    this._message = b
  }
  s(Ia, C);
  Ia.prototype.Ta = function(b) {
    this.Qa(b)
  };
  Ia.prototype.ja = function(b) {
    this._message != h ? this._message.Ra(b) : Ia.e.ja.call(this, b)
  };
  p("flex.messaging.messages.AsyncMessageExt", Ia);
  p("flex.messaging.messages.AsyncMessageExt.prototype.readExternal", Ia.prototype.Ta);
  p("flex.messaging.messages.AsyncMessageExt.prototype.writeExternal", Ia.prototype.ja);

  function Gd(b) {
    qd.call(this, b);
    this.ob = {};
    this.V = [];
    this.Ee = !1;
    this.lc = this.Rb = h
  }
  s(Gd, qd);
  o = Gd.prototype;
  o.wb = function() {
    this.a.b.d("'" + this.a.c + "' is setting up WebSocket connection.");
    if ("WebSocket" in window) try {
      this.Wa = new AdvancedWebSocket(this.a.J, [], {debug: false, connectionTimeout: 3000, maxRetries: 0}), this.Wa.parent = this, this.Wa.onopen = this.Dh, this.Wa.onclose = this.Bh, this.a.b.d("'" + this.a.c + "' underlying WebSocket is connecting.")
    } catch (b) {
      this.a.b.d("'Encountered error [" + b + "]' while creating WebSocket channel."), this.a.eb(Kc(this.a, !1, "Client.Error.WebSocket", "WebSocket#setupWebSocket", "Encountered errror while creating channel.", b, !1, !1))
    } else this.a.eb(Kc(this.a, !1, "Client.Error.WebSocket", "WebSocket#internalConnect", "WebSocket is not supported in the browser.", h, !1, !1))
  };
  o.hd = function(b) {
    if (ud(this.a)) {
      this.a.b.d("'" + this.a.c + "' underlying WebSocket is disconnecting.");
      this.Ee = !0;
      var c = this;
      this.lc = window.setInterval(function() {
        if (c.V.length == 0 || c.Wa == h) c.a.b.d("'" + c.a.c + "' tearing down current disconnect task with id: " + c.lc), this.clearInterval(c.lc), c.lc = h, c.Wa.onclose = function() {
          c.a.b.d("'" + this.parent.a.c + "' underlying WebSocket closed.")
        }, c.Wa.close()
      }, 20);
      this.a.b.d("'" + this.a.c + "' setup disconnect task with id: " + this.lc);
      xd(this.a, b)
    }
  };
  o.r = function(b) {
    var c = b.message;
    this.ob[c.h()] = b;
    //console.dir(this.ob);
    b = this.Kc(c);
    this.a.b.d("'" + this.a.c + "' Pushing message " + c.h() + " to the pending sends queue.");
    this.V.push(b)
  };

  function Hd(b) {
    if (b.Rb == h)
      b.Rb = window.setInterval(function() {
        if (b.Wa == h || b.Ee && b.V.length == 0) {
          b.a.b.d("'" + b.a.c + "' tearing down current message send task with id: " + b.Rb);
          this.clearInterval(b.Rb);
          b.Rb = h;
        } else {
          if (b.Wa.bufferedAmount == 0 && b.V.length != 0) {
            b.Wa.send(b.V[0]);
            b.V.shift();
          }
        }
      }, 20);
    b.a.b.d("'" + b.a.c + "' setup message send task with id: " + b.Rb)
  }

  function clearObject(b) {
    b.a.b.d("'" + b.a.c + "' clearing pending sends object");
    setTimeout(function() {
      b.ob = {};
      clearObject(b);
    }, 420000);
  }

  o.Dh = function(b) {
    this.parent.a.b.d("'" + this.parent.a.c + "' underlying WebSocket is sending ping message.");
    var c = new D;
    c.t(5);
    c.g(u.Xa, this.parent.a.z().lb);
    c.g("DSMessagingVersion", this.parent.a.Cd);
    c = this.parent.Kc(c);
    this.onmessage = function(b) {
      var c = this.parent,
        b = b.data;
      c.a.b.d("'" + c.a.c + "' underlying WebSocket received ping response.");
      b = c.fb(b)[0].data;
      c.a.z().sd(b.getHeader(u.Xa));
      b.F("DSMessagingVersion") && fa(c.a, b.getHeader("DSMessagingVersion"));
      Hd(c);
      ga(c.a);
      clearObject(this.parent);
      this.onmessage = this.parent.Ch
    };
    try {
      sendWS(this, c) ? this.parent.a.b.d("'" + this.parent.a.c + "' underlying WebSocket sent ping message.") : (this.parent.a.b.error("'" + this.parent.a.c + "' underlying WebSocket failed to send ping message."), b = Kc(this.a, !1, "Client.Error.WebSocket", "WebSocket#wsOnOpen", '"Buffer full / Failed to send ping message.', h, !1, !1), this.parent.a.eb(b))
    } catch (d) {
      this.parent.a.b.d("'Encountered error [" + d + "]' while sending ping message."), b = Kc(this.a, !1, "Client.Error.WebSocket", "WebSocket#wsOnOpen", "Encountered errror while sending ping message.",
        d, !1, !1), this.parent.a.eb(b)
    }
  };

  o.Bh = function() {
    this.parent.a.b.error("'" + this.parent.a.c + "' underlying WebSocket closed.");
    this.parent.hd()
  };
  o.Ch = function(b) {
    this.parent.a.b.d("'" + this.parent.a.c + "' underlying WebSocket received message.");
    b = this.parent.fb(b.data)[0].data;
    if (b instanceof G) {
      if (b.ea()) {
        var c = b.ea(),
          c = this.parent.ob[c];
        delete this.parent.ob.Ke;
        Ac(c);
        b.g(cb, !0);
        if (c.l) {
          c.l.w(b, c.message);
        }
        // We need this call in order to trigger RPC Fault listener
        c.l.j(b, c.message);
      }
    } else if (b instanceof E || b instanceof F) {
        c = b.ea();
        c = this.parent.ob[c];
        delete this.parent.ob.Ke;
        Ac(c);
        if (c && c.l) {
          c.l.w(b, c.message);
        }
    }
    else if (b instanceof C || b instanceof Ia) {
      this.parent.a.fa(ia(b));
    }
    else {
      c = b.ea();
      c = this.parent.ob[c];
      delete this.parent.ob.Ke;
      var d = new G;
      d.Ca(c.message.h());
      d.ba("Unsupported Amf response message type: " + b._CLASS);
      d.Ua(b);
      d.aa("Client.Error.DeliveryInDoubt");
      c.l.j(d, c.message)
    }
  };

  function Id() {
    this._CLASS = "flex.client.documentservices.JobStatus";
    this.statusCode = 0
  }
  Id.prototype.jg = n("statusCode");
  Id.prototype.sh = m("statusCode");
  Id.prototype.toString = function() {
    switch (this.statusCode) {
      default: return "JOB_STATUS_UNKNOWN";
      case 1:
        return "JOB_STATUS_QUEUED";
      case 2:
        return "JOB_STATUS_IN_PROGRESS";
      case 3:
        return "JOB_STATUS_COMPLETED";
      case 4:
        return "JOB_STATUS_FAILED";
      case 5:
        return "JOB_STATUS_RUNNING"
    }
  };
  p("flex.client.documentservices.JobStatus", Id);
  p("flex.client.documentservices.JobStatus.JOB_STATUS_UNKNOWN", 0);
  p("flex.client.documentservices.JobStatus.JOB_STATUS_QUEUED", 1);
  p("flex.client.documentservices.JobStatus.JOB_STATUS_IN_PROGRESS", 2);
  p("flex.client.documentservices.JobStatus.JOB_STATUS_COMPLETED", 3);
  p("flex.client.documentservices.JobStatus.JOB_STATUS_FAILED", 4);
  p("flex.client.documentservices.JobStatus.JOB_STATUS_RUNNING", 5);
  p("flex.client.documentservices.JobStatus.prototype.getStatusCode", Id.prototype.jg);
  p("flex.client.documentservices.JobStatus.prototype.setStatusCode", Id.prototype.sh);
  p("flex.client.documentservices.JobStatus.prototype.toString", Id.prototype.toString);

  function Ma(b) {
    D.call(this);
    this._CLASS = "flex.messaging.messages.CommandMessageExt";
    this._message = b
  }
  s(Ma, D);
  Ma.prototype.Ta = function(b) {
    this.Qa(b)
  };
  Ma.prototype.ja = function(b) {
    this._message != h ? this._message.Ra(b) : Ma.e.ja.call(this, b)
  };
  p("flex.messaging.messages.CommandMessageExt", Ma);
  p("flex.messaging.messages.CommandMessageExt.prototype.readExternal", Ma.prototype.Ta);
  p("flex.messaging.messages.CommandMessageExt.prototype.writeExternal", Ma.prototype.ja);

  function Jd() {
    this._CLASS = "flex.client.documentservices.JobId";
    this.jobId = h;
    this.persistent = !1
  }
  Jd.prototype.Xf = n("jobId");
  Jd.prototype.fh = m("jobId");
  Jd.prototype.bg = n("persistent");
  Jd.prototype.ih = m("persistent");
  p("flex.client.documentservices.JobId", Jd);
  p("flex.client.documentservices.JobId.prototype.getJobId", Jd.prototype.Xf);
  p("flex.client.documentservices.JobId.prototype.setJobId", Jd.prototype.fh);
  p("flex.client.documentservices.JobId.prototype.getPersistent", Jd.prototype.bg);
  p("flex.client.documentservices.JobId.prototype.setPersistent", Jd.prototype.ih);

  function T(b, c, d) {
    this.T = {};
    this.c = b;
    this.nf = this.J = c;
    this.Da = h;
    y(d) ? (Kd(this) && f(Error("HTTP mode does not make sense with WebSocket; set it to null when WebSocket is being used.")), this.Da = d) : this.Da = Kd(this) ? h : 0;
    var e;
    b = Ld(this);
    "http" == b || "https" == b ? e = new rd(this) : Kd(this) ? e = new Gd(this) : f(Error("Unsupported protocol: " + Ld(this)));
    this.p = e;
    this.M = !1;
    this.Rc = this.H = h;
    this.Sc = 0;
    this.Lb = h;
    this.mc = -1;
    this.ua = h;
    this.Yc = Md;
    this.b = Za(I.zd);
    this.Cd = 1;
    this.pb = new Nd(this);
    this.ya = Od;
    this.f = wd;
    this.Hd = !1;
    ub("DSA", "flex.messaging.messages.AsyncMessageExt", !0);
    ub("DSK", "flex.messaging.messages.AcknowledgeMessageExt", !0);
    ub("DSC", "flex.messaging.messages.CommandMessageExt", !0);
    ub("com.adobe.idp.dsc.provider.impl.lcr.DocumentReference", "flex.client.documentservices.DocumentReference", !0);
    ub("com.adobe.idp.dsc.registry.infomodel.impl.FaultImpl", "flex.client.documentservices.Fault", !0);
    ub("com.adobe.idp.dsc.impl.FaultResponseImpl", "flex.client.documentservices.FaultResponse", !0);
    ub("com.adobe.idp.dsc.provider.impl.lcr.RemotingJobId",
      "flex.client.documentservices.JobId", !0);
    ub("com.adobe.idp.jobmanager.common.JobStatus", "flex.client.documentservices.JobStatus", !0)
  }
  s(T, z);
  var Md = 3E3,
    Od = 0,
    yd = 1,
    Xc = 2,
    Pd = {},
    wd = 0;
  o = T.prototype;
  o.Dc = n("M");
  o.gb = function(b) {
    if (b != this.M) {
      var c = A(this, "authenticated", this.M, b);
      this.M = b;
      this.H.gb(this.M, this.Lb, !1);
      this.dispatchEvent(c)
    }
  };
  o.z = n("H");
  o.Gf = n("Sc");
  o.Vg = function(b) {
    x("Connect timeout", b);
    this.Sc = b
  };
  o.Of = n("ua");
  o.af = function(b) {
    var c;
    if (c = b != h) a: if (this.ua == h || this.ua.length != b.length) c = !0;
    else {
      for (c = 0; c < b.length; c++) {
        for (var d = b[c], e = !1, i = 0; i < this.ua.length; i++)
          if (d == this.ua[i]) {
            e = !0;
            break
          }
        if (!e) {
          c = !0;
          break a
        }
      }
      c = !1
    }
    if (c) this.ua = b, this.mc = -1
  };
  o.Uf = n("Da");
  o.Vf = n("Yc");
  o.dh = function(b) {
    x("Http polling interval millis", b);
    this.Yc = b
  };
  o.ub = n("c");
  o.ac = n("ya");
  o.jc = function(b) {
    x("Request timeout millis", b);
    this.ya = b
  };
  o.zc = n("f");
  o.s = function(b) {
    if (this.f != b) {
      var c = A(this, "state", this.f, b);
      this.f = b;
      if (this.f == 2) this.pb.kd = !0;
      this.b.d("'" + this.c + "' channel state: " + la(Pd, b));
      this.dispatchEvent(c)
    }
  };
  o.rg = n("J");
  o.wc = function(b) {
    this.H = b;
    this.H.addEventListener("channelConnect", b.za);
    this.H.addEventListener("channelDisconnect", b.R);
    this.H.addEventListener("channelFault", b.X);
    this.C = !0;
    Qd(this);
    this.wb()
  };
  o.m = function(b) {
    this.H != b && f(Error("ChannelSet is not connected to the Channel."));
    this.H.removeEventListener("channelConnect", b.za);
    this.H.removeEventListener("channelDisconnect", b.R);
    this.H.removeEventListener("channelFault", b.X);
    this.C = !1;
    this.f == 2 && (this.H.R(Ic(this, !1, !1, !1)), this.p.hd(!1));
    this.H.sd(Lc)
  };
  o.send = function(b, c) {
    if (c.$b() == h || c.$b().length == 0)(b.Ja() == h || b.Ja().length == 0) && f(Error("Message or agent must specify a destination.")), c.$(b.Ja());
    c.g(u.Ce, this.c);
    c.g(u.Xa, this.H.lb);
    this.b.d("'" + this.c + "' channel sending message '" + c.h() + "' for '" + b.c + "'.");
    var d = new yc(b, c),
      e = d.message;
    e.F(u.Oc) ? (e = e.getHeader(u.Oc), zc(d, e)) : this.ya > 0 && zc(d, this.ya);
    this.p.r(d)
  };
  o.eb = function(b) {
    Rd(this);
    this.s(wd);
    this.b.error("'" + this.c + "' channel connect failed.");
    !b.U && this.wd() ? (Sd(this), b = Ic(this, !0, b.U, this.f == 2 || this.f == 1), this.H.dispatchEvent(b), this.dispatchEvent(b)) : Td(this.pb);
    this.f == 2 || this.f == 1 || (this.f == 3 && b.ee(!0), this.H.dispatchEvent(b), this.dispatchEvent(b))
  };

  function ga(b) {
    Rd(b);
    if (b.f != 2) {
      b.s(2);
      b.mc = -1;
      var c = Hc(b);
      b.H.dispatchEvent(c);
      b.dispatchEvent(c);
      Td(b.pb)
    }
  }

  function xd(b, c) {
    y(c) || (c = !1);
    b.s(wd);
    !c && b.wd() ? Sd(b) : Td(b.pb);
    var d = Ic(b, !1, !1, !1);
    b.H.dispatchEvent(d);
    b.dispatchEvent(d)
  }
  o.vb = function() {
    return this.pb.vb()
  };
  o.qa = function(b) {
    if (!this.Hd || !(b instanceof Ha)) return b;
    var c = b.qa();
    return c != h ? c : b
  };

  function fa(b, c) {
    b.Hd = c >= b.Cd;
    b.Hd && b.b.d("'" + b.c + "' channel is using small messages.")
  }

  function Tc(b) {
    b.p instanceof rd && b.p.Kd()
  }
  o.wb = function() {
    if (this.f != 1) {
      this.s(1);
      if (!(this.J == h || this.J.length == 0)) {
        var b = this.J.indexOf("{server.name}");
        if (b != -1) this.J = this.J.replace("{server.name}", window.location.hostname);
        b = this.J.indexOf("{server.port}");
        if (b != -1) this.J = this.J.replace("{server.port}", window.location.port)
      }
      this.p.wb()
    }
  };
  o.fc = function() {
    return this.p instanceof rd ? this.p.fc() : !1
  };

  function ud(b) {
    if (b.f != 4) return b.s(4), !0;
    return !1
  }

  function Sd(b) {
    if (!Ud(b.pb)) b.mc++, b.ua != h && b.mc + 1 <= b.ua.length ? (b.s(3), b.J = b.ua[b.mc], b.b.info("'" + b.c + "' channel attempting to failover to " + b.J), b.wb()) : (b.b.info("'" + b.c + "' channel has exhausted failover options and has reset to its initial url."), b.J = b.nf)
  }
  o.Bc = m("Lb");

  function Kd(b) {
    b = Ld(b);
    return "ws" == b || "wss" == b
  }

  function Ld(b) {
    if (b.J == h || b.J.indexOf(":") == -1) return window.location.protocol.substr(0, window.location.protocol.indexOf(":"));
    return b.J.substr(0, b.J.indexOf(":"))
  }
  o.fa = function(b) {
    this.H.dispatchEvent(b);
    this.dispatchEvent(b)
  };
  o.qd = function(b, c) {
    this.p.r(new yc(b, c))
  };
  o.wd = function() {
    return this.C && (this.pb.wd() || this.ua != h && this.ua.length > 0)
  };

  function Qd(b) {
    if (b.Sc != 0) window.a = b, b.Rc = window.setTimeout(function() {
      var b = this.a;
      Rd(b);
      if (b.f != 2) b.C = !1, b.eb(Kc(b, !1, "Channel.Connect.Failed", "error", "Connect attempt timed out"))
    }, b.Sc)
  }

  function Rd(b) {
    if (b.Rc != h) window.clearTimeout(b.Rc), b.Rc = h
  }
  p("flex.client.channels.Channel", T);
  p("flex.client.channels.Channel.DEFAULT_HTTP_POLLING_INTERVAL_MILLIS", Md);
  p("flex.client.channels.Channel.DEFAULT_REQUEST_TIMEOUT_MILLIS", Od);
  p("flex.client.channels.Channel.HttpMode", {});
  p("flex.client.channels.Channel.HttpMode.REGULAR", 0);
  p("flex.client.channels.Channel.HttpMode.PIGGYBACKING", yd);
  p("flex.client.channels.Channel.HttpMode.POLLING", Xc);
  p("flex.client.channels.Channel.PROPERTY_AUTHENTICATED", "authenticated");
  p("flex.client.channels.Channel.PROPERTY_STATE", "state");
  p("flex.client.channels.Channel.State", Pd);
  p("flex.client.channels.Channel.State.DISCONNECTED", wd);
  p("flex.client.channels.Channel.State.CONNECTING", 1);
  p("flex.client.channels.Channel.State.CONNECTED", 2);
  p("flex.client.channels.Channel.State.RECONNECTING", 3);
  p("flex.client.channels.Channel.State.DISCONNECTING", 4);
  p("flex.client.channels.Channel.prototype.getChannelSet", T.prototype.z);
  p("flex.client.channels.Channel.prototype.getId", T.prototype.ub);
  p("flex.client.channels.Channel.prototype.getUrl", T.prototype.rg);
  p("flex.client.channels.Channel.prototype.isAuthenticated", T.prototype.Dc);
  p("flex.client.channels.Channel.prototype.getState", T.prototype.zc);
  p("flex.client.channels.Channel.prototype.getHttpMode", T.prototype.Uf);
  p("flex.client.channels.Channel.prototype.getConnectTimeoutMillis", T.prototype.Gf);
  p("flex.client.channels.Channel.prototype.setConnectTimeoutMillis", T.prototype.Vg);
  p("flex.client.channels.Channel.prototype.getFailoverURLs", T.prototype.Of);
  p("flex.client.channels.Channel.prototype.setFailoverURLs", T.prototype.af);
  p("flex.client.channels.Channel.prototype.getHttpPollingIntervalMillis", T.prototype.Vf);
  p("flex.client.channels.Channel.prototype.setHttpPollingIntervalMillis", T.prototype.dh);
  p("flex.client.channels.Channel.prototype.getRequestTimeoutMillis", T.prototype.ac);
  p("flex.client.channels.Channel.prototype.setRequestTimeoutMillis", T.prototype.jc);
  p("flex.client.channels.Channel.prototype.connect", T.prototype.wc);
  p("flex.client.channels.Channel.prototype.disconnect", T.prototype.m);
  p("flex.client.channels.Channel.prototype.send", T.prototype.send);

  function Vd() {
    C.call(this);
    this._CLASS = "flex.messaging.messages.ReliabilityMessage";
    this.operation = Wd
  }
  s(Vd, C);
  var Wd = 1E3;
  Vd.prototype.t = m("operation");
  Vd.prototype.A = n("operation");

  function Xd(b) {
    H.call(this, b.Ja());
    this.ra(b.c);
    this.l = b
  }
  s(Xd, H);
  Xd.prototype.w = function(b, c, d) {
    d ? Xd.e.w.call(this, b, c) : Yd(this.q, b, c, this)
  };
  Xd.prototype.j = function(b, c, d) {
    d ? Xd.e.j.call(this, b, c) : Yd(this.q, b, c, this)
  };
  Xd.prototype.fd = function() {
    return I.Ae
  };

  function U(b) {
    Q.call(this, b);
    this.Zc = Zd;
    this.ka = new D;
    this.ka.ic("trigger-connect");
    this.ka.t(13);
    this.la = new J("DSAdvMsg");
    this.la.addEventListener("acknowledge", this.Ig);
    this.la.addEventListener("messageFault", this.Jg);
    this.la.G("DSAdvMsg");
    this.Pc = this.Ad = 0;
    this.Bd = this.Fe = h;
    this.Ob = !1;
    this.va = h;
    this.Qb = 0;
    this.b = Za(I.ve);
    this.N = this.Dd = h;
    this.Sb = 0;
    this.oc = {};
    this.Fa = [];
    this.nb = h;
    this.Ed = 1E3;
    this.Ga = [];
    this.mb = {};
    this.pc = !1;
    this.wa = h;
    this.qc = 0;
    this.Wb = h;
    this.ad = this.Gd = this.xa = !1;
    this.I = this.P =
      h;
    this.C = !1;
    this.He = 0
  }
  s(U, Q);
  var Zd = !1;
  o = U.prototype;
  o.Ag = n("Zc");
  o.nh = m("Zc");
  o.vb = n("He");
  o.oh = function(b) {
    x("Reliable reconnect duration millis", b);
    this.He = b
  };
  o.za = function(b) {
    this.la.za(b);
    this.I != h && $d(this);
    this.Gd = !0;
    U.e.za.call(this, b);
    this.Gd = !1;
    for (b = this.Ga.slice(); b.length > 0;) {
      var c = b.shift();
      this.Ga.shift();
      delete this.mb[c[1].h()];
      this.send(c[0], c[1])
    }
  };
  o.R = function(b) {
    this.I == h ? U.e.R.call(this, b) : this.f == 2 && !this.xa && this.C && !b.U ? ae(this, b) : this.Wb == h && b.hb.vb() == -1 ? (this.xa = !1, U.e.R.call(this, b), be(this)) : this.b.d("Ignoring ChannelEvent.DISCONNECT; reliable reconnect attempt pending.")
  };
  o.X = function(b) {
    this.I == h || b.ta ? U.e.X.call(this, b) : this.f == 2 && !this.xa && this.C && !b.U ? (this.Dd = b, ae(this, b)) : this.Wb == h && b.hb.vb() == -1 ? (this.xa = !1, U.e.X.call(this, b), be(this)) : this.b.d("Ignoring ChannelFaultEvent; reliable reconnect attempt pending.")
  };
  o.m = function(b) {
    if (b != h && !(b instanceof Xd) && this.wa != h) {
      var c = this.wa[b.c];
      c != h && (delete this.wa[b.c], this.qc--, U.e.m.call(this, c))
    }
    U.e.m.call(this, b);
    if (this.o.length == 1 && this.o[0] == this.la) this.C = !1, this.la.m(), be(this, 2)
  };
  o.send = function(b, c, d) {
    if (d) U.e.send.call(this, b, c);
    else if (this.f == 2 && !this.xa)
      if (this.Gd) ce(this, b, c);
      else if (this.Zc && !(c instanceof D && c.A() == 13))
        if (this.I != h && !this.Ob) {
          d = -1;
          if (c.F("DSSeqNum")) d = c.getHeader("DSSeqNum");
          else {
            d = this.Sb;
            this.Sb++;
            if (this.Sb == 2147483647) this.Sb = 0;
            c.g("DSSeqNum", d);
            c.g("DSSeqId", this.I)
          }
          var e = this.wa[b.c];
          if (e == h) this.b.d("Creating ReliableProxyAgent for MessageAgent '" + b.c + "'."), e = new Xd(b), e.q = this, this.wa[b.c] = e, this.qc++;
          c.getHeader("DSFetchPendingReply") ? (this.b.d("Attempting to fetch pending reply for message with sequence number '" +
            d + "'."), c.g("DSFetchPendingReply", h)) : (this.b.d("Adding reliable send message with sequence number '" + d + "' to the reliable outbound queue."), this.N.push([d, c, e]));
          de(this, e, c)
        } else ce(this, b, c), $d(this);
      else de(this, b, c);
    else if (ce(this, b, c), !this.xa) this.Fe = c.$b(), ee(this)
  };
  o.ed = function(b) {
    for (U.e.ed.call(this, b); this.Ga.length > 0;) {
      var c = this.Ga.shift(),
        d = c[1];
      delete this.mb[d.h()];
      d.F("DSSeqNum") && d.g("DSSeqNum", h);
      var e = fe(this, d.h(), 0, b);
      c[0].j(e, d)
    }
  };
  o.fa = function(b, c) {
    if (c) U.e.fa.call(this, b);
    else {
      var d = b.k.getHeader("DSSeqId");
      if (!(d != h && d != this.I)) {
        b.k.getHeader("DSReqSeqAck") && this.I != h && ge(this, !0);
        this.Pc != 0 && this.Ad++;
        d = b.k.getHeader("DSAdaptiveFrequency");
        if (d != h)
          if (d.indexOf("start") == 0) this.b.d("AdvancedChannelSet started measuring its message receive rate."), this.Pc = (new Date).valueOf(), this.Ad = 1;
          else if (d.indexOf("stop") == 0) {
            var d = Number(d.substr(d.indexOf("_;_") + 3)),
              e = this.Ad / (((new Date).valueOf() - this.Pc) / 1E3);
            this.b.d("AdvancedChannelSet message receive rate for the window is " +
              e.toFixed(2) + " while server send rate is " + d + ".");
            d = Math.round(d - e);
            d >= 1 && (this.b.d("AdvancedChannel is reporting rate difference of " + d + " to the server."), e = new E, e.Na(d.toFixed(0)), e.G(this.la.S()), e.$("_DSAF"), de(this, this.la, e));
            this.Pc = 0
          }
        this.Zc ? (Sc(this), he(this, b), Rc(this)) : U.e.fa.call(this, b)
      }
    }
  };
  o.Ye = function() {
    Sc(this);
    this.f == 2 && !this.xa && this.Lh != h && (Yc(this), Rc(this))
  };

  function de(b, c, d) {
    ie(b, d);
    b.send(c, d, !0)
  }

  function je(b, c) {
    for (var d = b.N.length, e = 0; e < d; ++e)
      if (b.N[e][0] == c) return b.b.d("Acknowledgement for reliable sent message '" + c + "' received."), b.N.splice(e, 1)[0];
    return h
  }

  function ke(b, c) {
    var d = c[0],
      e;
    if (d < 0) e = !1;
    else {
      var i = e = !1;
      if (b.P != h)
        for (var k = 0; k < b.P.length; ++k) {
          var l = b.P[k];
          if (l[0] <= d && d <= l[1]) break;
          var r = k + 1 < b.P.length ? b.P[k + 1] : h;
          if (r != h) {
            if (r[0] <= d) continue;
            l[1] + 1 == d ? ++l[1] : r[0] - 1 == d ? --r[0] : (b.P.splice(k + 1, 0, [d, d]), i = !0);
            l[1] + 1 == r[0] && (l[1] = r[1], b.P.splice(k + 1, 1))
          } else l[1] + 1 == d ? ++l[1] : (b.P.push([d, d]), i = !0);
          e = !0;
          break
        } else if (b.P == h && d == 0) b.P = [
        [0, 0]
      ], e = !0;
      i && (b.b.d("Detected gap in sequence. Sending an ack to the remote host to fill the gap."), ge(b, !1))
    }
    if (e) b.b.d("Adding received message with sequence number '" +
      d + "' to the reliable inbound queue."), b.ad = !0;
    else return b.b.d("Ignoring received message with sequence number '" + d + "'; duplicate delivery."), !1;
    e = b.va.length;
    if (e > 0) {
      for (; --e >= 0;)
        if (d > b.va[e][0]) return b.va.splice(e + 1, 0, c), !0;
      b.va.unshift(c)
    } else b.va.push(c);
    return !0
  }

  function le(b) {
    if (b.nb != h) window.clearTimeout(b.nb), b.nb = h
  }

  function be(b, c) {
    y(c) || (c = 0);
    b.I != h && b.b.d("Shutting down current reliable messaging sequence with id: " + b.I);
    b.xa = !1;
    for (me(b); b.Ga.length > 0;) {
      var d = b.Ga.shift(),
        e = d[1];
      delete b.mb[e.h()];
      e.getHeader("DSSeqNum") != h && delete e.g("DSSeqNum", h);
      var i = fe(b, e.h(), c);
      d[0].j(i, e)
    }
    if (b.N != h)
      for (var k = b.N.length, l = 0; l < k; ++l) d = b.N[l], e = d[1], d = d[2], i = fe(b, e.h(), c), d.l.j(i, e);
    if (b.wa != h)
      for (l in e = Ic(b.v, !0, !1, !1), i = Hc(b.v), b.wa) d = b.wa[l], c == 1 && d.l instanceof R && d.l.oa && (d.l.R(e), d.l.za(i)), d.m();
    b.Ob = !1;
    b.I =
      h;
    b.va = h;
    b.Qb = 0;
    b.N = h;
    b.Sb = 0;
    b.pc = !1;
    b.wa = h;
    b.qc = 0;
    b.Dd = h;
    b.C = !1;
    b.P = h;
    b.Fa = [];
    le(b)
  }

  function ne(b, c) {
    var d = !1,
      e;
    for (e in b.Fa)
      if (b.Fa[e][1].h() == c) {
        d = !0;
        break
      }
    if (!d)
      for (e = b.N.length - 1; e >= 0; --e) d = b.N[e][1], d.h() == c && b.Fa.push([b.N[e][2].l, d]);
    if (b.nb == h) window.q = b, b.nb = window.setTimeout(function() {
      var b = this.q,
        c;
      for (c in b.Fa) {
        var d = b.Fa[c];
        d[1].td("DSFetchPendingReply", !0);
        b.send(d[0], d[1])
      }
      c = b.Ed << 2;
      c > 3E4 && (c = 3E4);
      b.Ed = c;
      b.nb = h
    }, b.Ed)
  }

  function fe(b, c, d, e) {
    var i = new G;
    i.aa("Client.Error.MessageSend");
    i.ba("Could not send message reliably.");
    switch (d) {
      case 0:
        i.g("DSRetryableErrorHint", !0);
        i.ia("Failed to negotiate a reliable messaging sequence with remote host.");
        break;
      case 1:
        i.ia("Existing reliable sequence was reset to a new sequence upon reconnect to remote host.");
        break;
      case 2:
        i.ia("Client initiated disconnect.")
    }
    i.Ca(c);
    if (d == 0) {
      if (e == h) e = b.Dd;
      e instanceof P && i.ia(e.Aa() + " " + e.La() + " " + e.Ka());
      i.Ua(e)
    }
    return i
  }

  function oe(b, c) {
    b.b.d("Opening a new reliable messaging sequence with id: " + c);
    b.I = c;
    b.va = [];
    b.Qb = 0;
    b.N = [];
    b.Sb = 0;
    b.wa = {};
    b.qc = 0
  }

  function pe(b) {
    for (; b.va != h && b.va.length > 0 && b.va[0][0] == b.Qb;) {
      b.Qb++;
      if (b.Qb == 2147483647) b.Qb = 0;
      var c = b.va.shift();
      if (c.length == 2 && c[1] instanceof v) {
        var d = c[1].k;
        d.g("DSSeqNum", h);
        d.g("DSSeqAck", h);
        b.b.d("Processing reliable pushed message with sequence number: " + c[0]);
        b.fa(c[1], !0)
      } else {
        var d = c[3],
          e = c[1];
        e.g("DSSeqNum", h);
        e.g("DSSeqAck", h);
        e = c[2];
        e.g("DSSeqNum", h);
        e.g("DSSeqAck", h);
        b.b.d("Processing reliable reply message with sequence number: " + c[0]);
        c[1] instanceof G ? (e = c[1], c = c[2], d.j(e, c, !0),
          d.l.j(e, c)) : (e = c[1], c = c[2], d.w(e, c, !0), d.l.w(e, c))
      }
    }
  }

  function qe(b, c) {
    if (c.length > 0) {
      for (var d = [], e = 0; e < c.length; e++) d.push(c[e].getHeader("DSSeqNum"));
      b.b.d("Received batch of {0} reliable messages. Sequence numbers: {1}")
    }
    for (e = 0; e < c.length; e++)
      if (d = c[e], d.getHeader("DSSeqNum") == h) b.b.d("Received a batch message lacking a reliable sequence number: " + d);
      else if (d instanceof E) {
        var i = je(b, d.getHeader("DSSeqAck"));
        d.g("DSSeqAck", h);
        i != h ? Yd(b, d, i[1], i[2]) : b.b.warn("Received a reply message within a reliable message batch that had no corresponding request message: " + d)
      } else d instanceof
      C ? he(b, ia(d)) : b.b.error("Received an unsupported message within a reliable message batch: " + d)
  }

  function he(b, c) {
    var d = c.k.getHeader("DSSeqNum");
    b.I != h ? (ke(b, [d, c]), pe(b)) : b.b.d("Ignoring reliable receive with sequence number '" + d + "' because the sequence has been closed.")
  }

  function Yd(b, c, d, e) {
    c.F("DSSeqReplyPending") && ne(b, c.getHeader("DSSeqReplyPending"));
    if (c.getHeader("DSSeqBatch")) c.F("DSSeqReplyPending") && ne(b, c.getHeader("DSSeqReplyPending")), qe(b, c.u());
    else if (c.getHeader("DSSeqNum") == h) c instanceof G && "Client.Error.RequestTimeout" == c.Aa() && (e.j(c, d, !0), e.l.j(c, d));
    else {
      var i = c.getHeader("DSSeqNum");
      if (b.I != h) {
        if (c instanceof G) {
          var k = c.Sa();
          if (k instanceof O || k instanceof P) {
            b.b.d("Received an error for a reliable request but a resend will be attempted. Error root cause: " +
              k);
            c = d.getHeader("DSSeqNum");
            e = b.N.length;
            for (i = 0; i < e; ++i)
              if (b.N[i][0] == c) {
                b.b.d("Attempting to resend reliable request with sequence number: " + c);
                c = b.N.splice(i, 1)[0][2];
                b.f == 2 ? b.send(c.l, d) : (b = fe(b, d.h(), 0, k), c.l.j(b, d));
                break
              }
            return
          }
        }
        c.F("DSSeqAck") && je(b, c.getHeader("DSSeqAck"));
        if (ke(b, [i, c, d, e])) {
          k = b.Fa.length;
          for (c = 0; c < k; ++c)
            if (b.Fa[c][1] == d) {
              b.Fa.splice(c, 1);
              break
            }
          b.Fa.length == 0 && b.nb != h && le(b);
          pe(b)
        }
      } else b.b.d("Ignoring reliable reply with sequence number '" + i + "' because the sequence has been closed.")
    }
  }
  o.Ig = function(b) {
    if (!b.k.F(E.Fh)) {
      var c = this.q.oc[b.k.ea()];
      c != h ? (delete this.q.oc[b.k.ea()], c[0](b)) : this.b.info("No ack callback registered for internal message with id: " + b.k.ea())
    }
  };
  o.Jg = function(b) {
    var c = this.q.oc[b.k.ea()];
    c != h ? (delete this.q.oc[b.k.ea()], c[1](b)) : this.b.info("No fault callback registered for internal message with id: " + b.k.ea())
  };

  function ce(b, c, d) {
    b.mb[d.h()] == h && (b.mb[d.h()] = !0, b.Ga.push([c, d]))
  }

  function $d(b) {
    if (!b.Ob) {
      if (b.Lg == 0) b.Lg = 1E3;
      b.C = !0;
      b.Ob = !0;
      var c = new Vd;
      c.$("_DSRM");
      b.I == h ? c.t(0) : (c.t(1), c.g("DSSeqId", b.I), b.P != h && c.g("DSSeqAck", b.P));
      b.b.d("Sending reliable handshake.");
      b.qd(c, function(d) {
        b.Ob = !1;
        b.xa = !1;
        c.A() == 0 ? (oe(b, d.k.u()), b.b.d("Created new reliable messaging sequence with id: " + d.k.u())) : c.A() == 1 && typeof d.k.u() == "string" ? (b.b.d("Resetting to a new reliable messaging sequence with id: " + d.k.u()), be(b, b.Eh), oe(b, d.k.u())) : b.b.d("Reconnected to existing reliable messaging sequence with id: " +
          b.I);
        for (; b.Ga.length > 0;) {
          var e = b.Ga.shift();
          delete b.mb[e[1].h()];
          b.send(e[0], e[1])
        }
        c.A() == 1 && d.k.u() instanceof Array && d.k.u().length > 0 && (qe(b, d.k.u()), ge(b, !1, !0));
        Rc(b)
      }, function(c) {
        b.b.error("Failed to negotiate reliable messaging sequence. Root cause: " + c);
        b.Ob = !1;
        be(b);
        b.v.m(b)
      })
    }
  }

  function ae(b, c) {
    b.b.d("Attempting to repair reliable messaging sequence with id: " + b.I);
    b.xa = !0;
    for (Sc(b); b.N.length;) {
      var d = b.N.shift(),
        e = d[2];
      b.mb[d[1].h()] = !0;
      b.Ga.push([e.l, d[1]]);
      e.m();
      delete b.wa[e.l.c];
      b.qc--
    }
    if (!c.Vb) c.ee(!0), window.q = b, b.Wb = window.setTimeout(function() {
      ee(this.q)
    }, 1)
  }
  o.qd = function(b, c, d) {
    this.oc[b.h()] = [c, d];
    this.v.send(this.la, b)
  };

  function ge(b, c, d) {
    y(c) || (c = !0);
    y(d) || (d = !1);
    if (d) b.Bd = !0;
    if (b.pc) b.b.d("Ignoring request for explicit reliable sequence ack request because an ack request is outstanding.");
    else if (b.pc = !0, b.xa) b.pc = !1;
    else {
      b.ad = !0;
      d = new Vd;
      d.$("_DSRM");
      d.t(2);
      d.g("DSSeqId", b.I);
      ie(b, d);
      if (b.Bd) b.Bd = !1, d.g("DSSeqFillTail", !0);
      c ? b.b.d("Responding to an explicit reliable sequence ack request.") : b.b.d("Sending a reliable sequence ack explicitly.");
      c = function(c) {
        b.b.d("Explicit reliable sequence ack completed successfully? " +
          !(c instanceof Ua));
        b.pc = !1;
        if (!(c instanceof Ua) && c instanceof Oa) c = c.k, c.u() instanceof Array && qe(b, c.u())
      };
      b.qd(d, c, c)
    }
  }

  function ie(b, c) {
    if (b.ad) {
      for (var d = "", e = 0; e < b.P.length; e++) d += " (" + b.P[e] + ")";
      b.b.d("Adding a reliable messaging sequence ack to the outbound message:" + d);
      b.ad = !1;
      c.g("DSSeqAck", b.P)
    }
  }

  function me(b) {
    if (b.Wb != h) window.clearTimeout(b.Wb), b.Wb = h
  }

  function ee(b) {
    me(b);
    b.C = !0;
    b.ka.$(b.Fe);
    b.la.q = h;
    b.la.q = b;
    de(b, b.la, b.ka)
  }
  p("flex.client.channels.advanced.AdvancedChannelSet", U);
  U.DEFAULT_RELIABLE = Zd;
  U.DEFAULT_RELIABLE_RECONNECT_DURATION_MILLIS = 0;
  U.prototype.isReliable = U.prototype.Ag;
  U.prototype.setReliable = U.prototype.nh;
  U.prototype.getReliableReconnectDurationMillis = U.prototype.vb;
  U.prototype.setReliableReconnectDurationMillis = U.prototype.oh;

  function Nd(b) {
    this.a = b;
    this.kd = !1;
    this.Db = h;
    this.Cb = -1;
    this.Ue = this.ce = this.be = 0
  }

  function Ud(b) {
    if (b.kd) {
      b.kd = !1;
      var c = -1;
      b.a.z() instanceof U && (c = b.a.z().vb());
      if (c != -1) return b.a.s(3), b.Cb = c, b.be = (new Date).valueOf(), b.a.b.info("'" + b.a.c + "' channel attempting its initial reliable reconnect."), re(b, 1), !0
    }
    if (b.Cb != -1) {
      if (b.Db != h) return !0;
      b.ce = (new Date).valueOf();
      c = b.Cb - (b.ce - b.be);
      if (c > 0) {
        var d = 1E3;
        d <<= b.Ue++;
        if (d < c) return b.a.b.info("'" + b.a.c + "' channel has " + c + " ms remaining for reliable reconnect; attempting a reliable reconnect in " + d + " ms."), re(b, d), !0
      }
      Td(b)
    }
    return !1
  }
  Nd.prototype.wd = function() {
    return this.kd || this.Cb != -1
  };
  Nd.prototype.vb = n("Cb");

  function Td(b) {
    b.Cb = -1;
    b.be = 0;
    b.ce = 0;
    b.Ue = 0;
    if (b.Db != h) b.a.b.info("'" + b.a.c + "' channel stopping its reliable reconnect attempts."), window.clearTimeout(b.Db), b.Db = h
  }

  function re(b, c) {
    if (b.Cb != -1 && b.Db == h) window.nd = b, b.Db = window.setTimeout(function() {
      this.nd.a.b.info("'" + this.nd.a.c + "' channel attempting reliable reconnect: " + (new Date).valueOf());
      this.nd.a.p.wb();
      this.nd.Db = h
    }, c)
  };

  function W(b) {
    J.call(this, b);
    this.ra("MultiTopicProducer-" + se++);
    this.Ha = []
  }
  s(W, J);
  var se = 0;
  o = W.prototype;
  o.lg = n("Ha");
  o.th = function(b) {
    if (this.Ha !== b && b instanceof Array) {
      y(b) || (b = []);
      var c = A(this, this.Hh, this.Ha, b);
      this.Ha = b;
      this.dispatchEvent(c)
    }
  };
  o.vf = function(b) {
    this.Ha.push(b)
  };
  o.Og = function(b) {
    for (var c = 0; c < this.Ha.length; c++)
      if (this.Ha[c] == b) return this.Ha.splice(c, 1), !0;
    return !1
  };
  o.r = function(b, c) {
    this.Ha.length > 0 && b.g("DSSubtopic", this.Ha);
    W.e.r.call(this, b, c)
  };
  p("flex.client.messaging.MultiTopicProducer", W);
  W.PROPERTY_SUBTOPICS = "subtopics";
  W.prototype.addSubtopic = W.prototype.vf;
  W.prototype.getSubtopics = W.prototype.lg;
  W.prototype.removeSubtopic = W.prototype.Og;
  W.prototype.setSubtopics = W.prototype.th;

  function te(b) {
    J.call(this, b);
    this.ra("AsyncRequest-" + ue++);
    this.Fc = {}
  }
  s(te, J);
  var ue = 0;
  te.prototype.w = function(b, c) {
    var d = b.pa()[cb],
      d = y(d) && d;
    te.e.w.call(this, b, c);
    if (!d) {
      var d = b.ea(),
        e = this.Fc[d];
      e != h && (delete this.Fc[d], e.result(ia(b)))
    }
  };
  te.prototype.j = function(b, c) {
    var d = c.h(),
      e = this.Fc[d];
    e != h && (delete this.Fc[d], e.j(Va(b, c)))
  };
  te.prototype.Cc = function(b, c) {
    b.h() == h && b.ic(ya());
    this.Fc[b.h()] = c;
    this.send(b)
  };
  p("flex.client.rpc.AsyncRequest", te);
  p("flex.client.rpc.AsyncRequest.instanceCounter", ue);

  function X() {
    B.call(this);
    this._CLASS = "flex.messaging.messages.RemotingMessage";
    this.operation = this.source = h;
    this.parameters = []
  }
  s(X, B);
  o = X.prototype;
  o.u = n("parameters");
  o.Na = function(b) {
    b != h && (b instanceof Array ? this.parameters = this.parameters.concat(b) : this.parameters.push(b))
  };
  o.bc = n("source");
  o.vd = m("source");
  o.A = n("operation");
  o.t = m("operation");
  p("flex.messaging.messages.RemotingMessage", X);
  X.prototype.getBody = X.prototype.u;
  X.prototype.setBody = X.prototype.Na;
  X.prototype.getSource = X.prototype.bc;
  X.prototype.setSource = X.prototype.vd;
  X.prototype.getOperation = X.prototype.A;
  X.prototype.setOperation = X.prototype.t;

  function ve(b, c) {
    this.T = {};
    this.name = b;
    this.hc = c
  }
  s(ve, z);
  ve.prototype.send = function() {
    var b = new X;
    b.t(this.name);
    if (arguments.length > 0) {
      var c = [].splice.call(arguments, 0, arguments.length);
      b.Na(c)
    }
    b.vd(this.hc.bc());
    this.Cc(b)
  };
  ve.prototype.Cc = function(b) {
    var c = new pa(this.result, this.j, h);
    c.Re = this;
    c.hc = this.hc;
    this.hc.Pa.Cc(b, c)
  };
  ve.prototype.result = function(b) {
    b = b.k;
    b = new Dc("result", b.u(), h, b);
    this.Re.dispatchEvent(b);
    this.hc.dispatchEvent(b)
  };
  ve.prototype.j = function(b) {
    b = Cc(b, h);
    this.Re.dispatchEvent(b);
    this.hc.dispatchEvent(b)
  };
  p("flex.client.rpc.remoting.Operation", ve);
  p("flex.client.rpc.remoting.Operation.prototype.send", ve.prototype.send);

  function Y(b) {
    this.T = {};
    this.Pa = new te(b);
    this.c = "RemoteObject-" + we++;
    this.xb = Za(I.Be);
    this.Se = {};
    this.bd = h
  }
  s(Y, z);
  var we = 0;
  o = Y.prototype;
  o.zf = n("Pa");
  o.z = function() {
    return this.Pa.z()
  };
  o.rd = function(b) {
    this.Pa.rd(b)
  };
  o.Ja = function() {
    return this.Pa.Ja()
  };
  o.ub = n("c");
  o.ra = m("c");
  o.ac = function() {
    return this.Pa.ac()
  };
  o.jc = function(b) {
    this.Pa.jc(b)
  };
  o.bc = n("bd");
  o.vd = m("bd");
  o.m = function() {
    this.Pa.m()
  };
  o.Cc = function(b) {
    ja("methodName", b);
    var c = h;
    arguments.length > 1 && (c = [].splice.call(arguments, 1, arguments.length - 1));
    this.xb.info("'" + this.c + "' invoking method '" + b + "' with on destination '" + this.Ja() + "'.");
    this.xb.info("Arguments:'" + c + "'");
    var d = this.A(b);
    c != h ? d.send.apply(d, c) : d.send()
  };
  o.ud = function(b, c) {
    this.Pa.ud(b, c)
  };
  o.A = function(b) {
    var c = this.Se[b];
    c == h && (c = new ve(b, this), this.Se[b] = c);
    return c
  };
  p("flex.client.rpc.remoting.RemoteObject", Y);
  Y.instanceCounter = we;
  Y.prototype.getAsyncRequest = Y.prototype.zf;
  Y.prototype.getChannelSet = Y.prototype.z;
  Y.prototype.setChannelSet = Y.prototype.rd;
  Y.prototype.getDestinationId = Y.prototype.Ja;
  Y.prototype.getId = Y.prototype.ub;
  Y.prototype.setId = Y.prototype.ra;
  Y.prototype.getRequestTimeoutMillis = Y.prototype.ac;
  Y.prototype.setRequestTimeoutMillis = Y.prototype.jc;
  Y.prototype.getSource = Y.prototype.bc;
  Y.prototype.setSource = Y.prototype.vd;
  Y.prototype.disconnect = Y.prototype.m;
  Y.prototype.invoke = Y.prototype.Cc;
  Y.prototype.setRemoteCredentials = Y.prototype.ud;
  Y.prototype.getOperation = Y.prototype.A;

  function Ad(b, c, d, e) {
    this.l = b;
    this.od = c;
    this.a = d;
    this.uc = e
  }
  Ad.prototype.Ic = function(b) {
    for (var c = 0; c < this.od.length; c++) {
      var d = this.od[c],
        e = new G;
      e.Ca(d.h());
      e.ba("Channel failed before a reply was received for the sent message.");
      e.Ua(b.body);
      e.aa("Client.Error.DeliveryInDoubt");
      this.l.j(e, d)
    }
  };
  Ad.prototype.Ve = function(b) {
    if (b.status != 200) this.Ic(b);
    else {
      var c = this.uc.fb(b.body);
      if (c instanceof Array)
        for (b = 0; b < c.length; b++) {
          var d = c[b].data;
          if (d instanceof G) d.g(cb, !0), this.l.j(d, this.od[b]);
          else if (b == 0 || b == 2) {
            if (d.getHeader("DSNoOpPoll") != h) break;
            d = d.u();
            if (d != h && d instanceof Array)
              for (var e = 0; e < d.length; e++) this.a.fa(ia(d[e]))
          } else b == 1 ? (d instanceof E || d instanceof F) && this.l != h && this.l.w(d, this.od[b]) : f(Error("Unsupported Amf response message type: " + d._CLASS))
        } else this.Ic(b)
    }
  };

  function pd(b, c, d) {
    this.name = b;
    this.Gg = typeof c == "boolean" ? c : !1;
    this.data = d
  };
  var pc = 0,
    oc = 1,
    vc = 2,
    mc = 3,
    sc = 5,
    hd = 6,
    wc = 7,
    fd = 8,
    gd = 9,
    tc = 10,
    qc = 11,
    uc = 12,
    id = 13,
    jd = 14,
    kc = 15,
    nc = 16,
    jc = 17;

  function dd(b) {
    M.call(this, b);
    this.Va = [];
    this.B = [];
    this.ie = []
  }
  s(dd, $c);
  o = dd.prototype;
  o.reset = function() {
    this.B = [];
    this.Va = [];
    this.ie = []
  };
  o.n = function() {
    return this.Hc(this.ga())
  };
  o.Hc = function(b) {
    var c = h;
    switch (b) {
      case dc:
        c = this.ha();
        break;
      case gc:
        b = xe(this);
        if ((b & 1) == 0) c = this.B[b >> 1];
        else {
          if ((b & 3) == 1) c = this.ie[b >> 2];
          else {
            var c = (b & 4) == 4,
              d = (b & 8) == 8;
            b >>= 4;
            var e = this.ha(),
              c = new Mb(e, d, c);
            this.ie.push(c);
            for (d = 0; d < b; d++) e = this.ha(), c.ld.push(e)
          }
          d = c.Ne;
          b = ad([c.className, h]);
          this.B.push(b);
          if (d) b.Ta ? b.Ta(this) : f(Error("Class must have readExternal method to receive IExternalizable instances."));
          else {
            d = c.ld.length;
            for (e = 0; e < d; e++) b[c.gd(e)] = this.n();
            if (c.Me)
              for (;;) {
                c = this.ha();
                if (c ==
                  h || c.length() == 0) break;
                b[c] = this.n()
              }
          }
          c = b
        }
        break;
      case ec:
        b = xe(this);
        if ((b & 1) == 0) c = this.B[b >> 1];
        else {
          b >>= 1;
          for (d = c = h;;) {
            e = this.ha();
            if (e == h || e.length == 0) break;
            d == h && (c = d = {}, this.B.push(c));
            d[e] = this.n()
          }
          if (d == h) {
            c = [];
            this.B.push(c);
            for (e = 0; e < b; e++) c.push(this.n())
          } else
            for (e = 0; e < b; e++) d[String(e)] = this.n()
        }
        break;
      case Wb:
        c = !1;
        break;
      case Vb:
        c = !0;
        break;
      case bc:
        b = xe(this);
        c = b << 3 >> 3;
        break;
      case Yb:
        c = this.Gc();
        break;
      case ye:
        c = g;
        break;
      case cc:
        c = h;
        break;
      case ze:
      case Qb:
        c = this.ae();
        break;
      case Xb:
        c = this.$d();
        break;
      case fc:
        b = xe(this);
        if ((b & 1) == 0) c = this.B[b >> 1];
        else {
          b >>= 1;
          c = [];
          this.B.push(c);
          for (d = 0; d < b; d++) c.push(this.ga())
        }
        break;
      case Ae:
        b = xe(this);
        if ((b & 1) == 0) c = this.B[b >> 1];
        else {
          this.md();
          b >>= 1;
          c = {};
          this.B.push(c);
          for (d = 0; d < b; d++) e = this.n(), c[e] = this.n()
        }
        break;
      default:
        f(Error("Unknown type: " + b))
    }
    return c
  };
  o.ha = function(b) {
    if (b) return dd.e.ha.call(this, b);
    b = xe(this);
    if ((b & 1) == 0) return this.Va[b >> 1];
    b >>= 1;
    if (0 == b) return Be;
    b = dd.e.ha.call(this, b);
    this.Va.push(b);
    return b
  };
  o.$d = function() {
    var b = xe(this);
    if ((b & 1) == 0) return this.B[b >> 1];
    b = this.Gc();
    b = new Date(b);
    this.B.push(b);
    return b
  };

  function xe(b) {
    var c, d = b.ga() & 255;
    if (d < 128) return d;
    c = (d & 127) << 7;
    d = b.ga() & 255;
    if (d < 128) return c | d;
    c = (c | d & 127) << 7;
    d = b.ga() & 255;
    if (d < 128) return c | d;
    c = (c | d & 127) << 8;
    d = b.ga() & 255;
    return c | d
  }
  o.ae = function() {
    var b = h,
      b = xe(this);
    (b & 1) == 0 ? b = this.B[b >> 1] : (b >>= 1, b = 0 == b ? new String : this.ha(b), this.B.push(b));
    return Ab(b)
  };

  function Cd(b) {
    this.url = b;
    this.xb = Za(I.zd);
    this.body = h;
    this.headers = {};
    this.Ud = this.Ba = h
  }
  Cd.prototype.Na = m("body");

  function Ce(b) {
    try {
      return b.Ba.open("POST", b.url, !0), !0
    } catch (c) {
      var d = "There was a problem opening HTTP request = " + c.message;
      b.xb.error(d);
      b.Ud.dc(d);
      return !1
    }
  }

  function Dd(b) {
    var c;

    if(typeof XMLHttpRequest !== 'undefined') c = new XMLHttpRequest();
    else {
      var versions = ["MSXML2.XmlHttp.5.0",
        "MSXML2.XmlHttp.4.0",
        "MSXML2.XmlHttp.3.0",
        "MSXML2.XmlHttp.2.0",
        "Microsoft.XmlHttp"]

      for(var i = 0, len = versions.length; i < len; i++) {
        try {
          c = new ActiveXObject(versions[i]);

          break;
        }
        catch(e){
          f(Error("This browser does not support XMLHttpRequest."));
        }
      } // end for
    }
    //window.XMLHttpRequest ? c = new XMLHttpRequest : window.ActiveXObject ? c = new ActiveXObject("Microsoft.XMLHTTP") : f(Error("This browser does not support XMLHttpRequest."));
    c.overrideMimeType("application/x-amf; charset=x-user-defined");
    b.Ba = c;
    var d = b.Ud,
      e = b.Ba,
      i = b.xb;
    b.Ba.onreadystatechange = function() {
      if (e.readyState == 4) {
        var b = {};
        if (e.status == 200) {
          var c;
          if (e.responseBody != g) {
            if (typeof BinaryToArray == "undefined") {
              c = document.getElementsByTagName("head");
              var k = document.createElement("script");
              c[0].appendChild(k);
              k.type = "text/vbscript";
              k.text = "Function BinaryToArray(Binary)\nDim i\nReDim byteArray(LenB(Binary))\nFor i = 1 To LenB(Binary)\nbyteArray(i-1) = AscB(MidB(Binary, i, 1))\nNext\nBinaryToArray = byteArray\nEnd Function"
            }
            c =
              VBArray.prototype.toArray.call(BinaryToArray(e.responseBody));
            for (var k = c.length, l = "", r = 0; r < k; r++) l += String.fromCharCode(c[r]);
            c = l
          } else c = e.responseText;
          b.status = 200;
          b.body = c;
          if (e.getResponseHeader('X-Auth-Token')) {
            xAuthToken = e.getResponseHeader('X-Auth-Token');
            console.log("Got new token " + xAuthToken);
          }
          try {
            d.ec(b)
          } catch (e) {
            i.error("There was a problem deserializing data. Proxy usage?. Response status: " + e.status + " text: " + e.statusText);
          }
        } else i.error("There was a problem retrieving data. Response status: " + e.status + " text: " + e.statusText), b.status = e.status, b.body = "There was a problem retrieving data:" + e.statusText, d.dc(b)
      }
    };
    if (Ce(b)) {
      //console.log('Using token ' + xAuthToken);
      b.Ba.withCredentials = !0;
      b.Ba.overrideMimeType ? b.Ba.overrideMimeType("application/x-amf; charset=x-user-defined") : b.Ba.setRequestHeader("Accept-Charset","x-user-defined");
      b.Ba.setRequestHeader("Content-Type", "text/plain; charset=UTF-8");
      if (xAuthToken) {
        console.log('Setting token header ' + xAuthToken);
        b.Ba.setRequestHeader('X-Auth-Token', xAuthToken);
      }
      for (var k in b.headers) try {
        b.Ba.setRequestHeader(k, b.headers[k])
      } catch (l) {
        b.xb.error("HTTP Request " + l)
      }
      try {
        Bb(b.Ba, b.body)
      } catch (r) {
        b.xb.error("HTTP request send failure: " + r)
      }
    }
  };

  function Wc(b) {
    this._CLASS = "flex.messaging.io.ArrayCollection";
    this.source = b
  }
  Wc.prototype.Ta = function(b) {
    this.source = b.n()
  };
  Wc.prototype.ja = function(b) {
    b.L(this.source)
  };
  p("flex.messaging.io.ArrayCollection", Wc);
  p("flex.messaging.io.ArrayCollection.prototype.readExternal", Wc.prototype.Ta);
  p("flex.messaging.io.ArrayCollection.prototype.writeExternal", Wc.prototype.ja);
  var ye = 0,
    cc = 1,
    Wb = 2,
    Vb = 3,
    bc = 4,
    Yb = 5,
    dc = 6,
    ze = 7,
    Xb = 8,
    ec = 9,
    gc = 10,
    Qb = 11,
    fc = 12,
    Ae = 17,
    Be = "",
    ac = 536870911,
    $b = 268435455,
    Zb = -268435456;

  function R(b) {
    H.call(this, b);
    this.Tc = 0;
    this.ra("Consumer-" + De++);
    this.Ea = Ee;
    this.ab = h;
    this.oa = this.bb = !1;
    this.cb = h;
    this.$a = Fe;
    this.Xb = Ge;
    this.Q = this.na = h
  }
  s(R, H);
  var Ee = 0,
    Fe = 5,
    Ge = 5E3,
    De = 0;
  o = R.prototype;
  o.Md = n("Ea");
  o.gh = function(b) {
    if (this.Ea != b) {
      var c = A(this, "maxFrequency", this.Ea, b);
      this.Ea = b;
      this.dispatchEvent(c)
    }
  };
  o.hg = n("$a");
  o.ph = function(b) {
    x("Resubscribe attempts", b);
    if (this.$a != b) {
      var c = A(this, "resubscribeAttempts", this.$a, b);
      this.$a = b;
      b == 0 && He(this);
      this.dispatchEvent(c)
    }
  };
  o.ig = n("Xb");
  o.qh = function(b) {
    x("Resubscribe interval", b);
    if (this.Xb != b) {
      (b == 0 || this.ab != h) && He(this);
      var c = A(this, "resubscribeIntervalMillis", this.Xb, b);
      this.Xb = b;
      this.dispatchEvent(c)
    }
  };
  o.Qd = n("na");
  o.rh = function(b) {
    this.oa && f(Error("Selector value may not be assigned while the Consumer is actively subscribed."));
    this.na = b
  };
  o.Ac = n("Q");
  o.fe = function(b) {
    this.oa && f(Error("Subtopic value may not be assigned while the Consumer is actively subscribed."));
    this.Q = b
  };
  o.Bg = n("oa");
  o.Oa = function(b) {
    if (this.oa != b) {
      var c = A(this, "subscribed", this.oa, b);
      this.b.d("'" + this.c + "' subscribed: " + this.oa + " -> " + b);
      this.oa = b;
      var d = this.z().v;
      this.jd(d) && (b ? d.p instanceof rd && d.p.Wd() : Tc(d));
      this.dispatchEvent(c)
    }
  };
  o.m = function() {
    this.bb = !1;
    He(this);
    this.Oa(!1);
    R.e.m.call(this)
  };
  o.zh = function(b) {
    y(b) || (b = h);
    var c = this.S(),
      c = c != h && !c != b || c == h && b != h;
    this.oa && c && this.df();
    He(this);
    this.bb = !0;
    c && this.G(b);
    this.cb = this.dd(b);
    this.b.info("'" + this.c + "' subscribing to destination '" + this.Mb + "': " + this.cb.toString());
    this.r(this.cb)
  };
  o.df = function(b) {
    y(b) || (b = !1);
    this.oa ? (b = this.Jd(b), this.b.info("'" + this.c + "' unsubscribing from destination " + this.Mb), this.r(b)) : He(this)
  };
  o.w = function(b, c) {
    var d = b.pa()[cb];
    if (y(d) && d == !0 || !(c instanceof D)) R.e.w.call(this, b, c);
    else switch (d = c.A(), d == 11 && (d = c.F(Ie) ? 1 : 0), d) {
      case 0:
        He(this);
        d = b.S();
        this.b.info("'" + this.c + "' acknowledge for subscribe. Client id '" + d + "'.");
        this.G(d);
        this.Oa(!0);
        R.e.w.call(this, b, c);
        break;
      case 1:
        this.b.info("'" + this.c + "' acknowledge for unsubscribe.");
        this.G(h);
        this.Oa(!1);
        b.G(h);
        R.e.w.call(this, b, c);
        break;
      default:
        R.e.w.call(this, b, c)
    }
  };
  o.R = function(b) {
    this.Oa(!1);
    R.e.R.call(this, b);
    this.bb && !b.U && Je(this)
  };
  o.X = function(b) {
    b.ta || this.Oa(!1);
    R.e.X.call(this, b);
    this.bb && !b.U && !b.ta && Je(this)
  };
  o.j = function(b, c) {
    if (!this.Nb)
      if (b.F("DSRetryableErrorHint")) {
        if (this.ab == h) {
          if (this.cb != h && b.ea() == this.cb.h()) this.bb = !1;
          R.e.j.call(this, b, c)
        }
      } else R.e.j.call(this, b, c)
  };
  o.fa = function(b) {
    b = b.k;
    if (b instanceof D) switch (this.b.info("'" + this.c + "' received pushed command: " + Na(b.A())), b.A()) {
      case 10:
        this.Oa(!1);
        break;
      default:
        this.b.error("'" + this.c + "' received unsupported command: " + b)
    } else this.dispatchEvent(b instanceof G ? Va(b, b) : ia(b))
  };
  o.dd = function(b) {
    var c = new D;
    c.t(0);
    c.G(b);
    c.$(this.Mb);
    b = this.Q;
    b != h && c.g("DSSubtopic", b);
    this.na != h && c.g("DSSelector", this.na);
    this.Ea > 0 && c.g("DSMaxFrequency", this.Ea);
    return c
  };
  o.Jd = function(b) {
    var c = new D;
    c.t(1);
    c.G(this.S());
    c.$(this.Mb);
    this.Q != h && c.g("DSSubtopic", this.Q);
    this.na != h && c.g("DSSelector", this.na);
    b && (c.pa().DSPreserveDurable = b);
    return c
  };
  o.fd = function() {
    return I.ye
  };

  function Je(b) {
    if (b.bb && b.ab == h && !(b.$a == 0 || b.Xb == 0)) b.b.d("'" + b.c + "' starting resubscribe timer."), b.Tc = 0, Ke(b)
  }

  function He(b) {
    if (b.ab != h) b.b.d("'" + b.c + "' stopping resubscribe timer."), window.clearTimeout(b.ab), b.ab = h
  }
  o.jd = function(b) {
    return b.Da == Xc
  };

  function Ke(b) {
    if (b.$a != -1 && b.Tc >= b.$a) {
      He(b);
      b.bb = !1;
      var c = new G;
      c.aa("Client.Error.Subscribe");
      c.ba("Consumer subscribe error");
      c.ia("The consumer was not able to subscribe to its target destination.");
      c.Ca(b.cb.h());
      b.j(c, b.cb)
    } else if (b.b.d("'" + b.c + "' is trying to resubscribe: " + b.Tc + "/" + b.$a), b.Tc++, b.r(b.cb, !1), b.ab == h) window.wf = b, b.ab = window.setInterval(function() {
      Ke(this.wf)
    }, b.Xb)
  }
  p("flex.client.messaging.Consumer", R);
  p("flex.client.messaging.Consumer.DEFAULT_MAX_FREQUENCY", Ee);
  p("flex.client.messaging.Consumer.DEFAULT_RESUBSCRIBE_ATTEMPTS", Fe);
  p("flex.client.messaging.Consumer.DEFAULT_RESUBSCRIBE_INTERVAL_MILLIS", Ge);
  p("flex.client.messaging.Consumer.PROPERTY_MAX_FREQUENCY", "maxFrequency");
  p("flex.client.messaging.Consumer.PROPERTY_RESUBSCRIBE_ATTEMPTS", "resubscribeAttempts");
  p("flex.client.messaging.Consumer.PROPERTY_RESUBSCRIBE_INTERVAL_MILLIS", "resubscribeIntervalMillis");
  p("flex.client.messaging.Consumer.PROPERTY_SUBSCRIBED", "subscribed");
  p("flex.client.messaging.Consumer.instanceCounter", De);
  p("flex.client.messaging.Consumer.prototype.acknowledge", R.prototype.w);
  p("flex.client.messaging.Consumer.prototype.disconnect", R.prototype.m);
  p("flex.client.messaging.Consumer.prototype.getMaxFrequency", R.prototype.Md);
  p("flex.client.messaging.Consumer.prototype.getResubscribeAttempts", R.prototype.hg);
  p("flex.client.messaging.Consumer.prototype.getResubscribeIntervalMillis", R.prototype.ig);
  p("flex.client.messaging.Consumer.prototype.getSelector", R.prototype.Qd);
  p("flex.client.messaging.Consumer.prototype.getSubtopic", R.prototype.Ac);
  p("flex.client.messaging.Consumer.prototype.isSubscribed", R.prototype.Bg);
  p("flex.client.messaging.Consumer.prototype.setMaxFrequency", R.prototype.gh);
  p("flex.client.messaging.Consumer.prototype.setResubscribeAttempts", R.prototype.ph);
  p("flex.client.messaging.Consumer.prototype.setResubscribeIntervalMillis", R.prototype.qh);
  p("flex.client.messaging.Consumer.prototype.setSelector", R.prototype.rh);
  p("flex.client.messaging.Consumer.prototype.setSubtopic", R.prototype.fe);
  p("flex.client.messaging.Consumer.prototype.subscribe", R.prototype.zh);
  p("flex.client.messaging.Consumer.prototype.unsubscribe", R.prototype.df);

  function Bd(b, c) {
    this.da = b;
    this.p = c
  }
  Bd.prototype.Ic = function(b) {
    Ac(this.da);
    var c = new G;
    c.Ca(this.da.message.h());
    c.ba("Channel failed before a reply was received for the sent message.");
    c.Ua(b.body);
    c.aa("Client.Error.DeliveryInDoubt");
    this.da.l.j(c, this.da.message)
  };
  Bd.prototype.Ve = function(b) {
    Ac(this.da);
    b.status == 200 ? (b = this.p.fb(b.body)[0].data, b instanceof G ? (b.g(cb, !0), this.da.l.j(b, this.da.message)) : b instanceof E || b instanceof F ? this.da.l != h && this.da.l.w(b, this.da.message) : f(Error("Unsupported Amf response message type: " + b._CLASS))) : this.Ic(b)
  };

  function Le(b, c, d) {
    y(d) || (d = 0);
    this.Q = b;
    this.na = c;
    this.Ea = d
  }
  Le.prototype.Ac = n("Q");
  Le.prototype.Qd = n("na");
  Le.prototype.Md = n("Ea");
  p("flex.client.messaging.SubscriptionInfo", Le);
  p("flex.client.messaging.SubscriptionInfo.prototype.getSubtopic", Le.prototype.Ac);
  p("flex.client.messaging.SubscriptionInfo.prototype.getSelector", Le.prototype.Qd);
  p("flex.client.messaging.SubscriptionInfo.prototype.getMaxFrequency", Le.prototype.Md);

  function $(b) {
    R.call(this, b);
    this.ra("MultiTopicConsumer-" + Me++);
    this.rb = [];
    this.jb = {}
  }
  s($, R);
  var Me = 0,
    Ie = "DSlastUnsub";
  o = $.prototype;
  o.kg = n("rb");
  o.uf = function(b, c, d) {
    y(d) || (d = 0);
    this.rb.push(new Le(b, c, d));
    Ne(this)
  };
  o.Ng = function(b, c) {
    for (var d = 0; d < this.rb.length; d++) {
      var e = this.rb[d];
      if ((!y(b) || y(e.Q) && e.Q == b) && (!y(c) || y(e.na) && e.na == c)) return this.rb.splice(d, 1), Ne(this), !0
    }
    return !1
  };
  o.dd = function(b) {
    b = $.e.dd.call(this, b);
    b.t(11);
    for (var c = {}, d = 0; d < this.rb.length; d++) {
      var e = this.rb[d],
        i = (!y(e.Q) ? "" : e.Q) + "_;_" + (!y(e.na) ? "" : e.na);
      e.Ea > 0 && (i += "_;_" + e.Ea);
      c[i] = i
    }
    var d = [],
      e = [],
      k;
    for (k in c) y(this.jb[k]) || d.push(k);
    for (k in this.jb) y(c[k]) || e.push(k);
    d.length > 0 && (b.pa().DSAddSub = d);
    e.length > 0 && (b.pa().DSRemSub = e);
    this.jb = c;
    ma(this.jb) == 0 && (b.pa()[Ie] = !0);
    return b
  };
  o.Jd = function(b) {
    b = $.e.Jd.call(this, b);
    b.t(11);
    var c = [],
      d;
    for (d in this.jb) c.push(d);
    this.jb = {};
    c.length > 0 && (b.pa().DSRemSub = c);
    b.pa()[Ie] = !0;
    return b
  };
  o.r = function(b) {
    b.F("DSAddSub") || b.F("DSRemSub") ? $.e.r.call(this, b) : (this.q != h && this.q.f != 2 && b instanceof D && b.A() == 11 && $.e.r.call(this, b), b.F(Ie) ? this.Oa(!1) : this.q != h && this.q.f == 2 && this.Oa(!0))
  };
  o.Oa = function(b) {
    if (!y(b) || !b) this.jb = {};
    $.e.Oa.call(this, b)
  };

  function Ne(b) {
    (b.bb || b.oa) && b.r(b.dd(b.ib))
  }
  p("flex.client.messaging.MultiTopicConsumer", $);
  $.prototype.addSubscription = $.prototype.uf;
  $.prototype.getSubscriptions = $.prototype.kg;
  $.prototype.removeSubscription = $.prototype.Ng;

  function vd(b) {
    t.call(this, b)
  }
  s(vd, t);
  vd.prototype.ec = j();
  vd.prototype.xd = j();

  function sendWS(o, v) {
    o.send(v);
    return true;
  }
})();
