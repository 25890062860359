import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import Modal from 'react-bootstrap4-modal';
import { showRules as show, hideRules as hide } from "../reducers/lottorace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {scrollToTop} from "../helpers/Utils";

function mapStateToProps(state, ownProps) {
  return {
    showRules: state.lottorace.modal.showRules,
    rulesDesc: state.lottorace.rulesDesc
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showRulesModal: () => dispatch(show()),
    hideRulesModal: () => dispatch(hide()),
  };
}

class RulesModal extends Component {

  close = (event) => {
    event.preventDefault();
    this.props.hideRulesModal();
  };

  componentDidMount() {
    //$("#rulzText").html(this.props.rulesDesc);
    scrollToTop();
  }

  componentDidUpdate() {
    //$("#rulzText").html(this.props.rulesDesc);
  }

  componentWillReceiveProps(nextProps) {
    //if (this.props.rulesDesc != nextProps.rulesDesc) {
    //  $("#rulzText").html(nextProps.rulesDesc);
    //}
  }

  render() {
    return (
      <Modal visible={this.props.showRules} backdrop="static" onClickBackdrop={this.close} className="modal-fullscreen">
        <div className="modal-header">
          <h5 className="modal-title">
            <Translate id="gamerules.title" />
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div id="rulzText" className="rulezText" dangerouslySetInnerHTML={{ __html: this.props.showRules ? this.props.rulesDesc : '' }}></div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary resendButton" onClick={this.close} ><Translate id="generic.ok" /></button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RulesModal);
