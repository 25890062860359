const SHOW_TAB = 'SHOW_TAB';

const initialState = {
  tab: '#tab-tournament'
};

function mainlobbyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_TAB:
      return {
        ...state,
        tab: action.data
      }
    default:
      return state;
  }
}

export default mainlobbyReducer;

export function showTab(tab) {
  return {
    type: SHOW_TAB,
    data: tab
  };
}