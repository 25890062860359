import React from "react";
import Modal from "react-bootstrap4-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

function PopupJustOk(props) {
	console.log({ props });

	const handleClose = () => {
		if (props.complete) {
			props.complete(props.componentKey);
		}
	}

	return (
		<Modal id="popupJustOk" wrapperProps={{ id: "popupJustOk" }}
			dialogClassName={"modal-dialog-centered_"} backdrop="static" visible={true}>
			<div className="modal-secondary-content-two">
				<div className="d-flex modal-header">
					<h5 className="modal-title">
						<FontAwesomeIcon icon={faInfoCircle} size={"lg"} />&nbsp;{props.modalProps.title}
					</h5>
					<button className="btn btn-link p-0" onClick={() => handleClose()}>
						<FontAwesomeIcon icon={"times-circle"} size={"2x"} className={"bg-light border rounded-circle pull-right"} />
					</button>

				</div>
				<div className="modal-body my-2 ml-4" style={{ whiteSpace: 'pre-wrap' }}>
					{props.modalProps.body}
				</div>
				<div className="modal-footer justify-content-center">
					<button id="popupOkBtn" type="button" className="btn btn-success" onClick={() => handleClose()}>&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;</button>
				</div>
			</div>
		</Modal>
	)
}

export default PopupJustOk;
