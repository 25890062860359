import React, { Component, ErrorInfo, ReactNode } from "react";
import { PromisePortalComponent } from "./PromisePortal";

export interface Props {
  key: string,
  componentKey: string;
  index: number;
  data: PromisePortalComponent;
  onError(key: string, error: Error, info: ErrorInfo): void;
  onCancel(key: string): void;
  onComplete(key: string, data: object): void;
}

interface State {
  hasErrors: boolean;
}

class PromiseComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasErrors: false
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { componentKey, onError } = this.props;
    console.log('caught error!');
    this.setState({ hasErrors: true }, () => {
      onError(componentKey, error, info);
    });
  }

  handleCancel = (): void => {
    const { onCancel, componentKey } = this.props;
    //console.log('handleCancel', componentKey);
    onCancel(componentKey);
  }

  handleComplete = (): void => {
    const { onComplete, componentKey, data } = this.props;
    //console.log('handleComplete', componentKey);
    onComplete(componentKey, data);
  }

  render(): ReactNode {
    const { data, index, onCancel, onComplete } = this.props;
    const { hasErrors } = this.state;

    if (data == null)
      return (<span></span>);

    if (hasErrors || (index > 0 && data && !data.forceShow)) return <span></span>;

    const { ModalComponent, props, modalProps } = data;

    return (
      <ModalComponent
        {...props}
        modalProps={modalProps}
        cancel={onCancel}
        complete={onComplete}
        componentKey={this.props.componentKey}
      />
    );
  }
}

export default PromiseComponent;
