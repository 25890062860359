import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import Modal from 'react-bootstrap4-modal';
import { showPayout as show, hidePayout as hide } from "../reducers/lottorace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LotteryPermPayout from "../containers/GameLobby/PayoutPage/components/LotteryPermPayout";
import LottoRacePayout from "../containers/GameLobby/PayoutPage/components/LottoRacePayout";
import LotteryPayout from "../containers/GameLobby/PayoutPage/components/LotteryPayout";
import LotterySatellitePayout from "../containers/GameLobby/PayoutPage/components/LotterySatellitePayout";
import RafflePayout from "../containers/GameLobby/PayoutPage/components/RafflePayout";
import { isLotteryGame, isLotteryPermGame, isLottoRaceGame, isRaffleGame, isLotteryGameSatellite } from "../helpers/GameUtils";

function mapStateToProps(state, ownProps) {
  return {
    showRules: state.lottorace.modal.showRules,
    showPayout: state.lottorace.modal.showPayout,
    rulesDesc: state.lottorace.rulesDesc,
    currentGame: state.playout.currentGame
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showPayoutModal: () => dispatch(show()),
    hidePayoutModal: () => dispatch(hide()),
  };
}

class RulesModal extends Component {

  static propTypes /* remove-proptypes */ = {
    showRulesModal: PropTypes.func.isRequired,
    hideRulesModal: PropTypes.func.isRequired,
    showRules: PropTypes.bool,
    rulesDesc: PropTypes.string
  };

  close = (event) => {
    event.preventDefault();
    this.props.hidePayoutModal();
  };

  componentDidMount() {
    //$("#rulzText").html(this.props.rulesDesc);
  }

  componentDidUpdate() {
    //$("#rulzText").html(this.props.rulesDesc);
  }

  componentWillReceiveProps(nextProps) {
    //if (this.props.rulesDesc != nextProps.rulesDesc) {
    //  $("#rulzText").html(nextProps.rulesDesc);
    //}
  }

  render() {
    const currentGame = this.props.currentGame;

    const notAny = !isLotteryPermGame(currentGame) &&
      !isLottoRaceGame(currentGame) &&
      !isLotteryGame(currentGame) &&
      !isLotteryGameSatellite(currentGame) &&
      !isRaffleGame(currentGame);

    return (
      <Modal visible={this.props.showPayout} backdrop="static" onClickBackdrop={this.close} className="modal-fullscreen">
        <div className="modal-header">
          <h5 className="modal-title">
            Game Payout
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body px-0" style={{ background: "gray", overflowX: "hidden" }}>
          {currentGame &&
            <Fragment>
              {isLotteryPermGame(currentGame) && <LotteryPermPayout currentGame={currentGame} />}
              {isLottoRaceGame(currentGame) && <LottoRacePayout currentGame={currentGame} />}
              {isLotteryGame(currentGame) && <LotteryPayout currentGame={currentGame} />}
              {isLotteryGameSatellite(currentGame) && <LotterySatellitePayout currentGame={currentGame} />}
              {isRaffleGame(currentGame) && <RafflePayout currentGame={currentGame} />}
              {notAny && <LotteryPayout currentGame={currentGame} />}
            </Fragment>
          }

          {/* <div id="rulzText" className="rulezText" dangerouslySetInnerHTML={{__html: this.props.showRules?this.props.rulesDesc:''}}></div> */}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary resendButton" onClick={this.close} ><Translate id="generic.ok" /></button>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RulesModal);