import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {hideAdPopup as hideModal, showAdPopup as showModal} from "../reducers/lottorace";
import {getASArray} from "../helpers/Utils";
import PropTypes from "prop-types";

function mapStateToProps(state, ownProps) {
  return {
    pathname: ownProps.location.pathname,
    showAdFull: state.lottorace.modal.showAdFull,
    ads: state.lottorace.ads,
    screenType: ownProps.screenType,
    screenLocation: ownProps.screenLocation,
    screenSize: state.browser.mediaType
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    showAdModal: (ad, adInfo) => dispatch(showModal(ad, adInfo)),
    navigate: (route) => ownProps.history.push(route),
  };
}
class AdContainer extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      currentAd: null,
      currentAdInfo: null,
      currentAdAsModal: false,
      shouldHideContainer: sessionStorage.getItem('hide_' + props.screenType + '_' + props.screenLocation) || false
    }
  }

  componentDidMount() {
    //console.log('Mount Ad Container ' + this.props.screenType);

    this.processAds();
  }

  componentDidUpdate(prevProps, prevState, snapshot)
  {
    //console.log('Update Ad Container ' + this.props.screenType);

    if (this.props.ads && !prevProps.ads) {
      this.processAds();
    } else
    if (this.props.ads && this.props.ads.length != prevProps.ads.length)
    {
      this.processAds();
    }
  }

  processAds = () => {
    const screenAds = getASArray(this.props.ads).filter(a =>
      a.adScreenType == this.props.screenType &&
      a.adScreenLocation == this.props.screenLocation &&
      (a.adSize == this.props.screenSize.toUpperCase() || a.adSize == 'ALL'));

    //console.log('Found ' + screenAds.length + ' ads for ' + this.props.screenType + '/' + this.props.screenLocation);

    this.getRandomAd(screenAds);
  }

  getRandomItemBasedOnWeight = (values) => {
    let weightSum = values.map(item => item.adWeight).reduce((prev, next) => prev + next);
    let i, pickedValue,
      randomNr = Math.floor(Math.random() * Math.floor(weightSum)),
      threshold = 0;

    for (i = 0; i < values.length; i++) {
      if (values[i].adWeight === 0) {
        continue;
      }

      threshold += values[i].adWeight;
      if (threshold > randomNr) {
        pickedValue = values[i];
        break;
      }

      if (!pickedValue) {
        //nothing found based on probability value, so pick element marked with 0
        pickedValue = values.filter((value) => value.adWeight === 0);
      }
    }
    return pickedValue;
  }

  openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href,
    }).click();
  }

  handleAdClick = (ad) => {
    fetch(ad.adTargetUrlPc)
      .then( res => res.json() )
      .then( (ad) => {
        if (ad.client == 'WEB' || ad.client == 'MOBILE') {
          this.props.navigate(ad.url);
        } else {
          this.openInNewTab(ad.url);
        }
      });
  }

  handleAdHide = (ad) => {
    sessionStorage.setItem('hide_' + ad.adScreenType + '_' + ad.adScreenLocation, true);

    this.setState({ shouldHideContainer : true });
  }

  handleShowAdPopup = (ads) => {
    if (this.state.currentAd && this.state.currentAd.adScreenLocation == 'FULL') {
      const adShownInSession = sessionStorage.getItem('ad' + this.state.currentAd.id);
      if (adShownInSession == null) {
        sessionStorage.setItem('ad' + this.state.currentAd.id, 'true');
        this.props.showAdModal(this.state.currentAd, this.state.currentAdInfo);
      }
    }
    if (ads.length > 1) {
      setTimeout(() => this.getRandomAd(ads), this.state.currentAd.adDelay);
    }
  }

  getRandomAd = (ads) =>
  {
    if (ads.length == 0)
      return;

    const ad = this.getRandomItemBasedOnWeight(ads);

    if (ad == null) {
      //console.log('Random ad returned null');
      return;
    }

    //console.log('Showing ad', ad);

    const adUrl = siteAdsUrl + ad.adSourceUrl;

    fetch(adUrl)
      .then( res => res.json() )
      .then( (adInfo) => {
        //console.log(adInfo);
        if (adInfo.client != 'SCRIPT') {
            this.setState({
              currentAdAsModal: ad.adScreenLocation == 'FULL',
              currentAdInfo: adInfo,
              currentAd: ad
            }, () => {
              this.handleShowAdPopup(ads);
            });
        } else {
          this.setState({
            currentAdAsModal: ad.adScreenLocation == 'FULL',
            currentAdInfo: adInfo,
            currentAd: ad
          }, () => {
            this.handleShowAdPopup(ads);
          });
        }
        //TODO implement ad delay logic
      });
  }

  render() {
    const {currentAd, currentAdInfo, currentAdAsModal, shouldHideContainer} = this.state;

    return (
      <div className="game-lobby-ad">
        {currentAdAsModal == false && currentAdInfo && shouldHideContainer == false && currentAd.adScreenLocation == 'TOP' &&
          <button type="button" className="close mr-1 text-white ad-container-close" aria-label="Close" onClick={this.handleAdHide.bind(this, currentAd)}>
            <span aria-hidden="true">&times;</span>
          </button>
        }
        {currentAdAsModal == false && currentAdInfo && shouldHideContainer == false &&
          <div className="text-center cursor-pointer" onClick={this.handleAdClick.bind(this, currentAd)}>
            {currentAdInfo.client != 'SCRIPT' && <img src={currentAdInfo.url} className="img-fluid"/>}
            {currentAdInfo.client == 'SCRIPT' && <div dangerouslySetInnerHTML={{__html: currentAdInfo.url}} />}
          </div>
        }
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdContainer));
