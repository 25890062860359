import update from 'immutability-helper';
import { arraySizeNonNull, isManualRaffleGame } from "../helpers/GameUtils";

const OPEN_NUMBER_PICKING = 'OPEN_NUMBER_PICKING';
const OPEN_NUMBER_PICKING_MANUAL_RAFFLE = 'OPEN_NUMBER_PICKING_MANUAL_RAFFLE';
const OPEN_NUMBER_PICKING_2ND = 'OPEN_NUMBER_PICKING_2ND';
const OPEN_NUMBER_PICKING_EDIT = 'OPEN_NUMBER_PICKING_EDIT';
const OPEN_NUMBER_PICKING_EDIT_SAVE = 'OPEN_NUMBER_PICKING_EDIT_SAVE';
const NUMBER_PICKING_DELETE = 'NUMBER_PICKING_DELETE';

const OPEN_NUMBER_PICKING_CONFIRM = 'OPEN_NUMBER_PICKING_CONFIRM';
const SHOW_NUMBER_PICKING_CONFIRM_SECTION = 'SHOW_NUMBER_PICKING_CONFIRM_SECTION';

const OPEN_NUMBER_PICKING_CONFIRM_MANUAL_RAFFLE = 'OPEN_NUMBER_PICKING_CONFIRM_MANUAL_RAFFLE';
const OPEN_NUMBER_PICKING_JOINED = 'OPEN_NUMBER_PICKING_JOINED';
const OPEN_NUMBER_PICKING_NOT_JOINED = 'OPEN_NUMBER_PICKING_NOT_JOINED';

const OPEN_NUMBER_PICKING_PICK_X_MULTI = 'OPEN_NUMBER_PICKING_PICK_X_MULTI';

const RESET_NUMBER_PICKING = 'RESET_NUMBER_PICKING';
const RESET_CLICKED_BALLS = 'RESET_CLICKED_BALLS';
const INIT_MANUAL_RAFFLE_BALLS = 'INIT_MANUAL_RAFFLE_BALLS';

const BALL_CLICK = 'BALL_CLICK';
const BALL_CLICK_2ND = 'BALL_CLICK_2ND';
const BALL_UNCLICK = 'BALL_UNCLICK';
const BALL_UNCLICK_2ND = 'BALL_UNCLICK_2ND';
const INSTA_CLICK = 'INSTA_CLICK';
const INSTA_PICK_X_MULTI_CLICK = 'INSTA_PICK_X_MULTI_CLICK';

const INSTA_MANUAL_CLICK = 'INSTA_MANUAL_CLICK';

const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION';
const CHANGE_ALLOW_JOIN_NEW_GAME = 'CHANGE_ALLOW_JOIN_NEW_GAME';

const PICK_X_MULTI_OPTION = 'PICK_X_MULTI_OPTION';
const DONE_PICKING = 'DONE_PICKING';
const LAST_PLAY_INVALID = 'LAST_PLAY_INVALID';
const PLAY_CONFIRMED = 'PLAY_CONFIRMED';

const initialState = {
  showSection: "numberPicking",
  selectedNumbersArray: [],
  selectedMultiOptionArray: [],
  selectedCurrency: 'primary',
  subscriptions: 0,
  clickedBalls: [],
  clickedBalls2nd: [],
  multiOption: [0, 0, 0],
  playsJoined: 0,
  playsJoinedTotal: 0,
  gameInstanceIdJoined: 0,
  joinCode: -1,
  allowJoinNewGame: true,
  pickXType: false,
  pickSize: 0,
  pickSize2nd: 0,
  donePicking: false,
  lastPlayNotAvailable: false,
  playConfirmed: false,
};

function numberpickReducer(state = initialState, action = {}) {
  switch (action.type) {

    case OPEN_NUMBER_PICKING:
      return handleNumberPicking(state, action);

    case OPEN_NUMBER_PICKING_MANUAL_RAFFLE:
      return handleNumberPickingManualRaffle(state, action);

    case OPEN_NUMBER_PICKING_2ND:
      return handleNumberPicking2nd(state, action);

    case OPEN_NUMBER_PICKING_EDIT:
      return handleNumberPickingEdit(state, action);

    case OPEN_NUMBER_PICKING_EDIT_SAVE:
      return handleNumberPickingEditSave(state, action);

    case NUMBER_PICKING_DELETE:
      return handleNumberPickingDelete(state, action);

    case OPEN_NUMBER_PICKING_CONFIRM:
      return handleNumberPickingConfirm(state, action);

    case SHOW_NUMBER_PICKING_CONFIRM_SECTION:
      return update(state, {
        showSection: { $set: "numberPickingConfirm" }
      });


    case OPEN_NUMBER_PICKING_CONFIRM_MANUAL_RAFFLE:
      return handleNumberPickingConfirmManualRaffle(state, action);

    case OPEN_NUMBER_PICKING_JOINED:
      return handleNumberPickingJoined(state, action);

    case OPEN_NUMBER_PICKING_NOT_JOINED:
      return handleNumberPickingNotJoined(state, action);

    case OPEN_NUMBER_PICKING_PICK_X_MULTI:
      return handleNumberPickingPickXMulti(state, action);

    case BALL_CLICK:
      return handleBallClickEvent(state, action);

    case BALL_CLICK_2ND:
      return handleBallClickEvent2nd(state, action);

    case BALL_UNCLICK:
      return handleBallUnclickEvent(state, action);

    case BALL_UNCLICK_2ND:
      return handleBallUnclickEvent2nd(state, action);

    case INSTA_CLICK:
      return handleInstaClickEvent(state, action);

    case INSTA_PICK_X_MULTI_CLICK:
      return handleInstaPickXMultiClickEvent(state, action);

    case INSTA_MANUAL_CLICK:
      return handleInstaManualClickEvent(state, action);

    case CHANGE_CURRENCY:
      return handleChangeCurrencyEvent(state, action);

    case CHANGE_SUBSCRIPTION:
      return handleChangeSubscriptionEvent(state, action);

    case CHANGE_ALLOW_JOIN_NEW_GAME:
      return update(state, {
        allowJoinNewGame: { $set: action.data }
      });

    case PICK_X_MULTI_OPTION:
      return handlePickXMultiOption(state, action);

    case DONE_PICKING:
      return handleDonePicking(state, action);

    case LAST_PLAY_INVALID:
      return lastPlayNotAvailable(state, action);

    case PLAY_CONFIRMED:
      return update(state, {
        playConfirmed: { $set: action.data.playConfirmed }
      });

    case INIT_MANUAL_RAFFLE_BALLS:
      if (action.data.prefix.length) {

        let newClickedBalls = new Array(action.data.gamePlaySize).fill(-1); // fill with -1

        for (let i = 0; i < action.data.prefix.length; i++) {
          // push all prefixes to the front of the balls as per index
          newClickedBalls[i] = action.data.prefix[i];
        };
        return update(state, {
          clickedBalls: { $set: newClickedBalls },
          clickedBalls2nd: { $set: Array(action.data.gamePlaySize).fill(-1) },
        });

      } else {
        // else simply initialize with -1 all random
        return update(state, {
          clickedBalls: { $set: Array(action.data.gamePlaySize).fill(-1) },
          clickedBalls2nd: { $set: Array(action.data.gamePlaySize).fill(-1) },
        });

      }

    case RESET_CLICKED_BALLS:
      return update(state, {
        clickedBalls: { $set: Array(action.data.gamePlaySize).fill(-1) },
        clickedBalls2nd: { $set: Array(action.data.gamePlaySize2nd).fill(-1) },
      });

    case RESET_NUMBER_PICKING:
      return update(state, {
        showSection: { $set: "numberPicking" },
        selectedCurrency: { $set: 'primary' },
        selectedNumbersArray: { $set: [] },
        selectedMultiOptionArray: { $set: [] },
        clickedBalls: { $set: Array(action.data.pickSize).fill(-1) },
        clickedBalls2nd: { $set: Array(action.data.pickSize2nd).fill(-1) },
        joinCode: { $set: -1 },
        subscriptions: { $set: 0 },
        subscriptionCode: { $set: null },
        multiOption: { $set: [0, 0, 0] },
        gameInstanceIdJoined: { $set: 0 },
        donePicking: { $set: false },
      });
    default:
      return state;
  }
}

export default numberpickReducer;

function handleNumberPickingManualRaffle(state, action) {
  const startClickedBallsArray = Array(action.data.pickSize).fill(-1);
  const startClickedBalls2ndArray = Array(action.data.pickSize2nd).fill(-1);

  if (action.data.prefix.length) {
    for (let i = 0; i < action.data.prefix.length; i++) {
      // push all prefixes to the front of the balls as per index
      startClickedBallsArray[i] = action.data.prefix[i];
    };
  }

  return update(state, {
    showSection: { $set: "numberPicking" },
    // selectedNumbersArray: { $set: [] },
    clickedBalls: { $set: startClickedBallsArray },
    clickedBalls2nd: { $set: startClickedBalls2ndArray },
    joinCode: { $set: -1 },
    pickSize: { $set: action.data.pickSize },
    pickSize2nd: { $set: action.data.pickSize2nd },
    multiOption: { $set: [0, 0, 0] },
    joinedInstantLotteryGame: { $set: 0 }
  });
}

function handleNumberPicking(state, action) {
  const startClickedBallsArray = Array(action.data.pickSize).fill(-1);
  const startClickedBalls2ndArray = Array(action.data.pickSize2nd).fill(-1);
  return update(state, {
    showSection: { $set: "numberPicking" },
    // selectedNumbersArray: { $set: [] },
    clickedBalls: { $set: startClickedBallsArray },
    clickedBalls2nd: { $set: startClickedBalls2ndArray },
    joinCode: { $set: -1 },
    pickSize: { $set: action.data.pickSize },
    pickSize2nd: { $set: action.data.pickSize2nd },
    multiOption: { $set: [0, 0, 0] },
    joinedInstantLotteryGame: { $set: 0 }
  });
}

function handleNumberPicking2nd(state, action) {
  return update(state, {
    showSection: { $set: "numberPicking2nd" },
    clickedBalls2nd: { $set: Array(action.data.pickSize2nd).fill(-1) },
    joinCode: { $set: -1 },
    pickSize2nd: { $set: action.data.pickSize2nd }
  });
}

function handleNumberPickingDelete(state, action) {
  let newSelectedNumbersArray = state.selectedNumbersArray.filter((item, itemIdx) => itemIdx !== action.data.idx)
  return update(state, {
    selectedNumbersArray: { $set: newSelectedNumbersArray }
  })
}

function handleNumberPickingEdit(state, action) {
  return update(state, {
    showSection: { $set: "numberPicking" },
    // clickedBalls: { $set: action.data.line.split('_') },
    clickedBalls: { $set: action.data.line },
    joinCode: { $set: -1 },
  });
}

function handleNumberPickingEditSave(state, action) {
  return update(state, {
    showSection: { $set: "numberPickingConfirm" },
    clickedBalls: { $set: [] },
    selectedNumbersArray: { [action.data.idx]: { $push: [state.clickedBalls] } }
  });
}

function moveUserInputToEnd(array) {
  const nonNegativeNumbers = array.filter(num => num !== -1);
  array.fill(0); // Fill with zeros
  nonNegativeNumbers.forEach((num, index) => {
    array[array.length - nonNegativeNumbers.length + index] = num;
  });
  return array;
}

function handleNumberPickingConfirmManualRaffle(state, action) {
  // if manual raffle we push BEFORE first auto play
  // push input to the end e.g. input [2, 2, -1, -1] output [0, 0, 2, 2]
  let newSelectedNumbersArray = [];

  // if (action.data.editMode) {
  //   newSelectedNumbersArray = [...state.selectedNumbersArray];
  //   newSelectedNumbersArray[action.data.editIndex] = state.clickedBalls;
  // } else {
  newSelectedNumbersArray = [state.clickedBalls, ...state.selectedNumbersArray];

  // }
  // let firstAutoplayIndex = selectedNumbersPicked?.findIndex(picked => picked == false);


  return update(state, {
    showSection: { $set: "numberPickingConfirm" },
    selectedNumbersArray: { $set: newSelectedNumbersArray },
    donePicking: { $set: false },
  });

};

function handleNumberPickingConfirm(state, action) {
  if (arraySizeNonNull(state.clickedBalls) >= 1) {
    var clicked = state.clickedBalls;
    if (arraySizeNonNull(state.clickedBalls2nd) >= 1) {
      clicked.push("#");
      clicked = clicked.concat(state.clickedBalls2nd);
    }
    // push line if clicked balls length equal to pickSize
    if (arraySizeNonNull(state.clickedBalls) == state.pickSize) {
      return update(state, {
        showSection: { $set: "numberPickingConfirm" },
        selectedNumbersArray: { $push: [clicked] },
        playConfirmed: { $set: false }
      });
      // if clicked balls size not equal to pickSize do not push line
    } else {
      return update(state, {
        showSection: { $set: "numberPickingConfirm" },
        playConfirmed: { $set: false }
      });

    }
  } else {
    return update(state, {
      showSection: { $set: "numberPickingConfirm" },
      playConfirmed: { $set: false }
    });
  }
}

function handleNumberPickingJoined(state, action) {
  return update(state, {
    showSection: { $set: "numberPickingJoined" },
    playsJoined: { $set: action.data.playsJoined },
    playsJoinedTotal: { $set: action.data.playsJoinedTotal },
    gameInstanceIdJoined: { $set: action.data.gameInstanceId },
    joinCode: { $set: action.data.joinCode }
  });
}

function handleNumberPickingNotJoined(state, action) {
  return update(state, {
    showSection: { $set: "numberPickingNotJoined" },
    joinCode: { $set: action.data }
  });
}

function handleNumberPickingPickXMulti(state, action) {
  return update(state, {
    showSection: { $set: "numberPickingPickXMulti" },
    multiOption: { $set: [0, 0, 0] }
  });
}

function handlePickXMultiOption(state, action) {
  const clicked = state.clickedBalls;

  return update(state, {
    showSection: { $set: "numberPickingConfirm" },
    selectedNumbersArray: { $push: [clicked] },
    selectedMultiOptionArray: { $push: [action.data] },
    multiOption: { $set: action.data }
  });
}

function handleDonePicking(state, action) {
  return update(state, {
    donePicking: { $set: true }
  });
}

function lastPlayNotAvailable(state, action) {
  return update(state, {
    lastPlayNotAvailable: { $set: action.data.lastPlayNotAvailable }
  });
}

function handleBallClickEvent(state, action) {
  const ball = Number(action.data.ball);
  const pickX = action.data.pickX ? action.data.pickX : false;
  const ballIndex = Number(action.data.ballIndex);

  if (ballIndex != -1) {
    if (state.clickedBalls[ballIndex] > -1) {
      return handleBallUnclickEvent(state, action);
    }
    return update(state, {
      clickedBalls: { [ballIndex]: { $set: ball } }
    });
  } else {
    return update(state, {
      clickedBalls: { [state.clickedBalls.indexOf(-1)]: { $set: ball } }
    });
  }
}

function handleBallClickEvent2nd(state, action) {
  const ball = action.data.ball;
  const ballIndex = action.data.ballIndex;

  if (ballIndex != -1) {
    if (state.clickedBalls2nd[ballIndex] > -1) {
      return handleBallUnclickEvent2nd(state, action);
    }
    return update(state, {
      clickedBalls2nd: { [ballIndex]: { $set: ball } }
    });
  } else {
    return update(state, {
      clickedBalls2nd: { [state.clickedBalls2nd.indexOf(-1)]: { $set: ball } }
    });
  }
}

function handleInstaClickEvent(state, action) {
  return update(state, {
    clickedBalls: { $set: Array(state.pickSize).fill(-1) },
    clickedBalls2nd: { $set: Array(state.pickSize2nd).fill(-1) },
    selectedNumbersArray: { $set: action.data },
    donePicking: { $set: false },
  });
}

function handleInstaPickXMultiClickEvent(state, action) {
  return update(state, {
    selectedMultiOptionArray: { $set: action.data }
  });
}

function handleInstaManualClickEvent(state, action) {
  return update(state, {
    clickedBalls: { $set: Array(state.pickSize).fill(-1) },
    clickedBalls2nd: { $set: Array(state.pickSize2nd).fill(-1) },
    selectedNumbersArray: { $push: action.data }
  });
}

function handleChangeCurrencyEvent(state, action) {
  if (action.data == 'primary') {
    return update(state, {
      selectedCurrency: { $set: action.data }
    });
  } else {
    return update(state, {
      subscriptions: { $set: 0 },
      selectedCurrency: { $set: action.data }
    });
  }
}

function handleChangeSubscriptionEvent(state, action) {
  return update(state, {
    subscriptions: { $set: action.data }
  });
}

function handleBallUnclickEvent(state, action) {
  const ball = action.data.ball;
  const ballIndex = action.data.ballIndex;

  return update(state, {
    clickedBalls: { [ballIndex]: { $set: -1 } }
  });
}

export function resetNumberPicking(pickSize, pickSize2nd) {
  return {
    type: RESET_NUMBER_PICKING,
    data: { pickSize: pickSize, pickSize2nd: pickSize2nd }
  };
}

export function setDonePicking() {
  return {
    type: DONE_PICKING
  }
}

export function setLastPlayNotAvailable(isLastPlayNotAvailable) {
  return {
    type: LAST_PLAY_INVALID,
    data: { lastPlayNotAvailable: isLastPlayNotAvailable }
  }
}

export function setPlayConfirmed(playConfirmed) {
  return {
    type: PLAY_CONFIRMED,
    data: { playConfirmed: playConfirmed }
  }
}

export function resetClickedBalls(gamePlaySize, gamePlaySize2nd) {
  return {
    type: RESET_CLICKED_BALLS,
    data: { gamePlaySize: gamePlaySize, gamePlaySize2nd: gamePlaySize2nd }
  };
}

export function resetManualRaffleBalls(gamePlaySize, prefix) {
  return {
    type: INIT_MANUAL_RAFFLE_BALLS,
    data: { gamePlaySize: gamePlaySize, prefix: prefix }
  };
}

function handleBallUnclickEvent2nd(state, action) {
  const ball = action.data.ball;
  const ballIndex = action.data.ballIndex;

  return update(state, {
    clickedBalls2nd: { [ballIndex]: { $set: -1 } }
  });
}

export function showNumberPicking(pickSize) {
  return {
    type: OPEN_NUMBER_PICKING,
    data: { pickSize: pickSize }
  };
}

export function showNumberPickingManualRaffle(pickSize, prefix) {
  return {
    type: OPEN_NUMBER_PICKING_MANUAL_RAFFLE,
    data: { pickSize: pickSize, prefix: prefix }
  };
}


export function showNumberPicking2nd(pickSize2nd) {
  return {
    type: OPEN_NUMBER_PICKING_2ND,
    data: { pickSize2nd: pickSize2nd }
  };
}

export function showNumberPickingEdit(line, idx) {
  return {
    type: OPEN_NUMBER_PICKING_EDIT,
    data: { line: line, idx: idx },
  };
}

export function showNumberPickingEditSave(idx) {
  return {
    type: OPEN_NUMBER_PICKING_EDIT_SAVE,
    data: { idx: idx }
  };
}

export function doNumberPickingDelete(idx) {
  return {
    type: NUMBER_PICKING_DELETE,
    data: { idx: idx }
  }
}

export function showNumberPickingConfirm() {
  return {
    type: OPEN_NUMBER_PICKING_CONFIRM
  };
}

export function showNumberPickingConfirmSection() {
  return {
    type: SHOW_NUMBER_PICKING_CONFIRM_SECTION
  };
}

export function showNumberPickingConfirmManualRaffle(selectedNumbersPicked, editMode, editIndex) {
  return {
    type: OPEN_NUMBER_PICKING_CONFIRM_MANUAL_RAFFLE,
    data: {
      selectedNumbersPicked: selectedNumbersPicked,
      editMode: editMode,
      editIndex: editIndex
    }
  }
}

export function showNumberPickingJoined(n, t, s, g) {
  return {
    type: OPEN_NUMBER_PICKING_JOINED,
    data: { playsJoined: n, playsJoinedTotal: t, subscriptions: s, gameInstanceId: g }
  };
}

export function showNumberPickingNotJoined(code) {
  return {
    type: OPEN_NUMBER_PICKING_NOT_JOINED,
    data: code
  };
}

export function showNumberPickingPickXMulti(code) {
  return {
    type: OPEN_NUMBER_PICKING_PICK_X_MULTI,
    data: code
  };
}

export function ballClick(ball, ballIndex, pickX) {
  return {
    type: BALL_CLICK,
    data: { ball: ball, ballIndex: ballIndex, pickX: pickX }
  };
}

export function ballUnClick(ball, ballIndex) {
  return {
    type: BALL_UNCLICK,
    data: { ball: ball, ballIndex: ballIndex }
  };
}

export function ballClick2nd(ball, ballIndex) {
  return {
    type: BALL_CLICK_2ND,
    data: { ball: ball, ballIndex: ballIndex }
  };
}

export function ballUnClick2nd(ball, ballIndex) {
  return {
    type: BALL_UNCLICK_2ND,
    data: { ball: ball, ballIndex: ballIndex }
  };
}

export function instaClick(bets) {
  return {
    type: INSTA_CLICK,
    data: bets
  };
}

export function instaPickXMultiClick(options) {
  return {
    type: INSTA_PICK_X_MULTI_CLICK,
    data: options
  };
}

export function instaManualClick(bets) {
  return {
    type: INSTA_MANUAL_CLICK,
    data: bets
  };
}

export function changeCurrency(currency) {
  return {
    type: CHANGE_CURRENCY,
    data: currency
  };
}

export function changeSubscription(subscriptions) {
  return {
    type: CHANGE_SUBSCRIPTION,
    data: subscriptions
  };
}

export function changeAllowJoinNewGame(allowJoinNewGame) {
  return {
    type: CHANGE_ALLOW_JOIN_NEW_GAME,
    data: allowJoinNewGame
  };
}

export function pickXMultiUpdate(multiOption) {
  return {
    type: PICK_X_MULTI_OPTION,
    data: multiOption
  };
}
