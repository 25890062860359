import '../../../../styles/new/vendor/SuperResponsiveTableStyle.css';

import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { formatMoney, getASArray } from "../../../../helpers/Utils";
import { Fragment } from "react";
import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import useLocalize from '../../../../helpers/use-localize';

const LotterySatellitePayout = ({ currentGame }) => {
  const { translate } = useLocalize();
  const getGamePlaySizeBonus = () => {
    if (currentGame.gameRule == 'oneDrawGameBonusV2') {
      return 1;
    } else {
      return currentGame.gamePlaySize2nd;
    }
  }

  return (
    <div className="row py-3 px-2">
      <div className={classNames("col-md-8")}>
        <div className="text-center">
          <div className="text-bigger-50  font-weight-bold pb-2">
            <Translate id="payout.estimated_payout" defaultMessage="Estimated Payout" />&nbsp;
            {currentGame.gameLobby && currentGame.gameLobby.gamePayoutInfo &&
              (<Translate id="payout.ranks_paid" data={{ count: getASArray(currentGame.gameLobby.gamePayoutInfo).length }} defaultMessage="{count} Ranks Paid" />)}
          </div>

          {currentGame.gameLobby && getASArray(currentGame.gameLobby.gamePayoutInfo) &&
            <div className="payout-list-container">
              <Table className="w-100 bg-white text-dark">
                <Thead className="bg-dark  text-bigger-20">
                  <Tr>
                    <Th className="px-2 text-white text-left"><Translate id="payout.balls_matched" defaultMessage="Balls Matched" /></Th>
                    <Th className="px-2 text-white text-left"><Translate id="payout.prize_type" defaultMessage="Balls Matched" /></Th>
                    <Th className="px-2 text-white text-left"><Translate id="payout.payout" defaultMessage="Payout" /></Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {getASArray(currentGame.gameLobby.gamePayoutInfo)
                    .sort((a, b) => (a.rankPrize > b.rankPrize) ? -1 : ((b.rankPrize > a.rankPrize) ? 1 : 0))
                    .map(function (p, i) {
                      return (
                        <Tr key={i} className={classNames({ "bg-white": i % 2 == 0, "bg-light": i % 2 != 0 }, "text-bigger-20")}>

                          <Td className="px-2 text-left">
                            {p.rankNumber != 0 &&
                              <span>
                                {currentGame.gamePlaySize - p.rankNumber + 1} {(currentGame.gamePlaySize - p.rankNumber + 1) > 1 ? translate("general.balls") : translate("general.ball")}
                                {p.rankNumber2nd != 0 &&
                                  <Fragment> + {p.rankNumber2nd} {translate("general.bonus")}</Fragment>}
                              </span>}

                            {p.rankNumber == 0 && p.rankNumber2nd != 0 &&
                              <span>{p.rankNumber2nd} {translate("general.bonus")}</span>}
                          </Td>

                          <Td className="px-2 text-left">
                            {p.satelliteItems == null && <span>{translate("general.cash")}</span>}
                            {p.satelliteItems != null && <span>{translate("general.satellite")}</span>}
                          </Td>

                          <Td className="px-2 text-left">
                            {/* {`${formatMoney(p.rankPrize, currentGame.payoutCurrencyId)}`} */}
                            {p.rankPrize >= 1 && `${formatMoney(p.rankPrize, currentGame.payoutCurrencyId)}`}
                            {p.rankPrize <= 1 && p.satelliteItems != null &&
                              <React.Fragment>
                                {getASArray(p.satelliteItems)[0].entries}&nbsp;
                                {getASArray(p.satelliteItems)[0].entries > 1 ? <span>{translate("gamelobby.entries")}</span> : <span>{translate("payout.entry")}</span>}
                                &nbsp;to&nbsp;{getASArray(p.satelliteItems)[0].gameTitle}
                              </React.Fragment>}
                          </Td>

                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </div>}
        </div>
        <div className="w-100 text-smaller-75 ">

        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex justify-content-center">
          <img src="./static/img/smiley_face.png" className="img-fluid" />
        </div>
      </div>
    </div>
  )
}

export default LotterySatellitePayout;
