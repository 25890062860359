import { getAge, shouldValidateField } from "./Utils";

const isEmpty = value => value === undefined || value === null || value === '';
const join = (rules) => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0 /* first error */];

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'registration.quick.errors.email';
  }
}

//"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
export function passwordComplexity(value) {
  if (!isEmpty(value) && !/^(?=.*\d)(?=.*[a-zA-Z]).{6,20}$/i.test(value)) {
    return 'registration.quick.errors.password';
  }
}

export function passwordRequired(value) {
  if (isEmpty(value)) {
    return 'registration.quick.errors.password';
  }
}

export function countryVal(value) {

  if ($("#country").val() == "XX") {
    $("#country").css("color", "#a94442");
    return 'registration.quick.errors.country';
  } else {
    $("#country").css("color", "#000000");
  }
}

export function stateVal(value) {

  if ($("#provinceFrm").val() == "XX") {
    $("#provinceFrm").css("color", "#a94442");
    return 'registration.quick.errors.state';
  } else {
    $("#provinceFrm").css("color", "#000000");
  }
}

export function checkTermsAndCondition(value) {
  if (value == false) {
    return 'registration.quick.errors.tandc';
  }
}

export function checkboxOn(value) {
  if (mid != 1) return;
  if (!value || value == false) {
    return 'registration.quick.errors.tandc';
  }
}

export function phone(value) {
  if (!isEmpty(value) && !/^(\d+-?)+\d+$/i.test(value)) {
    return 'registration.quick.errors.phone';
  }
}

export function numbersOnly(value) {
  if (!isEmpty(value) && !/[0123456789]/.test(value)) {
    return 'Numbers only';
  }
}

export function characters(value) {
  if (!isEmpty(value) && !/^[a-zA-Z0-9_]+$/.test(value)) {
    return 'registration.quick.errors.username';
  }
}

export function passwordMatching(value1, value2) {
  if (!isEmpty(value1) || (!isEmpty(value2) && (value1 != value2))) {
    return 'registration.quick.errors.password';
  }
}

export function required(value) {
  if (isEmpty(value)) {
    return 'registration.quick.errors.required';
  }
}

export function postalCodeRequired(value) {
  if (shouldValidateField('postal_code') && isEmpty(value)) {
    return 'registration.quick.errors.required';
  }
}

export function tandcRequired(value) {
  if (isEmpty(value)) {
    return 'registration.quick.errors.tandc';
  }
}

export function greaterThan(min) {
  return value => {
    if (!isEmpty(value) && value <= min) {
      return 'registration.quick.errors.required';
    }
  };
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return 'validation.minlength.' + min;//`Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value)) {
      if (value.length > max || value?.value?.length > max) {
        return 'validation.maxlength.' + max;//`Must be no more than ${max} characters`;
      }
    };
  };
};


export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function isnumber(value) {
  if (!Number.isFinite(Number(value))) {
    return 'Must be an integer';
  }
}

export function amount(value) {
  if (!Number.isFinite(Number(value)) || (Number(value) < 0)) {
    return 'deposit.coupon.amount.required';
  }
}

export function oneOf(enumeration) {
  return value => {
    if (!~enumeration.indexOf(value)) {
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value == null || value !== data[field]) {
        return 'registration.quick.errors.no_match';
      }
    }
  };
}

export function requireIfChecked(field) {
  return (value, data) => {
    if (data[field] == true) {
      if (isEmpty(value)) {
        return 'deposit.coupon.required';
      }
    }
  };
}

export function requireTCIfChecked(field) {
  return (value, data) => {
    if (data[field] == true) {
      if (value == undefined || value == false) {
        return 'registration.quick.errors.tandc';
      }
    }
  };
}

export function requireIfCheckedSelfLimits(field) {
  return (value, data) => {
    if (data[field] == true) {
      if (isEmpty(value) || (Number(value) <= 0)) {
        return 'respgaming.amount.required';
      }
    }
  };
}

export function requireIfCountry(country) {
  return (value, data) => {
    // console.log(value, data, country);
    if (data) {
      if (country == data["country"]) {
        if (value == null)
          return 'registration.quick.errors.required';
      }
    }
  };
}

export function validateBirthdate(value) {
  return (value, data) => {
    if (data['bday_day'] != '-1' && data['bday_month'] != '-1' && data['bday_year'] != '-1') {
      const date = new Date(data['bday_year'], data['bday_month'], data['bday_day']);
      const age = getAge(date);
      // build date object and validate to make sure its 18yr+
      // "registration.quick.errors.young"
      if (age < 18) { // 6570 days in 18 yrs
        return 'registration.quick.errors.young';
      };

    } else {
      return 'registration.quick.errors.required';
    };
  };
}

export function createValidator(rules) {
  return (data = {}, props = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  };
}

