import classNames from "classnames";
import { Translate } from "react-localize-redux";
import { formatMoney, getASArray } from "../../../../helpers/Utils";
import React from "react";

const LottoRacePayout = ({ currentGame }) => {

  const getNth = (num) => {
    if (num < 0) return "";
    var rankStr = num + "";
    var lastDig = rankStr.substring(rankStr.length - 1);
    if (rankStr == "11") return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
    if (rankStr == "12") return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
    if (rankStr == "13") return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
    if (lastDig == "1") return (<span>{num}<sup><Translate id={"generic.first"} /></sup></span>);
    if (lastDig == "2") return (<span>{num}<sup><Translate id={"generic.second"} /></sup></span>);
    if (lastDig == "3") return (<span>{num}<sup><Translate id={"generic.third"} /></sup></span>);
    else return (<span>{num}<sup><Translate id={"generic.all"} /></sup></span>);
  }

  const getMultiplier = (prize, stakes) => {
    if (stakes == 0) return "n/a";

    const num = prize / stakes;
    const diff = Math.abs((num + 0.5) - Math.round(num + 0.5));

    if (diff == 0) {
      return num.toFixed(1) + "x";
    } else if ((num - Math.floor(num)) >= 0.5) {
      return Math.floor(num) + ".5x";
    } else {
      return Math.floor(num) + "x";
    }
  }

  const roundToNearest = (roundTo, value) => {
    return Math.round(value / roundTo) * roundTo;
  }

  return (
    <div className="row py-3">
      <div className={classNames("col-md-8")}>
        <div className="text-center">
          <div className="text-bigger-50 text-light font-weight-bold pb-2">
            <Translate id="payout.estimated_payout" defaultMessage="Estimated Payout" />&nbsp;
            <br className="payout-ranks-break" />
            (<Translate id="payout.ranks_paid" data={{ count: getASArray(currentGame.gameLobby.gamePayoutInfo).length }} defaultMessage="{count} Ranks Paid" />)
          </div>

          {getASArray(currentGame.gameLobby.gamePayoutInfo) &&
            <div className="table-responsive payout-list-container">
              <table className="w-100 bg-white text-dark">
                <thead className="bg-dark text-light text-bigger-20 payout-header">
                  <tr>
                    <th className="px-2 payout-header-cell text-center"><Translate id="payout.rank" defaultMessage="Rank" /></th>
                    <th className="px-2 payout-header-cell text-center"><Translate id="payout.multiplier" defaultMessage="Multiplier" /></th>
                    <th className="px-2 payout-header-cell text-center"><Translate id="payout.payout" defaultMessage="Payout" /></th>
                  </tr>
                </thead>
                <tbody>
                  {getASArray(currentGame.gameLobby.gamePayoutInfo).map(function (p, i) {
                    return (
                      <tr key={i} className={classNames(
                        { "bg-white": i % 2 == 0, "bg-light": i % 2 != 0 }, "text-bigger-20")}>
                        <td className="px-2 text-left payout-body-cell text-center">{getNth(p.rankNumber)}</td>
                        <td className="px-2 payout-body-cell text-center">{getMultiplier(p.rankPrize, currentGame.gamePlayCost + currentGame.gameFeePerPlay)}</td>
                        <td className="px-2 text-left payout-body-cell text-center">{formatMoney(p.rankPrize, currentGame.payoutCurrencyId)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>}
        </div>
        <div className="w-100 text-smaller-75 text-light">
          <Translate id="payout.disclaimer" defaultMessage="All values are estimated assuming no ties in each rank" />
        </div>
      </div>
      <div className="col-md-4">
        <div className="d-flex justify-content-center">
          <img src="./static/img/smiley_face.png" className="img-fluid" />
        </div>
      </div>
    </div>
  )
}

export default LottoRacePayout;
