import React from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import PromisePortal from "../thirdparty/react-promise-portal/PromisePortal";
import en_US from '../i18n/1/en_US';
import PopupJustOk from "../components/Modals/Popups/PopupJustOk";
import { format as dateFormat, parseISO } from 'date-fns';

const key = 'slightly_more_difficult_324098@##$95832';

export function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function splitObjectEntries(obj) {
  const entries = Object.entries(obj);
  const middleIndex = Math.ceil(entries.length / 2);

  const obj1 = Object.fromEntries(entries.slice(0, middleIndex));
  const obj2 = Object.fromEntries(entries.slice(middleIndex));

  return [obj1, obj2];
}


export function getTileStyle(game) {
  if (!game) return {};

  if (game.gameTileBackgroundColor) {
    return {
      background: game.gameTileBackgroundColor
    }
  } else {
    return {}
  }
}


export function supportsCanvas() {
  return !!document.createElement('canvas').getContext;
}

export function shuffleArray(array) {
  if (!array) return;

  let currentIndex = array.length, randomIndex;

  // while there remain elements to shuffle
  while (currentIndex != 0) {

    // pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // swap it with the current element
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


export function getColorsFromGradient(gradient) {
  if (!gradient || typeof gradient != "string") {
    return '';
  }

  if (!gradient.startsWith("linear-gradient")) {
    return [gradient, gradient];
  }

  let cleanedColor = gradient.slice(15).replace('(', '').replace(')', '').split(',');

  let firstColor;
  let secondColor;
  let colorDeg = "180";

  if (cleanedColor[0].endsWith("deg")) {
    if (cleanedColor[1].endsWith("%")) {
      firstColor = cleanedColor[1].trim().split(' ')[0];
      secondColor = cleanedColor[2].trim().split(' ')[0];
      colorDeg = cleanedColor[0].split("deg")[0];
    } else {
      firstColor = cleanedColor[1];
      secondColor = cleanedColor[2];
      colorDeg = cleanedColor[0].split("deg")[0];
    }

  } else {
    if (cleanedColor[0].endsWith("%")) {
      firstColor = cleanedColor[0].trim().split(' ')[0];
      secondColor = cleanedColor[1].trim().split(' ')[0];
    } else {
      firstColor = cleanedColor[0];
      secondColor = cleanedColor[1];
    }
  }

  return [firstColor, secondColor, colorDeg];
}

export function throttle(cb, delay) {
  let wait = false;

  return (...args) => {
    if (wait) {
      return;
    }

    cb(...args);
    wait = true;
    setTimeout(() => {
      wait = false;
    }, delay);
  }
}

function iOSversion() {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)][0];
  } else {
    return 9999;
  }
}

export function decodeBase64(string) {
  try {
    return decodeURIComponent(escape(window.atob(string)));

  } catch (e) {
    return string;
  }
}

export function encodeBase64(string) {
  try {
    return window.btoa(string);
  } catch (e) {
    return string;
  }
}

export function encryptString(string) {
  let encryptedString = AES.encrypt(string, key).toString();
  if (encryptedString) return encryptedString;
}

export function decryptString(string) {
  let bytes = AES.decrypt(string, key);
  let decryptedString = bytes.toString(Utf8);
  if (decryptedString) return decryptedString;
}

const firstBy = require('thenby');
const accounting = require('accounting');

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
}

export function iOS() {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const getBrowserVersion = function () {
  var ua = navigator.userAgent, tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

export function isBrowserSupportedInstantGame() {
  let isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
  let browserAndVersion = getBrowserVersion();
  let browser = browserAndVersion[0];
  let version = browserAndVersion[1];

  if (!isMobile) {
    switch (browser) {
      case browser.contains('IE'): return false;
      case browser.contains('Edge'): return version >= 12;
      case browser.contains('Firefox'): return version >= 18;
      case browser.contains('Chrome'): return version >= 49;
      case browser.contains('Safari'): return version >= 10;
      case browser.contains('Opera'): return version >= 36;
      default: return true;

    };

  } else {
    // if iphone
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      var result = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)][0];

      return result >= 10;
    } else {
      // if not iphone switch browser
      switch (browser) {
        case browser.contains('Opera Mobile'): return version >= 62;
        case browser.contains('Chrome'): return version >= 89;
        case browser.contains('Firefox'): return version >= 86;
        case browser.contains('Samsung'): return version > 5;
        default: return true;
      };
    };
  };
};


export const SCREEN_XS = 576;
export const SCREEN_SM = 767;
export const SCREEN_MD = 991;
export const SCREEN_LG = 1199;


export function mobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "android";
  }

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return "default";
}


export function getHomeUrl() {
  if (typeof siteHomeUrlOverride === 'undefined' || siteHomeUrlOverride === null) {
    return '/';
  } else {
    return siteHomeUrlOverride;
  }
}

export function showLoginPopup(url) {
  if (mid == 29) {
    showMetroDialog("<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i> Not Logged In", "You must login to play.", '[OK#btn-info]',
      function () { }
    );
  } else {
    showMetroDialog("<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i> Not Logged In", "You must login to play.", '[Login#btn-info]',
      function () {
        window.location.href = url + "/login?redirectUrl=" + encodeURIComponent(document.location.href)
      }
    );
  }
}

export function loadLocalLanguage(initialize) {
  return new Promise((fulfill, reject) => {
    let localLanguage = {
      languages: ["en_US"],
      translation: en_US.messages,
      options: {
        renderToStaticMarkup,
        ignoreTranslateChildren: true,
        defaultLanguage: 'en_US',
        onMissingTranslation: (props) => {
          return ''
        }
      }
    };

    initialize(localLanguage);

    fulfill();
  });
}

export function loadRemoteLanguage(locale) {
  return new Promise((fulfill, reject) => {

    fetch(siteUrlOverride + '/client/i18n/locales/' + mid, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(res => res.json())
      .then((jsonList) => {

        fetch(siteUrlOverride + '/client/i18n/locales/' + mid + '/' + locale, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          method: "GET",
          credentials: 'include',
        })
          .then(res => res.json())
          .then((jsonLanguage) => {
            let remoteLanguage = {
              languages: jsonList,
              translation: jsonLanguage.messages,
              options: {
                renderToStaticMarkup,
                ignoreTranslateChildren: true,
                //defaultLanguage: 'en_US',
                onMissingTranslation: (props) => { return '' }
              }
            };
            fulfill(remoteLanguage);
          });
      })
      .catch(reason => {
        console.error("Fallback to local language -> " + reason.toString());

        let remoteLanguage = {
          languages: [
            { name: "English", code: "en_US", active: true }
          ],
          translation: {}, //defaultLanguage.messages,
          options: {
            renderToStaticMarkup,
            defaultLanguage: "en_US",
            onMissingTranslation: () => { return "" }
          }
        };
        fulfill(remoteLanguage);
      });
  });
}

export function loadRemoteLocale(locale) {
  return new Promise((fulfill, reject) => {
    fetch(siteUrlOverride + '/client/i18n/locales/' + mid + '/' + locale, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET",
      credentials: 'include',
    })
      .then(res => res.json())
      .then((jsonLanguage) => {
        fulfill(jsonLanguage);
      });
  });
}

export function loadLocale(locale) {
  return new Promise((fulfill, reject) => {
    fulfill(loadRemoteLocale(locale));
  })
}

export function getASArray(array) {
  try {
    return array.source ? array.source : array;
  } catch (e) {
    return [];
  }
}

export function format2decimals(value) {
  let haveDecimal = value.indexOf(".") > 1;

  if (value) {
    if (haveDecimal) {
      let computedValue = value.slice(0, value.indexOf(".") + 3);
      return computedValue;
    } else {
      return value;
    }
  }
}


export function getCurrencySymbol(currency) {
  if (currency == 'USD' || currency == 'MXN' ||
    currency == 'CAD' || currency == 'ARS' ||
    currency == 'AUD' || currency == 'HKD' ||
    currency == 'NZD' || currency == 'COP' || currency == 'RDD') return "$";
  else if (currency == 'GBP') return "£";
  else if (currency == 'EUR') return "€";
  else if (currency == 'NGN') return "₦";
  else if (currency == 'BRL') return "R$";
  else if (currency == 'CNY') return "¥";
  else if (currency == 'INR') return "₹";
  else if (currency == 'VND') return "₫";
  else if (currency == 'IDR') return "Rp";
  else if (currency == 'ILS') return "₪";
  else if (currency == 'JMD') return "J$";
  else if (currency == 'JPY') return "¥";
  else if (currency == 'PEN') return "S/.";
  else if (currency == 'PHP') return "₱";
  else if (currency == 'RON') return "lei";
  else if (currency == 'RUB') return "₽";
  else if (currency == 'ZAR') return "R";
  else if (currency == 'KRW') return "₩";
  else if (currency == 'THB') return "฿";
  else if (currency == 'UYU') return "$U";
  else if (currency == 'RSD') return "РСД";
  else if (currency == 'FPP') return 'ß';
  else if (currency == 'IQD') return 'د.ع.';
  else if (currency == 'BHD') return 'BD';
  else if (currency == 'BTN') return 'Nu.';
  else if (currency == 'LRD') return '';
  else if (currency == '9JA') return ' 9JA ';
  else if (currency == 'AED') return 'د.إ';
  else return "";
}

export function formatMoneyWithFree(value, currency) {
  if (value == 0) {
    return 'FREE';
  } else {
    return formatMoney(value, currency);
  }
}

export function formatMoneyFriendly(value, currency, maxBeforeFriendly = 999999999) {
  value = parseFloat(value);

  const fractionDigits = value % 1 != 0 ? 2 : 0;

  const symbol = getCurrencySymbol(currency);

  if (currency == 'EUR') {
    return accounting.formatMoney(value, symbol, fractionDigits, ".", ",");
  } else
    if (currency == 'GBP' || currency == 'USD' || currency == 'MXN' || currency == 'NGN') {
      if (value > maxBeforeFriendly) {
        return symbol + getFriendlyNumber(value);
      } else {
        return accounting.formatMoney(value, symbol, fractionDigits, ",", ".");
      }
    } else
      if (currency == 'FPP' || currency == 'LRD' || currency == '9JA' || currency == 'BPM' || currency == 'BTT' || currency == 'BOM' || currency == 'JACKPOT') {
        if (value > maxBeforeFriendly) {
          return getFriendlyNumber(value) + " " + symbol;
        } else {
          return accounting.formatMoney(value, symbol, fractionDigits, ",", ".", "%v %s");
        }
      } else if (currency == 'BTN') {
        if (value > maxBeforeFriendly) {
          return symbol + getFriendlyNumber(value);
        } else {
          return accounting.formatMoney(value, symbol, fractionDigits, ",", ".", "%s%v");
        }
      } else {
        if (symbol != "") {
          if (value > maxBeforeFriendly) {
            return symbol + '' + getFriendlyNumber(value);
          } else {
            return accounting.formatMoney(value, symbol, fractionDigits, ",", ".");
          }
        } else {
          return accounting.formatNumber(value);
        }

      }
}

export function formatMoney(value, currency, nofloor) {
  if (!nofloor && value > 10000) {
    value = Math.floor(value);
  }

  const fractionDigits = value % 1 != 0 ? 2 : 0;
  const symbol = getCurrencySymbol(currency);
  if (currency == 'EUR') {
    if (window.defaultCountry == 'IE') {
      return accounting.formatMoney(value, symbol, fractionDigits, ",", ".");
    } else
      return accounting.formatMoney(value, symbol, fractionDigits, ".", ",");
  } else
    if (currency == 'GBP' || currency == 'USD' || currency == 'MXN' || currency == 'NGN') {
      return accounting.formatMoney(value, symbol, fractionDigits, ",", ".");
    } else
      if (currency == 'BHD') {
        return accounting.formatMoney(value, symbol, fractionDigits, ",", ".", "%s %v");
      } else
        if (currency == 'BTN') {
          return accounting.formatMoney(value, symbol, fractionDigits, ",", ".", "%s %v");
        } else
          if (currency == 'FPP' || currency == 'LRD' || currency == '9JA') {
            if (value > 9999) {
              return friendlyNumberNew(value) + " " + symbol;
            } else {
              return accounting.formatMoney(value, symbol, fractionDigits, ",", ".", "%v %s");
            }
          } else {
            if (symbol != "") {
              return accounting.formatMoney(value, symbol, fractionDigits, ",", ".");
            } else {
              return accounting.formatNumber(value);
            }
          }
}

var mergeSort = function (array, comparefn) {
  function merge(arr, aux, lo, mid, hi, comparefn) {
    var i = lo;
    var j = mid + 1;
    var k = lo;
    while (true) {
      var cmp = comparefn(arr[i], arr[j]);
      if (cmp <= 0) {
        aux[k++] = arr[i++];
        if (i > mid) {
          do
            aux[k++] = arr[j++];
          while (j <= hi);
          break;
        }
      } else {
        aux[k++] = arr[j++];
        if (j > hi) {
          do
            aux[k++] = arr[i++];
          while (i <= mid);
          break;
        }
      }
    }
  }

  function sortarrtoaux(arr, aux, lo, hi, comparefn) {
    if (hi < lo) return;
    if (hi == lo) {
      aux[lo] = arr[lo];
      return;
    }
    var mid = Math.floor(lo + (hi - lo) / 2);
    sortarrtoarr(arr, aux, lo, mid, comparefn);
    sortarrtoarr(arr, aux, mid + 1, hi, comparefn);
    merge(arr, aux, lo, mid, hi, comparefn);
  }

  function sortarrtoarr(arr, aux, lo, hi, comparefn) {
    if (hi <= lo) return;
    var mid = Math.floor(lo + (hi - lo) / 2);
    sortarrtoaux(arr, aux, lo, mid, comparefn);
    sortarrtoaux(arr, aux, mid + 1, hi, comparefn);
    merge(aux, arr, lo, mid, hi, comparefn);
  }

  function merge_sort(arr, comparefn) {
    var aux = arr.slice(0);
    sortarrtoarr(arr, aux, 0, arr.length - 1, comparefn);
    return arr;
  }

  return merge_sort(array, comparefn);
}

export function sortLinesArray(arrToSort, crit1, crit2) {
  /*arrToSort.sort(
    firstBy(function (v1, v2) {return v1[crit1] - v2[crit1];},-1)
      .thenBy(function (v1, v2) {return v1[crit2] - v2[crit2]})
  );*/

  mergeSort(arrToSort, firstBy(function (v1, v2) { return v1[crit1] - v2[crit1]; }, -1)
    .thenBy(function (v1, v2) { return v1[crit2] - v2[crit2] }));
}

export function sortLinesArrayByProperty(arrToSort) {
  mergeSort(arrToSort, firstBy(function (v1, v2) { return v1.numMatches - v2.numMatches; }, -1)
    .thenBy(function (v1, v2) { return v1.rank - v2.rank }));
}

export function sortLinesArrayQuick(arrToSort) {
  return arrToSort.sort((a, b) => a.rank - b.rank);
}

export function sortWinnersListRaffle(arrToSort, playerId) {
  return arrToSort.sort(firstBy("playRank").thenBy(function (v) { return (v.playerId == playerId) ? 1 : -1 }));
}

export function sortPlayItems(arrToSort) {
  for (var i = 0; i < arrToSort.length; i++) {
    arrToSort[i].sortPlayNumbers();
  }
}

export function checkNumMatches(num, allLines, bonusSlot, numIndex, matchInOrder = false, matchInSequenceLeft = false, matchInSequenceRight = false) {
  var count = 0;
  for (var i = 0; i < allLines.length; i++) {
    var play = allLines[i]; //PlayItem
    if (play.checkForMatch(num, false, bonusSlot, numIndex, matchInOrder, matchInSequenceLeft, matchInSequenceRight) > -1) {
      if (play.isMyPlay) {
        count++;
      }
    }
  }
  return count;
}

export function doTheMatching(num, allLines, bonusSlot, numIndex, matchInOrder = false, matchInSequenceLeft = false, matchInSequenceRight = false) {
  var count = 0;
  for (var i = 0; i < allLines.length; i++) {
    var play = allLines[i]; //PlayItem
    if (play.checkForMatch(num, true, bonusSlot, numIndex, matchInOrder, matchInSequenceLeft, matchInSequenceRight) > -1) {
      play.arPlayDataFormatted = "";
      play.arPlayDataMatches = "";
      //play.sortPlayNumbers();
      if (!play.isMyPlay) {
        count++;
      }
    }
  }
  return count;
}

export function doTheMatchingRaffle(draw, allLines) {
  for (var i = 0; i < allLines.length; i++) {
    var play = allLines[i]; //PlayItem
    if (play.checkForMatchRaffle(draw) > -1) {
      play.arPlayDataFormatted = "";
      play.arPlayDataMatches = "";
    }
  }
}

export function doLineSorting(allLines) {
  for (var i = 0; i < allLines.length; i++) {
    var play = allLines[i]; //PlayItem
    if (play.points > 0) {// optimization
      play.sortPlayNumbers();
    }
  }
}

export function getNthHTML(num) {
  if (num < 0) return "";
  var rankStr = num + "";
  var last2Dig;
  if (rankStr.length >= 2) {
    last2Dig = rankStr.substring(rankStr.length - 2);
    if (last2Dig == "11" || last2Dig == "12" || last2Dig == "13") {
      return num + "<sup>th</sup>";
    }
  }

  var lastDig = rankStr.substring(rankStr.length - 1);
  if (lastDig == "1") return '1<sup>st</sup>';
  if (lastDig == "2") return '2<sup>nd</sup>';
  if (lastDig == "3") return '3<sup>rd</sup>';
  else return num + "" + '<sup>th</sup>' + "";
};

export function getPlayById(playId, myLines) {
  for (var i = 0; i < myLines.length; i++) {
    if (myLines[i].playId == playId) {
      return myLines[i];
    }
  }
  return {};
}

function showEmailVerification(requestEmailConfirmation, primaryEmail) {
  window.showEmailConfirmation("Email Verification Required", "Before continuing, you must verify your email address.</br></br> Please check your email and click on the verification link sent to you.");

  $('#regResend').click(function () {
    requestEmailConfirmation();
    window.showRegistrationJustOk("Email Sent", "We sent you an email to " + primaryEmail);

    $('#regOkJustOk').click(function () {
      $(this).trigger('notify-hide');
    });

    $(this).trigger('notify-hide');
  });

  $('#regOk').click(function () {
    $(this).trigger('notify-hide');
  });
}

function checkSegment(seg) {
  if (seg != 'A' && seg != 'B') {
    return true;
  } else {
    return false;
  }
}

function hasFunds(currencyId, session, entryFee) {
  for (var i = 0; i < getASArray(session.playerAccounts).length; i++) {
    var acc = getASArray(session.playerAccounts)[i];
    if (acc && acc.accountCurrency == currencyId && acc.accountId == 43 && parseFloat(acc.accountBalance) >= entryFee) {
      return true;
    }
  }

  return false;
}

function hasDeposits(playerSegment) {
  switch (playerSegment) {
    case 'A', 'A1', 'B', 'C':
      return false;

    default:
      return true;
  }
}

export function allowJoin(session, showFullRegistration, requestEmailConfirmation, currentGame, navigate, plays = 1, translate) {

  var playerSegment = '';
  if (session && session.player && session.player.verification) {
    playerSegment = session.player.verification.playerSegment;
  }

  if (playerSegment == '') return false;

  if (playerSegment == 'A' || playerSegment == 'A1') {
    window.showRegistrationJustOk("Not available in your country", siteName + " is not currently available in your country.  We will notify you if this changes in the future");
    $('#regOkJustOk').click(function () {
      $(this).trigger('notify-hide');
    });
    return false;
  };

  if (currentGame && currentGame.gamePlayCost == 0 && !hasDeposits(playerSegment) && session.configuration.freerollNoDeposits == false) {
    console.log("Non depositors cannot play free-rolls!");
    showNSFPopup(translate("popup.freerolls_disabled.title"), translate("popup.freerolls_disabled.text"));

    $('#nsfOk').click(function () {
      $(this).trigger('notify-hide');
    });

    $('#nsfDeposit').click(function () {
      if (navigate) {
        navigate('/cashier');
      }
      $(this).trigger('notify-hide');
    });
    return false;
  }

  if (session.configuration['EMAIL_ON_FULL_REGISTRATION'] == "true" && session.player.verification.emailVerified == false && checkSegment(playerSegment) == true) {
    showEmailVerification(requestEmailConfirmation, session.player?.details.primaryEmail);

    return;
  }

  if (playerSegment == 'B') {
    showFullRegistration();
    return false;
  }

  if (currentGame && currentGame.currencyId && currentGame.gamePlayCost && currentGame.gameFeePerPlay && !hasFunds(currentGame.currencyId, session, (currentGame.gamePlayCost + currentGame.gameFeePerPlay) * plays)) {
    showNSFPopup(translate("popup.no_money.title"), translate("popup.no_money.text"));

    $('#nsfOk').click(function () {

      $(this).trigger('notify-hide');
    });

    if (mid == 29) {
      $('#nsfDeposit').hide();
    }

    $('#nsfDeposit').text(translate("registration.popup.deposit"));

    $('#nsfDeposit').click(function () {
      if (mid == 28) {
        document.location.href = "https://www.westernlotto.com/account/add-funds";
      } else if (mid == 29) {
        document.location.href = 'https://1xbet.com/en/office/recharge/';
      } else if (mid == 35) {
        document.location.href = "#";
      } else if (mid == 36) {
        document.location.href = "https://dundeeunited.fandraw.com/account/add-funds";
      } else if (mid == 48) {
        document.location.href = "https://celeblotto.com/account/add-funds";
      } else {
        if (navigate) {
          navigate('/cashier');
        }
        $(this).trigger('notify-hide');
      }
    });
    return false;
  }


  switch (playerSegment) {
    case 'A':
      //ineligible jurisdiction
      //retVal = false;
      return false;

    case 'A1':
      //inactive player
      //retVal =  false;
      return false;

    case 'B':
      /*var par:String;
       if (realMoney) {
       par = 'true';
       } else {
       par = 'false';
       }
       ExternalInterface.call("completeFullRegistration", par);*/
      //show full registration here
      //navigate("/fullregistration");
      showFullRegistration();
      return false;

    case 'C':
      return true;

    case 'E':
      return true;

    case 'F':
      return true;

    case 'G':
      return true;

    default:
      return true;
  }
}

export function friendlyNumber(n, d) {
  var x = ('' + n).length, p = Math.pow, d = p(10, d)
  x -= x % 3
  return Math.round(n * d / p(10, x)) / d + " kMGTPE"[x / 3]
}

var pow = Math.pow, floor = Math.floor, abs = Math.abs, log = Math.log;

function round(n, precision) {
  var prec = Math.pow(10, precision);
  return Math.floor(n * prec) / prec; // we always round down!
}

export function friendlyNumberNew(n) {
  var base = floor(log(abs(n)) / log(1000));
  var suffix = 'kmb'[base - 1];
  return suffix ? round(n / pow(1000, base), 2) + suffix : '' + n;
}

//Hide precision if it's 00
export function formatNumbers(num, precision = true, p_prec = -1, p_showZero = true) {
  var returnStr;
  var prec;
  try {
    (!precision && p_prec == -1) ? prec = 0 : prec = 1;
    if (p_prec != -1)
      prec = p_prec;

    //if less than 1000, return as is.
    if (num < 1000) {
      returnStr = Math.round(num).toString();

      //thousands
    } else if (num < 999999.99) {
      returnStr = (num / 1000).toFixed(prec) + 'K';
      //millions
    }
    else if (num < 999999999.99) {
      returnStr = (num / 1000000).toFixed(prec) + 'M';
    }
    else {
      (!precision) ? prec = 0 : prec = 2;
      if (p_prec != -1) prec = p_prec;
      if (num < 999999999999.99) {
        returnStr = (num / 1000000000).toFixed(prec) + 'B';
      }
      else if (num < 999999999999999.99) {
        returnStr = (num / 1000000000000).toFixed(prec) + 'T';
      }
      else if (num < 999999999999999999.99) {
        returnStr = (num / 1000000000000000).toFixed(prec) + 'Q';
      }
    }
    if (!p_showZero) {
      var arr = returnStr.split(".");
      if (arr.length > 1) {
        var dec = arr[1]; //50M,
        var dig = dec.substr(0, prec);//50
        dec = dec.substr(prec);//M

        if (Number(dig) == 0)
          returnStr = arr[0] + dec;
        else {
          var newDig;
          var nonZeroInd;
          for (var i = dig.length - 1; i >= 0; i--) {
            if (dig.charAt(i) != "0") {
              nonZeroInd = i;
              returnStr = arr[0] + "." + dig.substr(0, i + 1) + dec;
              break;
            }
          }
        }
      }
    }
  } catch (e) {
    console.log("An unexpected error has occured in: MathUtil.formatNumbers()");
  }
  return returnStr;
}

/* ADDED WITH BINGO */

export function getElementOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

export function getFacebookAvatarLink(fbid) {
  try {
    return `https://graph.facebook.com/${atob(fbid)}/picture?type=square`
  } catch (e) {
    return `https://graph.facebook.com/${fbid}/picture?type=square`
  }
};


export function countdownSecondsFormatTimer(milliseconds) {
  let totalSeconds = Math.round(milliseconds / 1000);

  let seconds = parseInt(totalSeconds % 60, 10);

  if (seconds < 1) {
    return 'NOW';
  } else {
    return seconds;
  };

};

export function countdownFormatTimer(milliseconds, end, translate) {
  let totalSeconds = Math.round(milliseconds / 1000);

  let seconds = parseInt(totalSeconds % 60, 10);
  let minutes = parseInt(totalSeconds / 60, 10) % 60;
  let hours = parseInt(totalSeconds / 3600, 10);
  let days = parseInt((totalSeconds / 86400), 10);

  seconds = seconds < 10 ? '0' + seconds : seconds;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  hours = hours < 10 ? '0' + hours : hours;

  if (days == 0) {
    if (totalSeconds < 1) {
      return "--:--:--";
    }
    if (window.globalConfig.templateId == 1) {
      return hours + ':' + minutes + ':' + seconds;
    } else
      return (<span><b>{hours}</b>h <b>{minutes}</b>m <b>{seconds}</b>s</span>);
  } else
    if (days < 7) {
      return dateFormat(end, translate("generic.datetimeformat.tournament.less_than_7"));
    } else
      if (days >= 7) {
        return dateFormat(end, translate("generic.datetimeformat.tournament.more_than_7"));
      }
};

export const getElementById = (id) => {
  if (id.startsWith('#')) id.replace('#', '');
  return window.document.getElementById(id);
}

export const updateTopNavigationTitle = (title) => {
  const topNavigation = window.document.getElementById('navigation-back-text');
  if (topNavigation != null)
    topNavigation.innerHTML = '&nbsp;|&nbsp;' + title;
}

export const updateBackgroundImage = (imageUrl) => {
  const contentWrapper = window.document.getElementById('contentSection');
  contentWrapper.style.background = "url('" + imageUrl + "') center center / cover no-repeat fixed";
}

export const resetBackgroundImage = () => {
  const contentWrapper = window.document.getElementById('contentSection');
  if (contentWrapper) {
    contentWrapper.style.background = null;/*window.globalConfig.defaultBackground;*/

  }
}

export const updateModalBackgroundImage = (imageUrl) => {
  const contentWrapper = window.document.getElementsByClassName('modal-content')[0];
  contentWrapper.style.backgroundImage = "url('" + imageUrl + "')";
  contentWrapper.style.backgroundSize = "cover";
  contentWrapper.style.borderImage = "none";
}

export function formatDecimal(value) {
  return accounting.formatMoney(value, '', 2, ",", ".").replaceAll('.00', '');
}

const hasOwn = Object.prototype.hasOwnProperty

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y
  } else {
    return x !== x && y !== y
  }
}

export function shallowEqual(objA, objB) {
  if (is(objA, objB)) return true

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  const keysA = Object.keys(objA)
  const keysB = Object.keys(objB)

  if (keysA.length !== keysB.length) return false

  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false
    }
  }

  return true
}

export async function showTimeDeltaPopup() {
  if (!sessionStorage.getItem('timeDeltaPopupShown') &&
    showServerTimeDeltaWarning && showServerTimeDeltaWarning == true) {
    if (Math.abs(window.serverTimeDelta) >= 5000) {
      await PromisePortal.show(PopupJustOk, {
        title: 'System Time Incorrect',
        body: 'Your system clock does not seem to be correct.\nThis could cause issues when playing games.\nPlease update your system time for the optimal experience.'
      }).then(() => {
        sessionStorage.setItem('timeDeltaPopupShown', true);
      });
    };
  }
};

export function showBrowserOutdatedPopup() {
  PromisePortal.show(PopupJustOk, {
    title: 'Outdated OS/Browser version',
    body: 'Please update your OS before playing this game.'
  });
};

window.showBrowserOutdatedPopup = showBrowserOutdatedPopup;

// splits an 1D array into a matrix of x itemsPerRow
export const split1DArray = (arr, itemsPerRow) => {
  if (!arr) return;

  let matrix = [];
  let cardRow = [];

  for (let i = 1; i <= arr.length; i++) {
    cardRow.push(arr[i - 1]);
    if (i > 0 && i % itemsPerRow == 0) {
      matrix.push(cardRow);
      cardRow = [];
    };
  };
  return matrix;
};

// gets the element's 1D array index based on matrix indexRow and indexLetter
export const getRealIndex = (indexRow, indexLetter, crosswordType) => {
  return indexRow * crosswordType + indexLetter;
};

const is_date = function (input) {
  if (Object.prototype.toString.call(input) === "[object Date]")
    return true;
  return false;
};

export function showDate(date, format) {
  if (date == null)
    return 'N/A';

  try {
    if (is_date(date) || !isNaN(date)) {
      return dateFormat(date, format);
    } else {
      return dateFormat(parseISO(date), format);
    }
  } catch (e) {
    return 'N/A';
  }
}

function isLeapYear(year) {
  var d = new Date(year, 1, 28);
  d.setDate(d.getDate() + 1);
  return d.getMonth() == 1;
};

export function isOverAge(date, age) {
  return age <= getAge(date);
}

export function getAge(date) {
  var d = new Date(date);
  var now = new Date();

  var years = now.getFullYear() - d.getFullYear();
  d.setFullYear(d.getFullYear() + years);

  if (d > now) {
    years--;
    d.setFullYear(d.getFullYear() - 1);
  };

  var days = (now.getTime() - d.getTime()) / (3600 * 24 * 1000);
  return Math.floor(years + days / (isLeapYear(now.getFullYear()) ? 366 : 365));
};

export function getPlural(word, count) {
  if (count == 1) {
    return word;
  } else {
    return word + 's';
  }
}

export function getBonus(word, count) {
  if (count == 0) {
    return 'No Bonus';
  } else
    if (count == 1) {
      return word;
    } else {
      return count + ' ' + word;
    }
}

export const shouldValidateField = (fieldName) => {
  const requiredOverrides = window.store.getState().lottorace.session.configuration.customFields?.full_reg?.required_overrides;
  if (requiredOverrides && requiredOverrides[fieldName] != null && requiredOverrides[fieldName] == false) {
    return false;
  };
  return true;
};

export function scrollToTop() {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch (error) {
    // just a fallback for older browsers
    window.scrollTo(0, 0);
  }
}

export function scrollToRef(ref) {
  if (ref && ref.current /* + other conditions */) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

export function getFriendlyNumber(n) {
  var units = ["k", "M", "B", "T"];

  // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
  let unit = Math.floor(((n).toFixed(0).length - 1) / 3) * 3
  // Calculate the remainder
  var num = String((n / ('1e' + unit)).toFixed(2)).replaceAll('.00', '').replaceAll(".50", ".5");
  var unitname = units[Math.floor(unit / 3) - 1]

  // output number remainder + unitname
  return num + unitname;
}
