var React = require('react');
var PropTypes = require('prop-types');
var createReactClass = require('create-react-class');
// Generic Countdown Timer UI component
//
// https://github.com/uken/react-countdown-timer
//
// props:
//   - initialTimeRemaining: Number
//       The time remaining for the countdown (in ms).
//
//   - interval: Number (optional -- default: 1000ms)
//       The time between timer ticks (in ms).
//
//   - formatFunc(timeRemaining): Function (optional)
//       A function that formats the timeRemaining.
//
//   - tickCallback(timeRemaining): Function (optional)
//       A function to call each tick.
//
//   - completeCallback(): Function (optional)
//       A function to call when the countdown completes.
//
var CountdownTimer = createReactClass({
  displayName: 'CountdownTimer',

  propTypes: {
    endTime: PropTypes.number.isRequired,
    initialTimeRemaining: PropTypes.number.isRequired,
    interval: PropTypes.number,
    formatFunc: PropTypes.func,
    tickCallback: PropTypes.func,
    completeCallback: PropTypes.func
  },

  getDefaultProps: function () {
    return {
      endTime: 0,
      interval: 1000,
      formatFunc: null,
      tickCallback: null,
      completeCallback: null,
      loaded: false
    };
  },

  getInitialState: function () {
    // Normally an anti-pattern to use this.props in getInitialState,
    // but these are all initializations (not an anti-pattern).
    return {
      endTime: this.props.endTime || null,
      timeRemaining: this.props.initialTimeRemaining,
      timeoutId: null,
      prevTime: null,
      loaded: false
    };
  },

  componentDidMount: function () {
    this.setState({ loaded: true });
  },

  componentWillReceiveProps: function (newProps) {
    if (this.state.timeoutId) { clearTimeout(this.state.timeoutId); }
    this.setState({ prevTime: null, timeRemaining: newProps.initialTimeRemaining });
    if (this.state.loaded == false && newProps.loaded == true) {
      this.tick();
    } else
      if (this.state.loaded == true && newProps.loaded == false) {
        clearTimeout(this.state.timeoutId);
      }
  },

  componentDidUpdate: function () {
    try {
      if ((!this.state.prevTime) && this.state.timeRemaining > 0) {
        this.tick();
      }
    } catch (e) {
      console.log("CountdownTimer componentDidUpdate is null");
    }
  },

  componentWillUnmount: function () {
    this.setState({ loaded: false });
    clearTimeout(this.state.timeoutId);
  },

  tick: function () {
    if (this.state.loaded == false) return;
    var currentTime = Date.now();
    var dt = this.state.prevTime ? (currentTime - this.state.prevTime) : 0;
    var interval = this.props.interval;

    // correct for small variations in actual timeout time
    var timeRemainingInInterval = (interval - (dt % interval));
    var timeout = timeRemainingInInterval;

    if (timeRemainingInInterval < (interval / 2.0)) {
      timeout += interval;
    }

    var timeRemaining = Math.max(this.state.timeRemaining - dt, 0);
    var countdownComplete = (this.state.prevTime && timeRemaining <= 900);

    //if (this.isMounted()) {
    try {
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId);
      }

      this.setState({
        timeoutId: countdownComplete ? null : setTimeout(this.tick, timeout),
        prevTime: currentTime,
        timeRemaining: timeRemaining
      });
    } catch (e) {
      console.log("CountdownTimer not mounted!");
    }
    //}

    if (countdownComplete) {
      if (this.props.completeCallback) { this.props.completeCallback(); }
      return;
    }

    if (this.props.tickCallback) {
      this.props.tickCallback(timeRemaining);
    }
  },

  getFormattedTime: function (milliseconds) {
    if (this.props.formatFunc && this.props.endTime) {
      return this.props.formatFunc(milliseconds, this.props.endTime);
    }

    var totalSeconds = Math.round(milliseconds / 1000);

    var seconds = parseInt(totalSeconds % 60, 10);
    var minutes = parseInt(totalSeconds / 60, 10) % 60;
    var hours = parseInt(totalSeconds / 3600, 10);

    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;

    return hours + ':' + minutes + ':' + seconds;
  },

  render: function () {
    var timeRemaining = this.state.timeRemaining;

    return (
      <span className='timer'>
        {this.getFormattedTime(timeRemaining)}
      </span>
    );
  }
});

export default CountdownTimer;