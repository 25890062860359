import React from "react";
import { connect } from "react-redux";
import { hideDepositModal } from "../reducers/lottorace";
import { isMobile } from "../helpers/Utils";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		if (window.logAccount != null && LogRocket) {
			window.LogRocket.captureException(error);
		}

		console.log("Error caught in componentDidCatch", error, window.store);

		const reduxStore = window.store.getState();

		window.trackError({
			'exPlayer': (reduxStore.lottorace.loggedIn && reduxStore.lottorace.session.player) ? reduxStore.lottorace.session.player.id + "" : -1,
			'exUsername': (reduxStore.lottorace.loggedIn && reduxStore.lottorace.session.player) ? reduxStore.lottorace.session.player.username : '',
			'exUrl': document.location.href,
			'exMessage': 'HTML5 JS Error',
			'exDescription': error.message,
			'exErrorObject': error.stack,
			'exFatal': false
		});

		if (error && error.message.indexOf('cross-origin frame') > -1) {
			window.store.dispatch(hideDepositModal());
		} else
			if (error && error.message.indexOf('cross-origin frame') == -1) {
				console.log('Showing error popup!');
				/*showMetroDialog('Refresh Time', "Its time to get squeaky clean! Click OK to clean away!", '[Reload#btn-info]',
					(reload) => {
						window.location.href = window.siteRoot;
					});*/
			}

	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <section className="jumbotron mb-0">
				<div className="container">
					<div id="mainContent" className="rounded white">
						<div className="row px-5">
							<div className="col-sm-12 text-center text-dark">
								<img src={window.staticBaseUrl + "/members/" + window.mid + (isMobile() ? "/logo/logo_small.png" : "/logo/logo.png")} className="img-fluid pt-2" width="300" />
								<div className="py-4">
									We are very sorry but something went wrong.	This error has been logged and reported.
									<br />
									<div className={"py-2 font-italic"}>Thank you for helping us improve.</div>
									<br />
									Please click on the button below to resume.
								</div>
								<div className="mb-4" role="status">
									<button className={"btn btn-primary"} onClick={() => window.location.href = window.siteRoot}>Continue</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>;
		}

		return this.props.children;
	}
}

export default connect()(ErrorBoundary);
