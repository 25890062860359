PlayerClientDesktopDto = function(){
  this._CLASS = 'PlayerClientDesktopDto';
  this.language = '';
  this.manufacturer = '';
  this.os = '';
  this.pixelAspectRatio = 0;
  this.playerType = '';
  this.screenColor = '';
  this.screenDPI = 0;
  this.screenResolutionX = 0;
  this.screenResolutionY = 0;
  this.serverString = '';
  this.clientVersion = '';
  this.timeZone = '';
  this.latitude = '';
  this.longitude = '';
  this.ip = '';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.player.PlayerClientDesktopDto', 'PlayerClientDesktopDto', true);

EligibleLocationRequest = function(){
  this._CLASS = 'EligibleLocationRequest';
  this.memberId = '';
  this.latitude = '';
  this.longitude = '';
  this.ip = '';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.common.EligibleLocationRequest', 'EligibleLocationRequest', true);

CheckPlaysRequest = function(){
  this._CLASS = 'CheckPlaysRequest';
  this.gameInstanceId = '';
  this.plays = '';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.actions.CheckPlaysRequest', 'CheckPlaysRequest', true);

PlayerDto = function(){
  this._CLASS = 'PlayerDto';
  this.username = '';
  this.password = ''
  this.memberId = '';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.player.PlayerDto', 'PlayerDto', true);

PlayerDetailDto = function(){
  this._CLASS = 'PlayerDetailDto';
  this.firstName = '';
  this.lastName = '';
  this.birthdate = '';
  this.gender = '';
  this.phoneMobile = '';
  this.primaryEmail = '';
  this.primaryAddressCity = '';
  this.primaryAddressState = '';
  this.primaryAddressStreet = '';
  this.primaryAddressPostalcode = '';
  this.primaryAddressCountry = '';
  this.referredByMemberId = '';
  this.languageId = '';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.player.PlayerDetailDto', 'PlayerDetailDto', true);

PurchaseTicketRequest = function(){
  this._CLASS = 'PurchaseTicketRequest';
  this.ticketRequest = null;
  this.playerInfo = null;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.actions.PurchaseTicketRequest', 'PurchaseTicketRequest', true);

SearchResultDto = function(result, totalCount) {
  this._CLASS = 'SearchResultDto';
  this.result = result;
  this.totalCount = totalCount;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.base.SearchResultDto', 'SearchResultDto', true);

RegistrationVO = function(username){
  this._CLASS = 'RegistrationVO';
  this.username = username;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.web.web.vo.RegistrationVO', 'RegistrationVO', true);

DepositRegistrationVO = function(firstName) {
  this._CLASS = 'DepositRegistrationVO';
  this.firstName = firstName;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.web.web.vo.DepositRegistrationVO', 'DepositRegistrationVO', true);

Address = function(address, city, province, postCode, country, password) {
  this._CLASS = 'Address';
  this.address = address;
  this.city = city;
  this.province = province;
  this.postCode = postCode;
  this.country = country;
  this.password = password;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.web.web.vo.Address', 'Address', true);

ContactPreference = function(contactByEmail, contactEmailNews, contactEmailDeposits, contactEmailWithdrawals, contactEmailWins) {
  this._CLASS = 'ContactPreference';
  this.contactByEmail = contactByEmail;
  this.contactEmailNews = contactEmailNews;
  this.contactEmailDeposits = contactEmailDeposits;
  this.contactEmailWithdrawals = contactEmailWithdrawals;
  this.contactEmailWins = contactEmailWins;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.web.web.vo.ContactPreference', 'ContactPreference', true);

ResponsibleGaming = function(dailyLimit, weeklyLimit, monthlyLimit, weeklyBetLimit, dailyLimitEnabled, weeklyLimitEnabled, monthlyLimitEnabled, weeklyBetLimitEnabled) {
  this._CLASS = 'ResponsibleGaming';
  this.dailyLimit = dailyLimit;
  this.weeklyLimit = weeklyLimit;
  this.monthlyLimit = monthlyLimit;
  this.weeklyBetLimit = weeklyBetLimit;
  this.dailyLimitEnabled = dailyLimitEnabled;
  this.weeklyLimitEnabled = weeklyLimitEnabled;
  this.monthlyLimitEnabled = monthlyLimitEnabled;
  this.weeklyBetLimitEnabled = weeklyBetLimitEnabled;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.web.web.vo.ResponsibleGaming', 'ResponsibleGaming', true);

PaymentSession = function() {
  this._CLASS = 'PaymentSession';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.payments.dto.PaymentSession', 'PaymentSession', true);

PlayerPaymentDto = function(id) {
  this._CLASS = 'PlayerPaymentDto'
  this.id = id;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.payments.dto.PlayerPaymentDto', 'PlayerPaymentDto', true);

CardPaymentDto = function(id) {
  this._CLASS = 'CardPaymentDto'
  this.id = id;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.payments.dto.CardPaymentDto', 'CardPaymentDto', true);

EWalletDto = function(id) {
  this._CLASS = 'EWalletDto'
  this.id = id;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.payments.dto.EWalletDto', 'EWalletDto', true);

DepositRequestForm = function() {
  this._CLASS = 'DepositRequestForm';
  this.languageId = localStorage.getItem("language");
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.payments.dto.DepositRequestForm', 'DepositRequestForm', true);

DirectDebitRequestForm = function() {
  this._CLASS = 'DirectDebitRequestForm';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.payments.dto.DirectDebitRequestForm', 'DirectDebitRequestForm', true);

SearchRequestDto = function(page, maxResults, request) {
  this._CLASS = 'SearchRequestDto';
  this.page = page;
  this.maxResults = maxResults;
  this.request = request;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.base.SearchRequestDto', 'SearchRequestDto', true);

GameHistorySearchDto = function(playerId, languageId, gameEndedMin, gameEndedMax) {
  this._CLASS = 'GameHistorySearchDto';
  this.playerId = playerId;
  this.languageId = languageId;
  this.gameEndedMin = gameEndedMin;
  this.gameEndedMax = gameEndedMax;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.GameHistorySearchDto', 'GameHistorySearchDto', true);


GameFilterDto = function(memberId, languageId) {
  this._CLASS = 'GameFilterDto';
  this.free = -1;
  this.tournament = -1;
  this.progressive = -1;
  this.empty = -1;
  this.instaplay = -1;
  this.playCountMin = null;
  this.playCountMax = null;
  this.playSizeMin = null;
  this.playSizeMax = null;
  this.playCostMin = null;
  this.playCostMax = null;
  this.drawRangeMin = null;
  this.drawRangeMax = null;
  this.gameId = null;
  this.memberId = memberId;
  this.currencyId = null;
  this.languageId = languageId;
  this.tournamentType = null;
  this.firstResult = null;
  this.maxResults = null;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.actions.GameFilterDto', 'GameFilterDto', true);

InitRequestDto = function(filter) {
  this._CLASS = 'InitRequestDto';
  this.filter = filter;
  this.clientInformation = null;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.init.InitRequestDto', 'InitRequestDto', true);

SyncResponseDto = function(clientInformationUpdated, serverTimeMilis) {
  this._CLASS = 'SyncResponseDto';
  this.clientInformationUpdated = clientInformationUpdated;
  this.serverTimeMilis = serverTimeMilis;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.sync.SyncResponseDto', 'SyncResponseDto', true);

SystemCurrencyDto = function(id, title, symbolLeft, symbolRight, decimalPoint, thousandsPoint, decimalPlaces, value) {
  this._CLASS = 'SystemCurrencyDto';
  this.id = id;
  this.title = title;
  this.symbolLeft = symbolLeft;
  this.symbolRight = symbolRight;
  this.decimalPoint = decimalPoint;
  this.thousandsPoint = thousandsPoint;
  this.decimalPlaces = decimalPlaces;
  this.value = value;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.system.SystemCurrencyDto', 'SystemCurrencyDto', true);

PlayerDto = function(id, username) {
  this._CLASS = 'PlayerDto';
  this.id = id;
  this.username = username;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.player.PlayerDto', 'PlayerDto', true);

InitResponseDto = function(synResponose, currencies, player, playerAccounts, afterLoginEvents, filteredGames) {
  this._CLASS = 'InitResponseDto';
  this.synResponose = synResponose;
  this.currencies = currencies;
  this.player = player;
  this.playerAccounts = playerAccounts;
  this.afterLoginEvents = afterLoginEvents;
  this.filteredGames = filteredGames;
}

JoinGameRequest = function(gameId, gameInstanceId, userPlays, userPlaysCount, allowJoinNewGame, currencyId, subscriptions) {
  this._CLASS = 'JoinGameRequest';
  this.gameId = gameId;
  this.gameInstanceId = gameInstanceId;
  this.userPlays = userPlays;
  this.userPlaysCount = userPlaysCount;
  this.currencyId = currencyId;
  this.subscriptions = subscriptions;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.actions.JoinGameRequest', 'JoinGameRequest', true);

JoinGameResponse = function(gameInstanceId, gameStatus, exitCode, exitDescription, subscriptionCode, joinEvent) {
  this._CLASS = 'JoinGameResponse';
  this.gameInstanceId = gameInstanceId;
  this.gameStatus = gameStatus;
  this.exitCode = exitCode;
  this.exitDescription = exitDescription;
  this.subscriptionCode = subscriptionCode;
  this.joinEvent = joinEvent;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.actions.JoinGameResponse', 'JoinGameResponse', true);

GameDto = function(bonoboPayout) {
  this._CLASS = 'GameDto';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.GameDto', 'GameDto', true);

GameLobbyDto = function(bonoboPayout) {
  this._CLASS = 'GameLobbyDto';
  this.gameBrandingInfo = {source : []};
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.GameLobbyDto', 'GameLobbyDto', true);

GameDetailDto = function(bonoboPayout) {
  this._CLASS = 'GameDetailDto';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.desktop.vo.game.GameDetailDto', 'GameDetailDto', true);

GameSessionPayout = function(bonoboPayout) {
  this._CLASS = 'GameSessionPayout';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.common.cache.game.GameSessionPayout', 'GameSessionPayout', true);

GameSessionPlayer = function(bonoboPayout) {
  this._CLASS = 'GameSessionPlayer';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.common.cache.game.GameSessionPlayer', 'GameSessionPlayer', true);

GameSessionPlayerPlay = function(bonoboPayout) {
  this._CLASS = 'GameSessionPlayerPlay';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.common.cache.game.GameSessionPlayerPlay', 'GameSessionPlayerPlay', true);

PlayResult = function() {
  this._CLASS = 'PlayResult';
  this.aggregate = false;
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.common.cache.game.results.PlayResult', 'PlayResult', true);

PendingForm = function() {
  this._CLASS = 'PendingForm';
}
flex.messaging.io.ClassAliasRegistry.registerAlias('bonobo.web.web.vo.PendingForm', 'PendingForm', true);

var print = function(o){
  var str='';

  for(var p in o){
    if(typeof o[p] == 'string'){
      str+= p + ': ' + o[p]+'; \n';
    }else{
      str+= p + ': { \n' + print(o[p]) + '}';
    }
  }

  return str;
}
